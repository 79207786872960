import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { AiFillDelete, AiOutlineClose } from "react-icons/ai";

import { FiEdit } from "react-icons/fi";
import API from "../../Api";
import MyBuyingOffers from "./MyBuyingOffers";
import EditBuyingAds from "./EditBuyingAds";
import * as Colors from '../../Constant/Colors'



function MyByingsAds(props) {
  const { open, close, set } = props;
  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id, country, symbol } = user_Data.data;
  const [loader, setLoader] = useState(false);
  const [openMyBuyingOfferMod, setOpenMyBuyingOfferMod] = useState(false);
  const [openMyEditBuyingMod, setOpenMyEditBuyingMod] = useState(false);
  const [openDeletMod, setOpenDeletMod] = useState(false);
  const [data, setData] = useState({});
  const [apiValueBuy, setApiValueBuy] = useState([]);
  const [apiValueOffers, setApiValueOffers] = useState([]);
  const [deletId, setDeletId] = useState("");
  const [date, setDate] = useState(0);
  const [offersStatus0, setOffersStatus0] = useState([]);
  const [offersStatus1, setOffersStatus1] = useState([]);
  const [offersStatus0Lits, setOffersStatus0Lits] = useState([]);
  const [offersStatus1List, setOffersStatus1List] = useState([]);
  const [offersList, setOffersList] = useState(0);
  const [percentage, setPercentage] = useState('');


  const MyBuyAdsOfferModOpen = async (props) => {

    setOffersList(1)
    close();
    try {
      const response = await API().getMyAddsOffers(props, country);
      setApiValueOffers(response.offers);
      let ite0 = []
      let ite1 = []
      setOpenMyBuyingOfferMod(true);
      response.offers.map((item, i) => {
        if (item.seller_status == 1) {

          ite1.push(item)
          setOffersStatus1List(ite1)

        } else {

          ite0.push(item)
          if (i >= response.offers.length - 1) {


            setOffersStatus0Lits(ite0)
            // setOpenMyBuyingOfferMod(true);
          }

        }
      })


    } catch (err) {
      console.log(err, "offersErr");
    }
  };
  const MyBuyAdsOfferModClose = () => {
    setOpenMyBuyingOfferMod(false);
    set(true);
  };
  const MyEditBuyAdsModOpen = (props) => {

    setDate(1);
    setData(props);
    setOpenMyEditBuyingMod(true);
  };
  const MyEditBuyAdsModClose = () => {
    setOpenMyEditBuyingMod(false);
    setDate(0);
  };

  const ConfirmDeleteModOpen = (id) => {

    setOpenDeletMod(true);
    setDeletId(id);
  };
  const ConfirmDeleteModClose = () => {
    setOpenDeletMod(false);
  };
  const DeleteAdd = async () => {
    try {
      const deletData = await API().getDeleteAds(deletId);

      ConfirmDeleteModClose();
      await getdata();
      // setIsLoding(false);
    } catch (err) {
      console.log(err, "gerdelet");
    }
  };
  const getdata = async () => {
    try {
      const MyGetAdds = await API().mygetadds(country, id);
      const { buying_ads } = MyGetAdds;
      // let myBuyinadd = []
      setApiValueBuy(buying_ads)
      const Percentage = await API().getPercentage();
      setPercentage(Percentage.data.percentage)


    } catch (error) {
      console.log(error, "errorMyBuyAdds");
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <Modal open={open} onClose={close}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div className="modal-header">
              <h2>My Buying Ads</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                {apiValueBuy.length > 0 ? (
                  <>
                    {apiValueBuy.map((item) => {
                      return (
                        <div
                          key={item.ad_id}
                          className=" row col-10 mt-2 rounded shadow-sm"
                        >
                          <div className="col-9 col-lg-10    ">
                            <h5 className="mt-2">
                              <span className="text-success">{item.price}</span>{" "}
                              {`${symbol} / ${item.symbol}`}
                            </h5>

                            <h5 style={{ color: "gray" }}>
                              Maximum:{" "}
                              <span>{`${item.maximum_crypto_amount} ${item.symbol}`}</span>
                            </h5>

                            <h5 style={{ color: "gray" }}>{item.bank_name}</h5>
                            <h5 style={{ color: "gray" }}>{item.first_name}</h5>
                            <button
                              onClick={() => MyBuyAdsOfferModOpen(item.ad_id)}
                              type="button"
                              class="btn mb-2 btn-outline-primary"
                              style={{ color: Colors.darkBlue }}
                            >
                              Show Offers {item.seller_status == 1 ? "(1)" :
                                `(${item.offers_count})`
                              }
                            </button>
                          </div>
                          <div className="col-3 col-lg-2 ">
                            <FiEdit
                              onClick={() => MyEditBuyAdsModOpen(item)}
                              size={22}
                              color="rgb(147, 147, 147)"
                              style={{ marginLeft: 20, marginTop: 20 }}
                            />
                            <AiFillDelete
                              onClick={() => ConfirmDeleteModOpen(item.ad_id)}
                              size={25}
                              color="rgb(147, 147, 147)"
                              style={{ marginLeft: 20, marginTop: 50 }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      height: 200,
                      borderRadius: 20,
                      border: "2px solid rgb(215, 215, 215)",
                    }}
                    className=" row col-10 mt-2 align-items-center shadow-sm"
                  >
                    <h2 className="text-center" style={{ color: "gray" }}>
                      {" "}
                      No Ads Yet
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/*############################### 
        #### My Buyings Ads Offers #### 
        ###############################*/}
      {offersList == 1 ?
        <MyBuyingOffers
          offers={apiValueOffers}
          open={openMyBuyingOfferMod}
          close={setOpenMyBuyingOfferMod}
          user_Data={user_Data}
          offersStatus1List={offersStatus1List}
          offersStatus0Lits={offersStatus0Lits}
          percentage={percentage}
        />
        : <></>}
      {/*################################## 
        ######## Edit MyBuying Ads ######## 
        ##################################*/}
      {date == 1 ? (
        <EditBuyingAds
          open={openMyEditBuyingMod}
          close={MyEditBuyAdsModClose}
          country={country}
          user_Symbol={symbol}
          Data={data}
          getdata={getdata}
        />
      ) : (
        <></>
      )}

      {/*################################## 
        ####### Delete MyBuying Ads ####### 
        ##################################*/}
      <Modal open={openDeletMod}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div className="modal-body">
              <div className="col-12 row justify-content-center">
                <h5 className="text-center">Are you sure you want to Delete</h5>
                <div className=" col-6 mt-2 d-flex justify-content-between">
                  <button
                    onClick={() => ConfirmDeleteModClose()}
                    style={{ fontSize: 20, borderRadius: 20 }}
                    type="button"
                    className="btn mb-2 btn-outline-secondary"
                  >
                    Close
                  </button>
                  <button
                    onClick={DeleteAdd}
                    style={{ fontSize: 20, borderRadius: 20 }}
                    type="button"
                    className="btn mb-2 btn-outline-secondary"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MyByingsAds;
