import React, { useState, useEffect } from "react";
// import API from "../../Api";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { FiSend, FiPaperclip, FiCameraOff } from "react-icons/fi";
import profile from '../../Images/image1.jpg';
import OtpInput from "react-otp-input";
import API from "../../Api";

import * as Colors from '../../Constant/Colors'
import BankDetailsMod from "./BankDetailsMod";
// import io from 'socket.io-client';

// var socket = io('https://f557-162-241-121-1.ngrok.io');
function MyBuyingOffers(props) {
  const { open, close, offers, user_Data, offersStatus0Lits, offersStatus1List, percentage } = props;
  const { email, id } = user_Data.data
  const [addId, setAddId] = useState("");
  const [bidUserId, setBidUserId] = useState("")
  const [isLoader, setIsLoader] = useState(false)
  const [bankDetaiMod, setBankDetaiMod] = useState(false)
  const [bankDetailApi, setBankDetailApi] = useState('');
  const [data, setData] = useState(0);
  const [bidDetails, setBidDetails] = useState({});
  const [chatModal, setChatModal] = useState(false);

  const [message, setMessage] = useState('');
  const [resMessage, setResMessage] = useState([]);

  var getMessages = resMessage

  const [otpMod, setOtpMod] = useState(false);
  // const [offersStatus0Lits, setoffersStatus0Lits] = useState([]);
  // const [offersStatus1List, setoffersStatus1List] = useState([]);



  const MyBuyingOfferModClose = () => {
    close(false);
    // set(true);
  };

  const SallerBankDetails = async (data) => {

    setBidUserId(data.bid_user_id)
    setBidDetails(data)
    setAddId(data.ad_id)
    try {
      const resDetails = await API().getAcceptSellingOfferDetail(
        data.my_crpto_id,
        data.bid_user_id,
        data.quantity,
        data.ad_id,
        id
      )
      if (resDetails.status == true) {
        setBankDetailApi(resDetails)
        setBankDetaiMod(true)

        MyBuyingOfferModClose()
        setData(1)
      } else {
        alert(`Saller have No ${data.crpto_symbol}`)
        MyBuyingOfferModClose()
      }



    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }

  const AcceptOffer = async (data) => {


    try {
      const res = await API().getBuyerAcceptStatus(data.bid_id)

      if (res.status == true) {
        alert('Accepted')
        close()
      }
    } catch (err) { console.log(err, 'errAceptbuingoffer') }
  }

  const chatModOpen = () => {
    setChatModal(true)

  }
  const chatModClose = () => {
    setChatModal(false)
  }

  const chat = async () => {

    try {


      // const resMessage = await API().postMessage(1)

      if (offers[0].trasaction_id >= 1) {
        // setResMessage(resMessage.data)



        // socket.emit("user_join", { "t_id": offers[0].trasaction_id, "u_id": id })


        // socket.on("user_joined", (data) => {
        //   // alert(JSON.stringify(data))
        // })


        // socket.on("message", (data) => {
        //   getMessages.push(data)

        //   // alert(JSON.parse(data))
        // })
      }
      setIsLoader(false)
    } catch (err) { console.log(err, 'chat') }
  }
  const SendMasseg = () => {
    // socket.emit("text", { "msg": message, "t_id": offers[0].trasaction_id, "u_id": id })
    // alert('aaaa')
    setMessage('')
  }

  useEffect(() => {
    // chat()


  }, [getMessages])




  if (isLoader) {
    return (

      <div
        style={{ backgroundColor: 'gray' }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
      </div>

    );
  } else {
    return (
      <div>

        <Modal open={open} onClose={MyBuyingOfferModClose}>
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div className="modal-header">
                <h2>Offers List</h2>
                <AiOutlineClose
                  onClick={MyBuyingOfferModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">
                  {offers.length >= 1 ?
                    <>
                      {offersStatus1List.length >= 1 ?
                        <>
                          {offersStatus1List.map((item) => {
                            return (
                              <div
                                key={item.id}
                                style={{
                                  borderRadius: 20,
                                  border: "2px solid rgb(215, 215, 215)",
                                }}
                                className=" row col-10 mt-2 align-items-center shadow-sm"
                              >
                                <h3 className="text-center mt-2 mb-3">
                                  Offers Details
                                </h3>

                                <h5 className="col-5">Name:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {item.bid_user_name}
                                </h5>
                                <h5 className="col-5"> Quantity of :</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.maximum_crypto_amount} ${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Offer Amount:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.bid_amount} ${item.bid_user}`}
                                </h5>
                                <h3 className="text-center mt-2 mb-3">My Details</h3>
                                <h5 className="col-5">Amount Limit:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.minimum_crypto_amount}${item.crpto_symbol} to ${item.maximum_crypto_amount}${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Total Price:</h5>
                                <h5 className="col-7 " style={{ color: "gray" }}>
                                  {`${item.price} ${item.ad_user}`}
                                </h5>

                                <div className="">
                                  {item.seller_status == 0 ?
                                    <>
                                      {item.buyer_status == 0 ?

                                        <div className="d-flex justify-content-between align-items-center">
                                          <Button
                                            onClick={() => AcceptOffer(item)}
                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: Colors.darkBlue,
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Accept Offer
                                          </Button>

                                          <Button

                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: Colors.darkBlue,
                                              width: '40%'
                                            }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={chatModOpen}
                                          >
                                            Chatskk
                                          </Button>
                                        </div>
                                        :
                                        <div className="d-flex justify-content-between align-items-center">
                                          <Button

                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: 'rgb(119, 112, 173)',
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Waiting...
                                          </Button>
                                          <Button

                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: Colors.darkBlue,
                                              width: '40%'
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Chat
                                          </Button>
                                        </div>
                                      }
                                    </>
                                    :
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Button
                                        onClick={() => SallerBankDetails(item)}
                                        style={{
                                          fontSize: 15,
                                          marginBottom: "10px",
                                          borderRadius: "20px",
                                          marginTop: "5px",
                                          backgroundColor: Colors.darkBlue,
                                        }}
                                        variant="contained"
                                        color="secondary"

                                      >
                                        Bank Tranfer
                                      </Button>
                                      <>
                                        {chatModal ?
                                          <Button

                                            style={{
                                              fontSize: 15,
                                              // marginBottom: "10px",
                                              borderRadius: "20px",


                                              backgroundColor: Colors.darkBlue,

                                            }}
                                            className="col-lg-5"
                                            variant="contained"
                                            color="secondary"
                                            onClick={chatModClose}
                                          >
                                            close
                                          </Button>
                                          :
                                          <Button

                                            style={{
                                              fontSize: 15,
                                              // marginBottom: "10px",
                                              borderRadius: "20px",
                                              // marginTop: "10px",
                                              // height: '5%',
                                              width: '40%',
                                              backgroundColor: Colors.darkBlue,

                                            }}
                                            className="col-lg-5"
                                            variant="contained"
                                            color="secondary"
                                            onClick={chatModOpen}
                                          >
                                            Chat
                                          </Button>
                                        }
                                      </>
                                    </div>
                                  }
                                </div>

                                {/* ########### chat ############### */}


                                {/* ########### chat ############### */}
                              </div>
                            );
                          })}
                        </> : <>

                          {offersStatus0Lits.map((item) => {
                            return (
                              <div
                                key={item.id}
                                style={{
                                  borderRadius: 20,
                                  border: "2px solid rgb(215, 215, 215)",
                                }}
                                className=" row col-10 mt-2 align-items-center shadow-sm"
                              >
                                <h3 className="text-center mt-2 mb-3">
                                  Offers Details
                                </h3>

                                <h5 className="col-5">Name:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {item.bid_user_name}
                                </h5>
                                <h5 className="col-5">  Quantity of :</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.maximum_crypto_amount} ${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Offer Amount:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.bid_amount} ${item.bid_user}`}
                                </h5>
                                <h3 className="text-center mt-2 mb-3">My Details</h3>
                                <h5 className="col-5">Crypto {/* Amount Limit */}:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {item.minimum_crypto_amount}{' '}{item.crpto_symbol}
                                  {/* {`${item.minimum_crypto_amount}${item.crpto_symbol} to ${item.maximum_crypto_amount}${item.crpto_symbol}`} */}
                                </h5>
                                <h5 className="col-5">Total Price:</h5>
                                <h5 className="col-7 " style={{ color: "gray" }}>
                                  {`${item.price} ${item.ad_user}`}
                                </h5>

                                <div className="">
                                  {item.seller_status == 0 ?
                                    <>
                                      {item.buyer_status == 0 ?


                                        <Button
                                          onClick={() => AcceptOffer(item)}
                                          style={{
                                            fontSize: 15,
                                            marginBottom: "10px",
                                            borderRadius: "20px",
                                            marginTop: "5px",
                                            backgroundColor: Colors.darkBlue,
                                          }}
                                          variant="contained"
                                          color="secondary"

                                        >
                                          Accept Offer
                                        </Button>


                                        :
                                        <div className="d-flex justify-content-between align-items-center">
                                          <Button

                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: 'rgb(119, 112, 173)',
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Waiting...
                                          </Button>
                                          {/* <Button

                                          style={{
                                            fontSize: 15,
                                            marginBottom: "10px",
                                            borderRadius: "20px",
                                            marginTop: "5px",
                                            backgroundColor: Colors.darkBlue,
                                            width: '40%'
                                          }}
                                          variant="contained"
                                          color="secondary"

                                        >
                                          Chat
                                        </Button> */}
                                        </div>
                                      }
                                    </>
                                    :
                                    <div className="d-flex justify-content-between align-items-center">
                                      <Button
                                        onClick={() => SallerBankDetails(item)}
                                        style={{
                                          fontSize: 15,
                                          marginBottom: "10px",
                                          borderRadius: "20px",
                                          marginTop: "5px",
                                          backgroundColor: Colors.darkBlue,
                                        }}
                                        variant="contained"
                                        color="secondary"

                                      >
                                        Bank Tranfer
                                      </Button>
                                      <Button

                                        style={{
                                          fontSize: 15,
                                          marginBottom: "10px",
                                          borderRadius: "20px",
                                          marginTop: "5px",
                                          backgroundColor: Colors.darkBlue,
                                          width: '40%'
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        onClick={chatModOpen}
                                      >
                                        Chat
                                      </Button>
                                    </div>
                                  }
                                </div>

                                {/* ########### chat ############### */}


                                {/* ########### chat ############### */}
                              </div>
                            );
                          })}

                        </>}
                    </>
                    :

                    <div
                      style={{
                        height: 200,
                        borderRadius: 20,
                        border: "2px solid rgb(215, 215, 215)",
                      }}
                      className=" row col-10 mt-2 align-items-center shadow-sm"
                    >
                      <h2 className="text-center" style={{ color: "gray" }}>
                        {" "}
                        No Offers Yet
                      </h2>
                    </div>
                  }
                </div>
                {chatModal ?

                  <div style={{
                    // borderRadius: 20,
                    border: "2px solid rgb(215, 215, 215)", marginLeft: '8%', marginTop: '2%', padding: 10,
                    width: '85%',
                    // paddingBottom: 10
                  }} className="shadow-sm ">
                    <div style={{ maxHeight: 250, paddingBottom: 10 }} className="   overflow-scroll  ">

                      {/* msg */}
                      <div style={{ maxWidth: 800, padding: 5 }} className="border col-lg-8 col-sm-10 rounded d-flex justify-content-between">

                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                          {/* {image == null ?
        <FiCameraOff color="gray" size={20} />
          : */}
                          <img
                            src={profile}
                            class="img-thumbnail rounded-circle "
                            alt="Profile"
                            style={{ height: "40px", width: "40px" }}
                          />
                          {/* } */}
                        </div>
                        <div style={{ width: 210 }} className="">
                          <h6 style={{ marginTop: 1, color: 'black' }} className="">hassan ali</h6>
                          <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">for testing</p>
                        </div>
                      </div>
                      {/* msg */}
                      {/* msg */}
                      <div className=" d-flex justify-content-end">
                        <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                          <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                            {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                            <img
                              src={profile}
                              class="img-thumbnail rounded-circle "
                              alt="Profile"
                              style={{ height: "40px", width: "40px" }}
                            />
                            {/* } */}
                          </div>
                          <div style={{ width: 210 }} className="">
                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                              testing massage</p>
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-end">
                        <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                          <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                            {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                            <img
                              src={profile}
                              class="img-thumbnail rounded-circle "
                              alt="Profile"
                              style={{ height: "40px", width: "40px" }}
                            />
                            {/* } */}
                          </div>
                          <div style={{ width: 210 }} className="">
                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                              testing massage</p>
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-end">
                        <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                          <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                            {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                            <img
                              src={profile}
                              class="img-thumbnail rounded-circle "
                              alt="Profile"
                              style={{ height: "40px", width: "40px" }}
                            />
                            {/* } */}
                          </div>
                          <div style={{ width: 210 }} className="">
                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                              testing massage</p>
                          </div>
                        </div>
                      </div>
                      {/* msg */}

                    </div>
                    <div style={{
                      width: "100%",
                      height: "45px",
                      backgroundColor: "rgb(255, 255, 255)",
                      padding: 10,

                      border: "1px solid gray",
                      borderRadius: 50,
                    }} className="d-flex align-items-center">
                      <input placeholder="text" className="form-control border-0 shadow-none " />

                      <FiPaperclip style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                      <FiSend style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                    </div>
                  </div>
                  : <></>}
              </div>

            </div>
          </div>
        </Modal>

        {/* ###########################
      ########## OTP Modal ##########
      ##########################* */}
        {/* <Modal open={otpMod}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Code Sended on Email</h2>
              <AiOutlineClose
                onClick={OtpModClose}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">




                <OtpInput
                  value={OTPValue}
                  onChange={(e) => setOTPValue(e)}
                  numInputs={4}
                  containerStyle={{
                    marginTop: "20px",
                    // marginLeft: "20px",
                    width: "300px",
                  }}
                  isInputNum={true}
                  inputStyle={{
                    height: "60px",
                    width: "60px",
                    marginLeft: "12px",
                  }}
                  separator={<span>{""}</span>}
                />
                <div className="col-6">
                  {OTPLoader ? (
                    <div className="d-flex mt-1 justify-content-center align-items-center">
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <p className="text-center text-danger">{OTPres}</p>
                  )}
                </div>
                <button
                  onClick={OTPSend}
                  style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                  type="button"
                  className="btn btn-primary  mt-3"
                >

                  Send
                </button>

              </div>
            </div>
          </div>
        </div>
      </Modal> */}



        {/* ###########################
      ###### Bank Details Mod #######
      ##########################* */}
        {data == 1 ?
          <BankDetailsMod
            userId={id}
            addId={addId}
            bidDetails={bidDetails}
            bidUserId={bidUserId}
            bankDetaiMod={bankDetaiMod}
            setBankDetaiModClose={setBankDetaiMod}
            bankDetailApi={bankDetailApi}
            setData={setData}
          />
          : <></>}
      </div>
    );
  }
}

export default MyBuyingOffers;
