import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../reducer/Context";
import { useHistory } from "react-router-dom";
import API from "../Api";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import OtpInput from "react-otp-input";
import ls from "localstorage-slim";
import * as Colors from "../Constant/Colors";
import backgrounimage from '../Images/geometric-1732847.jpeg'
import { BsEyeSlash, BsEye } from "react-icons/bs";

function Login() {
  const { new_Data, setNew_Data } = useContext(UserContext);

  const [UserData, setUserData] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState("");
  const [twoFectorAuthenMod, setTwoFectorAuthenMod] = useState(false);
  const [twoFectorAuthenCode, setTwoFectorAuthenCode] = useState('');
  const [twoFectorAuthenCodeRes, setTwoFectorAuthenCodeRes] = useState('');
  const [twoFectorAuthenCodeLoader, setTwoFectorAuthenCodeLoader] = useState(false);
  const [forgotPassMod, setForgotPassMod] = useState(false);
  const [changePassMod, setChangePassMod] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotEmailApiRes, setForgotEmailApiRes] = useState({
    status: "",
    loading: false,
  });
  const [passwordApiRes, setPasswordApiRes] = useState({
    status: "",
    loading: false,
  });
  const [OTPEmailForgot, setOTPEmailForgot] = useState("");
  const [OTPForgotEmailApiRes, setOTPForgotEmailApiRes] = useState({
    status: "",
    loading: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [visiblepass, Setvisiblepass] = useState({
    Type: "password",
    iconName: BsEyeSlash,
  });
  const [conVisiblepass, SetConVisiblepass] = useState({
    Type: "password",
    iconName: BsEyeSlash,
  });
  const history = useHistory();

  function SingUp() {
    history.push("/signup");
  }
  function RouteNavBar() {
    history.replace("/Home");
  }
  const ForgotPassModOpen = () => {
    setForgotPassMod(true);
  };
  const ForgotPassModClose = () => {
    setForgotPassMod(false);
    setForgotEmail("");
    setOTPEmailForgot("");
    setForgotEmailApiRes({ status: "" });
    setOTPForgotEmailApiRes({
      status: "",
    });
  };

  const ChangePassModClose = () => {
    setChangePassMod(false);
    setConfirmPassword("");
    setNewPassword("");
    setForgotEmail("");
  };

  const ForgotEmailSend = async () => {
    try {
      setForgotEmailApiRes({ loading: true });

      const response = await API().postForgotPassword(forgotEmail);
      console.log(response, 'ssssss')
      setForgotEmailApiRes({ loading: false, status: response.data.message });
    } catch (err) {
      console.log(err, "forgotpass");
    }
  };
  const OTPForgotEmailSend = async () => {
    try {
      setOTPForgotEmailApiRes({ loading: true });

      const response = await API().postPasswordCodeVerfiy(
        forgotEmail,
        OTPEmailForgot
      );

      setOTPForgotEmailApiRes({
        loading: false,
        status: response.data.message,
      });
      if (response.data.message === "Verified") {
        ForgotPassModClose();
        setChangePassMod(true);
      }
    } catch (err) {
      console.log(err, "forgotpass");
    }
  };
  const UbdatePassword = async () => {
    if (newPassword.length > 4 && confirmPassword.length > 4) {
      try {
        setPasswordApiRes({ loading: true });

        const response = await API().postUpdatePasswordForgoted(
          forgotEmail,
          newPassword,
          confirmPassword
        );

        setPasswordApiRes({
          loading: false,
          status: response.data.message,
        });
        if (response.data.message === "Password Updated") {
          ChangePassModClose();
        }
      } catch (err) {
        console.log(err, "forgotpass");
      }
    }
  };

  const CheckUserLogin = async () => {
    const userData = ls.get("Cradentials", { decrypt: true });
    // console.log('........................')
    // console.log('ssssssssssssssssssssssssssssssssssssssssssss')
    if (userData != null) {
      const responce = await API().signin(
        userData.userEmail,
        userData.userPassord
      );

      if (responce.data.status == true) {
        let data = JSON.stringify(responce.data);
        localStorage.setItem("userData", data);


        setNew_Data(responce.data);

        const userId = responce.data.data.id;
        // if (responce.data.data.user_verifyed === true) {
        history.replace("/Home");
        setIsLoading(false);
        // } else {
        //   history.push({
        //     pathname: "/Verification",
        //     state: { detail: userId },
        //   });
        // }
      }
    } else {
      setIsLoading(false);
      // setValidation('email or password invalid');
    }
  };
  useEffect(() => {
    CheckUserLogin();
  }, []);

  const userLogin = async () => {

    setLoginLoading(true);
    const userData = { userEmail: email, userPassord: password };
    if (email.length <= 0 || password.length <= 0) {
      setValidation("email or password invalid");
      setLoginLoading(false);
    } else {
      const responce = await API().signin(email, password);
      console.log(responce.data, 'responce.data.............................................!!!!!!!!')


      // console.log('reslsss')
      if (responce.data.status === true) {

        if (responce.data.data.auth_enable == 0) {

          ls.set("Cradentials", userData, { encrypt: true });

          localStorage.setItem('user_verifyed', responce.data.data.user_verifyed)
          let data = JSON.stringify(responce.data);

          localStorage.setItem("userData", data);
          setNew_Data(responce.data);

          const userId = responce.data.data.id;

          if (responce.data.data.type == 'admin') {
            ls.remove("Cradentials");
            setValidation("email or password does not match");
            setLoginLoading(false)
          } else {

            RouteNavBar();

          }
        } else {

          setUserData(responce.data)
          setTwoFectorAuthenMod(true)
        }

        setLoginLoading(false);
      } else {
        // console.log(responce.data.data, 'responce.data.status')
        setLoginLoading(false);
        setValidation(responce.data.data);
      }
    }

  };

  const TwoFectorAuthenModClose = () => {
    setTwoFectorAuthenMod(false)
    setTwoFectorAuthenCodeLoader(false)
    setTwoFectorAuthenCodeRes('')
  }

  const TwillioCodeSend = async () => {

    if (twoFectorAuthenCode.length >= 4) {

      const userData = { userEmail: email, userPassord: password };

      setTwoFectorAuthenCodeLoader(true)
      console.log(email,
        twoFectorAuthenCode, 'emailtwoFectorAuthenCode>>>>>>>>>>>>>>>>>')
      try {

        const res = await API().postAuthyVerficationWithOutToken(
          email,
          twoFectorAuthenCode

        )

        console.log(res, 'emailtwoFectorAuffffffffffffffffffffffffffffffffffthenCode>>>>>>>>>>>>>>>>>')

        if (res.status) {

          ls.set("Cradentials", userData, { encrypt: true });

          localStorage.setItem('user_verifyed', UserData.data.user_verifyed)
          let data = JSON.stringify(UserData);

          localStorage.setItem("userData", data);
          setNew_Data(UserData);

          const userId = UserData.data.id;

          if (UserData.data.type == 'admin') {
            ls.remove("Cradentials");
            setValidation("email or password does not match");
            setLoginLoading(false)
          } else {

            RouteNavBar();
            TwoFectorAuthenModClose()

          }


        } else {
          setTwoFectorAuthenCodeRes(res.message)
        }
        setTwoFectorAuthenCodeLoader(false)
        // console.log(res, 'postAuthyVerfication>>>>>>>>>>>>>>>>>')
      } catch (error) {
        console.log(error, 'errorTwillioCodeSend')
      }
    } else {
      setTwoFectorAuthenCodeRes('Please Enter Code')
    }
  }


  if (isLoading) {
    return (
      <div
        style={{ width: "100vw", height: "100vh", backgroundColor: Colors.bodyCol, }}
        className="d-flex justify-content-center border align-items-center"
      >
        <div className="spinner-border text-secondary" role="status"></div>
      </div>
    );
  } else {
    return (
      <div style={{ height: '100vh', backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }}>
        <div style={{}} className="col-12 col-lg-4 col-lg-4 mx-auto ">
          <div className="d-flex justify-content-center ">
            <h1 style={{ marginTop: 30, fontSize: "3.2rem", color: Colors.lightBlue }}>
              Folo
            </h1>
            <h1 style={{ marginTop: 50, fontSize: "2rem", color: Colors.darkBlue }}>Swap</h1>
          </div>
          <div className="  mt-2 pt-3 pb-3" >
            <h4 style={{ color: 'white' }} className="text-center">
              Login to FoloSwap
            </h4>
            <div className="pt-3 pb-3" >
              <input
                value={email}
                className="form-control shadow-sm mx-auto"
                style={{
                  width: "90%",
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: "rgb(199, 199, 199)",
                }}
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div
                className=" mt-3 d-flex justify-content-between shadow-sm mx-auto "
                style={{
                  width: "90%",
                  height: "45px",
                  backgroundColor: 'rgb(232, 240, 254)',
                  paddingTop: 10,
                  paddingBottom: 10,
                  border: "1px solid rgb(199, 199, 199)",
                  borderRadius: 5,
                }}
              >
                <input
                  value={password}
                  className="form-control border-0 shadow-none  "
                  style={{
                    width: "85%",
                    paddingLeft: 20,
                  }}
                  type={visiblepass.Type}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <visiblepass.iconName
                  size={30}
                  style={{ marginRight: "5%" }}
                  onClick={() => {
                    visiblepass.Type === "password"
                      ? Setvisiblepass({
                        Type: "text",
                        iconName: BsEye,
                      })
                      : Setvisiblepass({
                        Type: "password",
                        iconName: BsEyeSlash,
                      });
                  }}
                />
              </div>
              {loginLoading ? (
                <div
                  style={{}}
                  className="d-flex justify-content-center mt-2"
                >
                  <div className="spinner-border  text-secondary" role="status">

                  </div>
                </div>
              ) : (


                <div className="text-center text-danger">{validation}</div>
              )}

              <button
                onClick={ForgotPassModOpen}
                style={{ fontSize: 15, marginLeft: "10px", color: 'white' }}
                type="button"
                className="btn  btn-outline  shadow-none"
              >
                Forgot Password?
              </button>
              <button
                style={{ width: "90%", fontSize: 20, marginLeft: "5%", backgroundColor: Colors.darkBlue, color: "white" }}
                type="button"
                className="btn btn-outline  "
                onClick={() => userLogin()}
              >
                Log In
              </button>

              <button
                onClick={() => SingUp()}
                style={{ width: "90%", fontSize: 20, marginLeft: "5%", backgroundColor: Colors.lightBlue, color: "white" }}
                type="button"
                className="btn  btn-outline  mt-2"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
        {/*###########################
         ### Forgot Password Modal ###
         ##########################*/}
        <Modal open={forgotPassMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Confirm Email</h2>
                <AiOutlineClose
                  onClick={ForgotPassModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">
                  <input
                    value={forgotEmail}
                    className="form-control shadow-sm "
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgb(199, 199, 199)",
                    }}
                    placeholder="Email Address"
                    onChange={(e) => setForgotEmail(e.target.value)}
                  />
                  {forgotEmailApiRes.loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <>{forgotEmailApiRes.status}</>
                  )}
                  <button
                    onClick={ForgotEmailSend}
                    style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn btn-primary  mt-3"
                  >
                    Send
                  </button>
                  {forgotEmailApiRes.status === "Code Sended on Email" ? (
                    <>
                      <OtpInput
                        value={OTPEmailForgot}
                        onChange={(e) => setOTPEmailForgot(e)}
                        numInputs={4}
                        containerStyle={{
                          marginTop: "20px",
                          // marginLeft: "20px",
                          width: "300px",
                        }}
                        isInputNum={true}
                        inputStyle={{
                          height: "60px",
                          width: "60px",
                          marginLeft: "12px",
                        }}
                        separator={<span>{""}</span>}
                      />
                      {OTPForgotEmailApiRes.loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          ></div>
                        </div>
                      ) : (
                        <>{OTPForgotEmailApiRes.status}</>
                      )}
                      <button
                        onClick={OTPForgotEmailSend}
                        style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                        type="button"
                        className="btn btn-primary  mt-3"
                      >
                        Send
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/*###########################
         ### Change Password Modal ###
         ##########################*/}
        <Modal open={changePassMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Change Password</h2>
                <AiOutlineClose
                  onClick={ChangePassModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">
                  {newPassword.length > 4 ? (
                    <div
                      className=" mt-3 d-flex justify-content-between shadow-sm mx-auto "
                      style={{
                        width: "90%",
                        height: "45px",

                        paddingTop: 10,
                        paddingBottom: 10,
                        border: "1px solid rgb(199, 199, 199)",
                        borderRadius: 5,
                      }}
                    >
                      <input
                        value={newPassword}
                        className="form-control border-0 shadow-none  "
                        style={{
                          width: "85%",
                          paddingLeft: 20,
                        }}
                        type={visiblepass.Type}
                        placeholder="New Password"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <visiblepass.iconName
                        size={30}
                        style={{ marginRight: "5%" }}
                        onClick={() => {
                          visiblepass.Type === "password"
                            ? Setvisiblepass({
                              Type: "text",
                              iconName: BsEye,
                            })
                            : Setvisiblepass({
                              Type: "password",
                              iconName: BsEyeSlash,
                            });
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className=" mt-3 d-flex justify-content-between shadow-sm mx-auto "
                      style={{
                        width: "90%",
                        height: "45px",

                        paddingTop: 10,
                        paddingBottom: 10,
                        border: "1px solid red",
                        borderRadius: 5,
                      }}
                    >
                      <input
                        value={newPassword}
                        className="form-control border-0 shadow-none  "
                        style={{
                          width: "85%",
                          paddingLeft: 20,
                        }}
                        type={visiblepass.Type}
                        placeholder="New Password"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <visiblepass.iconName
                        size={30}
                        style={{ marginRight: "5%", color: "red" }}
                        onClick={() => {
                          visiblepass.Type === "password"
                            ? Setvisiblepass({
                              Type: "text",
                              iconName: BsEye,
                            })
                            : Setvisiblepass({
                              Type: "password",
                              iconName: BsEyeSlash,
                            });
                        }}
                      />
                    </div>
                  )}
                  {confirmPassword.length > 4 ? (
                    <div
                      className=" mt-3 d-flex justify-content-between shadow-sm mx-auto "
                      style={{
                        width: "90%",
                        height: "45px",

                        paddingTop: 10,
                        paddingBottom: 10,
                        border: "1px solid rgb(199, 199, 199)",
                        borderRadius: 5,
                      }}
                    >
                      <input
                        value={confirmPassword}
                        className="form-control border-0 shadow-none  "
                        style={{
                          width: "85%",
                          paddingLeft: 20,
                        }}
                        type={conVisiblepass.Type}
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <conVisiblepass.iconName
                        size={30}
                        style={{ marginRight: "5%" }}
                        onClick={() => {
                          conVisiblepass.Type === "password"
                            ? SetConVisiblepass({
                              Type: "text",
                              iconName: BsEye,
                            })
                            : SetConVisiblepass({
                              Type: "password",
                              iconName: BsEyeSlash,
                            });
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className=" mt-3 d-flex justify-content-between shadow-sm mx-auto "
                      style={{
                        width: "90%",
                        height: "45px",

                        paddingTop: 10,
                        paddingBottom: 10,
                        border: "1px solid red",
                        borderRadius: 5,
                      }}
                    >
                      <input
                        value={confirmPassword}
                        className="form-control border-0 shadow-none  "
                        style={{
                          width: "85%",
                          paddingLeft: 20,
                        }}
                        type={conVisiblepass.Type}
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <conVisiblepass.iconName
                        size={30}
                        style={{ marginRight: "5%", color: "red" }}
                        onClick={() => {
                          conVisiblepass.Type === "password"
                            ? SetConVisiblepass({
                              Type: "text",
                              iconName: BsEye,
                            })
                            : SetConVisiblepass({
                              Type: "password",
                              iconName: BsEyeSlash,
                            });
                        }}
                      />
                    </div>
                  )}

                  {passwordApiRes.loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    <>{passwordApiRes.status}</>
                  )}
                  <button
                    onClick={UbdatePassword}
                    style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn btn-primary  mt-3"
                  >
                    Set Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/*###################################
         ### 2 fector Authentication Modal ###
         ##################################*/}
        <Modal open={twoFectorAuthenMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2> Enter Google Auth Code</h2>
                <AiOutlineClose
                  onClick={TwoFectorAuthenModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">

                <div
                  className=" mt-1 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: 'white',
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1px solid rgb(199, 199, 199)",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={twoFectorAuthenCode}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "100%",
                      paddingLeft: 20,
                    }}

                    placeholder="Enter Code"
                    onChange={(e) => setTwoFectorAuthenCode(e.target.value)}
                  />

                </div>
                {twoFectorAuthenCodeLoader ? <></> :
                  <h6 className="text-danger text-center mt-2">{twoFectorAuthenCodeRes}</h6>}

                <div className="mt-3 d-flex justify-content-center">
                  <button
                    style={{ width: "60%", fontSize: 20, backgroundColor: Colors.darkBlue, color: "white" }}
                    type="button"
                    className="btn btn-outline    "
                    onClick={TwillioCodeSend}
                  >
                    {twoFectorAuthenCodeLoader ?
                      <div className="spinner-border text-secondary" role="status"></div> : ' Verify'}

                  </button>
                </div>

              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Login;
