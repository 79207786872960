import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { FiCameraOff } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "@material-ui/core/Modal";
import EditProfile from "./EditProfile";
import API from "../../Api";
import ChangePassword from "./ChangePassword";
import MyBuyingBidsScreen from "./MyBuyingBids";
import MySellingBidsScreen from "./MySellingBids";
import MyByingAdsScreen from "./MyByingsAds";
import MySellingAds from "./MySellingAds";
import NavBar from "../NavBar";
import ls from "localstorage-slim";
import * as Colors from '../../Constant/Colors'
import { UserContext } from "../../reducer/Context";
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import CoustumAlert from '../../Constant/CoustumAlert'
import OtpInput from "react-otp-input";

function IndexSettings() {
  const { new_Data, setNew_Data } = useContext(UserContext);
  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id, user_verifyed, country, } = user_Data.data;

  // console.log(user_Data.data, 'user_Data.data>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  const history = useHistory();

  const [crypto, setCrypto] = useState('');
  const [scanOrNotMod, setScanOrNotMod] = useState(false);

  const [walletAddress, setWalletAddress] = useState('');
  const [isloading, setIsloading] = useState(true);

  const Verifide = user_verifyed;
  const [apiUserDetails, setApiUserDetails] = useState({});
  const {
    first_name,
    last_name,
    phone_number,
    country_code,
    email,
    image,
    address,
    pin_created,
    auth_enable, authy_number,
    has_google2fa_secret
  } = apiUserDetails;

  const [authyEmail, setAuthyEmail] = useState('');

  const [authyData, setAuthyData] = useState({});


  const [twoFectorAuthenMod, setTwoFectorAuthenMod] = useState(false);
  const [twoFectorAuthenCode, setTwoFectorAuthenCode] = useState('');
  const [twoFectorAuthenCodeRes, setTwoFectorAuthenCodeRes] = useState('');
  const [twoFectorAuthenCodeLoader, setTwoFectorAuthenCodeLoader] = useState(false);
  const [TFPassorPin, setTFPassorPin] = useState(0);

  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [otpMod, setOtpMod] = useState(false);
  const [OTPValue, setOTPValue] = useState("");
  const [OTPLoader, setOTPLoader] = useState(false);
  const [OTPres, setOTPres] = useState('');

  const [insertPinModal, setInsertPinModal] = useState(false);
  const [insertPinLoader, setInsertPinLoader] = useState(false);
  const [inputPin, setInputPin] = useState('');


  const [insertUpdatePinModal, setInsertUpdatePinModal] = useState(false);
  const [oldPin, setOldPin] = useState('');
  const [inputUpdatePin, setInputUpdatePin] = useState('');
  const [updatePinResMessage, setUpdatePinResMessage] = useState('');


  const [openMyBuyingMod, setOpenMyBuyingMod] = useState(false);

  const [openMyBuyingBids, setOpenMyBuyingBids] = useState(false);

  const [openMySellingMod, setOpenMySellingMod] = useState(false);

  const [openMySellingBids, setOpenMySellingBids] = useState(false);
  const [tranferCoinMod, setTranferCoinMod] = useState(false);

  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const [openEditProfileMod, setOpenEditProfileMod] = useState(false);
  const [openChangePasswordMod, setOpenChangePasswordMod] = useState(false);
  const [bankDetailsMod, setBankDetailsMod] = useState(false);
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankListApi, setBankListApi] = useState([]);
  const [cryptoCurrencyApi, setCryptoCurrencyApi] = useState([]);
  const [sellectedCryptoCurrency, setSellectedCryptoCurrency] = useState('');
  const [walletApi, setWalletApi] = useState('');
  const [walletID, setWalletID] = useState('');
  const [transferCoinBtn, setTransferCoinBtn] = useState(false);
  const [walletResloding, setWalletResloding] = useState(false);
  let BankList = JSON.stringify({
    id: '',
    country_id: '',
    bank_name: ''
  })
  const [selectedBank, setSelectedBank] = useState(BankList);

  const sellectedBank = JSON.parse(selectedBank);
  const [validation, setValidation] = useState({

    BankName: true,
    phNum: true,
    autEmail: true,


  });

  const validateEmail = (e) => /^[^@ ]+@[^@ ]+\.[^@ \.]+$/.test(e);
  const isValidEmail = function (value) {
    if (validateEmail(value)) {
      return true;
    } else {
      return false;
    }
  };
  const [authyModal, setAuthyModal] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(phone_number);
  const [authyNum, setAuthyNum] = useState('');
  const [authyNumLoader, setAuthyNumLoader] = useState(false);
  const [authEnable, setAuthEnable] = useState(false);




  const bankNameValid = function (ftn) {
    return function (ftn) {
      if (ftn.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };




  const EditProfileModOpen = () => {
    setOpenEditProfileMod(true);
  };

  const changePasswordModOpen = () => {
    setOpenChangePasswordMod(true);
  };

  const MyBuyModOpen = () => {
    setOpenMyBuyingMod(true);
  };

  const MyBuyingBids = () => {
    setOpenMyBuyingBids(true);
  };

  const MyBuyModClose = () => {
    setOpenMyBuyingMod(false);
  };

  const MyBuyingBidsClose = () => {
    setOpenMyBuyingBids(false);
  };

  const MySellModOpen = () => {
    setOpenMySellingMod(true);
  };
  const MySellModClose = () => {
    setOpenMySellingMod(false);
  };

  const MySellingBids = () => {
    setOpenMySellingBids(true);
  };

  const MySellingBidsClose = () => {
    setOpenMySellingBids(false);
  };
  const UpdateBank = async () => {
    var bankAccValid = bankNameValid("nm");
    var FtnameVal = bankAccValid(bankAccountNumber);
    setValidation({ BankName: FtnameVal })
    if (FtnameVal) {
      const res = await API().postBankDetailSave(
        id,
        sellectedBank.id,
        bankAccountNumber

      )
      if (res.status == true) {
        BankDetailsModClose()



      }
    }

  }
  const BankDetailsModOpen = () => {
    setBankDetailsMod(true);
  };
  const BankDetailsModClose = () => {
    setBankDetailsMod(false)
    setBankAccountNumber('')
    GetData();
  };
  const SelectedBankAccountNum = (e) => {
    setSelectedBank(e.target.value);
  };

  const InsertPinModalOpen = () => {
    setInsertPinModal(true)
  }
  const InsertPinModalClose = () => {
    setInsertPinModal(false)
    setInputPin('')
  }

  const UpdatePinModalClose = () => {
    setInsertUpdatePinModal(false)
    setOldPin('')
    setInputUpdatePin('')
    setUpdatePinResMessage('')
  }
  const UpdatePin = async () => {

    if (oldPin.length == 4 && inputUpdatePin.length == 4) {
      setInsertPinLoader(true)
      console.log(oldPin,
        inputUpdatePin, 'resUpdatePin>>>>>>>>>>>>>>>>>>>>>>>>')
      try {

        const res = await API().postUpdatePincode(
          id,
          oldPin,
          inputUpdatePin
        )
        console.log(res, 'resUpdatePin>>>>>>>>>>>>>>>>>>>>>>>>')
        if (res.status) {
          setAlertShow(true)
          setTimeout(() => {
            setAlertShow(false)


          }, 3000);
          GetData()
          setInsertUpdatePinModal(false)
          setOldPin('')
          setInputUpdatePin('')
          setAlertMessage('Pin Updated')
        } else {
          setUpdatePinResMessage(res.msg)
        }

        setInsertPinLoader(false)

      } catch (err) {
        console.log(err, 'errUpdatePin>>>>>>>>>>>>>>>>>>>>>>>>')

      }

    }

  }
  const IsPinCreated = async () => {

    if (inputPin.length == 4) {
      setInsertPinLoader(true)
      try {
        const res = await API().postPinCodeSave(
          id,
          inputPin
        )

        if (res.status) {
          setAlertShow(true)
          setTimeout(() => {
            setAlertShow(false)


          }, 3000);
          GetData()
          setInsertPinModal(false)
          setInputPin('')
          setAlertMessage('Pin Created')


        }
        setInsertPinLoader(false)
      } catch (err) {
        console.log(err, 'errpostPinCodeSave>>>>>>>>>>>>>>>>>>>>>>>>')
      }

    }
  }

  const TwoFectorAuthenModClose = () => {
    setTwoFectorAuthenMod(false)
    setTwoFectorAuthenCodeLoader(false)
    setTwoFectorAuthenCodeRes('')
    setTwoFectorAuthenCode('')
  }

  const TwillioCodeSend = async () => {

    if (twoFectorAuthenCode.length >= 4) {


      setTwoFectorAuthenCodeLoader(true)
      try {

        const res = await API().postAuthyVerfication(
          email,
          twoFectorAuthenCode

        )

        if (res.status) {
          if (TFPassorPin == 2) {

            setInsertPinModal(true)
            TwoFectorAuthenModClose()
          } else {
            setOpenChangePasswordMod(true);
            TwoFectorAuthenModClose()
          }
        } else {
          // setInsertPinModal(true)
          setTwoFectorAuthenCodeRes(res.message)

        }
        setTwoFectorAuthenCodeLoader(false)
      } catch (error) {
        console.log(error, 'errorTwillioCodeSend')
      }
    } else {
      setTwoFectorAuthenCodeRes('Please Enter Code')
    }
  }

  const OtpModOpen = async () => {

    try {

      const res = await API().postEmailConformationCode(id, email)
      if (res.status == true) {
        console.log(res)
        setOtpMod(true);

      }
    }
    catch (err) {
      console.log(err, 'erremailsend')
    }
  };

  const OtpModClose = () => {
    setOtpMod(false);
    setOTPValue('')
    setOTPres('')

  };

  const OTPSend = async () => {
    try {
      setOTPLoader(true)

      const res = await API().postTransectionVerfiyCode(id, OTPValue)
      if (res.status == true) {
        OtpModClose()
        setOTPres('')
        setOTPLoader(false)
        setInsertPinModal(true)


      } else {
        setOTPLoader(false)
        setOTPres('Invalid Pin Code')
      }

    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }
  //  ##########################
  //  #### LOGOUT MODAL FUN ####
  //  ##########################

  const OpenLogOutMod = () => {
    setOpenLogOutModal(true);
  };

  const CloseLogOutMod = () => {
    setOpenLogOutModal(false);
  };

  const TransferCoin = async () => {
    setTransferCoinBtn(true)

    try {
      const ress = await API().postTransferCrypto(
        walletID,
        parseFloat(crypto),
        walletAddress
      )

      if (ress.status) {

        setTransferCoinBtn(false)
        setTranferCoinMod(false)
        alert("Transefer Successfully")
      }

    } catch (err) { console.log(err, 'errrrfundtransection') }

  }

  const GetData = async () => {
    setIsloading(true)
    try {



      const respons = await API().getUserProfileDetail(id);

      // console.log(respons.user_detail, 'user_detail>>>>>>>>>>>>>>>>>>>>')

      setAuthyEmail(respons.user_detail.authy_email)

      if (respons.user_detail.authy_number > 0) {
        setAuthyNum(JSON.stringify(respons.user_detail.authy_number))
      } else {
        setAuthyNum(respons.user_detail.phone_number)
      }
      if (respons.user_detail.auth_enable > 0) {
        setAuthEnable(true)
      }
      const resBankList = await API().getbank(country)
      const resBankDetails = await API().getBankDetails(id)
      const response = await API().getMyCryptoList(user_Data.data.id);
      setCryptoCurrencyApi(response.crypto_list)
      setSellectedCryptoCurrency(response.crypto_list[0])

      setBankListApi(resBankList)
      const resWallet = await API().getWalletBalance(response.crypto_list[0].wallet_id);

      console.log(resWallet, 'resWallet>>>>>>>>>>>>>>>>>>>>')
      console.log(resBankList, 'resBankList>>>>>>>>>>>>>>>>>>>>')
      console.log(resBankDetails, 'resBankDetails>>>>>>>>>>>>>>>>>>>>')
      console.log(response, 'responsegetMyCryptoList>>>>>>>>>>>>>>>>>>>>')


      setWalletID(response.crypto_list[0].wallet_id)
      if (resWallet.data.status) {

        setWalletApi(resWallet.data.balance)
        setWalletResloding(false)
      }

      console.log(respons, 'respons.user_detassssssssssssssssslllllllllllllllllllllllllllllllllllllllllllllllll......................................................il>>>>>>>>>>>>>>>>>>>>')
      localStorage.setItem('user_verifyed', respons.user_detail.user_verifyed)
      setApiUserDetails(respons.user_detail);
      setIsloading(false);
      if (resBankDetails.status == true) {

        let BankList = JSON.stringify({
          id: resBankDetails.bank_detail.bank_id,
          country_id: resBankDetails.bank_detail.country_id,
          bank_name: resBankDetails.bank_detail.bank_name
        })
        setBankAccountNumber(resBankDetails.bank_detail.account_number)
        setSelectedBank(BankList)
      } else {
        if (resBankList.length >= 1) {

          setSelectedBank(JSON.stringify(resBankList[0]))
        }
      }


    } catch (err) {
      console.log(err, "errSett");
    }
  };
  useEffect(() => {
    GetData();
  }, []);
  const InviteFreind = () => {
    history.push("/invits")

  }

  const PtoPTransactions = () => {

    history.push("/ptoptransactions")
  }

  const LogOut = async () => {
    try {
      setIsloading(true);
      const res = await API().getLogout(

      )
      console.log(res, 'ggggggggggggggggggggggggggggggggggggggggggggggggggggggggg');
      ls.remove("Cradentials");
      setNew_Data({ data: null });
      localStorage.removeItem("userData");
      history.replace("/");
      return true;
    } catch (exception) {
      return false;
    }
  };
  const handleChange = async (event) => {
    const res = JSON.parse(event.target.value)
    setWalletResloding(true)
    setSellectedCryptoCurrency(res);
    setWalletID(res.wallet_id)
    const resWallet = await API().getWalletBalance(res.wallet_id);

    if (resWallet.data.status) {

      setWalletApi(resWallet.data.balance)
      setWalletResloding(false)
    } else {

      setWalletApi('')
      setWalletResloding(false)
    }

  };


  const AuthyModalOpen = () => {
    setAuthyModal(true)
  }
  const AuthyModalClose = () => {
    if (authyData.hasOwnProperty('key') || authEnable) {
      setScanOrNotMod(true)
    } else {
      setAuthyModal(false)

    }
  }
  const phnValid = function (phn) {
    return function (phn) {
      if (phn.length == 10) {
        return true;
      } else {
        return false;
      }
    };
  };

  // const CreateAuthy = async () => {

  //   console.log(id,
  //     authyEmail,
  //     authyNum,
  //     country_code, 'qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq')

  //   var emailValidation = isValidEmail(authyEmail);
  //   var phnNumValid = phnValid("nm");
  //   var PhnVal = phnNumValid(authyNum);
  //   setValidation({
  //     phNum: PhnVal,
  //     autEmail: emailValidation
  //   });

  //   if (PhnVal && emailValidation) {


  //     try {
  //       setAuthyNumLoader(true)
  //       const res = await API().postAuthySetup(
  //         id,
  //         authyEmail,
  //         authyNum,
  //         country_code,
  //       )

  //       if (res) {
  //         AuthyModalClose()
  //         GetData()

  //         setAlertMessage(res.Message)
  //         setAlertShow(true)
  //         setTimeout(() => {
  //           setAlertShow(false)

  //         }, 3000);


  //       }
  //       setAuthyNumLoader(false)
  //       console.log(res, 'createAuthy..............................!!!!!!!!!!!111')
  //     } catch (error) {
  //       console.log(error, 'errcreateAuthy...................................!')
  //     }
  //   }
  // }

  const GenrateAuthyCode = async () => {

    try {
      setAuthyNumLoader(true)
      const res = await API().GoogleAuthGenerate(
      )
      if (res.status) {
        setAuthyData(res.data)
        setAlertMessage(res.data.message)
        setAlertShow(true)
        setTimeout(() => {
          setAlertShow(false)

        }, 3000);


      }
      setAuthyNumLoader(false)
      console.log(res.data, 'GenrateAuthyCode..............................!!!!!!!!!!!111')
    } catch (error) {
      console.log(error, 'err GenrateAuthyCode...................................!')
    }
  }

  const Disable = async () => {
    try {
      const res = await API().postAuthyStatus(
        0
      )
      setAuthyData({})
      setScanOrNotMod(false)
      setAuthyModal(false)
      setAuthEnable(false)

      GetData()

      console.log(res, has_google2fa_secret, 'res postAuthyStatus');
    } catch (error) {
      console.log(error, 'error11111111111111postAuthyStatus');
    }
  }


  const EnableDisable = async () => {
    try {
      const res = await API().getAutyEnableDisable(id)
      console.log(res, 'ressgetAutyEnableDisable')
      setAuthEnable(!authEnable)

      // GetData()
    } catch (error) {
      console.log(error, 'errAutyEnableDisable..................................!');
    }
  }

  if (isloading) {
    return (
      <div style={{ backgroundColor: Colors.Bgcolor, height: "100vh", backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }}>
        <NavBar />
        {alertShow ?
          <CoustumAlert alertMessage={alertMessage} />
          : <></>}
        <div
          style={{ height: "80vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ height: "100vh", backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }} >
        <NavBar />
        {alertShow ?
          <CoustumAlert alertMessage={alertMessage} />
          : <></>}
        <div style={{}} className=" ">
          <div className="container mt-5 " >
            <div className="row justify-content-center ">
              <div className="col-lg-3 shadow d-flex  rounded align-items-center " style={{ backgroundColor: Colors.darkBlue }}>
                <div className="col-12 ">

                  <h3 className="text-center"
                    style={{
                      color: "white",

                      // marginTop: 5,
                    }}
                  >
                    Settings
                  </h3>
                  {/* {Verifide ? (
                    <div
                      style={{ backgroundColor: Colors.lightBlue }}
                      className=" mb-3 border border-light border-2 shadow-sm rounded-pill text-center "
                    >
                      <h3
                        style={{
                          color: "white",

                          marginTop: 5,
                        }}
                      >
                        Verified
                      </h3>
                    </div>
                  ) : (
                    <div
                      style={{ backgroundColor: "red" }}
                      className=" mb-3 border border-secondary border-2 shadow-sm rounded-pill text-center "
                    >
                      <h3 style={{ color: "white", marginTop: 5 }}>UnVerified</h3>
                    </div>
                  )} */}

                  <Button
                    onClick={() => EditProfileModOpen()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    Edit Profile
                  </Button>
                  <Button
                    onClick={() => BankDetailsModOpen()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    Bank Details
                  </Button>
                  <Button
                    onClick={PtoPTransactions}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    My P2P Transaction
                  </Button>

                  <Button
                    onClick={() => {
                      // setTwoFectorAuthenMod(true)
                      setTFPassorPin(1)
                      setOpenChangePasswordMod(true);
                    }}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    Change Password
                  </Button>

                  {/* <Button
                    onClick={() => MyBuyModOpen()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    My Buying Ads
                  </Button>

                  <Button
                    onClick={() => MySellModOpen()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    My Selling Ads
                  </Button>

                  <Button
                    onClick={() => setTranferCoinMod(true)}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    Transfer Coins
                  </Button> */}

                  {/* <Button
                    onClick={() => MyBuyingBids()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    My Buying Offers
                  </Button>

                  <Button
                    onClick={() => MySellingBids()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    My Selling Offers
                  </Button> 
                 
                  */}

                  <Button
                    onClick={InviteFreind}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    Invite Friend
                  </Button>
                  <Button
                    onClick={AuthyModalOpen}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    2FA
                  </Button>

                  {pin_created ?
                    <Button
                      onClick={() => setInsertUpdatePinModal(true)}
                      style={{
                        width: "100%",
                        fontSize: 15,
                        color: "white",
                        marginTop: 10,
                        borderBottom: "1px solid white",
                      }}
                    >
                      Update Pin
                    </Button>
                    :

                    <Button
                      onClick={() => {
                        // setTwoFectorAuthenMod(true)
                        setTFPassorPin(2)
                        setInsertPinModal(true)
                      }}
                      style={{
                        width: "100%",
                        fontSize: 15,
                        color: "white",
                        marginTop: 10,
                        borderBottom: "1px solid white",
                      }}
                    >
                      Create Pin
                    </Button>
                  }
                  <Button
                    onClick={() => OpenLogOutMod()}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      color: "white",
                      marginTop: 10,
                      borderBottom: "1px solid white",
                    }}
                  >
                    Log out
                  </Button>
                </div>
              </div>
              <div className="col-lg-6 p-lg-4  pt-5 pb-5" style={{}}>
                <div className="col-lg-8 col-4 mb-4  mx-auto" >
                  <div className="col-lg-12 col-10 border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "200px", width: "200px" }}>
                    {image == null ?
                      <FiCameraOff color="white" size={40} />
                      :
                      <img
                        src={image}
                        className="img-thumbnail rounded-circle "
                        alt="Profile"
                        style={{ height: "200px", width: "200px" }}
                      />
                    }
                  </div>
                </div>
                <div className="col-lg-12  col-10 mx-auto" >
                  <div className=" d-md-flex">

                    <h4 style={{ color: "white", marginTop: 10 }}>Name:</h4>
                    <h4 style={{ marginLeft: 10, color: "white", marginTop: 10 }}>
                      {first_name} {last_name}
                    </h4>
                  </div>
                  <div className=" d-md-flex">
                    <h4 style={{ color: "white", marginTop: 10 }}>Email:</h4>
                    <h4 style={{ marginLeft: 10, color: "white", marginTop: 10 }}>{email}</h4>
                  </div>
                  <div className="d-md-flex">
                    <h4 style={{ color: "white", marginTop: 10 }}>Address:</h4>
                    <h4 style={{ marginLeft: 10, color: "white", marginTop: 10 }}>{address}</h4>
                  </div>
                  <div className="d-md-flex">
                    <h4 style={{ color: "white", marginTop: 10 }}>Mobile:</h4>
                    <div className="d-md-flex">
                      <h4 style={{ marginLeft: 10, color: "white", marginTop: 10 }}>
                        {country_code} -
                      </h4>
                      <h4 style={{ color: "white", marginTop: 10 }}>
                        {phone_number}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*############################### 
        ##### My Buyings Ads Modal ##### 
        ###############################*/}
            <MyByingAdsScreen
              set={setOpenMyBuyingMod}
              open={openMyBuyingMod}
              close={MyBuyModClose}
            />

            {/*############################### 
        ####### My Buyings Bids ######## 
        ###############################*/}

            <MyBuyingBidsScreen
              set={setOpenMyBuyingBids}
              open={openMyBuyingBids}
              close={MyBuyingBidsClose}
            />

            {/*############################### 
        #### My Sellings Ads Modal #### 
        ###############################*/}
            <MySellingAds
              set={setOpenMySellingMod}
              open={openMySellingMod}
              close={MySellModClose}
            />

            {/*############################### 
        ####### My Sellings Bids ####### 
        ###############################*/}

            <MySellingBidsScreen
              open={openMySellingBids}
              close={MySellingBidsClose}
              set={setOpenMySellingBids}
            />

            {/*####################### 
        ##### LogOut Modal ##### 
        #######################*/}

            <Modal open={openLogOutModal}>
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div className="modal-body">
                    <div className="col-12 row justify-content-center">
                      <h5 className="text-center">
                        Are you sure you want to Logout
                      </h5>
                      <div className=" col-8 col-lg-6 mt-2 d-flex justify-content-between">
                        <button
                          onClick={() => CloseLogOutMod()}
                          style={{ fontSize: 20, borderRadius: 20 }}
                          type="button"
                          className="btn mb-2 btn-outline-secondary"
                        >
                          Close
                        </button>
                        <button
                          onClick={LogOut}
                          style={{ fontSize: 20, borderRadius: 20 }}
                          type="button"
                          className="btn mb-2 btn-outline-secondary"
                        >
                          Log Out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            {/*############################# 
        ##### Edit Profile Modal ##### 
        #############################*/}

            <EditProfile

              setAlertShow={setAlertShow}
              setAlertMessage={setAlertMessage}
              get={GetData}
              open={openEditProfileMod}
              userDetaiL={apiUserDetails}
              close={setOpenEditProfileMod}
            />

            {/*############################## 
        #### Change Password Modal #### 
        #############################*/}
            <ChangePassword
              open={openChangePasswordMod}
              userDetaiL={apiUserDetails}
              close={setOpenChangePasswordMod}
            />

            {/*####################### 
        ##### Bank Details ##### 
        #######################*/}

            <Modal open={bankDetailsMod}>
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div className="modal-header">
                    <h1> Bank Details</h1>
                  </div>
                  <div className="modal-body">
                    <div className="col-12 row justify-content-center">
                      <div className="mt-2">
                        <h6>Bank Name:</h6>
                        <select
                          style={{
                            width: "100%",
                            height: 40,
                            borderColor: "rgb(199, 199, 199)",
                          }}
                          className="form-select px-3 text-secondary shadow-sm mt-2 "
                          aria-label="Default select example"
                          onChange={SelectedBankAccountNum}
                        >
                          <option selected>{sellectedBank.bank_name}</option>

                          {bankListApi.map((item) => {
                            return (

                              <>
                                {item.id !== sellectedBank.id ? (

                                  <>
                                    <option key={item.id} value={JSON.stringify(item)}>
                                      {item.bank_name}
                                    </option>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>

                            );
                          })}
                        </select>
                      </div>

                      <div className="mt-2">
                        {validation.BankName ? (
                          <>
                            <h6>Bank Account Number:</h6>
                            <input
                              value={bankAccountNumber}
                              onChange={(e) => setBankAccountNumber(e.target.value)}
                              placeholder="Enter-Bank-Account-Number"
                              className="form-control "
                              style={{ width: "100%" }}
                            ></input>
                          </>
                        ) : (
                          <>
                            <h6 className="text-danger">Bank Account Number:</h6>
                            <input
                              value={bankAccountNumber}
                              onChange={(e) => setBankAccountNumber(e.target.value)}
                              placeholder="Enter-Bank-Account-Number"

                              className="form-control border-danger"
                              style={{ width: "100%" }}
                            ></input>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button

                      onClick={() => BankDetailsModClose()}
                      type="button"
                      className="btn px-4 rounded-pill border-0 btn-primary"
                      style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
                    >
                      Close
                    </button>
                    <button
                      onClick={UpdateBank}
                      // style={{ fontSize: 20, borderRadius: 20 }}
                      type="button"
                      className="btn px-4 rounded-pill border-0 btn-primary"
                      style={{ backgroundColor: Colors.darkBlue, }}
                    >
                      Update Details
                    </button>


                  </div>
                </div>
              </div>
            </Modal>
            {/*####################### 
        ##### Tranfer Coin Mod ##### 
        #######################*/}

            <Modal open={tranferCoinMod}>
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div className="modal-header">
                    <h1> Transfer Coins</h1>
                  </div>
                  <div className="modal-body">
                    <div className="col-12 row justify-content-center">
                      <div className="mt-2  d-flex justify-content-between align-items-center ">
                        {/* <h6>Bank Name:</h6> */}
                        <select
                          style={{
                            width: "40%",
                            height: 40,
                            borderColor: "rgb(199, 199, 199)",
                          }}
                          className="form-select  px-3 text-secondary shadow-sm  "
                          aria-label="Default select example"
                          onChange={(e) => handleChange(e)}
                        >


                          {cryptoCurrencyApi.map((item) => {
                            return (


                              <option key={item.crpto_id} value={JSON.stringify(item)}>

                                {item.currreny_name}
                              </option>
                            )

                          })}
                        </select>
                        <div className="col-4 ">
                          <h4>Balance</h4>
                          {walletResloding ? (

                            <div className="spinner-border text-secondary" role="status"></div>
                          ) : (
                            // 100000000
                            <h6 className="text-success ms-1 " >{sellectedCryptoCurrency.currreny_name == 'BTC' ? walletApi.final_balance / 100000000 : walletApi.final_balance / 100000000} </h6>
                          )}
                        </div>
                      </div>

                      <h6>Crypto:</h6>
                      <div
                        className=" border  rounded  shadow-sm mx-auto "
                        style={{
                          width: "95%",
                          height: "45px",

                        }}
                      >
                        <input
                          className="form-control mt-1 border-0 shadow-none  "
                          value={crypto}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setCrypto(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "100%",

                          }}
                        />
                      </div>

                      <h6 className="mt-2">Wallet Address:</h6>
                      <div
                        className=" border  rounded  shadow-sm mx-auto "
                        style={{
                          width: "95%",
                          height: "45px",

                        }}
                      >
                        <input
                          className="form-control mt-1 border-0 shadow-none  "
                          value={walletAddress}
                          onChange={(e) => {

                            setWalletAddress(e.target.value);

                          }}
                          placeholder="Wallet Address"
                          style={{
                            width: "100%",

                          }}
                        />
                      </div>

                    </div>
                  </div>
                  <div className="modal-footer ">
                    <button

                      onClick={() => setTranferCoinMod(false)}
                      type="button"
                      className="btn px-4 rounded-pill btn-primary"
                      style={{ backgroundColor: Colors.lightBlue, color: Colors.darkBlue }}
                    >
                      Close
                    </button>

                    {walletAddress.length >= 2 && crypto.length >= 2 ?

                      <button
                        onClick={TransferCoin}
                        // style={{ fontSize: 20, borderRadius: 20 }}
                        type="button"
                        className="btn px-4 rounded-pill btn-primary"
                        style={{ backgroundColor: Colors.darkBlue, }}
                      >
                        {transferCoinBtn ? <div class="spinner-border  " style={{ color: Colors.lightBlue, height: 20, width: 20, marginLeft: 10, marginRight: 10 }} role="status"></div>
                          :
                          'Transfer'
                        }
                      </button>

                      : <div
                        // onClick={TransferCoin}
                        // style={{ fontSize: 20, borderRadius: 20 }}
                        type="button"
                        className="btn px-4 rounded-pill "
                        style={{ backgroundColor: 'rgb(130, 124, 178)', color: 'white' }}
                      >

                        Transfer

                      </div>}


                  </div>
                </div>
              </div>
            </Modal>

            {/*####################### 
        ##### Insert Pin Modal ##### 
        #######################*/}

            <Modal open={insertPinModal}>
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div className="modal-header">
                    <h1> Create Your Pin</h1>
                  </div>
                  <div className="modal-body">
                    <div className="col-12 row justify-content-center">
                      {/* <h6>Crypto:</h6> */}
                      <OtpInput
                        value={inputPin}
                        onChange={(e) => setInputPin(e)}
                        numInputs={4}
                        containerStyle={{
                          marginTop: "20px",
                          marginBottom: "20px",
                          // marginLeft: "20px",
                          width: "300px",
                        }}
                        isInputNum={true}
                        inputStyle={{
                          height: "60px",
                          width: "60px",
                          // marginLeft: "15px",
                        }}
                        separator={<span>{'-'}</span>}
                      />
                      {insertPinLoader ?
                        <div
                          style={{}}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div>
                        </div>
                        : <></>}

                    </div>

                  </div>
                  <div className="modal-footer ">
                    <button

                      onClick={InsertPinModalClose}
                      type="button"
                      className="btn px-4 border-0 shadow-none rounded-pill btn-primary"
                      style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
                    >
                      Close
                    </button>



                    <button
                      onClick={IsPinCreated}
                      // style={{ fontSize: 20, borderRadius: 20 }}
                      type="button"
                      className="btn px-4  border-0 shadow-none rounded-pill btn-primary"
                      style={{ backgroundColor: Colors.darkBlue, }}
                    >
                      Create Pin
                    </button>




                  </div>
                </div>
              </div>
            </Modal>

            {/*####################### 
        ##### Insert Update Pin Modal ##### 
        #######################*/}

            <Modal open={insertUpdatePinModal}>
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div className="modal-header">
                    <h1> Update Your Pin</h1>
                  </div>
                  <div className="modal-body">
                    <div className="col-12 row justify-content-center">
                      <h6 className="text-center">Old Pin</h6>
                      <OtpInput
                        value={oldPin}
                        onChange={(e) => setOldPin(e)}
                        numInputs={4}
                        containerStyle={{
                          // marginTop: "20px",
                          marginBottom: "20px",
                          // marginLeft: "20px",
                          width: "300px",
                        }}
                        isInputNum={true}
                        inputStyle={{
                          height: "60px",
                          width: "60px",
                          // marginLeft: "15px",
                        }}
                        separator={<span>{'-'}</span>}
                      />

                      <h6 className="text-center">New Pin</h6>
                      <OtpInput
                        value={inputUpdatePin}
                        onChange={(e) => setInputUpdatePin(e)}
                        numInputs={4}
                        containerStyle={{
                          // marginTop: "20px",
                          marginBottom: "20px",
                          // marginLeft: "20px",
                          width: "300px",
                        }}
                        isInputNum={true}
                        inputStyle={{
                          height: "60px",
                          width: "60px",
                          // marginLeft: "15px",
                        }}
                        separator={<span>{'-'}</span>}
                      />
                      {insertPinLoader ?
                        <div
                          style={{}}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div>
                        </div>
                        : <h6 className="text-center text-danger">
                          {updatePinResMessage}
                        </h6>
                      }
                    </div>
                    <p className="text-center">Forgot Pin? Please contact us on <a href="mailto:support@foloswap.com" style={{ fontSize: 18, fontWeight: 'bold' }}>support@foloswap.com </a > </p>
                  </div>
                  <div className="modal-footer ">
                    <button

                      onClick={UpdatePinModalClose}
                      type="button"
                      className="btn px-4 border-0 shadow-none rounded-pill btn-primary"
                      style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
                    >
                      Close
                    </button>



                    <button
                      onClick={UpdatePin}
                      // style={{ fontSize: 20, borderRadius: 20 }}
                      type="button"
                      className="btn px-4  border-0 shadow-none rounded-pill btn-primary"
                      style={{ backgroundColor: Colors.darkBlue, }}
                    >
                      Update Pin
                    </button>




                  </div>
                </div>
              </div>
            </Modal>

            {/* ###########################
      ########## OTP Modal ##########
      ##########################* */}
            <Modal open={otpMod}>
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Please check Email for verification code</h2>
                    <AiOutlineClose
                      onClick={OtpModClose}
                      size={20}
                      style={{ marginRight: 15 }}
                    />
                  </div>
                  <div className="modal-body">
                    <div className=" row justify-content-center">




                      <OtpInput
                        value={OTPValue}
                        onChange={(e) => setOTPValue(e)}
                        numInputs={4}
                        containerStyle={{
                          marginTop: "20px",
                          // marginLeft: "20px",
                          width: "300px",
                        }}
                        isInputNum={true}
                        inputStyle={{
                          height: "60px",
                          width: "60px",
                          marginLeft: "12px",
                        }}
                        separator={<span>{""}</span>}
                      />
                      <div className="col-6">
                        {OTPLoader ? (
                          <div className="d-flex mt-1 justify-content-center align-items-center">
                            <div
                              className="spinner-border text-secondary"
                              role="status"
                            ></div>
                          </div>
                        ) : (
                          <p className="text-center text-danger">{OTPres}</p>
                        )}
                      </div>

                      <button
                        onClick={OTPSend}
                        style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                        type="button"
                        className="btn btn-primary  mt-3"
                      >

                        Send
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>

        {/*###################################
         ### 2 fector Authentication Modal ###
         ##################################*/}
        <Modal open={twoFectorAuthenMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2> Enter authentication Code</h2>
                <AiOutlineClose
                  onClick={TwoFectorAuthenModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">

                <div
                  className=" mt-1 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: 'white',
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1px solid rgb(199, 199, 199)",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={twoFectorAuthenCode}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "100%",
                      paddingLeft: 20,
                    }}

                    placeholder="Enter Code"
                    onChange={(e) => setTwoFectorAuthenCode(e.target.value)}
                  />

                </div>
                {twoFectorAuthenCodeLoader ? <></> :
                  <h6 className="text-danger text-center mt-2">{twoFectorAuthenCodeRes}</h6>}

                <div className="mt-3 d-flex justify-content-center">
                  <button
                    style={{ width: "60%", fontSize: 20, backgroundColor: Colors.darkBlue, color: "white" }}
                    type="button"
                    className="btn btn-outline    "
                    onClick={() => TwillioCodeSend('pin')}
                  >
                    {twoFectorAuthenCodeLoader ?
                      <div className="spinner-border text-secondary" role="status"></div> : ' Verify'}

                  </button>
                </div>

              </div>
            </div>
          </div>
        </Modal>



        {/*###################################
         #######  Authentication Authy #######
         ##################################*/}
        <Modal open={authyModal}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2>2FA</h2>
                <AiOutlineClose
                  onClick={AuthyModalClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <h6 className="text-danger"> {authyData.message}</h6>

                <div className="d-flex justify-content-between"  >

                  {authyData.hasOwnProperty('svg') ?
                    <img
                      // onClick={fileOpen}
                      src={authyData.svg}
                      style={{ height: 250, width: 250, marginLeft: 100 }}
                    // className="img-thumbnail "
                    // alt="Profile"
                    />
                    : <div style={{ width: 250, marginLeft: 100 }}></div>}

                  {has_google2fa_secret || authyData.auth_enable ?
                    <div className="form-check d-flex justify-content-end form-switch"  >
                      <input onClick={EnableDisable} style={{ width: 70, height: 25 }} checked={authEnable} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                    </div> : <></>}
                </div>


                <h6 className="text-center">{authyData.key}</h6>


                <button
                  onClick={GenrateAuthyCode}
                  // style={{ fontSize: 20, borderRadius: 20 }}
                  type="button"
                  className="btn px-4 border-0 shadow-none mt-2 btn-primary"
                  style={{ width: '100%', backgroundColor: Colors.darkBlue, }}
                >
                  {authyNumLoader ? <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div> :
                    'Show QRCode'}

                </button>

                {/* <div className="mt-2">
                  {authy_number > 0 ?

                    <div className="d-flex justify-content-between">

                      <div>
                        <>
                          <h6>Email:</h6>
                          <div className="border px-2  align-items-center d-flex justify-content-between" style={{ backgroundColor: '#e9ecef' }}>

                            <input
                              disabled
                              value={authyEmail}
                              onChange={(e) => {

                                setAuthyEmail(e.target.value);

                              }}
                              placeholder='Enter Email'
                              className="form-control shadow-none p-0 border-0 py-2 "
                              style={{ width: "100%" }}
                            ></input>
                          </div>
                        </>
                        <>

                          <h6>Phone Number:</h6>
                          <div className="border  align-items-center d-flex justify-content-between" style={{ backgroundColor: '#e9ecef' }}>
                            <span style={{ marginLeft: "4%" }}>

                              {country_code}
                            </span>
                            <input
                              disabled
                              value={authyNum}
                              onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                  setAuthyNum(e.target.value);
                                }
                              }}

                              className="form-control shadow-none p-0 border-0 py-2 "
                              style={{ width: "100%" }}
                            ></input>
                          </div>
                        </>



                      </div>
                      {authy_number <= 0 ? <></> :
                        <div className="form-check d-flex justify-content-end form-switch"  >
                          <input onClick={EnableDisable} style={{ width: 70, height: 25 }} checked={authEnable} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                        </div>
                      }
                    </div>
                    :

                    <div className="d-flex justify-content-between">

                      <div>
                        <>

                          {validation.autEmail ? (
                            <>
                              <h6>Email:</h6>
                              <div className="border px-2  align-items-center d-flex justify-content-between">

                                <input
                                  value={authyEmail}
                                  onChange={(e) => {

                                    setAuthyEmail(e.target.value);

                                  }}
                                  placeholder='Enter Email'
                                  className="form-control shadow-none p-0 border-0 py-2 "
                                  style={{ width: "100%" }}
                                ></input>
                              </div>


                            </>
                          ) : (
                            <>

                              <h6>Email:</h6>
                              <div className="border px-2 border-danger  align-items-center d-flex justify-content-between">

                                <input
                                  value={authyEmail}
                                  onChange={(e) => {

                                    setAuthyEmail(e.target.value);

                                  }}
                                  placeholder='Enter Email'
                                  className="form-control shadow-none p-0 border-0 py-2 "
                                  style={{ width: "100%" }}
                                ></input>
                              </div>

                            </>
                          )}
                        </>
                        <>

                          {validation.phNum ? (
                            <>


                              <h6>Phone Number:</h6>
                              <div className="border  align-items-center d-flex justify-content-between">
                                <span style={{ marginLeft: "4%" }}>

                                  {country_code}
                                </span>
                                <input
                                  value={authyNum}
                                  onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                      setAuthyNum(e.target.value);
                                    }
                                  }}
                                  className="form-control shadow-none p-0 border-0 py-2 "
                                  style={{ width: "100%" }}
                                ></input>
                              </div>
                            </>
                          ) : (
                            <>


                              <h6 className="text-danger">Phone Number:</h6>
                              <div className="border border-danger  align-items-center d-flex justify-content-between">
                                <span style={{ marginLeft: "4%" }}>
                                  {country_code}
                                </span>
                                <input
                                  value={authyNum}
                                  onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                      setAuthyNum(e.target.value);
                                    }
                                  }}
                                  className="form-control shadow-none p-0 border-0 py-2 "
                                  style={{ width: "100%" }}
                                ></input>
                              </div>
                            </>
                          )}
                        </>

                      </div>
                      {authy_number <= 0 ? <></> :
                        <div className="form-check d-flex justify-content-end form-switch"  >
                          <input onClick={EnableDisable} style={{ width: 70, height: 25 }} checked={authEnable} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />

                        </div>
                      }
                    </div>
                  }
                  <div>
                    {authy_number <= 0 ?
                      <button
                        // onClick={CreateAuthy}
                        // style={{ fontSize: 20, borderRadius: 20 }}
                        type="button"
                        className="btn px-4 border-0 shadow-none mt-2 btn-primary"
                        style={{ width: '100%', backgroundColor: Colors.darkBlue, }}
                      >
                        {authyNumLoader ? <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div> :
                          'Create Authy'}

                      </button>
                      : <></>}

                  </div>
                </div> */}

              </div>
            </div>
          </div >
        </Modal >

        {/*###################################
         #######  Scan Or Not #######
         ##################################*/}
        <Modal open={scanOrNotMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2> </h2>
                <AiOutlineClose
                  onClick={() => setScanOrNotMod(false)}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body ">
                <h6>Please scan with the authenticator app to remain enabled.</h6>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={() => {
                      Disable()

                    }}
                    // style={{ fontSize: 20, borderRadius: 20 }}
                    type="button"
                    className="btn px-4 border-0 shadow-none mt-2 btn-primary"
                    style={{ width: '45%', backgroundColor: Colors.darkBlue, }}
                  >
                    {authyNumLoader ? <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div> :
                      'Disable'}

                  </button>

                  <button
                    onClick={() => {
                      setScanOrNotMod(false)
                      setAuthyModal(false)
                      setAuthyData({})
                      GetData()

                    }}
                    // style={{ fontSize: 20, borderRadius: 20 }}
                    type="button"
                    className="btn px-4 border-0 shadow-none mt-2 btn-primary"
                    style={{ width: '45%', backgroundColor: Colors.darkBlue, }}
                  >
                    {authyNumLoader ? <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div> :
                      'Remain Enable'}

                  </button>

                </div>



              </div>
            </div>
          </div >
        </Modal >
      </ div >
    );
  }
}

export default IndexSettings;
