import React, { useEffect, useState, useRef } from 'react';
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import profile from '../../Images/image1.jpg';
import NavBar from '../NavBar';
import { FiSend, FiPaperclip } from "react-icons/fi";
import * as Colors from '../../Constant/Colors'
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import BankDetailsMod from '../SettingScreens/BankDetailsMod';
import API from "../../Api";
import io from 'socket.io-client';
import { useHistory } from "react-router-dom";
import ScrollToBottom from 'react-scroll-to-bottom';
import CoustumAlert from '../../Constant/CoustumAlert'

import Modal from "@material-ui/core/Modal";
import { FiCameraOff } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

import tune from '../../Images/iphone_notification_so.mp3'

const domain = 'https://f557-162-241-121-1.ngrok.io'
var socket = io(domain);


function MySwapAdsOffers() {
    const [isloding, setIsloding] = useState(true);

    const history = useHistory();
    const adDetail = useLocation().state.detail;
    const percentage = useLocation().state.percentage;

    // console.log(adDetail, 'dddddddddddddddddddddddddddddddddddd')
    const {


    } = adDetail
    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, symbol, country, first_name } = user_Data.data;

    const [chatShow, setChatShow] = useState(0)
    const [chatButton, setChatButton] = useState('Chat')
    const [data, setData] = useState(0);
    const [bankDetailApi, setBankDetailApi] = useState('');
    const [message, setMessage] = useState('');
    const [resMessage, setResMessage] = useState([]);
    const [transectionId, setTransectionId] = useState(0);
    const [bankDetaiMod, setBankDetaiMod] = useState(false);
    const [apiValueOffers, setApiValueOffers] = useState([]);
    const [offersStatus0Lits, setOffersStatus0Lits] = useState([]);
    const [offersStatus1List, setOffersStatus1List] = useState([]);
    const [bidUserId, setBidUserId] = useState("")
    const [bidDetails, setBidDetails] = useState({});
    const [addId, setAddId] = useState("");

    const [data1, setData1] = useState(0);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [errors, setErrors] = useState("");
    const [profileImage, setProfileImage] = useState({
        image: null,
        name: null,
    });
    const [values, setValues] = useState({
        image: null,
        name: null,
    });

    const [fullSizeImage, setFullSizeImage] = useState("");
    const [showImageModal, setShowImageModal] = useState(false);

    const audio = new Audio(tune);



    var getMessages = []

    const chatOpen = () => {
        if (chatShow == 0) {

            setChatShow(1)
            setChatButton('Close')
        } else {
            setChatShow(0)
            setChatButton('Chat')
        }
    }

    const SallerBankDetails = async (item) => {

        setBidUserId(item.bid_user_id)
        setBidDetails(item)
        setAddId(item.ad_id)

        try {

            const resDetails = await API().getAcceptSellingOfferDetail(
                item.my_crpto_id,
                item.bid_user_id,
                item.quantity,
                item.ad_id,
                id
            )
            if (resDetails.status == true) {
                setBankDetailApi(resDetails)
                setBankDetaiMod(true)

                // MyBuyingOfferModClose()
                setData(1)
            } else {

                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)

                }, 3000);
                setAlertMessage(`Saller have No ${item.crpto_symbol}`)

                // MyBuyingOfferModClose()
            }


        } catch (err) {
            console.log(err, 'errOtpSetTransection')
        }
    }

    const SendMasseg = () => {
        socket.emit("text", { "msg": message, "t_id": transectionId, "u_id": id })

        setMessage('')



        let dd = {
            Message: message,
            first_name: first_name,
            file: null,
            user_id: id,
        }
        getMessages = resMessage
        getMessages.push(dd)


        setResMessage(getMessages)



    }
    const AcceptOffer = async (data) => {
        // console.log(data, 'dddddddddddddddddddddddddddddddddddddddddddddddddddddd')


        try {

            const res = await API().getbuyerSwapStatus(data.id)
            if (res.status == true) {

                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)

                    history.goBack()
                }, 3000);
                setAlertMessage('Accepted')
                // close()
            }
        } catch (err) { console.log(err, 'errAceptbuingoffer') }
    }
    const ShowImage = (item) => {
        setShowImageModal(true)
        setFullSizeImage(item)
    }

    const ImageRef = useRef();
    const fileOpen = () => {
        const trigger = ImageRef.current;
        trigger.click();
    };

    let UploadImage = async (files) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 315|C1DgmLVn8kCxwHN6ioyETx5jxjimMYjy5QXV3wxS");

        var formdata = new FormData();
        formdata.append("room_id", transectionId);
        formdata.append("user_id", id);
        formdata.append("message", '');
        formdata.append("file", files);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://f557-162-241-121-1.ngrok.io//upload_image", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));


    }

    const aprovedTransection = async () => {
        alert('approve')
        // try {
        //     const res = await API().postAcceptTransection(
        //         transectionImageApif.tansection_id,
        //         apiValueOffers[0].ad_id,
        //         apiValueOffers[0].quantity,
        //         apiValueOffers[0].bid_user_id,
        //         apiValueOffers[0].crpto_id,
        //     )

        //     console.log(res, 'res tansection_id')
        //     if (res.status) {

        //         setAlertShow(true)
        //         setTimeout(() => {
        //             setAlertShow(false)

        //             history.goBack()
        //         }, 3000);
        //         setAlertMessage('Aproved Transection')


        //     }
        // } catch (err) { console.log(err, 'errAcceptTransection') }
    }


    const handleChangeImage = async (e) => {
        const { value, files } = e.target;
        let ddddd = value.split("\\");
        ddddd = ddddd[ddddd.length - 1];
        const file = files[0];

        let ext = value.split(".");

        ext = ext[ext.length - 1];
        if (
            ext === "jpg" ||
            ext === "JPG" ||
            ext === "jpeg" ||
            ext === "bmp" ||
            ext === "png"
        ) {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: { error: false, text: "" },
                };
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                const FileasDataUrl = e.target.result;

                const base64 = FileasDataUrl.split(",");

                const base64Encoded = base64[base64.length - 1];
                setProfileImage({ name: ddddd, image: base64Encoded });

                setMessage('')


                let dd = {
                    Message: '',
                    first_name: first_name,
                    file: ddddd,
                    user_id: id,
                }
                getMessages = resMessage

                getMessages.push(dd)


                setResMessage(getMessages)

                setValues({ image: FileasDataUrl, name: ddddd });

                UploadImage(file)



            };
            reader.readAsDataURL(file);
        } else {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: {
                        error: true,
                        text: "Only jpg, jpeg, bmp and png are allowed",
                    },
                };
            });
        }

        e.target.value = "";
    };

    const GetData = async () => {

        try {
            const res = await API().getTransectionId(adDetail.ad_id)
            setTransectionId(res.transection_id)
            // getMySwapOffers
            // console.log(res.transection_id, 'res.transection_id>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            const MySwapOffers = await API().getMySwapOffers(adDetail.swap_id)
            console.log(MySwapOffers, 'MySwapOffers')
            // const response = await API().getMyAddsOffers(adDetail.ad_id, country);

            if (MySwapOffers.hasOwnProperty("my_swap_offers")) {


                setApiValueOffers(MySwapOffers.my_swap_offers);
                let ite0 = []
                let ite1 = []
                //   setOpenMyBuyingOfferMod(true);
                MySwapOffers.my_swap_offers.map((item, i) => {
                    if (item.seller_status == 1) {

                        ite1.push(item)
                        setOffersStatus1List(ite1)
                    } else {
                        ite0.push(item)
                        if (i >= MySwapOffers.my_swap_offers.length - 1) {
                            setOffersStatus0Lits(ite0)
                            // setOpenMyBuyingOfferMod(true);
                        }

                    }
                })
            }

            if (res.transection_id > 0) {

                const resMessage = await API().postMessage(res.transection_id)
                setResMessage(resMessage.data)
                socket.emit("user_join", { "t_id": res.transection_id, "u_id": id })
                socket.on("user_joined", (data) => {

                })

                socket.on("message", (data) => {
                    getMessages = resMessage.data

                    let senderDada = JSON.parse(data.sender)
                    let dd = {
                        Message: data.message,
                        first_name: senderDada.first_name,
                        file: senderDada.file,
                        last_name: senderDada.last_name,
                        user_id: senderDada.id,
                        file: data.url
                    }

                    if (id != senderDada.id) {
                        getMessages.push(dd)
                        audio.play()
                        setResMessage(getMessages)
                    }
                    setData1(1)
                    setData1(0)
                })

            }


            setIsloding(false)
        } catch (err) { console.log(err, 'errtransectionid') }
    }

    const DisputeRequest = async (item) => {
        try {
            const res = await API().getDisputeRequest(
                item.id,
                id
            )
            console.log(res, 'yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy')
            if (res.status) {
                GetData()
            }


        } catch (err) { console.log(err, 'errAcceptTransection') }
    }
    useEffect(() => {
        //
        GetData()



    }, [])


    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol, }}>
                <NavBar />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {
        return <div className="" style={{ height: '100vh', backgroundColor: Colors.bodyCol }}>

            <NavBar />

            {alertShow ?
                <CoustumAlert alertMessage={alertMessage} />
                : <></>}
            <div className=' d-lg-flex flex-wrap p-4 justify-content-lg-center  '>
                {/* <h1 className='text-light  text-center'> Offer Detail</h1> */}


                {apiValueOffers.length >= 1 ?
                    <>
                        {offersStatus1List.length >= 1 ?
                            <>
                                {offersStatus1List.map((item) => {
                                    console.log(item, 'ittttttttttttttttttttttttttttttttttttttttttttttttttttt')
                                    return (
                                        <div className=' mt-3  col-lg-6  row justify-content-center '>
                                            <div className='  col-lg-12 p-3 col-12  ' style={{ backgroundColor: 'white', borderRadius: 30 }}>
                                                {/* <h2 className=' text-center  ' >Ad Detail</h2>

                                                <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                    <h5 className=' col-md-5 ' style={{}} >Amount Limit: </h5>
                                                    <h5 className=' col-md-7 ' style={{}} >{item.minimum_crypto_amount} {item.crpto_symbol} to {item.maximum_crypto_amount} {item.crpto_symbol}</h5>
                                                </div>
                                                <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                    <h5 className=' col-md-5 ' style={{}} >Amount Of: </h5>
                                                    <h5 className=' col-md-7 ' style={{}} > {item.price} {symbol}/{item.crpto_symbol}</h5>
                                                </div> */}

                                                <h3 className=' text-center   ' >Offer Detail</h3>


                                                <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                    <h5 className=' col-md-5 ' style={{}} >Name: </h5>
                                                    <h5 className=' col-md-7 ' style={{}} > {item.user_name}</h5>
                                                </div>
                                                <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                    <h5 className=' col-md-5 ' style={{}} >You will receive: </h5>
                                                    <h5 className=' col-md-7 ' style={{}} > {item.sell_bid_crypto}
                                                        {' '} {item.recive_symbol}</h5>
                                                </div>
                                                <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                    <h5 className=' col-md-5 ' style={{}} >You will send: </h5>
                                                    <h5 className=' col-md-7 ' style={{}} >{item.recive_bid_crypto}
                                                        {' '} {item.send_symbol}</h5>
                                                </div>



                                                {item.seller_status == 0 ?
                                                    <div className=' pt-2 pb-2 mx-auto   col-md-12 col-lg-12   '>
                                                        <Button

                                                            style={{
                                                                fontSize: 15,
                                                                marginBottom: "10px",
                                                                borderRadius: "20px",
                                                                marginTop: "5px",
                                                                backgroundColor: 'rgb(119, 112, 173)',
                                                            }}
                                                            variant="contained"
                                                            color="secondary"

                                                        >
                                                            Waiting...
                                                        </Button>
                                                    </div>
                                                    :
                                                    <>
                                                        {item.approved_transection || item.decline_transection ? <></> :

                                                            <div className='d-md-flex pt-2 pb-2 mx-auto  border-top col-md-12 col-lg-12  justify-content-around '>

                                                                {/* <Button
                                                                    onClick={() => SallerBankDetails(item)}
                                                                    style={{
                                                                        fontSize: 15,
                                                                        marginBottom: "10px",
                                                                        borderRadius: "20px",
                                                                        marginTop: "5px",
                                                                        backgroundColor: Colors.darkBlue,

                                                                    }}
                                                                    variant="contained"
                                                                    color="secondary"

                                                                >
                                                                    Bank Transfer
                                                                </Button> */}
                                                                <Button
                                                                    onClick={aprovedTransection}
                                                                    style={{
                                                                        fontSize: 15,
                                                                        marginBottom: "10px",
                                                                        borderRadius: "20px",
                                                                        marginTop: "5px",
                                                                        backgroundColor: Colors.darkBlue,
                                                                    }}
                                                                    variant="contained"
                                                                    color="secondary"

                                                                >
                                                                    Approve
                                                                </Button>
                                                                {/* {item.bankTranfer ? <> */}

                                                                {item.dispute_transection ?
                                                                    <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-danger mt-2">Transaction Disputed </button>
                                                                    :
                                                                    <button style={{ borderRadius: "20px", width: 150, height: 38 }} className="btn  btn-outline-info mt-2"
                                                                        onClick={() => DisputeRequest(item)}
                                                                    >Dispute</button>
                                                                }
                                                                {/* </> : <></>} */}
                                                                <button style={{ borderRadius: "20px", width: 150, height: 38 }} className="btn  btn-outline-info mt-2" onClick={chatOpen}  >{chatButton}</button>

                                                            </div>
                                                        }


                                                    </>

                                                }



                                            </div>
                                        </div>
                                    )
                                })}
                            </> : <>
                                {offersStatus0Lits.map((item) => {
                                    return (

                                        <div className='  col-lg-5 m-2 p-3   ' style={{ backgroundColor: 'white', borderRadius: 30 }}>
                                            {/* <h2 className=' text-center  ' >Ad Detail</h2>

                                            <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                <h5 className=' col-md-5 ' style={{}} >Amount Limit: </h5>
                                                <h5 className=' col-md-7 ' style={{}} >{item.minimum_crypto_amount} {item.crpto_symbol} / {item.maximum_crypto_amount} {item.crpto_symbol}</h5>
                                            </div>
                                            <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                <h5 className=' col-md-5 ' style={{}} >Amount Of: </h5>
                                                <h5 className=' col-md-7 ' style={{}} > {item.price} {symbol}/{item.crpto_symbol}</h5>
                                            </div> */}

                                            <h3 className=' text-center   ' >Offer Detail</h3>


                                            <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                <h5 className=' col-md-5 ' style={{}} >Name: </h5>
                                                <h5 className=' col-md-7 ' style={{}} > {item.user_name}</h5>
                                            </div>
                                            <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                <h5 className=' col-md-5 ' style={{}} >You will receive: </h5>
                                                <h5 className=' col-md-7 ' style={{}} > {item.sell_bid_crypto}
                                                    {' '} {item.recive_symbol}</h5>
                                            </div>
                                            <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                                                <h5 className=' col-md-5 ' style={{}} >You will send: </h5>
                                                <h5 className=' col-md-7 ' style={{}} >{item.recive_bid_crypto}
                                                    {' '} {item.send_symbol}</h5>
                                            </div>


                                            <div className="">


                                                {item.buyer_status == 0 ?

                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Button
                                                            onClick={() => AcceptOffer(item)}
                                                            style={{
                                                                fontSize: 15,
                                                                marginBottom: "10px",
                                                                borderRadius: "20px",
                                                                marginTop: "5px",
                                                                backgroundColor: Colors.darkBlue,
                                                            }}
                                                            variant="contained"
                                                            color="secondary"

                                                        >
                                                            Accept Offer
                                                        </Button>

                                                    </div>
                                                    :
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Button

                                                            style={{
                                                                fontSize: 15,
                                                                marginBottom: "10px",
                                                                borderRadius: "20px",
                                                                marginTop: "5px",
                                                                backgroundColor: 'rgb(119, 112, 173)',
                                                            }}
                                                            variant="contained"
                                                            color="secondary"

                                                        >
                                                            Waiting...
                                                        </Button>

                                                    </div>
                                                }

                                            </div>



                                        </div>
                                    )
                                })}
                            </>}
                    </>
                    : (
                        <div
                            style={{
                                height: 200,
                                borderRadius: 20,
                                border: "2px solid rgb(215, 215, 215)",
                            }}
                            className=" row col-10 mt-2 align-items-center shadow-sm"
                        >
                            <h2 className="text-center" style={{ color: "#D2D2D2" }}>
                                {" "}
                                No Offers Yet
                            </h2>
                        </div>
                    )}

                {/* </div> */}

                {/*######################
            ######### CHAT ########## 
            ######################*/}

                {chatShow == 1 ?
                    <div className=' mt-lg-3 ms-lg-3 mt-3 col-lg-4 p-lg-3 ' style={{ backgroundColor: 'white', borderRadius: 30 }}>
                        {resMessage.length == 0 ?
                            <div className='chatDivAdd d-flex justify-content-center align-items-center '>
                                <h5 className='' style={{ color: 'rgb(221, 221, 221)' }}>You have no conversation</h5>
                            </div>
                            :

                            <ScrollToBottom className="overflow-auto  chatDivAdd">
                                {resMessage.map((item) => {
                                    return (
                                        <>
                                            {item.user_id == id ?
                                                <div className=" d-flex justify-content-end">
                                                    <div style={{ padding: 5, alignSelf: 'flex-end' }} className="border col-md-6 col-sm-12 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                                                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                                                            {item.image != null ?
                                                                <img
                                                                    src={`https://foloswap.com${item.image}`}
                                                                    class="img-thumbnail rounded-circle "
                                                                    alt="Profile"
                                                                    style={{ height: "40px", width: "40px" }}
                                                                />
                                                                : <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "40px", width: "40px" }} >

                                                                    <FiCameraOff color="gray" size={20} />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div style={{}} className=" col-10">
                                                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">{item.first_name}</h6>
                                                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                                                                {item.Message}  </p>

                                                            {item.file != null ?
                                                                <img
                                                                    onClick={() => ShowImage(`${domain}/static/uploads/${item.file}`)}
                                                                    src={`${domain}/static/uploads/${item.file}`}
                                                                    style={{ height: 220, width: 200 }}
                                                                    className=""
                                                                    alt="Profile"
                                                                />
                                                                : <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ maxWidth: 900, padding: 5 }} className="border col-lg-6 col-sm-10 rounded d-flex mt-1 justify-content-between">

                                                    <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                                                        {item.image != null ?
                                                            <img
                                                                src={`https://foloswap.com${item.image}`}
                                                                class="img-thumbnail rounded-circle "
                                                                alt="Profile"
                                                                style={{ height: "40px", width: "40px" }}
                                                            />
                                                            : <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "40px", width: "40px" }} >

                                                                <FiCameraOff color="gray" size={20} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{ width: 210 }} className="">
                                                        <h6 style={{ marginTop: 1, color: 'black' }} className="">{item.first_name}</h6>
                                                        <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">{item.Message}</p>

                                                        {item.file != null ?
                                                            <img
                                                                onClick={() => ShowImage(`${domain}/static/uploads/${item.file}`)}
                                                                src={`${domain}/static/uploads/${item.file}`}
                                                                style={{ height: 220, width: 200 }}
                                                                className=""
                                                                alt="Profile"
                                                            />
                                                            : <></>}
                                                    </div>
                                                </div>
                                            }
                                        </>

                                    )
                                })}

                            </ScrollToBottom>
                        }

                        <div style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "rgb(255, 255, 255)",
                            padding: 10,

                            border: "1px solid gray",
                            borderRadius: 50,
                        }} className="d-flex align-items-center mt-2">

                            <input
                                ref={ImageRef}
                                onChange={(e) => handleChangeImage(e)}
                                className="d-none"
                                type="file"
                            />
                            <input value={message} placeholder="type message..." className="form-control border-0 shadow-none " onChange={(e) => setMessage(e.target.value)} />

                            <FiPaperclip style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} onClick={fileOpen} />
                            <FiSend onClick={SendMasseg} style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                        </div>



                    </div>
                    : <></>
                }

            </div>
            {data == 1 ?
                <BankDetailsMod
                    userId={id}
                    addId={addId}
                    bidDetails={bidDetails}
                    bidUserId={bidUserId}
                    bankDetaiMod={bankDetaiMod}
                    setBankDetaiModClose={setBankDetaiMod}
                    bankDetailApi={bankDetailApi}
                    setData={setData}
                />
                : <></>}

            {/* ###########################
      ########## Show Image ##########
      ##########################* */}
            <Modal open={showImageModal}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header  justify-content-end">

                            <AiOutlineClose
                                onClick={() => setShowImageModal(false)}
                                size={20}
                                style={{ marginRight: 15 }}
                            />
                        </div>
                        <div className="modal-body">
                            <img

                                src={fullSizeImage}
                                style={{ height: 420, width: "100%" }}
                                className=""
                                alt="Profile"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div >;
    }
}

export default MySwapAdsOffers;
