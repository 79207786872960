import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";


import { AiOutlineCheck } from "react-icons/ai";


function EdditSwapAdsMod(props) {



    const [isLoader, setIsLoader] = useState(true);
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);


    const { open, id, setCreateSellingAdsMod, setAlertShow, setAlertMessage, adDetails, setData, getSwapAdList } = props;



    const timeDuration = [{ time: '180', name: 'min' }, { time: '120', name: 'min' }, { time: '90', name: 'min' }, { time: '60', name: 'min' }, { time: '30', name: 'min' }, { time: '20', name: 'min' }, { time: '10', name: 'min' }]
    const [sellectedTime, setSellectedTime] = useState("180");


    const [cryptoCurrencyApi, setCryptoCurrencyApi] = useState([]);
    const [sellectedCurrency, setSellectedCurrency] = useState(
        JSON.stringify({
            crpto_id: adDetails.send_crypto_id,
            wallet_id: adDetails.sell_wallet,
            currreny_name: adDetails.send_crypto_symbol,
        })
    );

    const selectedCurrency = JSON.parse(sellectedCurrency);
    console.log(adDetails, 'selectedCurrency')

    const [secondCryptoCurrencyApi, setSecondCryptoCurrencyApi] = useState([]);
    const [sellectedSecondCryptoCurrencyApi, setSellectedSecondCryptoCurrencyApi] = useState(JSON.stringify({
        crpto_id: adDetails.recive_crypto_id,
        currreny_name: adDetails.recive_crypto_symbol,
        Address: adDetails.recive_wallet
    }));



    const selectedSecondCryptoCurrency = JSON.parse(sellectedSecondCryptoCurrencyApi);


    const [percentage, setPercentage] = useState(0);
    const [chek, setChek] = useState(0);
    const [senderCoin, setSenderCoin] = useState(adDetails.sell_crypto);
    const [receiverCoin, setReceiverCoin] = useState(adDetails.recive_crypto);
    const [walletAddress, setWalletAddress] = useState(adDetails.recive_wallet);

    const [resMessage, setResMessage] = useState('');

    const [validation, setValidation] = useState({
        SenderCoin: true,
        ReceiverCoin: true,
        WalletAddress: true,

    });

    const SenderCoinValid = function (fm) {
        return function (fm) {
            if (fm > 0) {
                return true;
            } else {
                return false;
            }
        };
    };
    const ReceiverCoinValid = function (fm) {
        return function (fm) {
            if (fm => 0.1) {
                return true;
            } else {
                return false;
            }
        };
    };
    const WalletAddressValid = function (fm) {
        return function (fm) {
            if (fm.length > 2) {
                return true;
            } else {
                return false;
            }
        };
    };

    const CreateSellingAdsModClose = () => {
        setCreateSellingAdsMod(false)
        setSenderCoin('')
        setReceiverCoin('')
        setWalletAddress('')
        setResMessage('')
        setBtnLoader(false)
        setData(0)

        setValidation({
            SenderCoin: true,
            ReceiverCoin: true,
            WalletAddress: true
        })
    }

    const CreateSwapAd = async () => {

        var senderCoinValid = SenderCoinValid("ad");
        var dataSenderCoin = senderCoinValid(senderCoin);

        var receiverCoinValidValid = ReceiverCoinValid("ad");
        var dataReceiverCoin = receiverCoinValidValid(receiverCoin);

        var walletAddressValid = WalletAddressValid("ad");
        var dataWalletAddressValid = walletAddressValid(walletAddress);

        setValidation({
            SenderCoin: dataSenderCoin,
            ReceiverCoin: dataReceiverCoin,
            WalletAddress: dataWalletAddressValid
        })

        if (dataSenderCoin && dataReceiverCoin && dataWalletAddressValid) {

            setBtnLoader(true)
            try {
                const res = await API().postSwapAdEdit(
                    adDetails.swap_id,
                    senderCoin,
                    receiverCoin,
                    selectedCurrency.wallet_id,
                    walletAddress,
                    sellectedTime,
                    selectedCurrency.crpto_id,
                    selectedSecondCryptoCurrency.crpto_id

                )
                console.log(res, 'resssssssssssssssssssssssssssssssssssssssss')
                if (res.status) {

                    setAlertShow(true)
                    setTimeout(() => {
                        setAlertShow(false)

                    }, 3000);
                    setAlertMessage(res.message)
                    CreateSellingAdsModClose()
                    getSwapAdList()
                } else {
                    setResMessage(res.message)
                }

                setBtnLoader(false)
            } catch (error) {
                console.log(error, 'errrCreateSwapAd')
            }

        }

    }





    const GetData = async () => {

        try {
            setLoader(true)
            const Percentage = await API().getPercentage();
            setPercentage(Percentage.data)

            const response = await API().getMyCryptoList(id);
            setCryptoCurrencyApi(response.crypto_list);
            // setSellectedCurrency(JSON.stringify(response.crypto_list[0]))

            let array = []
            response.crypto_list.map((item, i) => {
                if (selectedCurrency.currreny_name != item.currreny_name) {
                    array.push(item)
                    if (i == response.crypto_list.length - 1) {

                        setSecondCryptoCurrencyApi(array)
                        setLoader(false)
                    } else {

                        // setSellectedSecondCryptoCurrencyApi(JSON.stringify(item))
                    }

                }
            })
            setIsLoader(false)

        } catch (err) { console.log(err, 'errSwap') }
    };

    useEffect(() => {
        GetData();
    }, []);


    const handleChange = async (event) => {
        setSellectedCurrency(event.target.value);
        const dd = JSON.parse(event.target.value)
        setLoader(true)

        let array = []
        cryptoCurrencyApi.map((item, i) => {
            if (dd.crpto_id != item.crpto_id) {
                array.push(item)
                if (i == cryptoCurrencyApi.length - 1) {

                    setSecondCryptoCurrencyApi(array)
                    setLoader(false)
                } else {

                    setSellectedSecondCryptoCurrencyApi(JSON.stringify(item))
                    if (chek) {

                        setWalletAddress(item.Address)
                    }
                }

            }
        })


    };

    const handleChangeSecondCurrency = async (event) => {


        const dd = JSON.parse(event.target.value)
        setSellectedSecondCryptoCurrencyApi(event.target.value);

        if (chek) {

            setWalletAddress(dd.Address)
        }
    };

    const handleChangeTime = (event) => {
        setSellectedTime(event.target.value);

    };

    const CheckOrNot = () => {
        if (!chek) {
            setChek(1)
            setWalletAddress(selectedSecondCryptoCurrency.Address)
        } else {
            setChek(0)
            setWalletAddress(adDetails.recive_wallet)

        }
    }
    const SenderCoin = async (e) => {


        if (!isNaN(e.target.value)) {


            setSenderCoin(e.target.value)

            const res = await API().postCryptoCheck(
                selectedCurrency.currreny_name,
                selectedSecondCryptoCurrency.currreny_name,
                e.target.value
            )
            setReceiverCoin(res.crypto_value)
        }
    }

    if (isLoader) {
        return <></>
    } else {

        return (
            <Modal open={open}>
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 style={{}}>Want to Edit your Ad? </h2>
                        </div>
                        <div className="modal-body">

                            <div className="mt-3">

                                <h6>{selectedCurrency.currreny_name} Coin That You Send:</h6>
                                {validation.SenderCoin ?
                                    <div
                                        className="border d-flex justify-content-between rounded shadow-sm"
                                        style={{
                                            backgroundColor: "white",
                                            padding: 0,
                                            paddingLeft: 10,
                                            height: 42, width: '90%',
                                        }}
                                    >
                                        <input
                                            value={senderCoin}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "75%",
                                                paddingLeft: 20,
                                            }}

                                            onChange={(e) => SenderCoin(e)}
                                            placeholder="0.0"
                                        />
                                        <select
                                            style={{ height: 40, width: '25%' }}
                                            className="form-select shadow-none "
                                            aria-label="Default select example"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            {cryptoCurrencyApi.map((item) => {

                                                return (
                                                    <>
                                                        {item.currreny_name == selectedCurrency.currreny_name ?
                                                            <option selected>
                                                                {selectedCurrency.currreny_name}
                                                            </option>
                                                            :
                                                            <option key={item.crpto_id} value={JSON.stringify(item)}>
                                                                {item.currreny_name}
                                                            </option>}
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    :
                                    <div
                                        className="border border-danger d-flex justify-content-between rounded shadow-sm"
                                        style={{
                                            backgroundColor: "white",
                                            padding: 0,
                                            paddingLeft: 10,
                                            height: 42, width: '90%',
                                        }}
                                    >
                                        <input
                                            value={senderCoin}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "75%",
                                                paddingLeft: 20,
                                            }}

                                            onChange={(e) => SenderCoin(e)}
                                            placeholder="Please Enter Amount"
                                        />
                                        <select
                                            style={{ height: 40, width: '25%' }}
                                            className="form-select shadow-none "
                                            aria-label="Default select example"
                                            onChange={(e) => handleChange(e)}
                                        >
                                            {cryptoCurrencyApi.map((item) => {

                                                return (
                                                    <>
                                                        {item.currreny_name == selectedCurrency.currreny_name ?
                                                            <option selected>
                                                                {selectedCurrency.currreny_name}
                                                            </option>
                                                            :
                                                            <option key={item.crpto_id} value={JSON.stringify(item)}>
                                                                {item.currreny_name}
                                                            </option>}
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </div>
                                }
                            </div>
                            <h6 className="mt-2 text-center text-danger">{resMessage}</h6>

                            <div className="mt-3">
                                <h6>{selectedSecondCryptoCurrency.currreny_name} Coin That You Receive:</h6>
                                {validation.ReceiverCoin ?
                                    <div
                                        className="border d-flex justify-content-between rounded shadow-sm align-items-center"
                                        style={{
                                            backgroundColor: "white",
                                            padding: 0,
                                            paddingLeft: 10,
                                            height: 42, width: '90%',
                                        }}
                                    >
                                        <input
                                            value={receiverCoin}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "75%",
                                                paddingLeft: 20,
                                            }}

                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setReceiverCoin(e.target.value);
                                                }
                                            }}

                                            placeholder="0.0"
                                        />
                                        {loader ?
                                            <div style={{ width: 30, height: 30, marginRight: 50, marginTop: 5 }} className="spinner-border text-secondary" role="status"></div>
                                            :
                                            <select
                                                style={{ height: 41, width: '25%' }}
                                                className="form-select shadow-none "
                                                aria-label="Default select example"
                                                onChange={(e) => handleChangeSecondCurrency(e)}
                                            >
                                                {secondCryptoCurrencyApi.map((item) => {
                                                    return (
                                                        <>
                                                            {item.currreny_name == selectedSecondCryptoCurrency.currreny_name ?
                                                                <option selected>
                                                                    {selectedSecondCryptoCurrency.currreny_name}
                                                                </option>
                                                                :
                                                                <option key={item.crpto_id} value={JSON.stringify(item)}>
                                                                    {item.currreny_name}
                                                                </option>}
                                                        </>
                                                    );
                                                })}
                                            </select>
                                        }
                                    </div>
                                    :
                                    <div
                                        className="border border-danger d-flex justify-content-between rounded shadow-sm align-items-center"
                                        style={{
                                            backgroundColor: "white",
                                            padding: 0,
                                            paddingLeft: 10,
                                            height: 42, width: '90%',
                                        }}
                                    >
                                        <input
                                            value={receiverCoin}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "75%",
                                                paddingLeft: 20,
                                            }}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setReceiverCoin(e.target.value);
                                                }
                                            }}
                                            placeholder="Please Enter Amount"
                                        />
                                        {loader ?
                                            <div style={{ width: 30, height: 30, marginRight: 50, marginTop: 5 }} className="spinner-border text-secondary" role="status"></div>
                                            :
                                            <select
                                                style={{ height: 41, width: '25%' }}
                                                className="form-select shadow-none "
                                                aria-label="Default select example"
                                                onChange={(e) => handleChangeSecondCurrency(e)}
                                            >
                                                {secondCryptoCurrencyApi.map((item) => {
                                                    return (
                                                        <>
                                                            {item.currreny_name == selectedSecondCryptoCurrency.currreny_name ?
                                                                <option selected>
                                                                    {selectedSecondCryptoCurrency.currreny_name}
                                                                </option>
                                                                :
                                                                <option key={item.crpto_id} value={JSON.stringify(item)}>
                                                                    {item.currreny_name}
                                                                </option>}
                                                        </>
                                                    );
                                                })}
                                            </select>
                                        }
                                    </div>
                                }
                            </div>


                            <h6 className="mt-2">Active Duration</h6>
                            <select
                                onChange={(e) => handleChangeTime(e)}
                                className="form-select"
                                aria-label="Default select example"
                                style={{
                                    backgroundColor: "white",
                                    padding: 0,
                                    paddingLeft: 30,
                                    height: 42, width: '90%',
                                }}
                            >
                                {timeDuration.map((item, i) => {
                                    return (
                                        <option key={i} value={item.time}>
                                            {item.time}  {item.name}
                                        </option>
                                    );
                                })}
                            </select>

                            <div className="mt-3">
                                <div className="d-flex ">

                                    <h6> FoloSwap {selectedSecondCryptoCurrency.currreny_name} Wallet Address:</h6>
                                    {!chek ?
                                        <div style={{ marginLeft: 5, height: 22, width: 22, border: '1px solid rgb(187, 187, 187)' }} className="d-flex justify-content-center align-items-center rounded" onClick={CheckOrNot}>
                                            <AiOutlineCheck size={20} color='rgb(187, 187, 187)' />
                                        </div>
                                        :
                                        <div style={{ marginLeft: 5, height: 22, width: 22, border: '1px solid green' }} className="d-flex justify-content-center align-items-center rounded" onClick={CheckOrNot}>
                                            <AiOutlineCheck size={20} color='green' />
                                        </div>
                                    }
                                </div>
                                {validation.WalletAddress ?
                                    <div
                                        className="border d-flex  justify-content-between rounded shadow-sm"
                                        style={{
                                            backgroundColor: "white",
                                            padding: 0,
                                            paddingLeft: 10,
                                            height: 42, width: '90%',
                                        }}
                                    >
                                        <input
                                            value={walletAddress}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "100%",
                                                paddingLeft: 20,
                                            }}

                                            onChange={(e) => setWalletAddress(e.target.value)}
                                            placeholder="Wallet Address"
                                        />

                                    </div>
                                    :
                                    <div
                                        className="border border-danger d-flex  justify-content-between rounded shadow-sm"
                                        style={{
                                            backgroundColor: "white",
                                            padding: 0,
                                            paddingLeft: 10,
                                            height: 42, width: '90%',
                                        }}
                                    >
                                        <input
                                            value={walletAddress}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "100%",
                                                paddingLeft: 20,
                                            }}

                                            onChange={(e) => setWalletAddress(e.target.value)}
                                            placeholder="Please Enter Wallet Address"
                                        />

                                    </div>
                                }
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={CreateSellingAdsModClose}
                                type="button"
                                className="btn px-4 rounded-pill border-0 btn-primary"
                                style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
                            >
                                Close
                            </button>
                            <button
                                onClick={CreateSwapAd}
                                type="button"
                                className="btn px-4 rounded-pill shadow-none border-0 btn-primary"
                                style={{ backgroundColor: Colors.darkBlue }}
                            >
                                {btnLoader ? <div style={{ width: 20, height: 20, marginRight: 40, marginLeft: 40, marginTop: 5 }} className="spinner-border text-secondary" role="status"></div> :
                                    'Ad Edit'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default EdditSwapAdsMod;
