import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import API from "../../Api";
import * as Colors from '../../Constant/Colors'



function ChangePassword(props) {
  const [isloading, setIsloading] = useState(false);
  const { open, close, userDetaiL } = props;
  const { first_name, last_name, email, image } = userDetaiL;
  const user_Data = JSON.parse(localStorage.getItem("userData"));

  const { id } = user_Data.data;
  const ChangePasswordModClose = () => {
    close(false);
    setPassword("");
    setChangePassword("");
    setValidation({
      pass: true,
      chgPass: true,
    });
    setPasswordResponce("");
  };

  const [password, setPassword] = useState("");
  const [passwordResponce, setPasswordResponce] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [visiblepass, Setvisiblepass] = useState({
    Type: "password",
    iconName: BsEyeSlash,
  });
  const [visibleChangepass, SetvisibleChangepass] = useState({
    Type: "password",
    iconName: BsEyeSlash,
  });
  const [validation, setValidation] = useState({
    pass: true,
    chgPass: true,
  });
  const pwValid = (inputtxt) => {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    if (inputtxt.match(passw)) {
      // alert('Correct, try another...')
      return true;
    }
    else {
      // alert('Wrong...!')
      return false;
    }
  }
  const ChgPasValid = function (cpw) {
    return function (cpw) {
      if (cpw.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  const UpdatePassword = async () => {
    // var psValid = pasValid("nm");
    var pswVal = pwValid(password);
    var cpwValid = ChgPasValid("nm");
    var cpwVal = cpwValid(changePassword);
    setValidation({
      pass: pswVal,
      chgPass: cpwVal,
    });

    if (pswVal && cpwVal) {
      try {
        setIsloading(true);
        const response = await API().postChangePassword(
          id,
          password,
          changePassword
        );
        setPasswordResponce(response.message);
        // setChangePassword('');
        // setPassword('');
        if (response.message === "Password Updated") {
          close(false);
          setPasswordResponce("");
          setPassword("");
          setChangePassword("");
        }
        setIsloading(false);
      } catch (err) {
        console.log(err, "chngpasserr");
      }
    } else {
      setPasswordResponce("Password Shouldbe 8 to 20 characters which contain at least one Number, one Uppercase and lowercase letter");

    }
  };
  if (isloading) {
    return (
      <div
        style={{ width: "100vw", height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <div class="spinner-border text-secondary" role="status"></div>
      </div>
    );
  } else {
    return (
      <div>
        <Modal open={open}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1>Change Password</h1>
              </div>
              <div className="modal-body">
                <div className=" d-flex ">
                  <img
                    src={image}
                    style={{ height: 150, width: 150 }}
                    className="img-thumbnail rounded-circle"
                    alt="Profile"
                  ></img>
                  <div className=" mt-4 ">
                    <h3
                      style={{ color: "gray", marginTop: 10, marginLeft: 10 }}
                    >
                      {first_name} {last_name}
                    </h3>
                    <h5 style={{ color: "gray", marginLeft: 10 }}>{email}</h5>
                  </div>
                </div>
                <h6 className="text-danger text-center">{passwordResponce}</h6>
                <div className="mt-2">
                  {validation.pass ? (
                    <>
                      <h6 className="mt-3">Old Password:</h6>
                      <div
                        className="  d-flex justify-content-between border shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",

                          paddingTop: 10,
                          paddingBottom: 10,

                          borderRadius: 5,
                        }}
                      >
                        <input
                          value={password}
                          className="form-control border-0 shadow-none  "
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                          type={visiblepass.Type}
                          placeholder="Enter Old Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <visiblepass.iconName
                          size={30}
                          style={{ marginRight: "5%" }}
                          onClick={() => {
                            visiblepass.Type === "password"
                              ? Setvisiblepass({
                                Type: "text",
                                iconName: BsEye,
                              })
                              : Setvisiblepass({
                                Type: "password",
                                iconName: BsEyeSlash,
                              });
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <h6 className=" mt-3 text-danger">Old Password:</h6>
                      <div
                        className="  d-flex justify-content-between border border-danger shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",

                          paddingTop: 10,
                          paddingBottom: 10,

                          borderRadius: 5,
                        }}
                      >
                        <input
                          value={password}
                          className="form-control border-0 shadow-none  "
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                          type={visiblepass.Type}
                          placeholder="Enter Old Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <visiblepass.iconName
                          size={30}
                          style={{ marginRight: "5%" }}
                          onClick={() => {
                            visiblepass.Type === "password"
                              ? Setvisiblepass({
                                Type: "text",
                                iconName: BsEye,
                              })
                              : Setvisiblepass({
                                Type: "password",
                                iconName: BsEyeSlash,
                              });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="mt-2">
                  {validation.chgPass ? (
                    <>
                      <h6>New Password:</h6>
                      <div
                        className="  d-flex justify-content-between border shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",

                          paddingTop: 10,
                          paddingBottom: 10,

                          borderRadius: 5,
                        }}
                      >
                        <input
                          value={changePassword}
                          className="form-control border-0 shadow-none  "
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                          type={visibleChangepass.Type}
                          placeholder="Enter New Password"
                          onChange={(e) => setChangePassword(e.target.value)}
                        />
                        <visibleChangepass.iconName
                          size={30}
                          style={{ marginRight: "5%" }}
                          onClick={() => {
                            visibleChangepass.Type === "password"
                              ? SetvisibleChangepass({
                                Type: "text",
                                iconName: BsEye,
                              })
                              : SetvisibleChangepass({
                                Type: "password",
                                iconName: BsEyeSlash,
                              });
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <h6 className="text-danger">New Password:</h6>
                      <div
                        className="  d-flex justify-content-between border border-danger shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",

                          paddingTop: 10,
                          paddingBottom: 10,

                          borderRadius: 5,
                        }}
                      >
                        <input
                          value={changePassword}
                          className="form-control border-0 shadow-none  "
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                          type={visibleChangepass.Type}
                          placeholder="Enter New Password"
                          onChange={(e) => setChangePassword(e.target.value)}
                        />
                        <visibleChangepass.iconName
                          size={30}
                          style={{ marginRight: "5%" }}
                          onClick={() => {
                            visibleChangepass.Type === "password"
                              ? SetvisibleChangepass({
                                Type: "text",
                                iconName: BsEye,
                              })
                              : SetvisibleChangepass({
                                Type: "password",
                                iconName: BsEyeSlash,
                              });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="modal-footer ">
                <button

                  onClick={ChangePasswordModClose}
                  type="button"

                  className="btn px-4 rounded-pill border-0 btn-primary"
                  style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
                >
                  Close
                </button>
                <button
                  onClick={UpdatePassword}

                  type="button"
                  className="btn px-4 rounded-pill border-0 btn-primary"
                  style={{ backgroundColor: Colors.darkBlue, }}
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ChangePassword;
