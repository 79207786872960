import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";

function CreateBuyingAds(props) {

  const history = useHistory();


  const { open, stateClose, country, User_symbol, getdata, User_Id, setAlertShow,
    setAlertMessage } = props;
  const [currencyApi, setCurrencyApi] = useState([]);
  const [sellectedCurrency, setSellectedCurrency] = useState(
    JSON.stringify({
      id: 1,
      name: "Bitcoin",
      symbol: "BTC",
    })
  );
  const timeDuration = [{ time: '30', name: 'min' }, { time: '20', name: 'min' }, { time: '10', name: 'min' }]
  const selectedCurrency = JSON.parse(sellectedCurrency);
  const [pamentMethod, setPamentMethod] = useState([]);
  const [selectedPamentMethod, setSelectedPamentMethod] = useState('');
  const [bankName, setBankName] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [FandDprice, setFandDprice] = useState(1);
  const [fixpriceBtnColor, setFixpriceBtnColor] = useState({ backgroundColor: Colors.darkBlue, color: Colors.lightGreen });
  const [dynpriceTColor, setDynpriceTColor] = useState({ backgroundColor: Colors.lightGreen, color: Colors.darkBlue });

  const pirceType = 1;

  const [fixPrice, setFixPirce] = useState("");
  const [percentage, setPercentage] = useState(0);
  let priceYouPay = fixPrice;
  let sellerSeePrice = fixPrice - (fixPrice / 100) * percentage.percentage;

  const [validation, setValidation] = useState({
    min: false
  });

  const [minCryptoPrice, setMinCryptoPrice] = useState(null);
  const [amountBTC, setAmountBTC] = useState("");
  const [minBTCAmount, setMinBTCAmount] = useState("");
  const [maxBTCAmount, setMaxBTCAmount] = useState("");
  const [sellectedTime, setSellectedTime] = useState("30");
  const [cryptoPrice, setCryptoPrice] = useState(0);
  const [priceloding, setPriceloding] = useState(true);
  var displyPrice = (cryptoPrice + cryptoPrice / 100 * percentage.percentage).toFixed(2)
  let SSp = fixPrice * maxBTCAmount
  // console.log(sellerSeePrice, maxBTCAmount, "maxBTCAmount....................................!");
  let sellerBtcPrice = Math.round(SSp)

  const fff = parseFloat(maxBTCAmount)
  const crtyptoSer = (fff + ((fff / 100) * percentage.percentage))

  // const crtyptoSe = parseFloat(crtyptoSer)
  const crtyptoSerch = (crtyptoSer).toFixed(7)


  // console.log(typeof (crtyptoSerch), 'crtyptocrtyptoSeSerch')
  // console.log(crtyptoSerch, 'crtyptocrtyptoSeSerch')
  // console.log(typeof (maxBTCAmount), typeof ((maxBTCAmount / 100)), typeof (percentage.percentage), 'crtyptocrtyptoSeSerch')
  const GetData = async () => {
    // console.log('top', 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
    try {
      // console.log('in', 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
      const response = await API().getcrypto();
      const Percentage = await API().getPercentage();
      // console.log(Percentage.data, 'Percentage')
      setPercentage(Percentage.data)
      setCurrencyApi(response);

      const bankData = await API().getbank(country);
      setSelectedBank(bankData[0].id,)
      setBankName(bankData);
      const pmntMthd = await API().getpementMethod();
      setPamentMethod(pmntMthd);
      // console.log(User_symbol, response[0].symbol, 'User_symbol')

      const rescrypto = await API().getCryptoCompairStatic(
        response[0].symbol,
        User_symbol
      )
      let key = Object.values(rescrypto.data)

      console.log('resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
      console.log(rescrypto.data, 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
      setCryptoPrice(key[0])
      setPriceloding(false)

      setSelectedPamentMethod(pmntMthd[0].id)
    } catch (err) { console.log(err, 'creatbuyingadd') }
  };

  useEffect(() => {
    GetData();
  }, []);

  const handleChange = async (event) => {
    // console.log(event.target.value, "event.target.value");
    setSellectedCurrency(event.target.value);

    let CryptoSymbol = JSON.parse(event.target.value)
    setPriceloding(true)
    if (FandDprice == 2) {
      setFixPirce(displyPrice)
    }
    const rescrypto = await API().getCryptoCompairStatic(
      CryptoSymbol.symbol,
      User_symbol
    )
    let key = Object.values(rescrypto.data)

    console.log('resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
    console.log(rescrypto.data, 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
    setCryptoPrice(key[0])
    let dPrice = (key[0] + key[0] / 100 * percentage.percentage).toFixed(2)

    if (FandDprice == 2) {
      setFixPirce(dPrice)
    }

    setPriceloding(false)


  };
  const handleChangeBank = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSelectedBank(event.target.value);
  };
  const handleChangePayment = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSelectedPamentMethod(event.target.value);
  };
  const handleChangeTime = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSellectedTime(event.target.value);


  };

  const MiniCryptoValid = function (pn) {
    return function (pn, maxBTCAmount) {
      if (pn > 0 && pn <= maxBTCAmount) {
        return true;
      } else {
        return false;
      }
    };
  };
  const CreateBuyingAdsModClose = () => {
    stateClose(false);
    setFixPirce("");
    setMinCryptoPrice("");
    setAmountBTC("");
    setMinBTCAmount("");
    setMaxBTCAmount("");
  };


  const Proceed = async () => {

    var MiniValid = MiniCryptoValid("ad");
    var Minidataa = MiniValid(minBTCAmount, maxBTCAmount);
    setValidation({ min: Minidataa })

    if (fixPrice > 0 && amountBTC > 0 && Minidataa && maxBTCAmount > 0) {
      console.log(selectedBank, "selectedBank....................................!");

      try {
        const postBuy = await API().postbuyingAd(
          User_Id,
          fixPrice,
          selectedCurrency.id,
          minCryptoPrice,

          amountBTC,
          minBTCAmount,
          maxBTCAmount,
          selectedPamentMethod,
          selectedBank,
          pirceType,
          sellectedTime,
          sellerBtcPrice,
          crtyptoSerch
        );
        console.log(postBuy, "......................");

        if (postBuy.status) {

          CreateBuyingAdsModClose();
          getdata();
          setAlertShow(true)
          setTimeout(() => {

            history.push("/mybuyingads")
            setAlertShow(false)

          }, 3000);
          setAlertMessage(postBuy.message)
        } else {
          CreateBuyingAdsModClose();
          getdata();
          setAlertShow(true)
          setTimeout(() => {

            setAlertShow(false)

          }, 3000);
          setAlertMessage(postBuy.message)
        }

      } catch (err) {


        console.log(err, "err");
      }
    }
  };

  const DynamicPrice = () => {
    setFandDprice(2)
    setFixPirce(displyPrice)
    setFixpriceBtnColor({ backgroundColor: Colors.lightGreen, color: Colors.darkBlue })


    setDynpriceTColor({ backgroundColor: Colors.darkBlue, color: Colors.lightGreen })
  }
  const FixedPrice = () => {
    setFandDprice(1)
    setFixPirce('')
    setFixpriceBtnColor({ backgroundColor: Colors.darkBlue, color: Colors.lightGreen })
    setDynpriceTColor({ backgroundColor: Colors.lightGreen, color: Colors.darkBlue })


  }

  return (
    <Modal open={open}>
      <div className="modal-dialog  modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mt-2 d-flex justify-content-between">
              <h6 style={{ color: Colors.darkBlue }}>Choose a CryptoCurrency </h6>

              <select
                style={{ width: "30%" }}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => handleChange(e)}
              >
                {currencyApi.map((item) => {

                  // console.log(item, selectedCurrency.symbol, 'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
                  return (
                    <>
                      {item.symbol == selectedCurrency.symbol ?
                        <option selected>
                          {selectedCurrency.symbol}
                        </option>
                        :
                        <option key={item.id} value={JSON.stringify(item)}>
                          {item.symbol}
                        </option>}
                    </>
                  );
                })}
              </select>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <h2>Price</h2>
              <div
                className="btn-group"
                role="group"
              // aria-label="Basic outlined example"
              >
                <button
                  onClick={FixedPrice}

                  style={{ backgroundColor: fixpriceBtnColor.backgroundColor, color: fixpriceBtnColor.color }}
                  type="button"
                  className="btn border-0 shadow-none"

                >
                  Fixed Price
                </button>
                <button
                  onClick={DynamicPrice}
                  style={{ backgroundColor: dynpriceTColor.backgroundColor, color: dynpriceTColor.color }}
                  type="button"
                  className="btn border-0 shadow-none"
                >
                  Dynamic Price
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center border col-6 mt-2 ms-auto">
              {priceloding ? (
                <div className="spinner-border text-secondary" role="status"></div>
              ) : (
                <>
                  <h5 className="text-success mt-2"> {displyPrice} </h5>

                  <h5 className=" mt-2 ms-2">{selectedCurrency.symbol}/{User_symbol}</h5>
                </>
              )}
            </div>
            <div className="">
              {FandDprice === 1 ? (
                <>
                  {fixPrice <= 0 ? (
                    <>
                      <h6 className="text-danger">Fixed Price:</h6>
                      <div
                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>{User_symbol}</h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>Fixed Price:</h6>
                      <div
                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>{User_symbol}</h5>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {fixPrice <= 0 ? (
                    <>
                      <h6 className="text-danger">bitUSD Price:</h6>
                      <div
                        className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {User_symbol}/bitUSD
                        </h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>bitUSD Price:</h6>
                      <div
                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {User_symbol}/bitUSD
                        </h5>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="mt-2">
              <h6>{selectedCurrency.symbol} Price That Seller See:</h6>
              <div
                className="border d-flex justify-content-between rounded shadow-sm"
                style={{
                  backgroundColor: "rgb(237, 237, 237)",
                  padding: 6,
                  paddingLeft: 10,
                  height: 40,
                }}
              >
                <span style={{ paddingLeft: 10, width: "80%" }}>{sellerSeePrice}</span>
                <h5 style={{}}>
                  {User_symbol}/{selectedCurrency.symbol}
                </h5>
              </div>
            </div>
            {FandDprice === 1 ? (
              <></>
            ) : (
              <>
                <div className="mt-2">
                  {minCryptoPrice <= 0 ? (
                    <>
                      <h6 className="text-danger">
                        Minimum {selectedCurrency.symbol} Price:
                      </h6>
                      <div
                        className="border d-flex border-danger justify-content-between rounded shadow-sm"
                        style={{
                          padding: 6,
                          paddingLeft: 10,
                          height: 40,
                        }}
                      >
                        <input
                          value={minCryptoPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinCryptoPrice(e.target.value);
                            }
                          }}
                          placeholder="0"
                          className="form-control border-0 shadow-none  "
                          style={{ width: "85%" }}
                        ></input>
                        <h5>{User_symbol}</h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>Minimum {selectedCurrency.symbol} Price:</h6>
                      <div
                        className="border d-flex justify-content-between rounded shadow-sm"
                        style={{
                          padding: 6,
                          paddingLeft: 10,
                          height: 40,
                        }}
                      >
                        <input
                          value={minCryptoPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinCryptoPrice(e.target.value);
                            }
                          }}
                          placeholder="0"
                          className="form-control border-0 shadow-none  "
                          style={{ width: "85%" }}
                        ></input>
                        <h5>{User_symbol}</h5>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="mt-2">
              <h6>{selectedCurrency.symbol} Price That You Pay:</h6>
              <div
                className="border d-flex justify-content-between rounded shadow-sm"
                style={{
                  backgroundColor: "rgb(237, 237, 237)",
                  padding: 6,
                  paddingLeft: 10,
                  height: 40,
                }}
              >
                <span style={{ width: "80%", paddingLeft: 10 }}>{priceYouPay}</span>
                <h5 style={{}}>
                  {User_symbol}/{selectedCurrency.symbol}
                </h5>
              </div>
            </div>
            <h2>Amount</h2>
            <div className="mt-2">
              {amountBTC <= 0 ? (
                <>
                  <h6 className="text-danger">
                    Amount of {selectedCurrency.symbol}:
                  </h6>
                  <div
                    className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={amountBTC}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setAmountBTC(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  <h6>Amount of {selectedCurrency.symbol}:</h6>
                  <div
                    className=" mt-3 border rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={amountBTC}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setAmountBTC(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              )}
            </div>
            <div className="mt-2">
              {minBTCAmount.length <= 0 ? (

                <>
                  <h6 className="text-danger">
                    Minimum {selectedCurrency.symbol} Amount:
                  </h6>
                  <div
                    className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={minBTCAmount}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setMinBTCAmount(e.target.value);
                          setValidation({ min: true })
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  {!validation.min ?
                    <>
                      <h6 className="text-danger">
                        Minimum {selectedCurrency.symbol} Amount:
                      </h6>
                      <div
                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={minBTCAmount}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinBTCAmount(e.target.value);
                              setValidation({ min: true })
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {selectedCurrency.symbol}
                        </h5>
                      </div>
                    </>
                    :
                    <>
                      <h6>Minimum {selectedCurrency.symbol} Amount:</h6>
                      <div
                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={minBTCAmount}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinBTCAmount(e.target.value);
                              setValidation({ min: true })

                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {selectedCurrency.symbol}
                        </h5>
                      </div>
                    </>}
                </>
              )}
            </div>
            <div className="mt-2">
              {maxBTCAmount <= 0 ? (
                <>
                  <h6 className="text-danger">
                    Maximum {selectedCurrency.symbol} Amount:
                  </h6>
                  <div
                    className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={maxBTCAmount}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setMaxBTCAmount(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  <h6>Maximum {selectedCurrency.symbol} Amount:</h6>
                  <div
                    className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={maxBTCAmount}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setMaxBTCAmount(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              )}
            </div>
            <h2 className="mt-2">Active Duration</h2>
            <select
              onChange={(e) => handleChangeTime(e)}
              className="form-select"
              aria-label="Default select example"

            >
              {timeDuration.map((item, i) => {
                return (
                  <option key={i} value={item.time}>
                    {item.time}  {item.name}
                  </option>
                );
              })}
            </select>

            <h2>Payment Details</h2>
            <div className="mt-2">
              <h6>Payment Method</h6>
              <select
                onChange={(e) => handleChangePayment(e)}
                className="form-select"
                aria-label="Default select example"
              >
                {pamentMethod.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-2">
              <h6>Bank Name</h6>
              <select
                onChange={(e) => handleChangeBank(e)}
                className="form-select"
                aria-label="Default select example"
              >
                {bankName.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.bank_name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={CreateBuyingAdsModClose}
              type="button"
              className="btn px-4 rounded-pill border-0 btn-primary"
              style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
            >
              Close
            </button>
            <button
              onClick={Proceed}
              type="button"
              className="btn px-4 border-0 rounded-pill btn-primary"
              style={{ backgroundColor: Colors.darkBlue }}
            >
              Create Buying Ad
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateBuyingAds;
