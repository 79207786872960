import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";


import { useHistory } from "react-router-dom";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import * as Colors from "../../Constant/Colors";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CreateSwapAd from "./CreateSwapAd";
import OfferBid from "./OfferBid";
import { GoPlus } from "react-icons/go";
import SearchListModal from "./SearchListModal";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
        marginTop: "5%",

        // alignItems: "center",
        // justifyContent: "center",
    },
    paper: {
        width: "100%",
        padding: 10,
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

export default function AddList(props) {


    const { swapAdList, setAlertShow, setAlertMessage, cryptoCurrencyApi } = props

    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, email } = user_Data.data




    const history = useHistory();


    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");

    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [createSellingAdsMod, setCreateSellingAdsMod] = useState(false);
    const [searchAdsMod, setSearchAdsMod] = useState(false);
    const [offerBidMod, setOfferBidMod] = useState(false);
    const [adDetails, setAdDetails] = useState({});
    const [data, setData] = useState(0);

    const [bidUserWalletAddress, setBidUserWalletAddress] = useState('');
    const [bidUserWalletSend, setBidUserWalletSend] = useState('');


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const CreateSellingAdsModOpen = () => {
        setCreateSellingAdsMod(true);
    };
    const SearchModOpen = () => {
        setSearchAdsMod(true);
    };

    const MySwapOffers = () => {


        history.push({
            pathname: "/myswapoffers",
        })
    }
    const MySwapAds = () => {


        history.push({
            pathname: "/myswapads",
        })
    }
    // console.log(cryptoCurrencyApi, '====================================');

    const OfferBidModOpen = (item) => {
        setData(1)
        setAdDetails(item)
        // cryptoCurrencyApi.map((i) => {
        //     if (item.send_crypto_symbol == i.currreny_name) {

        //     }
        // })
        cryptoCurrencyApi.map((i, ind) => {

            if (item.recive_crypto_symbol == i.currreny_name) {
                setOfferBidMod(true)
                setBidUserWalletAddress(i)

                setBidUserWalletSend(i)
                // if (ind == cryptoCurrencyApi.length - 1) {

                //     console.log('bidUserWalletSend')
                //     setOfferBidMod(true)
                // }

            }
        })
    }
    const OfferBidModClose = () => {
        setOfferBidMod(false)
        setData(0)
        setAdDetails('')
    }



    return (
        <>
            {!searchAdsMod ?
                <div className={classes.root}>


                    <Paper className={classes.paper}>
                        <div className=" d-lg-flex justify-content-between ">

                            <Button
                                onClick={CreateSellingAdsModOpen}
                                variant="contained"
                                color="primary"
                                className="btn shadow-none mt-1 col-lg-3 col-12 border-0 btn-primary"
                                style={{
                                    backgroundColor: Colors.darkBlue,

                                    fontSize: 16,
                                }}
                            >

                                <GoPlus size={20} style={{ marginRight: 10 }} />   Create Swap Ads
                            </Button>
                            <Button
                                onClick={SearchModOpen}
                                variant="contained"
                                color="primary"
                                className="btn  mt-1 shadow-none col-lg-2 col-12 border-0 btn-primary"
                                style={{
                                    backgroundColor: Colors.darkBlue,

                                    fontSize: 16,
                                }}
                            >
                                Search Ads
                            </Button>
                        </div>
                        <div className=" text-center">
                            <h4>Want to Swap ? Here are some ads </h4>
                        </div>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? "small" : "medium"}
                                aria-label="enhanced table"
                            >
                                <TableRow hover>
                                    {/* <TableCell align="center">#</TableCell> */}
                                    <TableCell align="center">name</TableCell>
                                    <TableCell align="center">You will recieve</TableCell>
                                    <TableCell align="center"> You will send </TableCell>
                                    <TableCell align="center">Reciver Wallet Address</TableCell>
                                    <TableCell align="center">Send Offers</TableCell>
                                </TableRow>
                                <TableBody>
                                    {stableSort(swapAdList, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (

                                                <TableRow hover key={row.ad_id}>

                                                    <TableCell align="center">{row.name}</TableCell>

                                                    <TableCell align="center">

                                                        {(row.sell_crypto)} {row.send_crypto_symbol}

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.recive_crypto}  {row.recive_crypto_symbol}

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.recive_wallet}

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            onClick={() => OfferBidModOpen(row)}
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            className="btn shadow-none border-0 btn-primary"
                                                            style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                                        >
                                                            Send Offers
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>

                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={swapAdList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>

                    <div className="d-md-flex justify-content-between">
                        <Button
                            onClick={MySwapOffers}
                            variant="contained"
                            color="primary"
                            type="button"
                            className="btn mt-1 shadow-none border-0 btn-primary"
                            style={{ width: 150, backgroundColor: Colors.lightBlue, color: "white" }}
                        >
                            My Offers
                        </Button>
                        <Button
                            onClick={MySwapAds}
                            variant="contained"
                            color="primary"
                            type="button"
                            className="btn mt-1 shadow-none border-0 btn-primary"
                            style={{ width: 150, backgroundColor: Colors.lightBlue, color: "white" }}
                        >
                            My Ads
                        </Button>
                    </div>

                    {/*############################### 
        ### Create Sellings Ads Modal ### 
        ###############################*/}
                    <CreateSwapAd

                        setAlertShow={setAlertShow}
                        setAlertMessage={setAlertMessage}
                        setCreateSellingAdsMod={setCreateSellingAdsMod}
                        id={id}
                        open={createSellingAdsMod}
                    />
                    {/*############################### 
        ### OfferBid Modal ### 
        ###############################*/}
                    {data ?
                        <OfferBid

                            setAlertShow={setAlertShow}
                            setAlertMessage={setAlertMessage}
                            bidUserWalletResceive={bidUserWalletAddress}
                            bidUserWalletSend={bidUserWalletSend}
                            open={offerBidMod}
                            close={OfferBidModClose}
                            adDetails={adDetails}
                            id={id}
                        />
                        : <></>}
                </div>


                :

                <SearchListModal

                    setAlertShow={setAlertShow}
                    setAlertMessage={setAlertMessage}
                    setSearchAdsMod={setSearchAdsMod}
                    id={id}
                    open={searchAdsMod}
                />
            }
        </>
    );
}

