import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { NavLink } from "react-router-dom";
import * as Colors from "../Constant/Colors";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "rgb(230, 86, 136)",
        alignItems: "center",
        height: 80,
        // justifyContent: "center",
    },
}));

export default function NavBar() {
    const classNamees = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark " style={{ backgroundColor: Colors.darkBlue }}>
            <div className="container-fluid">
                <a activeClassName="active"
                    href="https://foloswap.com/" className="navbar-brand " style={{ marginLeft: "30px", color: 'white' }}>
                    FoloSwap
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav  mb-2 mb-lg-0">

                        <li
                            className="nav-item"
                            style={{ fontSize: "1rem", marginRight: "30px" }}
                        >
                            <NavLink
                                className="nav-link"


                                activeClassName="active"
                                to="/signup"
                            >
                                Sign up
                            </NavLink>
                        </li>

                        <li
                            className="nav-item"
                            style={{ fontSize: "1rem", marginRight: "30px" }}
                        >
                            <NavLink
                                className="nav-link"


                                activeClassName="active"
                                to="/signin"
                            >
                                Sign in
                            </NavLink>
                        </li>


                    </ul>
                </div>
            </div>
        </nav>

    );
}
