import React, { useState, useEffect, useContext } from 'react';
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import NavBar from '../NavBar';
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";
import API from "../../Api";

import { AiFillDelete, AiOutlineClose } from "react-icons/ai";

import { FiEdit } from "react-icons/fi";
import Modal from "@material-ui/core/Modal";
// import io from 'socket.io-client';
// const socket = io('http://162.241.121.1:3000');
import { useLocation } from "react-router-dom";
import EditBuyingAds from './EdditBuyingAdMod';
import CoustumAlert from '../../Constant/CoustumAlert'
import { ToseContext } from '../../reducer/Context';


function MySellingAds() {

    const { toseMassege, setToseMassege } = useContext(ToseContext)


    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, symbol, country } = user_Data.data;
    const [isloding, setIsloding] = useState(true);
    const [apiValueBuy, setApiValueBuy] = useState([]);
    const [date, setDate] = useState(0);
    const [data, setData] = useState({});
    const [openMyEditBuyingMod, setOpenMyEditBuyingMod] = useState(false);
    const [openDeletMod, setOpenDeletMod] = useState(false);
    const [deletId, setDeletId] = useState("");
    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [percentage, setPercentage] = useState('');
    const notification = useLocation();

    console.log(notification, '........................................................................!')
    const history = useHistory();

    const ShowOffers = (item) => {
        console.log(item, '..........................................item')
        // history.push("/details")

        history.push({
            pathname: "/mysellingadsoffers",
            state: { detail: item, percentage: percentage },
        })
    }

    const MyEditBuyAdsModOpen = (props) => {
        // console.log(props, "props");
        setDate(1);
        setData(props);
        setOpenMyEditBuyingMod(true);
    };
    const MyEditBuyAdsModClose = () => {
        setOpenMyEditBuyingMod(false);
        setDate(0);
    };

    const ConfirmDeleteModOpen = (id) => {
        // console.log(id, "propsid");
        setOpenDeletMod(true);
        setDeletId(id);
    };
    const DeleteAdd = async () => {
        try {
            const deletData = await API().getDeleteAds(deletId);
            // console.log(deletData, "Deletdata");
            ConfirmDeleteModClose();
            await GetData();
            // setIsLoding(false);
        } catch (err) {
            console.log(err, "gerdelet");
        }
    };
    const ConfirmDeleteModClose = () => {
        setOpenDeletMod(false);
    };

    const GetData = async () => {
        // console.log("helo");
        try {
            const MyGetAdds = await API().mygetadds(country, id);
            const { selling_ads } = MyGetAdds;
            // console.log(buying_ads, "buying_ads");
            // let myBuyinadd = []
            setApiValueBuy(selling_ads)
            const Percentage = await API().getPercentage();
            setPercentage(Percentage.data.percentage)
            setIsloding(false)

        } catch (error) {
            console.log(error, "errorMyBuyAdds");
        }
    };
    const DelNotification = async () => {
        const res = await API().getDeleteNotification(
            id
        )
        console.log(res, 'getDeleteNotification???????????????????????????????????????????')
    }

    if (toseMassege.hasOwnProperty('ad_id')) {

        DelNotification()
        setToseMassege('')
        GetData()
        console.log('.???????????????????????????????????????????????????????????');
    }

    useEffect(() => {
        GetData();
        // console.log(socket, 'http://162.241.121.1:3000.............................!')
    }, []);

    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol,/*  backgroundImage: `url(${backgrounimage})` */ }}>
                <NavBar />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {

        return <div className="overflow-auto" style={{ height: '100vh', backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }}>

            <NavBar />
            {alertShow ?
                <CoustumAlert alertMessage={alertMessage} />
                : <></>}
            <div className='card_container p-4  '>
                <h1 className='text-light text-center'>My Selling Ads</h1>
                {apiValueBuy.length > 0 ?

                    <div className=' mt-5  d-md-flex  flex-wrap'>
                        {apiValueBuy.map((item) => {
                            console.log(item, "item..............!");
                            return (

                                <>

                                    {notification.state == undefined ?
                                        <div className='col-md-6 col-lg-4 p-3   rounded-2  '>
                                            <div className='d-flex'>

                                                <h5 className='text-danger' style={{ backgroundColor: 'white', paddingLeft: 5, paddingRight: 5, borderRadius: 100 }} >{parseInt(item.price)}</h5>
                                                <h5 className='text-light' style={{ marginLeft: 10 }} >{`${symbol} / ${item.symbol}`}</h5>
                                            </div>
                                            <div className='d-flex'>
                                                <h5 className='text-light' >Amount Limit :
                                                </h5>
                                                <div>
                                                    <h5 className='text-light' >  {item.minimum_crypto_amount}{item.symbol} /

                                                    </h5>
                                                    <h5 className='text-light' >
                                                        {item.maximum_crypto_amount}{item.symbol}
                                                    </h5>
                                                </div>
                                            </div>
                                            <h5 className='text-light' >{item.bank_name}</h5>
                                            <h5 className='text-light' >{item.name}</h5>

                                            <div className='d-md-flex justify-content-between col-lg-8 '>                                            <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info text-light " onClick={() => ShowOffers(item)} >Show Offers {item.seller_status == 1 ? "(1)" :
                                                `(${item.offers_count})`
                                            }</button>

                                                <div className=" mt-2  col-md-3 justify-content-between col-8  d-flex ms-2 ">
                                                    <FiEdit
                                                        onClick={() => MyEditBuyAdsModOpen(item)}
                                                        size={22}
                                                        color="white"
                                                    // style={{ marginLeft: 20, marginTop: 20 }}
                                                    />
                                                    <AiFillDelete
                                                        onClick={() => ConfirmDeleteModOpen(item.ad_id)}
                                                        size={25}
                                                        color="white"
                                                    // style={{ marginLeft: 20, marginTop: 50 }}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        : <>
                                            {notification.state.detail.url == item.ad_id ?
                                                <div className='col-md-6 col-lg-4 p-3  rounded-2  '>
                                                    <div className='d-flex'>

                                                        <h5 className='text-danger' style={{ backgroundColor: 'white', paddingLeft: 5, paddingRight: 5, borderRadius: 100 }} >{parseInt(item.price)}</h5>
                                                        <h5 className='text-light' style={{ marginLeft: 10 }} >{`${symbol} / ${item.symbol}`}</h5>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <h5 className='text-light' >Amount Limit :
                                                        </h5>
                                                        <div>
                                                            <h5 className='text-light' >  {item.minimum_crypto_amount}{item.symbol} /

                                                            </h5>
                                                            <h5 className='text-light' >
                                                                {item.maximum_crypto_amount}{item.symbol}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <h5 className='text-light' >{item.bank_name}</h5>
                                                    <h5 className='text-light' >{item.name}</h5>
                                                    <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info text-light " onClick={ShowOffers(item)} >Show Offers</button>
                                                </div>
                                                :
                                                <></>}
                                        </>
                                    }
                                </>
                            )
                        })}
                    </div>

                    :
                    <div className='border p-5 text-center rounded text-light col-md-6 mx-auto mt-5'>

                        <h4>No Ads Yet</h4>
                    </div>}
            </div>

            {/*################################## 
        ######## Edit MyBuying Ads ######## 
        ##################################*/}
            {date == 1 ? (
                <EditBuyingAds

                    setAlertShow={setAlertShow}
                    setAlertMessage={setAlertMessage}
                    open={openMyEditBuyingMod}
                    close={MyEditBuyAdsModClose}
                    country={country}
                    user_Symbol={symbol}
                    Data={data}
                    getdata={GetData}
                />
            ) : (
                <></>
            )}

            {/*################################## 
        ####### Delete MyBuying Ads ####### 
        ##################################*/}
            <Modal open={openDeletMod}>
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div className="modal-body">
                            <div className="col-12 row justify-content-center">
                                <h5 className="text-center">Are you sure you want to Delete</h5>
                                <div className=" col-6 mt-2 d-flex justify-content-between">
                                    <button
                                        onClick={() => ConfirmDeleteModClose()}
                                        style={{ fontSize: 20, borderRadius: 20 }}
                                        type="button"
                                        className="btn mb-2 btn-outline-secondary"
                                    >
                                        Close
                                    </button>
                                    <button
                                        onClick={DeleteAdd}
                                        style={{ fontSize: 20, borderRadius: 20 }}
                                        type="button"
                                        className="btn mb-2 btn-outline-secondary"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>;
    }
}

export default MySellingAds;
