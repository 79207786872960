import React from "react";
import NavBar from "./NavBar";

function Notification() {
  return (
    <div className="App">
      <NavBar />
      <h1>Notification</h1>
    </div>
  );
}

export default Notification;
