import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from '../../Constant/Colors'


function EditBuyingAds(props) {
    const { open, close, Data, getdata, country, user_Symbol, setAlertShow,
        setAlertMessage } = props;
    // console.log(getdata, "getdata");
    const {
        account_name,
        ad_id,
        ad_type,
        amount_of_crypto,
        bank,
        bank_account_number,
        bank_name,

        country_name,
        crypto_currency,

        maximum_crypto_amount,
        minimum_crypto_amount,
        minimum_crypto_price,
        name,
        payment_method,
        price,

        symbol,
    } = Data;
    // console.log(ad_id, " ad_id");
    // console.log(Data, "data");
    const [currencyApi, setCurrencyApi] = useState([]);
    const [sellectedCurrency, setSellectedCurrency] = useState(
        JSON.stringify({
            id: crypto_currency,
            name: country_name,
            symbol: symbol,
        })
    );
    const selectedCurrency = JSON.parse(sellectedCurrency);
    const [pamentMethod, setPamentMethod] = useState([]);
    const [selectedPamentMethod, setSelectedPamentMethod] = useState(
        JSON.stringify({
            id: payment_method,
            name: name,
        })
    );
    const selectedPmntMthd = JSON.parse(selectedPamentMethod);

    const [bankName, setBankName] = useState([]);
    const [selectedBank, setSelectedBank] = useState(
        JSON.stringify({
            id: bank,
            bank_name: bank_name,
        })
    );
    const selectedBnk = JSON.parse(selectedBank);
    // console.log(selectedBnk, "selectedBank");

    const pirceType = 1;

    const [fixPrice, setFixPirce] = useState(price);
    const [percentage, setPercentage] = useState('');
    let priceYouPay = fixPrice;
    let sellerSeePrice = parseInt(fixPrice) - (fixPrice / 100) * percentage.percentage;

    const [minCryptoPrice, setMinCryptoPrice] = useState(minimum_crypto_price);
    const [amountBTC, setAmountBTC] = useState(amount_of_crypto);
    const [minBTCAmount, setMinBTCAmount] = useState(minimum_crypto_amount);
    const [maxBTCAmount, setMaxBTCAmount] = useState(maximum_crypto_amount);
    // console.log(selectedBank, "selectedbank");
    // console.log(selectedPamentMethod, "selectedPamentMethod");
    const GetData = async () => {
        try {
            const response = await API().getcrypto();
            const Percentage = await API().getPercentage();
            setPercentage(Percentage)
            setCurrencyApi(response);

            const bankData = await API().getbank(country);

            setBankName(bankData);
            const pmntMthd = await API().getpementMethod();

            setPamentMethod(pmntMthd);
        } catch { }
    };

    useEffect(() => {
        GetData();
    }, []);

    const handleChange = (event) => {
        // console.log(event.target.value, "event.target.value");
        setSellectedCurrency(event.target.value);
    };
    const handleChangeBank = (event) => {
        // console.log(event.target.value, "event.target.value");
        setSelectedBank(event.target.value);
    };
    const handleChangePayment = (event) => {
        // console.log(event.target.value, "event.target.value");
        setSelectedPamentMethod(event.target.value);
    };
    // console.log(selectedBank, "sss");
    const Proceed = async () => {
        try {
            if (
                fixPrice > 0 &&
                amountBTC > 0 &&
                minBTCAmount > 0 &&
                maxBTCAmount > 0
            ) {
                const AddEditBuy = await API().postEditAdd(
                    ad_id,
                    amountBTC,
                    minBTCAmount,
                    maxBTCAmount,
                    selectedCurrency.id,
                    pirceType,
                    minCryptoPrice,
                    selectedBnk.id,

                    bank_account_number,
                    account_name,
                    ad_type,
                    fixPrice,

                    selectedPmntMthd.id
                );
                console.log(selectedBnk.id, "selectedbank");
                // console.log(selectedPmntMthd.id, "selectedPamentMethod");
                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)

                }, 3000);
                setAlertMessage(AddEditBuy.message)

                // console.log(AddEditBuy, "AddEditBuy");

                getdata();
                if (AddEditBuy.message === "Record Updated") {
                    close();
                }
            }
        } catch (err) {
            console.log(err, "err");
        }
    };

    return (
        <Modal open={open}>
            <div className="modal-dialog  modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="mt-2 d-flex justify-content-between">
                            <h6 style={{ color: Colors.darkBlue }}>Choose a CryptoCurrency </h6>

                            <select
                                style={{ width: "30%" }}
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => handleChange(e)}
                            >
                                <option selected>{selectedCurrency.symbol}</option>
                                {currencyApi.map((item) => {
                                    return (
                                        <>
                                            {item.id !== selectedCurrency.id ? (
                                                <>
                                                    <option key={item.id} value={JSON.stringify(item)}>
                                                        {item.symbol}
                                                    </option>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                })}
                            </select>
                        </div>

                        <div className="mt-2">
                            {fixPrice <= 0 ? (
                                <>
                                    <h6 className="text-danger">Fixed Price:</h6>
                                    <div
                                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={fixPrice}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setFixPirce(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>{user_Symbol}</h5>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h6>Fixed Price:</h6>
                                    <div
                                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={fixPrice}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setFixPirce(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>{user_Symbol}/bitUSD</h5>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mt-2">
                            <h6>{selectedCurrency.symbol} Price That Seller See:</h6>
                            <div
                                className="border d-flex justify-content-between rounded shadow-sm"
                                style={{
                                    backgroundColor: "rgb(237, 237, 237)",
                                    padding: 6,
                                    paddingLeft: 10,
                                    height: 40,
                                }}
                            >
                                <span style={{ width: "80%" }}>{sellerSeePrice}</span>
                                <h5 style={{}}>
                                    {user_Symbol}/{selectedCurrency.symbol}
                                </h5>
                            </div>
                        </div>
                        {minimum_crypto_price == null ? (
                            <></>
                        ) : (
                            <>
                                <div className="mt-2">
                                    {minCryptoPrice <= 0 ? (
                                        <>
                                            <h6 className="text-danger">
                                                Minimum {selectedCurrency.symbol} Price:
                                            </h6>
                                            <div
                                                className="border d-flex border-danger justify-content-between rounded shadow-sm"
                                                style={{
                                                    padding: 6,
                                                    paddingLeft: 10,
                                                    height: 40,
                                                }}
                                            >
                                                <input
                                                    value={minCryptoPrice}
                                                    onChange={(e) => {
                                                        if (!isNaN(e.target.value)) {
                                                            setMinCryptoPrice(e.target.value);
                                                        }
                                                    }}
                                                    placeholder="0"
                                                    className="form-control border-0 shadow-none  "
                                                    style={{ width: "85%" }}
                                                ></input>
                                                <h5>{user_Symbol}</h5>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h6>Minimum {selectedCurrency.symbol} Price:</h6>
                                            <div
                                                className="border d-flex justify-content-between rounded shadow-sm"
                                                style={{
                                                    padding: 6,
                                                    paddingLeft: 10,
                                                    height: 40,
                                                }}
                                            >
                                                <input
                                                    value={minCryptoPrice}
                                                    onChange={(e) => {
                                                        if (!isNaN(e.target.value)) {
                                                            setMinCryptoPrice(e.target.value);
                                                        }
                                                    }}
                                                    placeholder="0"
                                                    className="form-control border-0 shadow-none  "
                                                    style={{ width: "85%" }}
                                                ></input>
                                                <h5>{user_Symbol}</h5>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="mt-2">
                            <h6>{selectedCurrency.symbol} Price That You Pay:</h6>
                            <div
                                className="border d-flex justify-content-between rounded shadow-sm"
                                style={{
                                    backgroundColor: "rgb(237, 237, 237)",
                                    padding: 6,
                                    paddingLeft: 10,
                                    height: 40,
                                }}
                            >
                                <span style={{ width: "80%" }}>{priceYouPay}</span>
                                <h5 style={{}}>
                                    {user_Symbol}/{selectedCurrency.symbol}
                                </h5>
                            </div>
                        </div>
                        <h2>Amount</h2>
                        <div className="mt-2">
                            {amountBTC <= 0 ? (
                                <>
                                    <h6 className="text-danger">
                                        Amount of {selectedCurrency.symbol}:
                                    </h6>
                                    <div
                                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={amountBTC}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setAmountBTC(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>
                                            {selectedCurrency.symbol}
                                        </h5>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h6>Amount of {selectedCurrency.symbol}:</h6>
                                    <div
                                        className=" mt-3 border rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={amountBTC}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setAmountBTC(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>
                                            {selectedCurrency.symbol}
                                        </h5>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mt-2">
                            {minBTCAmount <= 0 ? (
                                <>
                                    <h6 className="text-danger">
                                        Minimum {selectedCurrency.symbol} Amount:
                                    </h6>
                                    <div
                                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={minBTCAmount}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setMinBTCAmount(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>
                                            {selectedCurrency.symbol}
                                        </h5>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h6>Minimum {selectedCurrency.symbol} Amount:</h6>
                                    <div
                                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={minBTCAmount}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setMinBTCAmount(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>
                                            {selectedCurrency.symbol}
                                        </h5>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="mt-2">
                            {maxBTCAmount <= 0 ? (
                                <>
                                    <h6 className="text-danger">
                                        Maximum {selectedCurrency.symbol} Amount:
                                    </h6>
                                    <div
                                        className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={maxBTCAmount}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setMaxBTCAmount(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>
                                            {selectedCurrency.symbol}
                                        </h5>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h6>Maximum {selectedCurrency.symbol} Amount:</h6>
                                    <div
                                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <input
                                            className="form-control border-0 shadow-none  "
                                            value={maxBTCAmount}
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value)) {
                                                    setMaxBTCAmount(e.target.value);
                                                }
                                            }}
                                            placeholder="0"
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                        />
                                        <h5 style={{ marginRight: "2%" }}>
                                            {selectedCurrency.symbol}
                                        </h5>
                                    </div>
                                </>
                            )}
                        </div>
                        <h2>Payment Details</h2>
                        <div className="mt-2">
                            <h6>Payment Method</h6>
                            <select
                                onChange={(e) => handleChangePayment(e)}
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected>{selectedPmntMthd.name}</option>
                                {pamentMethod.map((item) => {
                                    return (
                                        <>
                                            {item.id !== selectedPmntMthd.id ? (
                                                <>
                                                    <option key={item.id} value={JSON.stringify(item)}>
                                                        {item.name}
                                                    </option>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="mt-2">
                            <h6>Bank Name</h6>
                            <select
                                onChange={(e) => handleChangeBank(e)}
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected>{selectedBnk.bank_name}</option>
                                {bankName.map((item) => {
                                    // console.log(item, "itembank");
                                    return (
                                        <>
                                            {item.id !== selectedBnk.id ? (
                                                <>
                                                    <option key={item.id} value={JSON.stringify(item)}>
                                                        {item.bank_name}
                                                    </option>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={close}
                            type="button"
                            className="btn px-4 rounded-pill btn-primary"
                            style={{ backgroundColor: Colors.darkBlue }}
                        >
                            Close
                        </button>
                        <button
                            onClick={Proceed}
                            type="button"
                            className="btn px-4 rounded-pill btn-primary"
                            style={{ backgroundColor: Colors.darkBlue, }}
                        >
                            Edit Buying Ad
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default EditBuyingAds;
