import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { IoIosNotificationsOutline } from "react-icons/io";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: 200
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'end',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'end',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: "white" /* theme.palette.primary.main */,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: "black" /* theme.palette.common.white */,
            },
        },
    },
}))(MenuItem);

export default function MyadsDropDown() {

    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const MyByinAds = () => {
        history.push("/mybuyingads")
    }
    const MySellingAds = () => {
        history.push("/mysellingads")
    }

    return (
        <div style={{}}>
            <div className='btn'
                style={{ marginTop: 2, fontSize: "1rem", color: "white" }}
                // aria-controls="customized-menu"
                // aria-haspopup="true"
                // variant="contained"
                // color="primary"
                onClick={handleClick}
            >
                My Ads
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}

                onClose={handleClose}
            >
                {/* sssssssssss */}

                <StyledMenuItem>

                    <ListItemText primary="My Buying Ads" onClick={MyByinAds} />
                </StyledMenuItem>
                <StyledMenuItem>

                    <ListItemText primary="My Selling Ads" onClick={MySellingAds} />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
}
