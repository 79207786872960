import React, { useState, useEffect } from "react";
import API from "../../Api";
import IndexSellingsAds from "./IndexSellingsAds";
import IndexBuyinsAds from "./IndexBuyinsAds";
import NavBar from "../NavBar";

function IndexBS() {
  const user_Data = JSON.parse(localStorage.getItem("userData"));

  const { id, country, symbol } = user_Data.data;
  const [isLoading, setIsLoading] = useState(true);
  const [apiValueBuy, setApiValueBuy] = useState([]);
  const [apiValueSelling, setApiValueSelling] = useState([]);
  const [percentage, setPercentage] = useState('');
  // console.log(id, 'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')

  const getdata = async () => {

    try {

      const response = await API().getads(country, id);
      console.log(response, 'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')
      const { buying_ads, selling_ads } = response;

      setApiValueBuy(buying_ads);
      setApiValueSelling(selling_ads);
      const Percentage = await API().getPercentage();
      setPercentage(Percentage.data.percentage)
      setIsLoading(false);
    } catch (error) {
      console.log(error, "ssssss");
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  if (isLoading) {
    return (
      <div>
        <NavBar />
        <div
          style={{ width: "100vw", height: "100vh" }}
          className="d-flex justify-content-center  align-items-center"
        >
          <div class="spinner-border text-secondary" role="status"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <NavBar />
        <div className="mt-3">
          <IndexBuyinsAds
            getdata={getdata}
            User_Id={id}
            User_symbol={symbol}
            country={country}
            ApiBuyingAds={apiValueBuy}
            percentage={percentage}
          />
          <IndexSellingsAds
            User_Id={id}
            User_symbol={symbol}
            ApiSellingAds={apiValueSelling}
            country={country}
            getdata={getdata}
            percentage={percentage}
          />
        </div>
      </div>
    );
  }
}

export default IndexBS;
