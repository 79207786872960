import React, { useState, useEffect, useContext } from 'react';
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import NavBar from '../NavBar';
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";
import API from "../../Api";
// import io from 'socket.io-client';
// const socket = io('http://162.241.121.1:3000');
import { useLocation } from "react-router-dom";
import { ToseContext } from '../../reducer/Context';
function MyByinOffers() {

    const { toseMassege, setToseMassege } = useContext(ToseContext)

    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, symbol } = user_Data.data;
    const [isloding, setIsloding] = useState(true);
    const [myBuyingBids, setMyBuyingBids] = useState([]);
    const [myBidsDetails, setMyBidsDetails] = useState({});
    const [percentage, setPercentage] = useState('');
    const notification = useLocation();

    console.log(notification, '........................................................................!')
    const history = useHistory();

    const ShowDetails = (item) => {
        // history.push("/details")

        history.push({
            pathname: "/buyingoffersdetails",
            state: { detail: item, percentage: percentage },
        })
    }


    const GetApi = async () => {
        try {
            const respons = await API().getMyBids(id);
            const { buying_ads } = respons;

            setMyBuyingBids(buying_ads);
            const Percentage = await API().getPercentage();
            setPercentage(Percentage.data.percentage)
            setIsloding(false)
            // console.log(buying_ads, "myGetBids");
        } catch (err) {
            console.log(err, "myGetBids");
        }
    };

    const DelNotification = async () => {
        const res = await API().getDeleteNotification(
            id
        )
        console.log(res, 'getDeleteNotification???????????????????????????????????????????')
    }

    if (toseMassege.hasOwnProperty('ad_id')) {

        DelNotification()
        setToseMassege('')
        GetApi()
        console.log('.???????????????????????????????????????????????????????????');
    }
    useEffect(() => {
        GetApi();
        // console.log(socket, 'http://162.241.121.1:3000.............................!')
    }, []);

    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol,/*  backgroundImage: `url(${backgrounimage})` */ }}>
                <NavBar />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {

        return <div className="overflow-auto" style={{ height: '100vh', backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }}>

            <NavBar />
            <div className='card_container p-4  '>
                <h1 className='text-light text-center'>My Buying Offers</h1>
                {myBuyingBids.length > 0 ?

                    <div className=' mt-5  d-md-flex  flex-wrap'>
                        {myBuyingBids.map((item) => {
                            console.log(item, "myBuyingBids..........................................................!");
                            return (

                                <>

                                    {notification.state == undefined ?
                                        <div className='col-md-6 col-lg-4 p-3  rounded-2  '>
                                            <div className='d-flex'>

                                                <h5 className='text-success' style={{ backgroundColor: 'white', paddingLeft: 5, paddingRight: 5, borderRadius: 100 }} >{parseInt(item.price)}</h5>
                                                <h5 className='text-light' style={{ marginLeft: 10 }} >{`${symbol} / ${item.crpto_symbol}`}</h5>
                                            </div>
                                            <div className='d-flex'>
                                                <h5 className='text-light' >Amount Limit :
                                                </h5>
                                                <div>
                                                    <h5 className='text-light' >  {(item.minimum_crypto_amount - (item.minimum_crypto_amount / 100) * percentage).toFixed(7)} {item.crpto_symbol} /

                                                    </h5>
                                                    <h5 className='text-light' >
                                                        {(item.maximum_crypto_amount - (item.maximum_crypto_amount / 100) * percentage).toFixed(7)} {item.crpto_symbol}
                                                    </h5>
                                                </div>
                                            </div>
                                            <h5 className='text-light' >{item.bank_name}</h5>
                                            <h5 className='text-light' >{item.name} {item.last_name}</h5>
                                            <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info text-light " onClick={() => ShowDetails(item)} >Show Details</button>
                                        </div>

                                        : <>
                                            {notification.state.detail.url == item.ad_id ?
                                                <div className='col-md-6 col-lg-4 p-3  rounded-2  '>
                                                    <div className='d-flex'>

                                                        <h5 className='text-success' style={{ backgroundColor: 'white', paddingLeft: 5, paddingRight: 5, borderRadius: 100 }} >{parseInt(item.price)}</h5>
                                                        <h5 className='text-light' style={{ marginLeft: 10 }} >{`${symbol} / ${item.crpto_symbol}`}</h5>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <h5 className='text-light' >Amount Limit :
                                                        </h5>
                                                        <div>
                                                            <h5 className='text-light' >  {(item.minimum_crypto_amount + (item.minimum_crypto_amount / 100) * percentage).toFixed(7)} {item.crpto_symbol} /

                                                            </h5>
                                                            <h5 className='text-light' >
                                                                {(item.maximum_crypto_amount + (item.maximum_crypto_amount / 100) * percentage).toFixed(7)} {item.crpto_symbol}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <h5 className='text-light' >{item.bank_name}</h5>
                                                    <h5 className='text-light' >{item.name} {item.last_name}</h5>
                                                    <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info text-light " onClick={ShowDetails(item)} >Show Details</button>
                                                </div>
                                                :
                                                <></>}
                                        </>
                                    }
                                </>
                            )
                        })}
                    </div>

                    :
                    <div className='border p-5 text-center rounded text-light col-md-6 mx-auto mt-5'>

                        <h4>No Offer Yet</h4>
                    </div>}
            </div>


        </div>;
    }
}

export default MyByinOffers;
