import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { AiOutlineClose } from "react-icons/ai";
import * as Colors from '../../Constant/Colors'
import OtpInput from "react-otp-input";
import API from "../../Api";
import { FiSend, FiPaperclip, FiCameraOff } from "react-icons/fi";
import profile from '../../Images/image1.jpg';

// import io from 'socket.io-client';

// var socket = io('https://f557-162-241-121-1.ngrok.io');

function MySellingOffers(props) {
  const { open, close, offers, user_Data, data2, offersStatus0Lits, offersStatus1List } = props;
  const { email, id } = user_Data.data
  const [OTPValue, setOTPValue] = useState("");
  const [OTPLoader, setOTPLoader] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const [otpMod, setOtpMod] = useState(false);
  const [OTPres, setOTPres] = useState('');
  const [transectionImageApif, setTransectionImageApif] = useState({});
  const [transectionImgStatus, setTransectionImgStatus] = useState(false);
  const [transectionImgModal, setTransectionImgModal] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [message, setMessage] = useState('');
  const [resMessage, setResMessage] = useState([]);
  var getMessages = resMessage


  const MySellAdsOfferModClose = () => {
    close(false);
    data2(0)
    // set(true);
  };
  const OtpModClose = () => {
    setOtpMod(false);
    close(true);
    setOTPValue('')
    setOTPres('')

  };
  const OtpModOpen = async () => {

    try {

      const res = await API().postEmailConformationCode(id, email)
      if (res.status == true) {

        setOtpMod(true);
        close(false);

      }
    }
    catch (err) {
      console.log(err, 'erremailsend')
    }
  };
  const chatModOpen = () => {
    setChatModal(true)
  }
  const chatModClose = () => {
    setChatModal(false)
  }

  const OTPSend = async () => {
    try {
      setOTPLoader(true)

      const res = await API().postTransectionVerfiyCode(id, OTPValue)
      if (res.status == true) {
        const res = await API().postSellerAcceptStatus(
          id,//ok
          offers[0].bid_id,//ok
          offers[0].ad_id,//0k
          offers[0].quantity,//ok
          offers[0].bid_user_id,//ok
          offers[0].bid_amount,//ok
          // offers[0].quantity,
          offers[0].my_crpto_id//ok

        )
        if (res.status == true) {

          setOTPres('')
          alert('Offer Accepted')
          OtpModClose()
        }
      } else {
        setOTPLoader(false)
        setOTPres('Invalid Pin Code')
      }

    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }
  const SeeTranImg = () => {
    setTransectionImgModal(true)
  }

  const chat = async () => {

    // const resMessage = await API().postMessage(offers[0].trasaction_id)
    // setResMessage(resMessage.data)


    // socket.emit("user_join", { "t_id": offers[0].trasaction_id, "u_id": id })


    // socket.on("user_joined", (data) => {
    //   // alert(JSON.stringify(data))
    // })


    // socket.on("message", (data) => {
    //   getMessages.push(data)
    //   // alert(JSON.parse(data))
    // })
  }
  const getData = async () => {
    try {
      chat()
      const res = await API().getTransectionDetail(
        offers[0].bid_user_id,
        id,
        offers[0].ad_id
      )

      if (res.status) {

        setTransectionImgStatus(res.status)
        setTransectionImageApif(res.transection)
      }

      setIsloading(false)
    } catch (err) { console.log(err, 'errrgetTransectionDetail') }

  }
  const SendMasseg = () => {
    // socket.emit("text", { "msg": message, "t_id": offers[0].trasaction_id, "u_id": id })
    // // alert('aaaa')
    // setMessage('')
  }

  const aprovedTransection = async () => {

    try {


      const res = await API().postAcceptTransection(
        transectionImageApif.tansection_id,
        offers[0].ad_id,
        offers[0].quantity,
        offers[0].bid_user_id,
        offers[0].my_crpto_id,
      )
      if (res.status) {
        MySellAdsOfferModClose()

      }
    } catch (err) { console.log(err, 'errAcceptTransection') }
  }

  getData()
  useEffect(() => {



  }, [getMessages])

  if (isloading) {
    return (

      <div
        style={{ backgroundColor: 'gray' }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
      </div>

    );
  } else {
    return (
      <div>
        <Modal open={open} onClose={MySellAdsOfferModClose}>
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div className="modal-header">
                <h2>Offers List</h2>
                <AiOutlineClose
                  onClick={MySellAdsOfferModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">
                  {offers.length > 0 ? (
                    <>
                      {offersStatus1List.length >= 1 ?
                        <>
                          {offersStatus1List.map((item) => {
                            return (
                              <div
                                key={item.id}
                                style={{
                                  borderRadius: 20,
                                  border: "2px solid rgb(215, 215, 215)",
                                }}
                                className=" row col-10 mt-2 align-items-center shadow-sm"
                              >
                                <div className="d-flex">

                                  <h3 className=" mt-2 mb-3  w-50 text-center " style={{ marginLeft: '25%' }}>My Offer</h3>

                                  {transectionImgStatus ?
                                    <>
                                      {chatModal ?
                                        <Button

                                          style={{
                                            fontSize: 15,
                                            marginBottom: "10px",
                                            borderRadius: "20px",
                                            marginTop: "10px",
                                            height: '5%',


                                            backgroundColor: Colors.darkBlue,

                                          }}
                                          className="col-lg-3"
                                          variant="contained"
                                          color="secondary"
                                          onClick={chatModClose}

                                        >
                                          close
                                        </Button>
                                        :
                                        <Button

                                          style={{
                                            fontSize: 15,
                                            // marginBottom: "10px",
                                            borderRadius: "20px",
                                            marginTop: "10px",
                                            height: '5%',

                                            backgroundColor: Colors.darkBlue,

                                          }}
                                          className="col-lg-3"
                                          variant="contained"
                                          color="secondary"
                                          onClick={chatModOpen}
                                        >
                                          Chat
                                        </Button>
                                      }
                                    </>
                                    : <></>}
                                </div>

                                <h5 className="col-5">Name:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {item.bid_user_name}
                                </h5>
                                <h5 className="col-5">Quantity of:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.maximum_crypto_amount} ${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Offer Amount:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.bid_amount} ${item.bid_user}`}
                                </h5>
                                <h3 className="text-center mt-2 mb-3">My Details</h3>
                                <h5 className="col-5"> Crypto{/* Amount Limit */}:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.maximum_crypto_amount} ${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Total Price:</h5>
                                <h5 className="col-7 " style={{ color: "gray" }}>
                                  {`${item.price} ${item.ad_user}`}
                                </h5>
                                <div>

                                  {offers[0].seller_status == 0 ?
                                    <Button
                                      onClick={OtpModOpen}
                                      style={{
                                        fontSize: 15,
                                        marginBottom: "10px",
                                        borderRadius: "20px",
                                        marginTop: "5px",
                                        backgroundColor: Colors.darkBlue,
                                      }}
                                      variant="contained"
                                      color="secondary"

                                    >
                                      Accept Offer
                                    </Button>
                                    :

                                    <>
                                      {transectionImgStatus ?

                                        <div className="d-flex justify-content-between align-items-center">

                                          <Button
                                            onClick={aprovedTransection}
                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: Colors.darkBlue,
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Approve
                                          </Button>
                                          <button onClick={SeeTranImg} style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info " >See Transection slip</button>

                                        </div>
                                        :
                                        <div className="d-flex justify-content-between align-items-center">
                                          <Button

                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: 'rgb(119, 112, 173)',
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Waiting...
                                          </Button>
                                          <>
                                            {chatModal ?
                                              <Button

                                                style={{
                                                  fontSize: 15,
                                                  marginBottom: "10px",
                                                  borderRadius: "20px",
                                                  marginTop: "10px",
                                                  height: '5%',


                                                  backgroundColor: Colors.darkBlue,

                                                }}
                                                className="col-lg-3"
                                                variant="contained"
                                                color="secondary"
                                                onClick={chatModClose}

                                              >
                                                close
                                              </Button>
                                              :
                                              <Button

                                                style={{
                                                  fontSize: 15,
                                                  // marginBottom: "10px",
                                                  borderRadius: "20px",
                                                  // marginTop: "10px",
                                                  height: '5%',

                                                  backgroundColor: Colors.darkBlue,

                                                }}
                                                className="col-lg-3"
                                                variant="contained"
                                                color="secondary"
                                                onClick={chatModOpen}
                                              >
                                                Chat
                                              </Button>
                                            }
                                          </>
                                        </div>
                                      }


                                    </>

                                  }
                                </div>
                              </div>

                            );
                          })}
                        </> : <>
                          {offersStatus0Lits.map((item) => {
                            return (
                              <div
                                key={item.id}
                                style={{
                                  borderRadius: 20,
                                  border: "2px solid rgb(215, 215, 215)",
                                }}
                                className=" row col-10 mt-2 align-items-center shadow-sm"
                              >
                                <h3 className="text-center mt-2 mb-3">
                                  Offers Details
                                </h3>

                                <h5 className="col-5">Name:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {item.bid_user_name}
                                </h5>
                                <h5 className="col-5">Quantity of:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.maximum_crypto_amount} ${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Offer Amount:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.bid_amount} ${item.bid_user}`}
                                </h5>
                                <h3 className="text-center mt-2 mb-3">My Details</h3>
                                <h5 className="col-5">Amount Limit:</h5>
                                <h5 className="col-7" style={{ color: "gray" }}>
                                  {`${item.maximum_crypto_amount} ${item.crpto_symbol}`}
                                </h5>
                                <h5 className="col-5">Total Price:</h5>
                                <h5 className="col-7 " style={{ color: "gray" }}>
                                  {`${item.price} ${item.ad_user}`}
                                </h5>
                                <div>

                                  {offers[0].seller_status == 0 ?
                                    <Button
                                      onClick={OtpModOpen}
                                      style={{
                                        fontSize: 15,
                                        marginBottom: "10px",
                                        borderRadius: "20px",
                                        marginTop: "5px",
                                        backgroundColor: Colors.darkBlue,
                                      }}
                                      variant="contained"
                                      color="secondary"

                                    >
                                      Accept Offer
                                    </Button>
                                    :

                                    <>
                                      {transectionImgStatus ?

                                        <div className="d-flex justify-content-between align-items-center">

                                          <Button
                                            onClick={aprovedTransection}
                                            style={{
                                              fontSize: 15,
                                              marginBottom: "10px",
                                              borderRadius: "20px",
                                              marginTop: "5px",
                                              backgroundColor: Colors.darkBlue,
                                            }}
                                            variant="contained"
                                            color="secondary"

                                          >
                                            Approve
                                          </Button>
                                          <button onClick={SeeTranImg} style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info " >See Transection slip</button>

                                        </div>
                                        : <Button

                                          style={{
                                            fontSize: 15,
                                            marginBottom: "10px",
                                            borderRadius: "20px",
                                            marginTop: "5px",
                                            backgroundColor: 'rgb(119, 112, 173)',
                                          }}
                                          variant="contained"
                                          color="secondary"

                                        >
                                          Waiting...
                                        </Button>}


                                    </>

                                  }
                                </div>
                              </div>
                            );
                          })}
                        </>
                      }
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          height: 200,
                          borderRadius: 20,
                          border: "2px solid rgb(215, 215, 215)",
                        }}
                        className=" row col-10 mt-2 align-items-center shadow-sm"
                      >
                        <h2 className="text-center" style={{ color: "gray" }}>
                          {" "}
                          No Offers Yet
                        </h2>
                      </div>
                    </>
                  )}
                </div>
                {chatModal ?

                  <div style={{
                    // borderRadius: 20,
                    border: "2px solid rgb(215, 215, 215)", marginLeft: '8%', marginTop: '2%', padding: 10,
                    width: '85%',
                    // paddingBottom: 10
                  }} className="shadow-sm ">
                    <div style={{ minHeight: 100, maxHeight: 250, paddingBottom: 10 }} className="   overflow-scroll  ">

                      {/* msg */}

                      {getMessages.map((item) => {
                        return (
                          <>
                            {item.user_id == id ?
                              <div className=" d-flex justify-content-end">
                                <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                                  <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                                    {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                                    <img
                                      src={profile}
                                      class="img-thumbnail rounded-circle "
                                      alt="Profile"
                                      style={{ height: "40px", width: "40px" }}
                                    />
                                    {/* } */}
                                  </div>
                                  <div style={{ width: 210 }} className="">
                                    <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">{item.first_name}</h6>
                                    <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                                      {item.Message}</p>
                                  </div>
                                </div>
                              </div>
                              :


                              <div style={{ maxWidth: 800, padding: 5 }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex justify-content-between">

                                <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                                  {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                                  <img
                                    src={profile}
                                    class="img-thumbnail rounded-circle "
                                    alt="Profile"
                                    style={{ height: "40px", width: "40px" }}
                                  />
                                  {/* } */}
                                </div>
                                <div style={{ width: 210 }} className="">
                                  <h6 style={{ marginTop: 1, color: 'black' }} className="">{item.first_name}</h6>
                                  <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">{item.Message}</p>
                                </div>
                              </div>
                            }
                          </>
                        )
                      })}



                    </div>
                    <div style={{
                      width: "100%",
                      height: "45px",
                      backgroundColor: "rgb(255, 255, 255)",
                      padding: 10,

                      border: "1px solid gray",
                      borderRadius: 50,
                    }} className="d-flex align-items-center">
                      <input placeholder="text" placeholder="type message..." className="form-control border-0 shadow-none " onChange={(e) => setMessage(e.target.value)} />

                      <FiPaperclip style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                      <FiSend style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} onClick={SendMasseg} />
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>
          </div>
        </Modal >

        {/* ###########################
      ########## OTP Modal ##########
      ##########################* */}
        < Modal open={otpMod} >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Please check Email for verification code</h2>
                <AiOutlineClose
                  onClick={OtpModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">




                  <OtpInput
                    value={OTPValue}
                    onChange={(e) => setOTPValue(e)}
                    numInputs={4}
                    containerStyle={{
                      marginTop: "20px",
                      // marginLeft: "20px",
                      width: "300px",
                    }}
                    isInputNum={true}
                    inputStyle={{
                      height: "60px",
                      width: "60px",
                      marginLeft: "12px",
                    }}
                    separator={<span>{""}</span>}
                  />
                  <div className="col-6">
                    {OTPLoader ? (
                      <div className="d-flex mt-1 justify-content-center align-items-center">
                        <div
                          className="spinner-border text-secondary"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <p className="text-center text-danger">{OTPres}</p>
                    )}
                  </div>

                  <button
                    onClick={OTPSend}
                    style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn btn-primary  mt-3"
                  >

                    Send
                  </button>

                </div>
              </div>
            </div>
          </div>
        </Modal >

        {/* ###########################
      ########## Transection Img Modal ##########
      ##########################* */}
        <Modal open={transectionImgModal}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header  justify-content-end">

                <AiOutlineClose
                  onClick={() => setTransectionImgModal(false)}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">



                  <div className="  " style={{ height: "480px", width: "520px" }}>
                    <img
                      src={transectionImageApif.transection_image}
                      class="img-thumbnail  "
                      alt="Profile"
                      style={{ height: "480px", width: "520px" }}
                    />
                  </div>





                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div >
    );
  }
}

export default MySellingOffers;
