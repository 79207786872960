import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";


export default function SAndMod(props) {

    const history = useHistory();
    const { open, close, data, buyOrSell, user_id, percentage, setReloadMod, setAlertShow, setAlertMessage, searchInput, searchButton } = props;
    // console.log(data, 'ss')
    // amount_of_crypto
    // price
    const [quantity, setQuantity] = useState("");
    const [quantityValidation, setQuantityValidation] = useState(true);
    const [bidAmount, setBidAmount] = useState("");
    const [bidAmountValidation, setBidAmountValidation] = useState(true);

    // console.log(typeof (quantity), quantity, "percentquantityage");


    const GetData = () => {

        console.log(buyOrSell, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

        if (searchButton == 'BTC') {
            // console.log(data.amount_to_show, searchInput, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

            setBidAmount(data.amount_to_show)
            setQuantity(searchInput)
        } else {
            setBidAmount(searchInput)
            setQuantity(data.crypto_to_show)
        }

    }
    const CloseBtn = () => {
        close();
        setBidAmount("");
        setQuantity("");
        setReloadMod(0)
    };
    const quantValid = function (qn) {
        return function (qn) {
            if (qn.length > 0) {
                return true;
            } else {
                return false;
            }
        };
    };
    const amountValid = function (pn) {
        return function (pn) {
            if (pn.length > 0) {
                return true;
            } else {
                return false;
            }
        };
    };
    const BidFunc = async () => {
        var QnValid = quantValid("qn");
        var QnVal = QnValid(quantity);
        var AmValid = amountValid("am");
        var AmVal = AmValid(bidAmount);
        setBidAmountValidation(AmVal);
        setQuantityValidation(QnVal);
        // console.log(id, add_id, quantity, bidAmount, 'bidsend');
        try {
            if (quantity > 0 && bidAmount > 0) {
                // setIsLoading(true);
                const response = await API().postBidRequest(
                    user_id,
                    data.ad_id,
                    quantity,
                    bidAmount
                );


                setBidAmount("");
                setQuantity("");

                setAlertShow(true)
                setTimeout(() => {
                    if (buyOrSell == 'buy') {

                        history.push("/MySellingOffers")
                    } else {
                        history.push("/MyByinOffers")
                    }
                    setAlertShow(false)

                }, 3000);
                setAlertMessage(response.message)

                CloseBtn();
            }
        } catch (err) {
            console.log(err, "errBidFunc");
        }
    };
    useEffect(() => {
        GetData()
    }, [])
    return (
        <Modal open={open} close={close}>
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" >
                <div class="modal-content" >
                    <div className="modal-header">
                        <h2>{buyOrSell == 'buy' ? "Selling" : "Buying"} Request</h2>
                    </div>
                    <div className="modal-body">
                        <div className="mb-2 col-10 mx-auto ">
                            {quantityValidation ? (
                                <>
                                    <h6>Amount oF {data.symbol}:</h6>
                                    <input disabled
                                        value={quantity}
                                        placeholder="0"
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                setQuantity(e.target.value);
                                            }
                                        }}
                                        className="form-control "
                                    ></input>
                                </>
                            ) : (
                                <>
                                    <h6 className="">Amount oF {data.symbol}:</h6>
                                    <input disabled
                                        value={quantity}
                                        placeholder="0"
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                setQuantity(e.target.value);
                                            }
                                        }}
                                        className="form-control "
                                    ></input>
                                </>
                            )}

                            {bidAmountValidation ? (
                                <>
                                    <h6>Amount oF {data.currency_symbol}:</h6>
                                    <input disabled
                                        value={bidAmount}
                                        placeholder="0"
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                setBidAmount(e.target.value);
                                            }
                                        }}
                                        className="form-control "
                                    ></input>
                                </>
                            ) : (
                                <>
                                    <h6 className="">Amount oF {data.currency_symbol}:</h6>
                                    <input disabled
                                        value={bidAmount}
                                        placeholder="0"
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                setBidAmount(e.target.value);
                                            }
                                        }}
                                        className="form-control "
                                    ></input>
                                </>
                            )}
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-10 row mt-2 rounded shadow" >
                                <h5 className="col-6 mt-2">{buyOrSell == 'buy' ? "Selling to:" : "Buying From:"} </h5>
                                <h5 className="col-6 mt-2">{data.first_name}</h5>

                                <h5 className="col-6 mt-2">Price</h5>
                                <h5 className="col-6 mt-2">
                                    {data.price} {data.currency_symbol}/{data.symbol}
                                </h5>
                                <h5 className="col-6 mt-2">Amount Limit</h5>
                                <div className="col-6 d-flex mt-2">
                                    <h5 style={{ color: "green" }}>

                                        {buyOrSell == 'buy' ?
                                            (data.maximum_crypto_amount + data.maximum_crypto_amount / 100 * percentage).toFixed(7)
                                            : (data.maximum_crypto_amount - data.maximum_crypto_amount / 100 * percentage).toFixed(7)}
                                        {/* {data.minimum_crypto_amount} */}
                                    </h5>
                                    <h5 style={{ color: "black", marginLeft: 5 }}>
                                        {/* {data.maximum_crypto_amount} */} {data.symbol}
                                    </h5>
                                </div>

                                <h5 className="col-6 mt-2">Payment Method</h5>
                                <h5 className="col-6 mt-2">{data.name}</h5>
                                <h5 className="col-6 mt-2">Location</h5>
                                <h5 className="col-6 mt-2">{data.country_name} </h5>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer ">
                        <button
                            type="button"
                            class="btn px-lg-4 px-3 rounded-pill border-0 btn-primary"
                            onClick={CloseBtn}
                            style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
                        >
                            Close
                        </button>
                        <button
                            onClick={BidFunc}
                            type="button"
                            className="btn px-lg-4 px-3 border-0 rounded-pill btn-primary"
                            style={{ backgroundColor: Colors.darkBlue }}
                        >
                            Send Offer ({data.symbol})
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
