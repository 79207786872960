import React, { useState, useEffect } from 'react';
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import NavBar from '../NavBar';
import { useHistory } from "react-router-dom";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";

import { AiFillDelete, AiOutlineClose } from "react-icons/ai";

import { FiEdit } from "react-icons/fi";
import Modal from "@material-ui/core/Modal";
// import io from 'socket.io-client';
// const socket = io('http://162.241.121.1:3000');
import { useLocation } from "react-router-dom";
import EdditSwapAdsMod from '../Myads/EdditSwapAdsMod';

import CoustumAlert from '../../Constant/CoustumAlert'
function MySwapAds() {


    const history = useHistory();

    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, symbol, country } = user_Data.data;
    const [isloding, setIsloding] = useState(true);
    const [apiValueBuy, setApiValueBuy] = useState([]);
    const [date, setDate] = useState(0);
    const [openMyEditBuyingMod, setOpenMyEditBuyingMod] = useState(false);
    const [openDeletMod, setOpenDeletMod] = useState(false);
    const [deletId, setDeletId] = useState("");

    const [percentage, setPercentage] = useState('');
    const notification = useLocation();

    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [createSellingAdsMod, setCreateSellingAdsMod] = useState(false);

    const [adDetails, setAdDetails] = useState({});

    const ShowOffers = (item) => {
        history.push({
            pathname: "/myswapadsoffer",
            state: { detail: item, percentage: percentage },
        })
    }
    console.log(date, 'ppppppppppppppppppppppppppppppppppppppppppppppppppp')

    const MyEditSwapAdsModOpen = (props) => {
        setAdDetails(props)
        setDate(1);
        setCreateSellingAdsMod(true)
    };


    const ConfirmDeleteModOpen = (id) => {
        setOpenDeletMod(true);
        setDeletId(id);
    };
    const DeleteAdd = async () => {
        try {
            const deletData = await API().getDeleteSwapAdd(deletId);
            ConfirmDeleteModClose();
            await GetData();
            // setIsLoding(false);
        } catch (err) {
            console.log(err, "gerdelet");
        }
    };
    const ConfirmDeleteModClose = () => {
        setOpenDeletMod(false);
    };

    const GetData = async () => {
        try {
            const MySwapAd = await API().getMySwapAd(id);
            setApiValueBuy(MySwapAd.MySwapAds)
            const Percentage = await API().getPercentage();
            setPercentage(Percentage.data.percentage)
            setIsloding(false)

        } catch (error) {
            console.log(error, "errorMyBuyAdds");
        }
    };
    useEffect(() => {
        GetData();

    }, []);

    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol }}>
                <NavBar />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {

        return <div className="overflow-auto" style={{ height: '100vh', backgroundColor: Colors.bodyCol, }}>

            <NavBar />
            {alertShow ?
                <CoustumAlert alertMessage={alertMessage} />
                : <></>}
            <div className='card_container p-4  '>
                <h1 className='text-light text-center'>My Swap Ads</h1>
                {apiValueBuy.length > 0 ?

                    <div className=' mt-5  d-md-flex  flex-wrap'>
                        {apiValueBuy.map((item) => {
                            ;
                            return (

                                <>

                                    {notification.state == undefined ?
                                        <div className='col-md-6 col-lg-4 p-3   rounded-2  '>

                                            {/* <div className=' '>
                                                <p  className='text-light border' >Receiving Address:
                                                </p>

                                                <p style={{ fontSize: 20 }} className=' border' >  {item.recive_wallet}
                                                </p>

                                            </div> */}


                                            <div className=''>
                                                <h5 style={{ fontSize: 16 }} className='text-light' >Receiving Address:
                                                </h5>

                                                <h5 style={{ fontSize: 16 }} className='text-light' >   {item.recive_wallet}

                                                </h5>

                                            </div>
                                            <div className='d-flex'>
                                                <h5 style={{ fontSize: 16 }} className='text-light' >You will send:
                                                </h5>

                                                <h5 style={{ fontSize: 16, marginLeft: 5 }} className='text-light' >   {item.sell_crypto}{'  '}{item.send_crypto_symbol}

                                                </h5>

                                            </div>
                                            <div className='d-flex'>
                                                <h5 style={{ fontSize: 16 }} className='text-light' >You will receive:
                                                </h5>

                                                <h5 style={{ fontSize: 16, marginLeft: 5 }} className='text-light' >   {item.recive_crypto}{'  '}{item.recive_crypto_symbol}

                                                </h5>

                                            </div>

                                            <div className='d-md-flex justify-content-between col-lg-8 '>                                            <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info text-light " onClick={() => ShowOffers(item)} >Show Offers {item.seller_status == 1 ? "(1)" :
                                                `(${item.offers_count})`
                                            }</button>

                                                <div className=" mt-2  col-md-3 justify-content-between col-8  d-flex ms-2 ">
                                                    <FiEdit
                                                        onClick={() => MyEditSwapAdsModOpen(item)}
                                                        size={22}
                                                        color="white"
                                                    // style={{ marginLeft: 20, marginTop: 20 }}
                                                    />
                                                    <AiFillDelete
                                                        onClick={() => ConfirmDeleteModOpen(item.swap_id)}
                                                        size={25}
                                                        color="white"
                                                    // style={{ marginLeft: 20, marginTop: 50 }}
                                                    />
                                                </div>

                                            </div>
                                        </div>

                                        : <>
                                            {notification.state.detail.url == item.ad_id ?
                                                <div className='col-md-6 col-lg-4 p-3  rounded-2  '>
                                                    <div className='d-flex'>

                                                        <h5 className='text-success' style={{ backgroundColor: 'white', paddingLeft: 5, paddingRight: 5, borderRadius: 100 }} >{parseInt(item.price)}</h5>
                                                        <h5 className='text-light' style={{ marginLeft: 10 }} >{`${symbol} / ${item.symbol}`}</h5>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <h5 className='text-light' >Amount Limit :
                                                        </h5>
                                                        <div>
                                                            <h5 className='text-light' >  {item.minimum_crypto_amount}{item.symbol} /

                                                            </h5>
                                                            <h5 className='text-light' >
                                                                {item.maximum_crypto_amount}{item.symbol}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <h5 className='text-light' >{item.bank_name}</h5>
                                                    <h5 className='text-light' >{item.name} {item.last_name}</h5>
                                                    <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info text-light " onClick={ShowOffers(item)} >Show Details</button>
                                                </div>
                                                :
                                                <></>}
                                        </>
                                    }
                                </>
                            )
                        })}
                    </div>

                    :
                    <div className='border p-5 text-center rounded text-light col-md-6 mx-auto mt-5'>

                        <h4>No Ads Yet</h4>
                    </div>}
            </div>

            {/*################################## 
        ######## Edit MyBuying Ads ######## 
        ##################################*/}
            {date == 1 ? (
                <EdditSwapAdsMod
                    setData={setDate}
                    adDetails={adDetails}
                    setAlertShow={setAlertShow}
                    setAlertMessage={setAlertMessage}
                    setCreateSellingAdsMod={setCreateSellingAdsMod}
                    id={id}
                    getSwapAdList={GetData}
                    open={createSellingAdsMod}
                />
            ) : (
                <></>
            )}

            {/*################################## 
        ####### Delete MyBuying Ads ####### 
        ##################################*/}
            <Modal open={openDeletMod}>
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div className="modal-body">
                            <div className="col-12 row justify-content-center">
                                <h5 className="text-center">Are you sure you want to Delete</h5>
                                <div className=" col-6 mt-2 d-flex justify-content-between">
                                    <button
                                        onClick={() => ConfirmDeleteModClose()}
                                        style={{ fontSize: 20, borderRadius: 20 }}
                                        type="button"
                                        className="btn mb-2 btn-outline-secondary"
                                    >
                                        Close
                                    </button>
                                    <button
                                        onClick={DeleteAdd}
                                        style={{ fontSize: 20, borderRadius: 20 }}
                                        type="button"
                                        className="btn mb-2 btn-outline-secondary"
                                    >
                                        Confirm
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>;
    }
}

export default MySwapAds;