import React, { useRef, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import API from "../Api";
import ls from "localstorage-slim";
import { GoPrimitiveDot } from "react-icons/go";

import { useLocation } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import * as Colors from '../Constant/Colors'
import NavBar from "./NavBar";

function Verification() {
  // const data = JSON.parse(localStorage.getItem("userData"));
  // const { email } = data.data;
  const id = useLocation().state.detail;
  console.log(id, "veriid");

  const [isloading, setIsloading] = useState(true);
  const [contryApi, setContryApi] = useState([]);
  const [contrySelected, setContrySelected] = useState(
    JSON.stringify({
      id: "",
      name: "",
      code: "",
    })
  );
  const countrySelected = JSON.parse(contrySelected);

  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const history = useHistory();
  const [apiCNICFrountImage, setApiCNICFrountImage] = useState("");
  const [CNICFrtImgMsg, setCNICFrtImgMsg] = useState({
    loading: false,
    status: "",
  });

  const [apiCNICBackImage, setApiCNICBackImage] = useState("");
  const [CNICBckImgMsg, setCNICBckImgMsg] = useState({
    loading: false,
    status: "",
  });
  const [apiCNICHoldingImage, setApiCNICHoldingImage] = useState("");
  const [CNICHolngImgMsg, setCNICHolngImgMsg] = useState({
    loading: false,
    status: "",
  });
  const [apiPasportImage, setApiPasportImage] = useState("");
  const [PasportImgMsg, setPasportImgMsg] = useState({
    loading: false,
    status: "",
  });
  const [apiUtilityBillImage, setApiUtilityBillImage] = useState("");
  const [UtilityBillImgMsg, setUtilityBillImgMsg] = useState({
    loading: false,
    status: "",
  });
  const [emailVerifyStatus, setEmailVerifyStatus] = useState(false);

  const [phoneVerifyStatus, setPhoneVerifyStatus] = useState(false);


  const [errors, setErrors] = useState("");
  const [saveEmail, setSaveEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSendResponse, setEmailSendResponse] = useState("");
  const [emailSendLoading, setEmailSendLoading] = useState(false);
  const [emailCodeResponse, setEmailCodeResponse] = useState("");
  const [emailCodeLoading, setEmailCodeLoading] = useState(false);
  const [savePhone, setSavePhone] = useState(false);
  const [phoneNumper, setPhoneNumper] = useState("");
  const [phoneSendResponse, setPhoneSendResponse] = useState("");
  const [phoneSendLoading, setPhoneSendLoading] = useState(false);
  const [phoneCodeResponse, setPhoneCodeResponse] = useState("");
  const [phoneCodeLoading, setPhoneCodeLoading] = useState(false);

  const [valuesCnicF, setValuesCnicF] = useState({
    image: null,
    name: null,
  });
  const [selectedCnicFImage, setSelectedCnicFImage] = useState({
    image: null,
    name: null,
  });
  const [valuesCnicB, setValuesCnicB] = useState({
    image: null,
    name: null,
  });
  const [selectedCnicBImage, setSelectedCnicBImage] = useState({
    image: null,
    name: null,
  });
  const [valuesCnicHold, setValuesCnicHold] = useState({
    image: null,
    name: null,
  });
  const [selectedCnicHoldImage, setSelectedCnicHoldImage] = useState({
    image: null,
    name: null,
  });
  const [selectedPassportImage, setSelectedPassportImage] = useState({
    image: null,
    name: null,
  });
  const [valuesPassport, setValuesPassport] = useState({
    image: null,
    name: null,
  });

  const [selectedUtilityBillImage, setSelectedUtilityBillImage] = useState({
    image: null,
    name: null,
  });
  const [valuesUtilityBill, setValuesUtilityBill] = useState({
    image: null,
    name: null,
  });

  const CloseLogOutMod = () => {
    setOpenLogOutModal(false);
  };
  const SelectedCountry = (e) => {
    setContrySelected(e.target.value);
  };
  const ImageCnicFRef = useRef();
  const ImageCnicBRef = useRef();
  const ImageCnicHoldRef = useRef();
  const ImagePassportRef = useRef();
  const ImageUtilityBillRef = useRef();
  const OpenLogOutMod = () => {
    setOpenLogOutModal(true);
  };
  const LogOut = () => {
    try {
      setIsloading(true);
      ls.remove("Cradentials");
      localStorage.removeItem("userData");
      history.push("/");
      return true;
    } catch (exception) {
      return false;
    }
  };
  const fileOpenCNICF = () => {
    const trigger = ImageCnicFRef.current;
    trigger.click();
  };
  const fileOpenCNICB = () => {
    const trigger = ImageCnicBRef.current;
    trigger.click();
  };
  const fileOpenCNICHold = () => {
    const trigger = ImageCnicHoldRef.current;
    trigger.click();
  };
  const fileOpenPassport = () => {
    const trigger = ImagePassportRef.current;
    trigger.click();
  };
  const fileOpenUtilityBill = () => {
    const trigger = ImageUtilityBillRef.current;
    trigger.click();
  };
  const handleChangeCNICFImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];

    const file = files[0];
    let ext = value.split(".");

    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;

        const base64 = FileasDataUrl.split(",");

        const base64Encoded = base64[base64.length - 1];
        setSelectedCnicFImage({ name: ddddd, image: base64Encoded });

        setValuesCnicF({ image: base64, name: ddddd });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };

  const handleChangeCNICBImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];

    const file = files[0];
    let ext = value.split(".");

    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;

        const base64 = FileasDataUrl.split(",");

        const base64Encoded = base64[base64.length - 1];
        setSelectedCnicBImage({ name: ddddd, image: base64Encoded });

        setValuesCnicB({ image: base64, name: ddddd });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };
  const handleChangeCNICHoldImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];

    const file = files[0];
    let ext = value.split(".");

    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;

        const base64 = FileasDataUrl.split(",");

        const base64Encoded = base64[base64.length - 1];
        setSelectedCnicHoldImage({ name: ddddd, image: base64Encoded });

        setValuesCnicHold({ image: base64, name: ddddd });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };
  const handleChangePassportImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];

    const file = files[0];
    let ext = value.split(".");

    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;

        const base64 = FileasDataUrl.split(",");

        const base64Encoded = base64[base64.length - 1];
        setSelectedPassportImage({ name: ddddd, image: base64Encoded });

        setValuesPassport({ image: base64, name: ddddd });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };

  const handleChangeUtilityBillImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];

    const file = files[0];
    let ext = value.split(".");

    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;

        const base64 = FileasDataUrl.split(",");

        const base64Encoded = base64[base64.length - 1];
        setSelectedUtilityBillImage({ name: ddddd, image: base64Encoded });

        setValuesUtilityBill({ image: base64, name: ddddd });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };
  const [OTPEmail, setOTPEmail] = useState("");
  const [OTPPhone, setOTPPhone] = useState("");

  const GetApi = async () => {
    try {
      const resGet = await API().getAllVerifcatunData(id);
      setEmail(resGet.Email);

      setEmailVerifyStatus(resGet.email_verify)
      setPhoneVerifyStatus(resGet.phone_verify)

      console.log(resGet, 'resGet.cnic_frunt_image')
      setApiCNICFrountImage(resGet.cnic_frunt_image);
      setApiCNICBackImage(resGet.cnic_back_image);
      setApiCNICHoldingImage(resGet.cnic_with_face);
      setApiPasportImage(resGet.passport_image);
      setApiUtilityBillImage(resGet.bill_image);
      setContrySelected(
        JSON.stringify({
          id: resGet.country_id,
          name: resGet.country_name,
          code: resGet.country_code,
        })
      );

      // setPhoneNumper(resGet.Number);
      const response = await API().getcounties();

      setContryApi(response.data);
      setIsloading(false);
    } catch (err) {
      console.log(err, "resErrGet");
    }
  };

  useEffect(() => {
    GetApi();
  }, []);

  const PostCnicFrountImage = async () => {
    if (selectedCnicFImage.name != null) {
      try {
        setCNICFrtImgMsg({ loading: true });
        const responce = await API().postFrountCnicImages(
          id,
          selectedCnicFImage.image,
          selectedCnicFImage.name
        );
        GetApi()
        setCNICFrtImgMsg({ Loading: false, status: responce.message });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (apiCNICFrountImage.length >= 1) {
        setCNICFrtImgMsg({ status: "Image Uploaded" });
      }
    }
  };
  const PostCnicBackImage = async () => {
    if (selectedCnicBImage.name != null) {
      try {
        setCNICBckImgMsg({ loading: true });
        const responce = await API().postBackCnicImages(
          id,
          selectedCnicBImage.image,
          selectedCnicBImage.name
        );
        setCNICBckImgMsg({ Loading: false, status: responce.message });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (apiCNICBackImage.length >= 1) {
        setCNICBckImgMsg({ status: "Image Uploaded" });
      }
    }
  };
  const PostCnicHoldingImage = async () => {
    if (selectedCnicHoldImage.name != null) {
      try {
        setCNICHolngImgMsg({ loading: true });
        const responce = await API().postCnicImageWithFace(
          id,
          selectedCnicHoldImage.image,
          selectedCnicHoldImage.name
        );
        GetApi()
        setCNICHolngImgMsg({ Loading: false, status: responce.message });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (apiCNICHoldingImage.length >= 1) {
        setCNICHolngImgMsg({ status: "Image Uploaded" });
      }
    }
  };

  const PostPasprtImage = async () => {
    if (selectedPassportImage.name != null) {
      try {
        setPasportImgMsg({ loading: true });
        const responce = await API().postPasportImage(
          id,
          selectedPassportImage.image,
          selectedPassportImage.name
        );
        setPasportImgMsg({ Loading: false, status: responce.message });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (apiPasportImage.length >= 1) {
        setPasportImgMsg({ status: "Image Uploaded" });
      }
    }
  };
  const PostUtilityBillImage = async () => {
    if (selectedUtilityBillImage.name != null) {
      try {
        setUtilityBillImgMsg({ loading: true });
        const responce = await API().postUtilityBillImage(
          id,
          selectedUtilityBillImage.image,
          selectedUtilityBillImage.name
        );
        GetApi()
        setUtilityBillImgMsg({ Loading: false, status: responce.message });
      } catch (err) {
        console.log(err);
      }
    } else {
      if (apiUtilityBillImage.length >= 1) {
        setUtilityBillImgMsg({ status: "Image Uploaded" });
      }
    }
  };

  const ReSendEmailOTP = async () => {
    setEmailSendLoading(true);
    try {
      const responce = await API().postEmailConformationCode(id, email);
      console.log(responce, 'resemailsend')
      setEmailSendResponse(responce.message);
      setEmailSendLoading(false);
      setSaveEmail(true);

    } catch (err) {
      console.log(err);
    }
  }
  const EmailSend = async () => {
    if (saveEmail == false) {
      setEmailSendLoading(true);
      try {
        const responce = await API().postEmailConformationCode(id, email);
        console.log(responce, 'resemailsend')
        setEmailSendResponse(responce.message);
        setEmailSendLoading(false);
        setSaveEmail(true);

      } catch (err) {
        console.log(err);
      }
    } else {
      setEmailCodeLoading(true);
      try {
        const responce = await API().postEmailVerfication(id, OTPEmail);

        setEmailCodeResponse(responce.message);
        setEmailCodeLoading(false);
        GetApi()
      } catch (err) {
        console.log(err);
      }
    }
  };
  const ReSendPhoneOTP = async () => {
    if (phoneNumper.length >= 4) {
      setPhoneSendLoading(true);
      console.log(countrySelected.code, 'sssssssssssssssss')
      const responce = await API().postPhonConformationCode(
        id,
        phoneNumper,
        countrySelected.id,
        countrySelected.code
      );
      console.log(responce, "resphonconfssssssss");
      setPhoneSendResponse(responce.message);
      setPhoneSendLoading(false);
      if (responce.status) {

        setSavePhone(true);
      }
    }
  }
  const PhoneSend = async () => {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!postPhoneVerfication!!!!!!!!!!!!!!!!!!!!')
    if (phoneNumper.length >= 4) {
      console.log('!!!!!!!!!!!!!!!!!!!!!!!PhoneVerfication!!!!!!!!!!!!!!!!!!!!')

      setPhoneCodeLoading(true);
      const responce = await API().postPhoneVerfication(
        id,
        email,
        phoneNumper)
      console.log(responce, 'postPhoneVerfication')

      setPhoneCodeResponse(responce.message);
      setPhoneCodeLoading(false);
      GetApi()
    } else {
      setPhoneCodeResponse('Invalid Authy code');

    }

  };

  if (isloading) {
    return (
      <div
        style={{ width: "100vw", height: "100vh" }}
        className=""
      >
        <NavBar />
        <div
          style={{ width: "100vw", height: "80vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <NavBar />
        <div className="container mb-4 overflow-hidden  ">
          <div
            className="d-lg-flex p-3 col-12 justify-content-lg-between "
            style={{ backgroundColor: Colors.darkBlue }}
          >
            <div className="col-12 col-lg-2">
              <select
                style={{
                  // width: "20%",
                  height: 45,
                  // borderColor: "green",
                }}
                className="form-select px-4 text-secondary border-0 shadow-sm "
                aria-label="Default select example"
                onChange={SelectedCountry}
              >
                {contryApi.map((item) => {
                  return (
                    <option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <h3 className="text-center  " style={{ color: "white" }}>
              Account not approved
            </h3>
            <div className=" row col-12 col-lg-2  justify-content-center">
              <Button
                className="border border-light"
                onClick={() => OpenLogOutMod()}
                style={{
                  width: "150px",
                  fontSize: 17,
                  color: "white",
                  // marginTop: 10,
                  // borderBottom: "1px solid gray",
                }}
              >
                Log out
              </Button>
            </div>
          </div>

          <div className="row col-12 ">
            <h5 style={{ color: "gray", marginLeft: "20px", marginTop: "20px" }}>
              Email Verification
            </h5>
            <div
              // className="border  shadow-sm"
              style={{
                width: "400px",

                marginLeft: "20px",
                marginTop: "10px",
              }}
            >
              <div
                className="border shadow-sm"
                style={{
                  height: "60px",
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {email}
              </div>
              {emailSendLoading ? (
                <div
                  style={{ height: "40px" }}
                  className="d-flex justify-content-center "
                >
                  <div
                    className=" spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : (
                <h6 className=" d-flex  justify-content-center " style={{ color: Colors.lightBlue }}>
                  {emailSendResponse}
                </h6>
              )}
            </div>
            {saveEmail ? (
              <div
                // className="border  shadow-sm"
                style={{
                  width: "400px",

                  // marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                <OtpInput
                  value={OTPEmail}
                  onChange={(e) => setOTPEmail(e)}
                  numInputs={4}
                  containerStyle={{
                    // marginLeft: "20px",
                    // marginTop: "-20px",
                    width: "400px",
                    // paddingLeft: "25px",
                  }}
                  isInputNum={true}
                  inputStyle={{
                    height: "60px",
                    width: "60px",
                    marginLeft: "20px",
                  }}
                  separator={<span>{""}</span>}
                />
                {emailCodeLoading ? (
                  <div
                    style={{ height: "40px" }}
                    className="d-flex justify-content-center "
                  >
                    <div
                      className=" spinner-border text-secondary"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <>
                    {emailVerifyStatus ? <></> :
                      <>
                        <Button
                          className=" "
                          style={{

                            fontSize: 12,
                            color: 'red',
                            marginLeft: "10px",
                            marginTop: "5px",
                            padding: 0
                          }}
                          onClick={ReSendEmailOTP}
                        >
                          Resend Otp
                        </Button>
                        <h6 className=" d-flex  justify-content-center " style={{ color: Colors.lightBlue }}>
                          {emailCodeResponse}
                        </h6>
                      </>
                    }
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
            {emailVerifyStatus ?
              <Button
                className="border text-success border-success "
                style={{
                  width: "200px",
                  height: "60px",
                  fontSize: 20,
                  color: Colors.darkBlue,
                  marginLeft: "10px",
                  marginTop: "10px",

                }}
              // onClick={EmailSend}
              >
                Verified
              </Button>
              : <Button
                className="border  border-primary "
                style={{
                  width: "400px",
                  height: "60px",
                  fontSize: 20,
                  color: Colors.darkBlue,
                  marginLeft: "10px",
                  marginTop: "10px",

                }}
                onClick={EmailSend}
              >
                Verify
              </Button>}
          </div>
          {/* <div className="row col-12 ">
            <h5 style={{ color: "gray", marginLeft: "20px", marginTop: "20px" }}>
              Authy 2F Verification
            </h5>
            {phoneVerifyStatus ? <></>
              :

              <div
                // className="border  shadow-sm"
                style={{
                  width: "400px",

                  marginLeft: "20px",
                  marginTop: "10px",
                }}
              >

                <div
                  className="border d-flex shadow-sm"
                  style={{
                    height: "60px",
                    paddingLeft: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <input
                    value={phoneNumper}
                    className="form-control border-0 shadow-none "
                    placeholder="Enter Authy Code"
                    onChange={(e) => setPhoneNumper(e.target.value)}
                  />
                </div>
                {phoneCodeLoading ? (
                  <div
                    style={{ height: "40px" }}
                    className="d-flex justify-content-center "
                  >
                    <div
                      className=" spinner-border text-secondary"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <h6 className=" d-flex justify-content-center " style={{ color: Colors.lightBlue }}>
                    {phoneCodeResponse}
                  </h6>
                )}
              </div>
            }
            {savePhone ? (
              <div
                // className="border  shadow-sm"
                style={{
                  width: "400px",

                  // marginLeft: "20px",
                  marginTop: "10px",
                }}
              >
                <OtpInput
                  value={OTPPhone}
                  onChange={(e) => setOTPPhone(e)}
                  numInputs={4}
                  containerStyle={{
                    // marginLeft: "20px",
                    width: "400px",
                  }}
                  isInputNum={true}
                  inputStyle={{
                    height: "60px",
                    width: "60px",
                    marginLeft: "20px",
                  }}
                  separator={<span>{""}</span>}
                />
                {phoneCodeLoading ? (
                  <div
                    style={{ height: "40px" }}
                    className="d-flex justify-content-center "
                  >
                    <div
                      className=" spinner-border text-secondary"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <>
                    {phoneVerifyStatus ?
                      <h6 className=" d-flex  justify-content-center " style={{ color: Colors.lightBlue }}>
                        {phoneCodeResponse}
                      </h6>
                      :
                      <>
                        <Button
                          className=" "
                          style={{

                            fontSize: 12,
                            color: 'red',
                            marginLeft: "10px",
                            marginTop: "5px",
                            padding: 0
                          }}
                          onClick={ReSendPhoneOTP}
                        >
                          Resend Otp
                        </Button>
                        <h6 className=" d-flex  justify-content-center " style={{ color: Colors.lightBlue }}>
                          {phoneCodeResponse}
                        </h6>
                      </>
                    }
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
            {phoneVerifyStatus ?
              <Button
                className="border text-success  border-success "
                style={{
                  width: "200px",
                  height: "60px",
                  fontSize: 20,
                  // color: Colors.darkBlue,
                  marginLeft: "10px",
                  marginTop: "10px",
                  // marginTop: 10,
                  // borderBottom: "1px solid gray",
                }}
              // onClick={PhoneSend}
              >
                Verified
              </Button>
              : <>
                <Button
                  className="border  border-primary "
                  style={{
                    width: "400px",
                    height: "60px",
                    fontSize: 20,
                    color: Colors.darkBlue,
                    marginLeft: "10px",
                    marginTop: "10px",
                    // marginTop: 10,
                    // borderBottom: "1px solid gray",
                  }}
                  onClick={PhoneSend}
                >
                  Verify
                </Button>
              </>}
          </div> */}

          {/* <div className=" col-12  justify-content-center  mt-2" style={{}}> */}
          <div className=" d-lg-flex    mt-4  " style={{}}>


            <input
              ref={ImageCnicFRef}
              onChange={(e) => handleChangeCNICFImage(e)}
              className="d-none"
              type="file"
            />
            <div>
              {valuesCnicF.image == null ? (
                <>

                  {apiCNICFrountImage.length >= 1 ?
                    <div style={{ height: 300, width: 400, borderWidth: 1 }}>

                      <img
                        onClick={fileOpenCNICF}
                        src={apiCNICFrountImage}
                        style={{ height: 300, width: 400 }}
                        className="img-thumbnail "
                        alt="CNIC Frount Image"
                      />
                    </div>
                    :
                    <div className="border text-center d-flex justify-content-center align-items-center" style={{ height: 300, width: 300 }} onClick={fileOpenCNICF}>
                      Please Select image
                    </div>
                  }
                </>
              ) : (
                <>
                  <img
                    onClick={fileOpenCNICF}
                    src={valuesCnicF.image}
                    style={{ height: 300, width: 300 }}
                    className="img-thumbnail "
                    alt="CNIC Frount Image"
                  />
                </>
              )}

            </div>
            <div className="  mt-3 ms-lg-5 position-relative" style={{ height: 250 }}>
              <h5 style={{ color: "black", }} className=' col-lg-12 col-md-7 ' >Upload National ID/Passport ID or DRIVING-LICENSE  {/* Front Side Of */} Image</h5>
              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 mt-3 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please make sure the content of the photo is complete and clearly visible</h6>
              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Picture size can't exceed 900kb</h6>
              {CNICFrtImgMsg.loading ? (
                <div
                  // style={{ width: "100vw", height: "100vh" }}
                  className=" d-flex mt-lg-5 col-md-6 col-md-6 justify-content-center "
                >
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : (
                <div className="text-center mt-lg-5 col-md-7" style={{ color: Colors.lightBlue }}>{CNICFrtImgMsg.status}</div>
              )}
              <Button
                className="border  border-primary position-absolute"
                style={{
                  width: 300,
                  bottom: 0,
                  height: 60,
                  fontSize: 20,
                  color: Colors.darkBlue,

                  // marginTop: 10,
                  // borderBottom: "1px solid gray",
                }}
                onClick={PostCnicFrountImage}
              >
                Save
              </Button>
            </div>

          </div>
          {/* <div className=" d-lg-flex    mt-4  " style={{}}>


          <input
            ref={ImageCnicBRef}
            onChange={(e) => handleChangeCNICBImage(e)}
            className="d-none"
            type="file"
          />


          <div>


            {valuesCnicB.image == null ? (
              <>

                {apiCNICBackImage.length >= 1 ?
                  <div style={{ height: 300, width: 400, borderWidth: 1 }}>
                    <img
                      onClick={fileOpenCNICB}
                      src={apiCNICBackImage}
                      style={{ height: 300, width: 400 }}
                      className="img-thumbnail "
                      alt="CNIC Back Image"
                    />
                  </div>
                  :
                  <div className="border text-center d-flex justify-content-center align-items-center" style={{ height: 300, width: 300, }} onClick={fileOpenCNICB}>
                    Please Select image
                  </div>
                }
              </>
            ) : (
              <>
                <img
                  onClick={fileOpenCNICB}
                  src={valuesCnicB.image}
                  style={{ height: 300, width: 300 }}
                  className="img-thumbnail "
                  alt="CNIC Back Image"
                />
              </>
            )}

          </div>

          <div className="   mt-3 ms-lg-5 position-relative" style={{ height: 150 }}>
            <h5 style={{ color: "gray", }} className='col-lg-8 col-md-7' >Upload National ID/DRIVING-LICENSE Back Side Of Image</h5>
            {CNICBckImgMsg.loading ? (
              <div
                // style={{ width: "100vw", height: "100vh" }}
                className="d-flex col-md-6 justify-content-center "
              >
                <div
                  className="spinner-border text-secondary"
                  role="status"
                ></div>
              </div>
            ) : (
              <div className="text-center col-md-8" style={{ color: Colors.lightBlue }}>{CNICBckImgMsg.status}</div>
            )}
            <Button
              className="border  border-primary position-absolute "
              style={{
                width: 300,
                fontSize: 20,
                height: 60, bottom: 0,
                color: Colors.darkBlue,
              }}
              onClick={PostCnicBackImage}
            >
              Save
            </Button>
          </div>
        </div> */}
          <div className="  mt-4 d-lg-flex " style={{}}>


            <input
              ref={ImageCnicHoldRef}
              onChange={(e) => handleChangeCNICHoldImage(e)}
              className="d-none"
              type="file"
            />
            <div>
              {valuesCnicHold.image == null ? (
                <>

                  {apiCNICHoldingImage.length >= 1 ?
                    <div style={{ height: 300, width: 400 }}>
                      <img
                        onClick={fileOpenCNICHold}
                        src={apiCNICHoldingImage}
                        style={{ height: 300, width: 400 }}
                        className="img-thumbnail "
                        alt="CNIC Image With Holding"
                      />
                    </div>

                    :
                    <div className="border text-center d-flex justify-content-center align-items-center" style={{ height: 300, width: 300 }} onClick={fileOpenCNICHold}>
                      Please Select image
                    </div>
                  }
                </>
              ) : (
                <>
                  <img
                    onClick={fileOpenCNICHold}
                    src={valuesCnicHold.image}
                    style={{ height: 300, width: 300 }}
                    className="img-thumbnail "
                    alt="CNIC Image With Holding"
                  />
                </>
              )}

            </div>

            <div className="   mt-3 ms-lg-5 position-relative " style={{ height: 250 }}>
              <h5 style={{ color: "black", }} className='col-lg-12 col-md-7' >Take a picture with your id beside your face and upload</h5>

              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 mt-3 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please upload a front photo of the Document{/*  and your personal signature */}</h6>
              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please make sure the content of the photo is complete and clearly visible</h6>
              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Picture size can't exceed 900kb</h6>
              {CNICHolngImgMsg.loading ? (
                <div
                  // style={{ width: "100vw", height: "100vh" }}
                  className="d-flex mt-lg-5 col-md-6 justify-content-center "
                >
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : (
                <div className="text-center mt-lg-5 col-md-7" style={{ color: Colors.lightBlue }}>{CNICHolngImgMsg.status}</div>
              )}
              <Button
                className="border mt-2 border-primary position-absolute "
                style={{
                  width: 300,
                  fontSize: 20,
                  color: Colors.darkBlue, bottom: 0, height: 60
                  // marginTop: 10,
                  // borderBottom: "1px solid gray",
                }}
                onClick={PostCnicHoldingImage}
              >
                Save
              </Button>
            </div>
          </div>


          {/* <div className="  mt-4 d-lg-flex " style={{}}>


          <input
            ref={ImagePassportRef}
            onChange={(e) => handleChangePassportImage(e)}
            className="d-none"
            type="file"
          />
          <div>
            {valuesPassport.image == null ? (
              <>
                {apiPasportImage >= 1 ?
                  <div style={{ height: 300, width: 400 }}>
                    <img
                      onClick={fileOpenPassport}
                      src={apiPasportImage}
                      style={{ height: 300, width: 400 }}
                      className="img-thumbnail "
                      alt="Pasport Image"
                    />
                  </div>
                  :

                  <div className="border text-center d-flex justify-content-center align-items-center" style={{ height: 300, width: 300 }} onClick={fileOpenPassport}>
                    Please Select image
                  </div>
                }
              </>
            ) : (
              <>
                <img
                  onClick={fileOpenPassport}
                  src={valuesPassport.image}
                  style={{ height: 300, width: 300 }}
                  className="img-thumbnail "
                  alt="Pasport Image"
                />
              </>
            )}


          </div>
          <div className="  mt-3 ms-lg-5  position-relative " style={{ height: 250 }}>
            <h5 style={{ color: "black" }} className='col-lg-12 col-md-7'>Upload Pasport Image </h5>

            <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 mt-3 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please upload a front photo of the Pasport</h6>
            <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please make sure the content of the photo is complete and clearly visible</h6>
            <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Picture size can't exceed 900kb</h6>

            {PasportImgMsg.loading ? (
              <div
                // style={{ width: "100vw", height: "100vh" }}
                className="d-flex  mt-lg-5 col-md-6 justify-content-center "
              >
                <div
                  className="spinner-border text-secondary"
                  role="status"
                ></div>
              </div>
            ) : (
              <div className="text-center mt-lg-5 col-md-7" style={{ color: Colors.lightBlue }}>{PasportImgMsg.status}</div>
            )}
            <Button
              className="border mt-2 border-primary position-absolute "
              style={{
                width: 300,
                fontSize: 20, height: 60,
                color: Colors.darkBlue,
                bottom: 0
                // marginTop: 10,
                // borderBottom: "1px solid gray",
              }}
              onClick={PostPasprtImage}
            >
              Save
            </Button>
          </div>
        </div> */}
          <div className="  mt-4 d-lg-flex  " style={{}}>


            <input
              ref={ImageUtilityBillRef}
              onChange={(e) => handleChangeUtilityBillImage(e)}
              className="d-none"
              type="file"
            />
            <div>
              {valuesUtilityBill.image == null ? (
                <>
                  {apiUtilityBillImage.length >= 1 ?
                    <div style={{ height: 300, width: 400 }}>

                      <img
                        onClick={fileOpenUtilityBill}
                        src={apiUtilityBillImage}
                        style={{ height: 300, width: 400 }}
                        className="img-thumbnail "
                        alt="Utility Bill Image"
                      />
                    </div>

                    :
                    <div className="border text-center d-flex justify-content-center align-items-center" style={{ height: 300, width: 300 }} onClick={fileOpenUtilityBill}>
                      Please Select image
                    </div>
                  }
                </>
              ) : (
                <>
                  <img
                    onClick={fileOpenUtilityBill}
                    src={valuesUtilityBill.image}
                    style={{ height: 300, width: 300 }}
                    className="img-thumbnail "
                    alt="Utility Bill Image"
                  />
                </>
              )}

            </div>
            <div className=" mt-3 ms-lg-5 position-relative " style={{ height: 250 }}>
              <h5 style={{ color: "black" }} className='col-lg-12 col-md-7'>Upload your proof of address (bank statement,utility)</h5>

              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 mt-3 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please upload a front photo of the Document{/*  and your personal signature */}</h6>
              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Please make sure the content of the photo is complete and clearly visible</h6>
              <h6 style={{ color: "gray", fontSize: 15, fontWeight: 'normal' }} className='m-0 col-lg-12 col-md-7 ' > <GoPrimitiveDot color={Colors.darkBlue} />Picture size can't exceed 900kb</h6>

              {UtilityBillImgMsg.loading ? (
                <div
                  // style={{ width: "100vw", height: "100vh" }}
                  className=" d-flex mt-lg-5 col-md-6 justify-content-center "
                >
                  <div
                    className="spinner-border text-secondary"
                    role="status"
                  ></div>
                </div>
              ) : (
                <div className="text-center mt-lg-5 col-md-7" style={{ color: Colors.lightBlue }}>{UtilityBillImgMsg.status}
                </div>
              )}
              <Button
                className="border mt-2 border-primary position-absolute "
                style={{
                  width: 300,
                  fontSize: 20,
                  color: Colors.darkBlue, bottom: 0,
                  height: 60
                  // marginTop: 10,
                  // borderBottom: "1px solid gray",
                }}
                onClick={PostUtilityBillImage}
              >
                Save
              </Button>
            </div>
          </div>


          <div className="d-flex justify-content-end">
            {emailVerifyStatus && phoneVerifyStatus && apiCNICFrountImage.length >= 1 && apiCNICHoldingImage.length >= 1 && apiUtilityBillImage.length >= 1 ?
              <Button
                className="border mt-2 border-primary  "
                style={{
                  width: 200,
                  fontSize: 20,
                  color: Colors.darkBlue, bottom: 0,
                  height: 60
                }}
                onClick={() => history.replace("/Home")}
              >
                Finish
              </Button>
              : <></>}
          </div>
          {/* </div> */}

          <Modal open={openLogOutModal}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="col-12 row justify-content-center">
                    <h5 className="text-center">
                      Are you sure you want to Logout
                    </h5>
                    <div className=" col-8 col-lg-6 mt-2 d-flex justify-content-between">
                      <button
                        onClick={() => CloseLogOutMod()}
                        style={{ fontSize: 20, borderRadius: 20 }}
                        type="button"
                        className="btn mb-2 btn-outline-secondary"
                      >
                        Close
                      </button>
                      <button
                        onClick={LogOut}
                        style={{ fontSize: 20, borderRadius: 20 }}
                        type="button"
                        className="btn mb-2 btn-outline-secondary"
                      >
                        Log Out
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div >
      </>
    );
  }
}

export default Verification;
