import React, { useEffect, useRef, useState } from "react";

import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from '../../Constant/Colors'
import { FiCameraOff } from "react-icons/fi";

function EditProfile(props) {
  const { open, close, userDetaiL, get, setAlertShow,
    setAlertMessage } = props;
  const user_Data = JSON.parse(localStorage.getItem("userData"));

  const { id } = user_Data.data;
  const {
    first_name,
    last_name,
    phone_number,
    email,
    image,

    country_id,
    country_name,
    country_code,
    address,
  } = userDetaiL

  // console.log(userDetaiL, 'userDetaiL>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  const EditProfileModClose = () => {
    close(false);
    setSelectedImage("");
    setValues({ image: null, name: null });
  };
  const ImageRef = useRef();
  const [selectedImage, setSelectedImage] = useState({
    image: null,
    name: null,
  });

  const [errors, setErrors] = useState("");
  const [values, setValues] = useState({
    image: null,
    name: null,
  });
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);

  const [editAddress, setEditAddress] = useState(address);

  const [phoneNumber, setPhoneNumber] = useState(phone_number);
  const [contryApi, setContryApi] = useState([]);
  let countryData = JSON.stringify({
    id: country_id,
    name: country_name,
    code: country_code,
  });
  const [contrySelected, setContrySelected] = useState(countryData);
  const countrySelected = JSON.parse(contrySelected);
  const SelectedCountry = (e) => {
    setContrySelected(e.target.value);
  };
  const [validation, setValidation] = useState({
    adrs: true,
    phNum: true,
    ftName: true,
    ltName: true,
  });
  const ftNValid = function (ftn) {
    return function (ftn) {
      if (ftn.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  const ltNValid = function (ltn) {
    return function (ltn) {
      if (ltn.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  const adValid = function (adr) {
    return function (adr) {
      if (adr.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  const phnValid = function (phn) {
    return function (phn) {
      if (phn.length == 10) {
        return true;
      } else {
        return false;
      }
    };
  };
  const updateProfile = async () => {
    var ftNmValid = ftNValid("nm");
    var FtnameVal = ftNmValid(firstName);
    var ltNmValid = ltNValid("nm");
    var LtnameVal = ltNmValid(lastName);
    var adreValid = adValid("nm");
    var AdVal = adreValid(editAddress);
    var phnNumValid = phnValid("nm");
    var PhnVal = phnNumValid(phoneNumber);

    setValidation({
      ftName: FtnameVal,
      ltName: LtnameVal,
      adrs: AdVal,
      phNum: PhnVal,
    });
    if (FtnameVal && LtnameVal && AdVal && PhnVal) {
      // setIsloding(true);
      try {
        const response = await API().postEditProfile(
          id,
          firstName,
          lastName,
          phoneNumber,
          selectedImage.image,
          selectedImage.name,
          editAddress,
          countrySelected.id,
          countrySelected.code
        );
        if (response.message == "Profile Updated") {


          setAlertShow(true)
          setTimeout(() => {
            setAlertShow(false)


          }, 3000);
          get();
          close(false);
          setAlertMessage('Profile Updated')
        }
        // setIsloding(false);
      } catch (err) {
        console.log(err, "editprofile");
      }
    }
  };

  const fileOpen = () => {
    const trigger = ImageRef.current;
    trigger.click();
  };
  const handleChangeImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];
    console.log(ddddd, "value");
    const file = files[0];
    let ext = value.split(".");
    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;
        const base64 = FileasDataUrl.split(",");
        const base64Encoded = base64[base64.length - 1];
        setSelectedImage({ name: ddddd, image: base64Encoded });

        setValues({ image: FileasDataUrl, name: ddddd });
        // setValues((prev) => {
        //   return {
        //     ...prev,
        //     image: [
        //       {
        //         name: value,
        //         base64: base64Encoded,
        //       },
        //     ],
        //   };
        // });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };
  const getdata = async () => {
    // setIsloding(true);

    try {
      const response = await API().getcounties();
      setContryApi(response.data);
      // setIsloding(false);
    } catch (error) {
      console.log(error, "contryApi");
    }
  };
  useEffect(() => {
    getdata();
  }, [firstName]);
  return (
    <div>
      <Modal open={open}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div className="modal-header">
              <h1> Edit Profile</h1>
            </div>
            <div className="modal-body">
              <div className=" d-flex ">
                <input
                  ref={ImageRef}
                  onChange={(e) => handleChangeImage(e)}
                  className="d-none"
                  type="file"
                />
                {values.image === null ? (
                  <>
                    {image == null ?
                      <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "120px", width: "120px" }} onClick={fileOpen}>

                        <FiCameraOff color="gray" size={30} />
                      </div>
                      :
                      <img
                        onClick={fileOpen}
                        src={image}
                        style={{ height: 150, width: 150 }}
                        className="img-thumbnail rounded-circle"
                        alt="Profile"
                      />
                    }
                  </>
                ) : (
                  <>
                    <img
                      onClick={fileOpen}
                      src={values.image}
                      style={{ height: 150, width: 150 }}
                      className="img-thumbnail rounded-circle"
                      alt="Profile"
                    />
                  </>
                )}

                <div className=" mt-4 ">
                  <h3 style={{ color: "gray", marginTop: 10, marginLeft: 10 }}>
                    {first_name} {last_name}
                  </h3>
                  <h5 style={{ color: "gray", marginLeft: 10 }}>{email}</h5>
                </div>
              </div>
              <div className="mt-2">
                {validation.ftName ? (
                  <>
                    <h6>Name:</h6>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="form-control "
                      style={{ width: "100%" }}
                    ></input>
                  </>
                ) : (
                  <>
                    <h6 className="text-danger">Name:</h6>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Fill This Feild"
                      className="form-control border-danger"
                      style={{ width: "100%" }}
                    ></input>
                  </>
                )}
              </div>
              <div className="mt-2">
                {validation.ltName ? (
                  <>
                    <h6>Last Name:</h6>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="form-control "
                      style={{ width: "100%" }}
                    ></input>
                  </>
                ) : (
                  <>
                    <h6 className="text-danger">Last Name:</h6>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Fill This Feild  "
                      className="form-control border-danger"
                      style={{ width: "100%" }}
                    ></input>
                  </>
                )}
              </div>
              <div className="mt-2">
                {validation.adrs ? (
                  <>
                    <h6>Address:</h6>
                    <input
                      value={editAddress}
                      onChange={(e) => setEditAddress(e.target.value)}
                      className="form-control "
                      style={{ width: "100%" }}
                    ></input>
                  </>
                ) : (
                  <>
                    <h6 className="text-danger">Address:</h6>
                    <input
                      value={editAddress}
                      onChange={(e) => setEditAddress(e.target.value)}
                      className="form-control border-danger "
                      placeholder="Fill This Feild"
                      style={{ width: "100%" }}
                    ></input>
                  </>
                )}
              </div>

              <div className="mt-2">
                <h6>Country</h6>
                <select
                  style={{
                    width: "100%",
                    height: 40,
                    borderColor: "rgb(199, 199, 199)",
                  }}
                  className="form-select px-3 text-secondary shadow-sm mt-2 "
                  aria-label="Default select example"
                  onChange={SelectedCountry}
                >
                  <option selected>{countrySelected.name}</option>

                  {contryApi.map((item) => {
                    return (

                      <>
                        {item.id !== countrySelected.id ? (
                          <>
                            <option key={item.id} value={JSON.stringify(item)}>
                              {item.name}
                            </option>
                          </>
                        ) : (
                          <></>
                        )}
                      </>

                    );
                  })}
                </select>
              </div>

              <div className="mt-2">
                {validation.phNum ? (
                  <>
                    <h6>Phone Number:</h6>
                    <div className="border  align-items-center d-flex justify-content-between">
                      <span style={{ marginLeft: "4%" }}>
                        {countrySelected.code}
                      </span>
                      <input
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="form-control p-0 border-0 py-2 "
                        style={{ width: "87%" }}
                      ></input>
                    </div>
                  </>
                ) : (
                  <>
                    <h6 className="text-danger">Phone Number:</h6>
                    <div className="border border-danger  align-items-center d-flex justify-content-between">
                      <span style={{ marginLeft: "4%" }}>
                        {countrySelected.code}
                      </span>
                      <input
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="form-control p-0 border-0 py-2 "
                        style={{ width: "87%" }}
                      ></input>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer ">
              <button

                onClick={() => EditProfileModClose()}
                type="button"
                className="btn px-4 rounded-pill border-0 btn-primary"
                style={{ backgroundColor: Colors.lightBlue, color: "white" }}
              >
                Close
              </button>
              <button
                onClick={updateProfile}
                // style={{ fontSize: 20, borderRadius: 20 }}
                type="button"
                className="btn px-4 border-0 rounded-pill btn-primary"
                style={{ backgroundColor: Colors.darkBlue, }}
              >
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditProfile;
