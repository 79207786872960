import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";


function CreateSellingAds(props) {


  const history = useHistory();


  const { open, stateClose, country, User_symbol, getdata, User_Id, setAlertShow,
    setAlertMessage } = props;
  const [currencyApi, setCurrencyApi] = useState([]);
  const [sellectedCurrency, setSellectedCurrency] = useState(
    JSON.stringify({
      id: 1,
      name: "Bitcoin",
      symbol: "BTC",
    })
  );

  console.log(sellectedCurrency, 'sssssssssssssssssssssssssssssssssssssssssssssss')

  const timeDuration = [{ time: '30', name: 'min' }, { time: '20', name: 'min' }, { time: '10', name: 'min' }]
  const selectedCurrency = JSON.parse(sellectedCurrency);
  const [pamentMethod, setPamentMethod] = useState([]);
  const [selectedPamentMethod, setSelectedPamentMethod] = useState('');
  const [bankName, setBankName] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [FandDprice, setFandDprice] = useState(1);
  const [fixpriceBtnColor, setFixpriceBtnColor] = useState({ backgroundColor: Colors.darkBlue, color: Colors.lightGreen });
  const [dynpriceTColor, setDynpriceTColor] = useState({ backgroundColor: Colors.lightGreen, color: Colors.darkBlue });


  const [percentage, setPercentage] = useState(0);
  const pirceType = 2;

  const [fixPrice, setFixPirce] = useState("");

  let priceYourecive = fixPrice;
  let buyerSeePrice = parseInt(fixPrice) + (fixPrice / 100) * percentage.percentage;
  // let buyerSeePrice = fixPrice + (fixPrice / 100) * 1;

  const [minCryptoPrice, setMinCryptoPrice] = useState(null);
  const [amountBTC, setAmountBTC] = useState("");
  const [minBTCAmount, setMinBTCAmount] = useState("");
  const [maxBTCAmount, setMaxBTCAmount] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [bankAccountName, setBankAccountName] = useState("");
  const [cryptoPrice, setCryptoPrice] = useState(0);
  const [priceloding, setPriceloding] = useState(true);

  const [validation, setValidation] = useState({
    min: false
  });

  const [sellectedTime, setSellectedTime] = useState("30");

  var displyPrice = (cryptoPrice - cryptoPrice / 100 * percentage.percentage).toFixed(2)
  // var displyPriceBnB = cryptoPrice


  console.log(sellectedTime, "event.target.value");

  const fff = parseFloat(maxBTCAmount)
  const crtyptoSer = (fff - ((fff / 100) * percentage.percentage))

  // const crtyptoSe = parseFloat(crtyptoSer)displyPriceBnB
  const crtyptoSerch = (crtyptoSer).toFixed(7)
  const GetData = async () => {

    // console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;')
    try {
      const response = await API().getcrypto();
      const Percentage = await API().getPercentage();


      setPercentage(Percentage.data)
      setCurrencyApi(response);

      const bankData = await API().getbank(country);
      setSelectedBank(bankData[0].id,)
      setBankName(bankData);
      const pmntMthd = await API().getpementMethod();
      setSelectedPamentMethod(pmntMthd[0].id)
      setPamentMethod(pmntMthd);

      const rescrypto = await API().getCryptoCompairStatic(
        response[0].symbol,
        User_symbol
      )
      let key = Object.values(rescrypto.data)

      // console.log('resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
      console.log(rescrypto.data, 'rescrypto>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      setCryptoPrice(key[0])
      setPriceloding(false)
    } catch { }
  };

  useEffect(() => {
    GetData();
  }, []);

  const handleChange = async (event) => {
    // console.log(event.target.value, "event.target.value");
    setSellectedCurrency(event.target.value);



    let CryptoSymbol = JSON.parse(event.target.value)
    setPriceloding(true)
    const rescrypto = await API().getCryptoCompairStatic(
      CryptoSymbol.symbol,
      User_symbol
    )
    let key = Object.values(rescrypto.data)

    // console.log(CryptoSymbol.symbol, 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
    // console.log(rescrypto.data, 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
    setCryptoPrice(key[0])

    let dPrice = (key[0] + key[0] / 100 * percentage.percentage).toFixed(2)

    if (FandDprice == 2) {
      setFixPirce(dPrice)
    }
    setPriceloding(false)
  };
  const handleChangeBank = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSelectedBank(event.target.value);
  };
  const handleChangePayment = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSelectedPamentMethod(event.target.value);
  };

  const handleChangeTime = (event) => {
    // console.log(event.target.value, "event.target.value");
    setSellectedTime(event.target.value);

  };
  const CreateSellingAdsModClose = () => {
    stateClose(false);
    setFixPirce("");
    setMinCryptoPrice("");
    setAmountBTC("");
    setMinBTCAmount("");
    setMaxBTCAmount("");
    setBankAccountNumber("");
    setBankAccountName("");
    // setSellectedCurrency(JSON.stringify({
    //   id: 1,
    //   name: "Bitcoin",
    //   symbol: "BTC",
    // }))

  };

  let SSp = fixPrice * maxBTCAmount
  let BuyerBtcPrice = Math.round(SSp)

  // console.log(BuyerBtcPrice, "BuyerBtcPrice............................!");
  const MiniCryptoValid = function (pn) {
    return function (pn, maxBTCAmount) {
      if (pn > 0 && pn <= maxBTCAmount) {
        return true;
      } else {
        return false;
      }
    };
  };

  const Proceed = async () => {
    var MiniValid = MiniCryptoValid("ad");
    var Minidataa = MiniValid(minBTCAmount, maxBTCAmount);
    setValidation({ min: Minidataa })


    if (
      fixPrice > 0 &&
      amountBTC > 0 &&
      Minidataa &&
      maxBTCAmount > 0 &&
      bankAccountNumber > 0 &&
      bankAccountName.length > 0
    ) {

      try {
        const postBuy = await API().postsellingAd(
          User_Id,
          fixPrice,
          selectedCurrency.id,
          minCryptoPrice,
          amountBTC,
          minBTCAmount,
          maxBTCAmount,
          selectedPamentMethod,
          selectedBank,
          pirceType,
          bankAccountNumber,
          bankAccountName,
          sellectedTime,
          BuyerBtcPrice,
          crtyptoSerch
        );
        console.log(postBuy, 'postBuy');
        if (postBuy.status) {

          CreateSellingAdsModClose();
          getdata();
          setAlertShow(true)
          setTimeout(() => {

            history.push("/mysellingads")
            setAlertShow(false)

          }, 3000);
          setAlertMessage(postBuy.message)
        } else {
          CreateSellingAdsModClose();
          getdata();
          setAlertShow(true)
          setTimeout(() => {

            setAlertShow(false)

          }, 3000);
          setAlertMessage(postBuy.message)
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };

  const DynamicPrice = () => {
    setFandDprice(2)
    setFixPirce(displyPrice)
    setFixpriceBtnColor({ backgroundColor: Colors.lightGreen, color: Colors.darkBlue })


    setDynpriceTColor({ backgroundColor: Colors.darkBlue, color: Colors.lightGreen })
  }
  const FixedPrice = () => {
    setFandDprice(1)
    setFixPirce('')
    setFixpriceBtnColor({ backgroundColor: Colors.darkBlue, color: Colors.lightGreen })
    setDynpriceTColor({ backgroundColor: Colors.lightGreen, color: Colors.darkBlue })


  }

  return (
    <Modal open={open}>
      <div className="modal-dialog  modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mt-2 d-flex justify-content-between">
              <h6 style={{ color: "green" }}>Choose a CryptoCurrency </h6>

              <select
                style={{ width: "30%" }}
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => handleChange(e)}
              >
                {currencyApi.map((item) => {
                  return (
                    <>
                      {item.symbol == selectedCurrency.symbol ?
                        <option selected>
                          {selectedCurrency.symbol}
                        </option>
                        :
                        <option key={item.id} value={JSON.stringify(item)}>
                          {item.symbol}
                        </option>}
                    </>
                  );
                })}
              </select>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <h2>Price</h2>
              <div
                className="btn-group"
                role="group"
              // aria-label="Basic outlined example"
              >
                <button
                  style={{ backgroundColor: fixpriceBtnColor.backgroundColor, color: fixpriceBtnColor.color }}
                  onClick={FixedPrice}
                  type="button"
                  className="btn shadow-none border-0"
                >
                  Fixed Price
                </button>
                <button
                  style={{ backgroundColor: dynpriceTColor.backgroundColor, color: dynpriceTColor.color }}
                  onClick={DynamicPrice}
                  type="button"
                  className="btn shadow-none border-0"
                >
                  Dynamic Price
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center border col-6 mt-2 ms-auto">
              {priceloding ? (
                <div className="spinner-border text-secondary" role="status"></div>
              ) : (
                <>
                  {/* {selectedCurrency.symbo === 'BNB' ? */}
                  {/* <h5 className="text-danger mt-2"> {displyPriceBnB} </h5>
                    : */}
                  <h5 className="text-danger mt-2"> {displyPrice} </h5>

                  {/* } */}

                  <h5 className=" mt-2 ms-2">{selectedCurrency.symbol}/{User_symbol}</h5>
                </>
              )}
            </div>
            <div className="mt-2">
              {FandDprice === 1 ? (
                <>
                  {fixPrice <= 0 ? (
                    <>
                      <h6 className="text-danger">Fixed Price:</h6>
                      <div
                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>{User_symbol}</h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>Fixed Price:</h6>
                      <div
                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>{User_symbol}</h5>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {fixPrice <= 0 ? (
                    <>
                      <h6 className="text-danger">bitUSD Price:</h6>
                      <div
                        className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {User_symbol}/bitUSD
                        </h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>bitUSD Price:</h6>
                      <div
                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={fixPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setFixPirce(e.target.value);
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {User_symbol}/bitUSD
                        </h5>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="mt-2">
              <h6>{selectedCurrency.symbol} Price That Buyer See:</h6>
              <div
                className="border d-flex justify-content-between rounded shadow-sm"
                style={{
                  backgroundColor: "rgb(237, 237, 237)",
                  padding: 6,
                  paddingLeft: 10,
                  height: 40,
                }}
              >
                <span style={{ paddingLeft: 10, width: "80%" }}>{buyerSeePrice}</span>
                <h5 style={{}}>
                  {User_symbol}/{selectedCurrency.symbol}
                </h5>
              </div>
            </div>
            {FandDprice === 1 ? (
              <></>
            ) : (
              <>
                <div className="mt-2">
                  {minCryptoPrice <= 0 ? (
                    <>
                      <h6 className="text-danger">
                        Minimum {selectedCurrency.symbol} Price:
                      </h6>
                      <div
                        className="border d-flex border-danger justify-content-between rounded shadow-sm"
                        style={{
                          padding: 6,
                          paddingLeft: 10,
                          height: 40,
                        }}
                      >
                        <input
                          value={minCryptoPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinCryptoPrice(e.target.value);
                            }
                          }}
                          placeholder="0"
                          className="form-control border-0 shadow-none  "
                          style={{ width: "85%" }}
                        ></input>
                        <h5>{User_symbol}</h5>
                      </div>
                    </>
                  ) : (
                    <>
                      <h6>Minimum {selectedCurrency.symbol} Price:</h6>
                      <div
                        className="border d-flex justify-content-between rounded shadow-sm"
                        style={{
                          padding: 6,
                          paddingLeft: 10,
                          height: 40,
                        }}
                      >
                        <input
                          value={minCryptoPrice}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinCryptoPrice(e.target.value);
                            }
                          }}
                          placeholder="0"
                          className="form-control border-0 shadow-none  "
                          style={{ width: "85%" }}
                        ></input>
                        <h5>{User_symbol}</h5>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="mt-2">
              <h6>{selectedCurrency.symbol} Price That You Receive:</h6>
              <div
                className="border d-flex justify-content-between rounded shadow-sm"
                style={{
                  backgroundColor: "rgb(237, 237, 237)",
                  padding: 6,
                  paddingLeft: 10,
                  height: 40,
                }}
              >
                <span style={{ paddingLeft: 10, width: "80%" }}>{priceYourecive}</span>
                <h5 style={{}}>
                  {User_symbol}/{selectedCurrency.symbol}
                </h5>
              </div>
            </div>
            <h2>Amount</h2>
            <div className="mt-2">
              {amountBTC <= 0 ? (
                <>
                  <h6 className="text-danger">
                    Amount of {selectedCurrency.symbol}:
                  </h6>
                  <div
                    className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={amountBTC}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setAmountBTC(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  <h6>Amount of {selectedCurrency.symbol}:</h6>
                  <div
                    className=" mt-3 border rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={amountBTC}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setAmountBTC(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              )}
            </div>
            <div className="mt-2">
              {minBTCAmount <= 0 ? (
                <>
                  <h6 className="text-danger">
                    Minimum {selectedCurrency.symbol} Amount:
                  </h6>
                  <div
                    className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={minBTCAmount}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setMinBTCAmount(e.target.value);
                          setValidation({ min: true })
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  {!validation.min ?
                    <>
                      <h6 className="text-danger">
                        Minimum {selectedCurrency.symbol} Amount:
                      </h6>
                      <div
                        className=" mt-3 border border-danger rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={minBTCAmount}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinBTCAmount(e.target.value);
                              setValidation({ min: true })
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {selectedCurrency.symbol}
                        </h5>
                      </div>
                    </>
                    : <>
                      <h6>Minimum {selectedCurrency.symbol} Amount:</h6>
                      <div
                        className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                        style={{
                          width: "100%",
                          height: "45px",
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <input
                          className="form-control border-0 shadow-none  "
                          value={minBTCAmount}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              setMinBTCAmount(e.target.value);
                              setValidation({ min: true })
                            }
                          }}
                          placeholder="0"
                          style={{
                            width: "85%",
                            paddingLeft: 20,
                          }}
                        />
                        <h5 style={{ marginRight: "2%" }}>
                          {selectedCurrency.symbol}
                        </h5>
                      </div>
                    </>}
                </>
              )}
            </div>
            <div className="mt-2">
              {maxBTCAmount <= 0 ? (
                <>
                  <h6 className="text-danger">
                    Maximum {selectedCurrency.symbol} Amount:
                  </h6>
                  <div
                    className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={maxBTCAmount}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setMaxBTCAmount(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              ) : (
                <>
                  <h6>Maximum {selectedCurrency.symbol} Amount:</h6>
                  <div
                    className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={maxBTCAmount}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setMaxBTCAmount(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "85%",
                        paddingLeft: 20,
                      }}
                    />
                    <h5 style={{ marginRight: "2%" }}>
                      {selectedCurrency.symbol}
                    </h5>
                  </div>
                </>
              )}
            </div>

            <h2 className="mt-2">Active Duration</h2>
            <select
              onChange={(e) => handleChangeTime(e)}
              className="form-select"
              aria-label="Default select example"

            >
              {timeDuration.map((item, i) => {
                return (
                  <option key={i} value={item.time}>
                    {item.time}  {item.name}
                  </option>
                );
              })}
            </select>
            <h2>Payment Details</h2>
            <div className="mt-2">
              <h6>Payment Method</h6>
              <select
                onChange={(e) => handleChangePayment(e)}
                className="form-select"
                aria-label="Default select example"
              >
                {pamentMethod.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-2">
              <h6>Bank Name</h6>
              <select
                onChange={(e) => handleChangeBank(e)}
                className="form-select"
                aria-label="Default select example"
              >
                {bankName.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.bank_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="mt-2">
              {bankAccountNumber <= 0 ? (
                <>
                  <h6 className="text-danger">Bank Account Number:</h6>
                  <div
                    className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={bankAccountNumber}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setBankAccountNumber(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "100%",
                        paddingLeft: 20,
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6>Bank Account Number:</h6>
                  <div
                    className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={bankAccountNumber}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setBankAccountNumber(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "100%",
                        paddingLeft: 20,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="mt-2">
              {bankAccountName <= 0 ? (
                <>
                  <h6 className="text-danger">Bank Account Name:</h6>
                  <div
                    className=" mt-3 border border-danger  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={bankAccountName}
                      onChange={(e) => setBankAccountName(e.target.value)}
                      placeholder="Account Name"
                      style={{
                        width: "100%",
                        paddingLeft: 20,
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h6>Bank Account Name:</h6>
                  <div
                    className=" mt-3 border  rounded d-flex justify-content-between shadow-sm mx-auto "
                    style={{
                      width: "100%",
                      height: "45px",
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                  >
                    <input
                      className="form-control border-0 shadow-none  "
                      value={bankAccountName}
                      onChange={(e) => setBankAccountName(e.target.value)}
                      placeholder="Account Name"
                      style={{
                        width: "100%",
                        paddingLeft: 20,
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={CreateSellingAdsModClose}
              type="button"
              className="btn px-4 rounded-pill border-0 btn-primary"
              style={{ backgroundColor: Colors.lightBlue, color: 'white' }}
            >
              Close
            </button>
            <button
              onClick={Proceed}
              type="button"
              className="btn px-4 rounded-pill border-0 btn-primary"
              style={{ backgroundColor: Colors.darkBlue }}
            >
              Create Selling Ad
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateSellingAds;
