import axios from "axios";

const Api = "https://foloswap.com/api"; // SYSTEM ACCESS IP
const Apisectrum = "https://foloswap.com"; // SYSTEM ACCESS IP

const ApiCryptoCompair = "https://min-api.cryptocompare.com";
// const msg = 'http://162.241.121.1:3000'
const msg = 'https://f557-162-241-121-1.ngrok.io'

const fetchJSONPOSTWithOuttokenwithbody = async ({ url, body }) => {
  // let res;

  // const data = await JSON.parse(localStorage.getItem("userData"));

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      // 'Authorization': `Bearer ${data.token}`,
      // prettier-ignore
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return res.json();
};
const fetchJSONwithouttoken = async ({ url }) => {
  const res = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return axios.get(url);
};
const fetchJSONPOSTwithbodywithouttoken = async ({ url, body }) => {
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  };
  // return axios.get(`${Apisectrum}/sanctum/csrf-cookie`).then(response => {
  //   axios.post(url, JSON.stringify(body), axiosConfig)
  // });

  return axios.post(url, JSON.stringify(body), axiosConfig);
};

const fetchJSON = async ({ url }) => {
  let res;
  const data = await JSON.parse(localStorage.getItem("userData"));
  console.log(data.token, '...........................................................................................');
  res = await fetch(url, {

    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      'Accept': 'application/json',
      // prettier-ignore
      'Authorization': `Bearer ${data.token}`,
    },
  });
  return res.json();
};

const fetchJSONPOSTwithbody = async ({ url, body }) => {
  let res;

  const data = await JSON.parse(localStorage.getItem("userData"));
  console.log(data.token, '...........................................................................................');
  res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      'Authorization': `Bearer ${data.token}`,
      // prettier-ignore
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return res.json();
};

const API = (credentials) => ({
  // ##########################################
  // ######## Get Apies WithOut Token #########
  // ##########################################

  getLogout: () => fetchJSON({ url: `${Api}/logout` }),
  getDeleteNotification: (id) => fetchJSONwithouttoken({ url: `${Api}/notification_delete/${id}` }),

  getcounties: () => fetchJSONwithouttoken({ url: `${Api}/get_counties` }),
  getcurrencyNoToken: () => fetchJSONwithouttoken({ url: `${Api}/currency_list_notoken` }),
  getcryptoNoToken: () => fetchJSONwithouttoken({ url: `${Api}/crypto_list_notoken` }),
  getPercentage: () => fetchJSONwithouttoken({ url: `${Api}/percentage` }),
  getCryptoCompair: (crypto, currensy) => fetchJSONwithouttoken({ url: `${ApiCryptoCompair}/data/price?fsym=${crypto}&tsyms=${currensy}` }),
  getCryptoCompairStatic: (crypto, currensy) => fetchJSONwithouttoken({ url: `${ApiCryptoCompair}/data/price?fsym=${crypto}&tsyms=${currensy}` }),

  // #######################################
  // ######## Get Apies With Token #########
  // #######################################
  // getAcceptTransection: (tansection_id) => fetchJSON({ url: `${Api}/admin/accept_transection/${tansection_id}` }),


  GoogleAuthGenerate: (
  ) => fetchJSON({ url: `${Api}/google-auth/generate` }),

  getAutyEnableDisable: (
    user_id
  ) => fetchJSON({ url: `${Api}/auty_enable_disable/${user_id}` }),

  getDisputeRequest: (
    bid_id,
    user_id
  ) => fetchJSON({ url: `${Api}/dispute_request/${bid_id}/${user_id}` }),

  getSwapTransectionId: (
    add_id
  ) => fetchJSON({ url: `${Api}/swap_transection_id/${add_id}` }),

  getSellerSwapStatus: (
    bid_id
  ) => fetchJSON({ url: `${Api}/seller_swap_status/${bid_id}` }),

  getbuyerSwapStatus: (
    bid_id
  ) => fetchJSON({ url: `${Api}/buyer_swap_status/${bid_id}` }),

  getDeleteSwapAdd: (
    swap_id
  ) => fetchJSON({ url: `${Api}/delete_swap_add/${swap_id}` }),


  getMySwapBids: (
    user_id
  ) => fetchJSON({ url: `${Api}/my_swap_bids/${user_id}` }),

  getMySwapOffers: (
    swap_id
  ) => fetchJSON({ url: `${Api}/my_swap_offers/${swap_id}` }),

  getMySwapAd: (
    user_id
  ) => fetchJSON({ url: `${Api}/my_SwapAds/${user_id}` }),

  getSwapAd: (
    user_id
  ) => fetchJSON({ url: `${Api}/getSwapAd/${user_id}` }),


  getFundTransferList: (
    user_id
  ) => fetchJSON({ url: `${Api}/fund_transfer_list/${user_id}` }),
  getMyRefers: (
    user_id

  ) => fetchJSON({ url: `${Api}/my_refers/${user_id}` }),

  getPinStatus: (
    user_id

  ) => fetchJSON({ url: `${Api}/pin_status/${user_id}` }),
  getDispute: (
    bid_id,
    user_id

  ) => fetchJSON({ url: `${Api}/dispute_request/${bid_id}/${user_id}` }),

  getPrice: (
    qentity,
    ad_type

  ) => fetchJSON({ url: `${Api}/price_calculation/${qentity}/${ad_type}` }),

  ReversePriceCalculation: (
    qentity,
    ad_type

  ) => fetchJSON({ url: `${Api}/reverse_price_calculation/${qentity}/${ad_type}` }),


  getTransectionId: (
    add_id,

  ) => fetchJSON({ url: `${Api}/transection_id/${add_id}` }),
  getMyTransection: (
    User_id,
    Crypto_id

  ) => fetchJSON({ url: `${Api}/my_transections/${Crypto_id}/${User_id}` }),
  getTransectionDetail: (
    id,
    bid_user_id,
    ad_id

  ) => fetchJSON({ url: `${Api}/transection_detail/${bid_user_id}/${id}/${ad_id}` }),






  getBuyerAcceptStatus: (
    AddId,

  ) => fetchJSON({ url: `${Api}/buyer_accept_status/${AddId}` }),


  getAcceptSellingOfferDetail: (
    myCrptoId,
    bidUserId,
    quantity,
    ad_id,
    id
  ) => fetchJSON({ url: `${Api}/accept_selling_offer_detail/${myCrptoId}/${bidUserId}/${quantity}/${ad_id}/${id}` }),
  getBankDetails: (id) => fetchJSON({ url: `${Api}/bank_details/${id}` }),
  getcurrency: () => fetchJSON({ url: `${Api}/currency_list` }),
  getcrypto: () => fetchJSON({ url: `${Api}/crypto_list` }),
  getMyCryptoList: (id) => fetchJSON({ url: `${Api}/my_crypto_list/${id}` }),
  getGraphdata: (change) =>
    fetchJSON({ url: `${Api}/cryptograph_with_currency/${change}` }),
  // getGraphdata: () => fetchJSON({url: `${Api}/cryptograph`}),
  getUserProfileDetail: (id) =>
    fetchJSON({ url: `${Api}/user_profile_detail/${id}` }),
  getbank: (id) => fetchJSON({ url: `${Api}/banks/${id}` }),
  getads: (country_id, user_id) => fetchJSON({ url: `${Api}/getads/${country_id}/${user_id}` }),
  mygetadds: (country, id) =>
    fetchJSON({ url: `${Api}/getads/${country}/${id}?user_id=${id}` }),
  getMyBids: (id) => fetchJSON({ url: `${Api}/my_bids/${id}` }),
  getMyAddsOffers: (id, country) => fetchJSON({ url: `${Api}/my_offers/${id}/${country}` }),
  getDeleteAds: (id) => fetchJSON({ url: `${Api}/delete_add/${id}` }),
  getpementMethod: () => fetchJSON({ url: `${Api}/payment_methods` }),
  getAllVerifcatunData: (id) => fetchJSON({ url: `${Api}/get_all_data/${id}` }),
  getBillImage: (id) => fetchJSON({ url: `${Api}/get_bill_image/${id}` }),

  getWalletBalance: (adress, crpto_id) => fetchJSON({ url: `${Api}/wallet_balance/${adress}/${crpto_id}` }),

  // ##########################################
  // ######## Post Apies WhithOutToken ########
  // ##########################################


  postAuthyVerficationWithOutToken: (
    email,
    TiwllioCode

  ) =>
    fetchJSONPOSTWithOuttokenwithbody({
      url: `${Api}/google-auth/verificationwithEmail`,
      body: {
        email: email, input_token: TiwllioCode
      },
    }),


  postMessage: (
    tid

  ) =>
    fetchJSONPOSTWithOuttokenwithbody({
      url: `${msg}/get_messages`,
      body: {
        room_id: tid
      },
    }),



  //   getMessageImage: (


  //   ) =>
  //     fetchJSONPOSTwithbodywithouttoken({
  //       url: `${msg}/upload_image`,
  //       body: {

  // message:,
  // room_id:,
  // user_id:,
  // file :,
  //       },
  //     }),

  getAdListBeforeLogin: (

    sellectedCurrency,
    priceType,
    searchButton,
    sellectedCountryCurrency,
    Eventprice
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/ad_list_before_login`,
      body: {
        crpto_type: sellectedCurrency,
        type: priceType,
        symbol: searchButton,
        country_symbol: sellectedCountryCurrency,
        price: Eventprice
      },
    }),
  // ############################
  // ######## Post Apies ########
  // ############################


  postSwapBidRequest: (
    user_id,
    swap_id,
    sellBidCrypto,
    reciveBidCrypto,
    walletId,
    reciveWalletAddress,
    cryptoId
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/swap_bid_request/${user_id}/${swap_id}`,
      body: {
        sell_bid_crypto: sellBidCrypto,
        recive_bid_crypto: reciveBidCrypto,
        wallet_id: walletId,
        recive_wallet: reciveWalletAddress,
        crypto_id: cryptoId
      },
    }),

  postAuthyStatus: (
    codeStatus
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/google-auth/status`,
      body: {
        status: codeStatus
      },
    }),

  postAuthyVerfication: (
    email,
    TiwllioCode

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/google-auth/verfication`,
      body: {
        email: email, input_token: TiwllioCode
      },
    }),

  postAuthySetup: (
    userId,
    email,
    authyNum,
    country_code,

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/authy_setup/${userId}`,
      body: {
        email: email,
        number: authyNum,
        country_code: country_code


      },
    }),

  postSwapAdEdit: (
    swap_id,
    sellCrypto,
    reciveCrypto,
    sellWalletId,
    reciveWalletAddress,
    duration,
    cryptoId,
    reciveCryptoId

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/swap_ad_edit/${swap_id}`,
      body: {
        sell_crypto: sellCrypto,
        recive_crypto: reciveCrypto,
        sell_wallet_id: sellWalletId,
        recive_wallet_address: reciveWalletAddress,
        duration: duration,
        crypto_id: cryptoId,
        recive_crypto_id: reciveCryptoId

      },
    }),


  postCreateSwapAd: (
    UserId,
    sellCrypto,
    reciveCrypto,
    sellWalletId,
    reciveWalletAddress,
    duration,
    cryptoId,
    reciveCryptoId

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/CreateSwapAd/${UserId}`,
      body: {
        sell_crypto: sellCrypto,
        recive_crypto: reciveCrypto,
        sell_wallet_id: sellWalletId,
        recive_wallet_address: reciveWalletAddress,
        duration: duration,
        crypto_id: cryptoId,
        recive_crypto_id: reciveCryptoId

      },
    }),

  postCryptoCheck: (
    sellCrypto,
    reciveCrypto,
    sendCryptoValue

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/crypto_check`,
      body: {
        sell_crypto: sellCrypto,
        recive_crypto: reciveCrypto,
        send_crypto_value: sendCryptoValue

      },
    }),

  postReferUser: (
    id,
    email

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/refer_user/${id}`,
      body: {
        email: email

      },
    }),
  postUpdatePincode: (
    id,
    OldPin,
    NewPin

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/update_pincode/${id}`,
      body: {
        old_pin: OldPin,
        new_pin: NewPin

      },
    }),
  postPinCodeSave: (
    id,
    pin

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/pin_code_save/${id}`,
      body: {
        pin_code: pin

      },
    }),
  postChekToken: (
    id
  ) =>
    fetchJSON({
      url: `${Api}/check_token/${id}`,
      body: {
        data: "test",

      },
    }),

  postTransferCrypto: (
    walletId,
    Amount,
    walletAddress

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/fund_transfer`,
      body: {
        wallet_id: walletId,
        amount: Amount,
        wallet_address: walletAddress
      },
    }),
  postAcceptTransection: (
    tansection_id,
    ad_id,
    quantity,
    bid_user_id,
    my_crpto_id,

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/admin/accept_transection/${tansection_id}`,
      body: {
        to_user: bid_user_id,
        ad_id: ad_id,
        bid_amount: quantity,
        crypto: my_crpto_id
      },
    }),
  postSellerAcceptStatus: (
    id,
    bid_id,
    ad_id,
    crypo_quntity,
    bid_user_id,
    bid_amount,
    // cryptoquntity,
    cryptoid,
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/seller_accept_status/${bid_id}`,
      body: {
        from_user: id,
        to_user: bid_user_id,
        ad_id: ad_id,
        bid_amount: bid_amount,
        crypto: crypo_quntity,
        // quantity: cryptoquntity,
        cryptoId: cryptoid
      },
    }),

  postTransectionImageUplode: (
    bidUserId,
    userId,
    addId,
    Image,
    ImageName,
    bidAmount,
    cryptoQuentity,
    bidId

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/transection_image_uplode`,
      body: {
        from_user: bidUserId,
        to_user: userId,
        ad_id: addId,
        image_name: ImageName,
        transection_image: Image,
        bid_amount: bidAmount,
        crypto: cryptoQuentity,
        bid_id: bidId
      },
    }),
  postBankDetailSave: (
    id,
    sellectedBankId,
    bankAccountNumber

  ) =>

    fetchJSONPOSTwithbody({
      url: `${Api}/bank_Detail_save/${id}`,
      body: {
        bank_id: sellectedBankId,
        account_number: bankAccountNumber
      },
    }),
  postAdList: (
    id,
    sellectedCurrency,
    priceType,
    searchButton,
    sellectedCountryCurrency,
    Eventprice
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/ad_list/${id}`,
      body: {
        crpto_type: sellectedCurrency,
        type: priceType,
        symbol: searchButton,
        country_symbol: sellectedCountryCurrency,
        price: Eventprice
      },
    }),

  postPasportImage: (id, pasportImage, pasportImageName) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/passport_image_api/${id}`,
      body: {
        passpot_image: pasportImage,
        image_name: pasportImageName,
      },
    }),
  postUtilityBillImage: (id, utilityBillImage, utilityBillImageName) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/utility_bill_api/${id}`,
      body: {
        bil_image: utilityBillImage,
        image_name: utilityBillImageName,
      },
    }),
  postCnicImageWithFace: (id, nicImageWithFace, nicImageWithFaceName) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/cnic_withface_api/${id}`,
      body: {
        cnic_with_face: nicImageWithFace,
        image_name: nicImageWithFaceName,
      },
    }),
  postFrountCnicImages: (id, nicfrountImg, nicfrountImgName) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/cnic_image_api/${id}`,
      body: {
        image_name: nicfrountImgName,
        front_image: nicfrountImg,
      },
    }),
  postBackCnicImages: (
    id,

    nicbackImg,
    nicbackImgName
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/cnic_back_image/${id}`,
      body: {
        image_name: nicbackImgName,
        back_image: nicbackImg,
      },
    }),
  postPhonConformationCode: (id, phoneValue, contryId, contryCode) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/phone_confrimation_code/${id}`,
      body: {
        number: phoneValue,
        country_id: contryId,
        country_code: contryCode,
      },
    }),


  postPhoneVerfication: (id, email, TiwllioCode) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/phone_verfication/${id}`,
      body: {
        email: email, input_token: TiwllioCode
      },
    }),
  postEmailConformationCode: (id, email) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/email_code/${id}`,
      body: {
        email: email,
      },
    }),
  postEmailVerfication: (id, emailPinCode) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/email_verfication/${id}`,
      body: {
        verification_code: emailPinCode,
      },
    }),
  postTransectionVerfiyCode: (id, emailPinCode) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/transection_verfiy_code/${id}`,
      body: {
        verification_code: emailPinCode,
      },
    }),
  postEditProfile: (
    id,
    firstName,
    lastName,
    phoneValue,
    profileImage,
    profileImageName,
    editAddress,
    countryId,
    countryCode
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/update_profile/${id}`,
      body: {
        first_name: firstName,
        last_name: lastName,
        number: phoneValue,
        image_name: profileImageName,
        profile_image: profileImage,
        address: editAddress,
        country: countryId,
        country_code: countryCode,
      },
    }),
  postChangePassword: (id, password, changePassword) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/update_password/${id}`,
      body: {
        old_password: password,
        new_password: changePassword,
      },
    }),

  postForgotPassword: (email) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/forgot_passowrd_code`,
      body: {
        email: email,
      },
    }),

  postPasswordCodeVerfiy: (email, emailPinCode) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/password_code_verfiy`,
      body: {
        email: email,
        confirm_code: emailPinCode,
      },
    }),

  postUpdatePasswordForgoted: (email, password, confirmPassword) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/update_password_forgoted`,
      body: {
        email: email,
        new_password: password,
        confirm_password: confirmPassword,
      },
    }),

  postbuyingAd: (
    id,
    fixPrice,
    chipValue,
    minCryptoPrice,
    amountBTC,
    minBTCAmount,
    maxBTCAmount,
    selectedPamentMethod,
    selectedBank,
    priceType,
    sellectedTime,
    sellerSeePrice,
    crtyptoSerch
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/create_buying_ad`,
      body: {
        user_id: id,
        price: fixPrice,
        crypto_currency: chipValue,
        minimum_crypto_price: minCryptoPrice,
        amount_of_crypto: amountBTC,
        minimum_crypto_amount: minBTCAmount,
        maximum_crypto_amount: maxBTCAmount,
        payment_method: selectedPamentMethod,
        bank: selectedBank,
        price_type: priceType,
        duration: sellectedTime,
        search_price: sellerSeePrice,
        crypto_search: crtyptoSerch
      },
    }),

  postEditAdd: (
    iid,
    amountBTC,
    minBTCAmount,
    maxBTCAmount,
    chipValueValue,
    iPriceType,
    minCryptoPrice,
    selectedBank,
    bankAccountNumber,
    bankAccountName,
    AdType,
    fixPrice,

    selectedPamentMethod
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/ad_edit/${iid}`,
      body: {
        amount_of_crypto: amountBTC,
        minimum_crypto_amount: minBTCAmount,
        maximum_crypto_amount: maxBTCAmount,
        crypto_currency: chipValueValue,
        price_type: iPriceType,
        minimum_crypto_price: minCryptoPrice,
        bank: selectedBank,
        bank_account_number: bankAccountNumber,
        account_name: bankAccountName,
        ad_type: AdType,
        price: fixPrice,
        payment_method: selectedPamentMethod,
      },
    }),

  postBidRequest: (id, add_id, quantity, bidAmount, cryptoId) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/send_bid_request/${id}`,
      body: {
        bid_id: add_id,
        quantity: quantity,
        bid_amount: bidAmount,
        crypto_id: cryptoId
      },
    }),

  postsellingAd: (
    id,
    fixPrice,
    chipValue,
    minCryptoPrice,
    amountBTC,
    minBTCAmount,
    maxBTCAmount,
    selectedPamentMethod,
    selectedBank,
    pirceType,
    bankAccountNumber,
    bankAccountName,
    sellectedTime,
    buyerSeePrice,
    crtyptoSerch
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/create_selling_ad`,
      body: {
        user_id: id,
        price: fixPrice,
        crypto_currency: chipValue,
        minimum_crypto_price: minCryptoPrice,
        amount_of_crypto: amountBTC,
        minimum_crypto_amount: minBTCAmount,
        maximum_crypto_amount: maxBTCAmount,
        payment_method: selectedPamentMethod,
        bank: selectedBank,
        price_type: pirceType,
        bank_account_number: bankAccountNumber,
        bank_account_name: bankAccountName,
        duration: sellectedTime,
        search_price: buyerSeePrice,
        crypto_search: crtyptoSerch
      },
    }),

  signup: (
    firstname,
    lastname,
    email,
    password,
    contrySelected,
    passportNumber,
    phoneValue,
    CNIC,
    address,
    profileImage,
    profileImageName,
    countryCode,
    referCode
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/user/signup`,
      body: {
        first_name: firstname,
        last_name: lastname,
        email: email,
        password: password,
        country: contrySelected,
        passport: passportNumber,
        number: phoneValue,
        cnic: CNIC,
        address: address,
        image_name: profileImageName,
        profile_image: profileImage,
        country_code: countryCode,
        refer_code: referCode
      },
    }),

  signin: (_username, _password) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/user/login`,
      body: { email: _username, password: _password },
    }),
});

export default API;
