import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import API from "../../Api";
import MySellingBidsDetails from "./MySellingBidsDetails";
import * as Colors from '../../Constant/Colors'




function MySellingBids(props) {
  const { open, close, set } = props;
  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id, symbol } = user_Data.data;
  const [mySellingBids, setMySellingBids] = useState([]);
  const [myBidsDetails, setMyBidsDetails] = useState({});
  const [data, setData] = useState(0);
  const [percentage, setPercentage] = useState('');
  const [openshowMySellingBidsDetails, setOpenshowMySellingBidsDetails] =
    useState(false);
  const ShowMySellingBidsDetails = (props) => {
    setOpenshowMySellingBidsDetails(true);
    close();
    setMyBidsDetails(props);
    setData(1)
  };
  const ShowMySellingBidsDetailsClose = () => {
    setOpenshowMySellingBidsDetails(false);
    set(true);
    setData(0)
  };
  // ss
  const GetApi = async () => {
    try {
      const respons = await API().getMyBids(id);
      // console.log(respons, "reeessssssssssssssssssssssssssss");
      const { selling_ads } = respons;
      setMySellingBids(selling_ads);
      //   setIsLoading(false);
      //   console.log(buying_ads, "myGetBids");
      const Percentage = await API().getPercentage();
      setPercentage(Percentage.data.percentage)
    } catch (err) {
      console.log(err, "myGetBids");
    }
  };
  useEffect(() => {
    GetApi();
  }, []);

  return (
    <div>
      <Modal open={open} onClose={close}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div className="modal-header">
              <h2>My Selling Offers</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                {mySellingBids.length > 0 ? (
                  <>
                    {mySellingBids.map((item) => {
                      //   console.log(item, "item");
                      return (
                        <div
                          key={item.bid_id}
                          className=" row col-10 mt-2 rounded shadow-sm"
                        >
                          <h5 className="mt-2">
                            <span className="text-danger  mr-4">
                              {parseInt(item.price) - (item.price / 100) * percentage} {" "}
                            </span>
                            {`${symbol} / ${item.crpto_symbol}`}
                          </h5>

                          <h5 className="">
                            Maximum:{" "}
                            <span>
                              {item.maximum_crypto_amount} {item.crpto_symbol}
                            </span>
                          </h5>

                          <h5>{item.bank_name}</h5>
                          <h5>{item.name}  {item.last_name}</h5>
                          <button
                            style={{ fontSize: 20, borderRadius: 20, color: Colors.darkBlue }}
                            onClick={() => ShowMySellingBidsDetails(item)}
                            type="button"
                            className="btn mb-2 btn-outline-primary"
                          >
                            Show Details
                          </button>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      style={{ height: 150 }}
                      className=" text-center col-10 rounded shadow-sm"
                    >
                      <h2 style={{ color: "gray", marginTop: "15%" }}>
                        No Bids Yet
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {data == 1 ?
        <MySellingBidsDetails
          BidsDetail={myBidsDetails}
          getApi={GetApi}
          open={openshowMySellingBidsDetails}

          close={ShowMySellingBidsDetailsClose}


        />
        : <></>
      }
    </div>
  );
}

export default MySellingBids;
