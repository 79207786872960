import React, { useEffect, useState, useRef } from "react";
import API from "../Api";
import { useHistory } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { RiCameraSwitchLine } from "react-icons/ri";
import ls from "localstorage-slim";
import * as Colors from "../Constant/Colors";
import { colors } from "@material-ui/core";
import backgrounimage from '../Images/geometric-1732847.jpeg'
import CoustumAlert from '../Constant/CoustumAlert'

function SingUp() {
  let countryData = JSON.stringify({
    id: 1,
    name: "Pakin",
    code: "+92",
  });
  const history = useHistory();
  const [isloding, setIsloding] = useState(false);
  const [contryApi, setContryApi] = useState([]);
  const [contrySelected, setContrySelected] = useState(countryData);
  const countrySelected = JSON.parse(contrySelected);
  console.log(countrySelected, 'countrySelected........................................')
  const [profileImage, setProfileImage] = useState({
    image: null,
    name: null,
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [refrenceCode, setRefrenceCode] = useState(null);
  const [email, setEmail] = useState("");
  const [cnic, setCnic] = useState(null);
  const [pasport, setPasport] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");



  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [visiblepass, Setvisiblepass] = useState({
    Type: "password",
    iconName: BsEyeSlash,
  });
  const [serverError, setServerError] = useState("");
  const [validation, setValidation] = useState({
    fn: true,
    ln: true,
    email: true,
    adress: true,
    cnic: true,

    pn: true,
    pw: true,
    cpw: true,

  });
  const [values, setValues] = useState({
    image: null,
    name: null,
  });
  const [errors, setErrors] = useState("");
  const validateEmail = (e) => /^[^@ ]+@[^@ ]+\.[^@ \.]+$/.test(e);

  const pwValid = (inputtxt) => {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    if (inputtxt.match(passw)) {
      // alert('Correct, try another...')
      return true;
    }
    else {
      // alert('Wrong...!')
      return false;
    }
  }




  const isValidEmail = function (value) {
    if (validateEmail(value)) {
      return true;
    } else {
      return false;
    }
  };
  const AdressValid = function (ad) {
    return function (ad) {
      if (ad.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  // const CNICValid = function (ab) {
  //   return function (ab) {
  //     if (ab.length > 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };
  // };

  const fnValid = function (fm) {
    return function (fm) {
      if (fm.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  // const pwValid = function (pw) {
  //   return function (pw) {
  //     if (pw.length > 7) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };
  // };

  const lnValid = function (ln) {
    return function (ln) {
      if (ln.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };
  const pnValid = function (pn) {
    return function (pn) {
      if (pn.length == 9 || pn.length == 10) {
        return true;
      } else {
        return false;
      }
    };
  };

  function SingIn() {
    history.push("/signin");
  }

  const getdata = async () => {
    setIsloding(true);

    try {
      const response = await API().getcounties();

      setContrySelected(JSON.stringify(response.data[0]))
      setContryApi(response.data);
      setIsloding(false);
    } catch (error) {
      console.log(error, "contryApi");
    }
  };
  const SelectedCountry = (e) => {
    setContrySelected(e.target.value);
  };

  // console.log(countrySelected.code, 'contrySelected.code,..>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  const Rigister = async () => {
    setIsloding(true);
    var emailValidation = isValidEmail(email);
    var adValid = AdressValid("ad");
    var dataad = adValid(address);
    // var nicValid = CNICValid("ab");
    // var datanic = nicValid(cnic);

    var fValid = fnValid("nm");
    var dataF = fValid(firstName);
    var lValid = lnValid("nm");
    var datal = lValid(lastName);
    var pValid = pnValid("nm");
    var dataP = pValid(phoneNumber);
    // var psValid = pwValid("nm");
    var dataPs = pwValid(password);
    var datacPs = pwValid(confPassword);

    setValidation({
      fn: dataF,
      ln: datal,
      email: emailValidation,
      adress: dataad,
      // cnic: datanic,
      // passport: datapp,
      pn: dataP,
      pw: dataPs,
      cpw: datacPs,

    });

    if (!dataPs) {
      setAlertShow(true)
      setAlertMessage('Password Shouldbe 8 to 20 characters which contain at least one Number, one Uppercase and lowercase letter')

      setTimeout(() => {
        setAlertShow(false)


      }, 3000);
    }


    if (
      emailValidation ||
      dataad ||
      // !datanic ||
      // !datapp ||
      dataF ||
      datal ||
      dataP ||
      dataPs
    ) {

      //   setIsloding(false);
      // } else {

      if (password == confPassword) {


        setIsloding(true);
        const responce = await API().signup(
          firstName,
          lastName,
          email,
          password,
          countrySelected.id,
          pasport,
          phoneNumber,
          cnic,
          address,
          profileImage.image,
          profileImage.name,
          countrySelected.code,
          refrenceCode
        );
        // console.log(responce.data.data.register, ',..>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        // register
        if (responce.data.data.register === "sucessfull") {
          let data = JSON.stringify(responce.data.data);
          const userData = { userEmail: email, userPassord: password };

          ls.set("Cradentials", userData, { encrypt: true });
          const userId = responce.data.data.user_id;
          localStorage.setItem("userData", data);
          setIsloding(false);
          history.replace("/Home");
          // history.replace({
          //   pathname: "/Verification",
          //   state: { detail: userId },
          // });
        } else {
          setIsloding(false);
          setServerError(responce.data.data.register);
        }
      } else {
        setValidation({
          fn: dataF,
          ln: datal,
          email: emailValidation,
          adress: dataad,
          // cnic: datanic,
          // passport: datapp,
          pn: dataP,
          pw: false,
          cpw: false,

        });

        setAlertShow(true)
        setAlertMessage('password and confirm password not match')

        setTimeout(() => {
          setAlertShow(false)


        }, 3000);
        setIsloding(false);


      }
    }
  };

  useEffect(() => {
    getdata();
  }, []);
  const ImageRef = useRef();
  const fileOpen = () => {
    const trigger = ImageRef.current;
    trigger.click();
  };
  const handleChangeImage = (e) => {
    const { value, files } = e.target;
    let ddddd = value.split("\\");
    ddddd = ddddd[ddddd.length - 1];

    const file = files[0];
    let ext = value.split(".");

    ext = ext[ext.length - 1];
    if (
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "bmp" ||
      ext === "png"
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          image: { error: false, text: "" },
        };
      });
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileasDataUrl = e.target.result;

        const base64 = FileasDataUrl.split(",");

        const base64Encoded = base64[base64.length - 1];
        setProfileImage({ name: ddddd, image: base64Encoded });

        setValues({ image: FileasDataUrl, name: ddddd });
        // setValues((prev) => {
        //   return {
        //     ...prev,
        //     image: [
        //       {
        //         name: value,
        //         base64: base64Encoded,
        //       },
        //     ],
        //   };
        // });
      };
      reader.readAsDataURL(file);
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          image: {
            error: true,
            text: "Only jpg, jpeg, bmp and png are allowed",
          },
        };
      });
    }
  };

  if (isloding) {
    return (
      <div
        style={{ width: "100vw", height: "100vh", backgroundColor: Colors.bodyCol,/*  backgroundImage: `url(${backgrounimage})` */ }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div>
      </div>
    );
  } else {
    return (
      <div className="overflow-auto" style={{ height: '100vh', backgroundColor: Colors.bodyCol,/*  backgroundImage: `url(${backgrounimage})` */ }}>
        {alertShow ?
          <CoustumAlert alertMessage={alertMessage} />
          : <></>}

        <div className="col-12 col-lg-4  col-lg-4 mx-auto">
          <div className="  pt-1 pb-1">
            <div className="d-flex  justify-content-center  ">
              <h1 style={{ marginTop: 20, fontSize: '3.2rem', marginLeft: 10, color: Colors.lightBlue }}>
                Folo
              </h1>
              <h1 style={{ marginTop: 40, fontSize: '2rem', color: Colors.darkBlue }}>Swap</h1>
            </div>
            <div className=" d-flex justify-content-center ">
              <h6 style={{ color: 'white' }} className="">
                Register to
              </h6>
              <div className="d-flex  ">
                <h6 style={{ marginLeft: 10, color: 'white' }}>
                  Folo
                </h6>
                <h6 style={{ color: 'white' }}>Swap</h6>
              </div>
            </div>
            <div className="">
              <div className=" d-flex justify-content-center ">
                <input
                  ref={ImageRef}
                  onChange={(e) => handleChangeImage(e)}
                  className="d-none"
                  type="file"
                />
                {values.image === null ? (
                  <>
                    <div
                      onClick={fileOpen}
                      style={{ height: 120, width: 120 }}
                      className="img-thumbnail d-flex justify-content-center shadow-sm rounded-circle"
                    >
                      <RiCameraSwitchLine
                        className="my-auto"
                        size={40}
                        color="gray"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      onClick={fileOpen}
                      src={values.image}
                      style={{ height: 120, width: 120 }}
                      className="img-thumbnail rounded-circle"
                      alt="Profile"
                    />
                  </>
                )}
                {/* <div className=" mt-4 ">
                  <h3 style={{ color: "gray", marginTop: 10, marginLeft: 10 }}>
                    {first_name} {last_name}
                  </h3>
                  <h5 style={{ color: "gray", marginLeft: 10 }}>{email}</h5>
                </div> */}
              </div>

              <div className=" col-11 justify-content-between mt-2 px-1 mb-2 mx-auto d-flex" >
                {!validation.fn ? (
                  <>
                    <input
                      value={firstName}
                      className="form-control shadow-none"
                      style={{
                        width: "45%",
                        paddingLeft: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderWidth: 1.5,
                        borderRadius: 5,
                        borderColor: "red",
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                    />
                  </>
                ) : (
                  <>
                    <input
                      value={firstName}
                      className="form-control shadow-none "
                      style={{
                        width: "45%",
                        paddingLeft: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderWidth: 1,
                        borderRadius: 5,
                        borderColor: "rgb(199, 199, 199)",
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name"
                    />
                  </>
                )}
                {!validation.ln ? (
                  <>
                    <input
                      value={lastName}
                      className="form-control shadow-sm "
                      style={{
                        width: "45%",
                        paddingLeft: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderWidth: 1.5,
                        borderRadius: 5,
                        borderColor: "red",
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                    />
                  </>
                ) : (
                  <>
                    <input
                      value={lastName}
                      className="form-control shadow-sm "
                      style={{
                        width: "45%",
                        paddingLeft: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderWidth: 1,
                        borderRadius: 5,
                        borderColor: "rgb(199, 199, 199)",
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name"
                    />
                  </>
                )}
              </div>
              {!validation.email ? (
                <>
                  <input
                    value={email}
                    className="form-control shadow-sm mx-auto"
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1.5,
                      borderRadius: 5,
                      borderColor: "red",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address"
                  />
                </>
              ) : (
                <>
                  <input
                    value={email}
                    className="form-control shadow-sm mx-auto"
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgb(199, 199, 199)",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email Address"
                  />
                  <div className="text-center " style={{ color: 'white' }}>{serverError}</div>
                </>
              )}

              {!validation.pw ? (

                <div
                  className=" mt-2 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1.5px solid red",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={password}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "85%",
                      paddingLeft: 20,

                    }}
                    type={visiblepass.Type}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <visiblepass.iconName
                    size={25}
                    style={{ marginRight: "5%" }}
                    onClick={() => {
                      visiblepass.Type === "password"
                        ? Setvisiblepass({
                          Type: "text",
                          iconName: BsEye,
                        })
                        : Setvisiblepass({
                          Type: "password",
                          iconName: BsEyeSlash,
                        });
                    }}
                  />
                </div>

              ) : (

                <div
                  className=" mt-2 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1px solid rgb(199, 199, 199)",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={password}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "85%",
                      paddingLeft: 20,
                    }}
                    type={visiblepass.Type}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <visiblepass.iconName
                    size={25}
                    style={{ marginRight: "5%" }}
                    onClick={() => {
                      visiblepass.Type === "password"
                        ? Setvisiblepass({
                          Type: "text",
                          iconName: BsEye,
                        })
                        : Setvisiblepass({
                          Type: "password",
                          iconName: BsEyeSlash,
                        });
                    }}
                  />
                </div>

              )}

              {!validation.cpw ? (

                <div
                  className=" mt-2 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1.5px solid red",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={confPassword}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "85%",
                      paddingLeft: 20,

                    }}
                    type={visiblepass.Type}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                  <visiblepass.iconName
                    size={25}
                    style={{ marginRight: "5%" }}
                    onClick={() => {
                      visiblepass.Type === "password"
                        ? Setvisiblepass({
                          Type: "text",
                          iconName: BsEye,
                        })
                        : Setvisiblepass({
                          Type: "password",
                          iconName: BsEyeSlash,
                        });
                    }}
                  />
                </div>

              ) : (

                <div
                  className=" mt-2 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1px solid rgb(199, 199, 199)",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={confPassword}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "85%",
                      paddingLeft: 20,
                    }}
                    type={visiblepass.Type}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                  <visiblepass.iconName
                    size={25}
                    style={{ marginRight: "5%" }}
                    onClick={() => {
                      visiblepass.Type === "password"
                        ? Setvisiblepass({
                          Type: "text",
                          iconName: BsEye,
                        })
                        : Setvisiblepass({
                          Type: "password",
                          iconName: BsEyeSlash,
                        });
                    }}
                  />
                </div>

              )}

              <select
                style={{
                  width: "90%",
                  height: 45,
                  borderColor: "rgb(199, 199, 199)",
                }}
                className="form-select px-4 text-secondary shadow-sm mt-2 mx-auto"
                aria-label="Default select example"
                onChange={SelectedCountry}
              >
                {contryApi.map((item) => {
                  return (
                    <option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {!validation.pn ? (

                <div
                  className=" mt-2 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1.5px solid red",
                    borderRadius: 5,
                  }}
                >
                  <span
                    className="text-secondary col-2 "
                    style={{
                      // width: "10%",
                      marginLeft: 15,
                    }}
                  >
                    {countrySelected.code}
                  </span>
                  <input
                    value={phoneNumber}
                    className="form-control   border-0 p-0 shadow-none"
                    style={{
                      width: "90%",
                    }}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    placeholder="Phone Number"
                  />
                </div>

              ) : (

                <div
                  className=" mt-2 d-flex  col-11 justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1px solid rgb(199, 199, 199)",
                    borderRadius: 5,
                  }}
                >
                  <span
                    className="text-secondary col-2  p-0"
                    style={{
                      // width: "10%",
                      marginLeft: "5%",
                    }}
                  >
                    {countrySelected.code}
                  </span>
                  <input
                    value={phoneNumber}
                    className="form-control   border-0 p-0 shadow-none"
                    style={{
                      width: "90%",
                    }}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    placeholder="Phone Number"
                  />
                </div>

              )}

              {!validation.adress ? (
                <>
                  <input
                    value={address}
                    className="form-control shadow-sm mt-2 mx-auto"
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1.5,
                      borderRadius: 5,
                      borderColor: "red",
                    }}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                  />
                </>
              ) : (
                <>
                  <input
                    value={address}
                    className="form-control shadow-sm mt-2 mx-auto"
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgb(199, 199, 199)",
                    }}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                  />
                </>
              )}

              <input
                value={refrenceCode}
                className="form-control shadow-sm mt-2 mx-auto"
                style={{
                  width: "90%",
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: "rgb(199, 199, 199)",
                }}
                onChange={(e) => setRefrenceCode(e.target.value)}
                placeholder="Referral Code"
              />

              {/* {!validation.cnic ? (
                <>
                  <input
                    value={cnic}
                    className="form-control  shadow-sm mt-2 mx-auto"
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "red",
                    }}
                    onChange={(e) => setCnic(e.target.value)}
                    placeholder="CNIC / Passport"
                  />
                </>
              ) : (
                <>
                  <input
                    value={cnic}
                    className="form-control  shadow-sm mt-2 mx-auto"
                    style={{
                      width: "90%",
                      paddingLeft: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "rgb(199, 199, 199)",
                    }}
                    onChange={(e) =>
                      setCnic(e.target.value)}
                    placeholder="CNIC / Passport"
                  />
                </>
              )} */}

              {/* <input
                value={pasport}
                className="form-control shadow-sm mt-2 mx-auto"
                style={{
                  width: "90%",
                  paddingLeft: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: "rgb(199, 199, 199)",
                }}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    setPasport(e.target.value);
                  }
                }}
                placeholder="Passport Number"
              /> */}

              <button
                onClick={Rigister}
                style={{
                  width: "90%",
                  fontSize: 25,

                  marginLeft: "5%",
                  backgroundColor: Colors.darkBlue,
                  color: "white"
                }}
                type="button"
                className="btn btn-outline  mt-2"
              >
                Sign Up
              </button>
              <div
                style={{ width: "90%" }}
                className=" text-center text-light mt-1 mx-auto"
              >
                <span style={{ color: 'white' }}>Already have an account?</span>
                <button
                  onClick={SingIn}
                  style={{
                    // width: "30%",
                    marginLeft: 2,
                    marginBottom: 3, color: Colors.lightGreen
                  }}
                  type="button"
                  className="btn  shadow-none p-0 "
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default SingUp;
