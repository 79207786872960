import React, { useState, useEffect, useContext } from 'react'
import NavBar from '../NavBar'
import API from "../../Api";
import Button from "@material-ui/core/Button";
import io from 'socket.io-client';
import Modal from "@material-ui/core/Modal";
import * as Colors from "../../Constant/Colors";
import SellAndBuyMod from './SAndBMod.js'
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import useSound from 'use-sound';
import tune from '../../Images/iphone_notification_so.mp3'
import CoustumAlert from '../../Constant/CoustumAlert'
import firebase from '../Notification/FirebaseConfig';
import { NitiContext, ToseContext } from "../../reducer/Context";
const socket = io('wss://streamer.cryptocompare.com');



const Home = () => {
    const audio = new Audio(tune);
    const { notification, setNotification } = useContext(NitiContext);
    const { toseMassege, setToseMassege } = useContext(ToseContext);

    console.log(toseMassege, '>>>>>>>>>>||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||');

    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id } = user_Data.data;
    // console.log(id, 'id')
    const [listLoding, setListLoding] = useState(false);
    const [isloding, setIsloding] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [BandSModalOpen, setBandSModalOpen] = useState(false);
    const [cryptoCurrencyApi, setCryptoCurrencyApi] = useState([]);

    const [searchResult, setSearchResult] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [sellectedAdData, setSellectedAdData] = useState({});
    const [percentage, setPercentage] = useState('');
    const [dynamicCurrency, setDynamicCurrency] = useState(1);
    const [priceType, setPriceType] = useState('');
    const [showDiv, setShowDiv] = useState(true);
    const [priceloding, setPriceloding] = useState(true);
    const [adsList, setAdsList] = useState([]);
    const [currencyApi, setCurrencyApi] = useState([]);
    const [sellectedCurrency, setSellectedCurrency] = useState("");
    const [sellectedCountryCurrency, setSellectedCountryCurrency] = useState("");

    // console.log("sellectedCountryCurrencysellectedCountryCurrency", sellectedCountryCurrency, sellectedCurrency)
    let buyerSeePrice = dynamicCurrency + (dynamicCurrency / 100) * percentage.percentage;
    let SellerSeePrice = dynamicCurrency - (dynamicCurrency / 100) * percentage.percentage;
    const [searchButton, setSearchButton] = useState(sellectedCountryCurrency);
    const [buyOrSell, setBuyOrSell] = useState('');
    const [reloadMod, setReloadMod] = useState(0);
    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');




    const Show = (props) => {
        setShowDiv(false)
        // console.log(props)
        if (props == 1) {

            setBuyOrSell('buy')
        } else {
            setBuyOrSell('sell')

        }
        setPriceType(props)
    }

    const Show1 = () => {

        setAdsList([])
        setSearchInput('')
        if (buyOrSell == 'buy') {
            setBuyOrSell('sell')
            setPriceType(2)
        } else {
            setBuyOrSell('buy')
            setPriceType(1)

        }

    }
    const BandSModOpen = (data) => {
        setSellectedAdData(data)
        setBandSModalOpen(true)
        setReloadMod(1)

    }
    const BandSModClose = () => {

        setBandSModalOpen(false)

    }
    const openModa = () => {

        setModalOpen(true)

    }
    const closeModa = () => {
        setModalOpen(false)
    }
    const ModaBtn1 = () => {
        setSearchButton(sellectedCurrency)
        closeModa()
    }
    const ModaBtn2 = () => {
        setSearchButton(sellectedCountryCurrency)
        closeModa()
    }


    const SearchAdList = async (e) => {
        // console.log(e.target.value)
        // console.log(sellectedCurrency,
        //     priceType,
        //     searchButton,
        //     sellectedCountryCurrency, '...>>>>>>>>>>>>>>>>>>>>...................!')
        setSearchInput(e.target.value)
        try {
            setListLoding(true)
            const response = await API().postAdList(
                id,
                sellectedCurrency,
                priceType,
                searchButton,
                sellectedCountryCurrency,
                e.target.value

            );
            // console.log(response, 'response>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>!')
            if (response.status == true) {

                setAdsList(response.ads)
                if (response.ads.length == 0) {
                    setSearchResult('result not found')
                }
            } else {
                setAdsList([])

            }

            setListLoding(false)
        } catch (err) {
            console.log(err, 'ErrSearchAdList')
        }
    };



    const GetData = async () => {

        try {
            const response = await API().getcrypto();
            const resCurrency = await API().getcurrency();
            // console.log(response[0].symbol, 'resCurrency')
            setSellectedCountryCurrency(resCurrency[0].symbol)
            setSellectedCurrency(response[0].symbol)
            setSearchButton(response[0].symbol)
            const Percentage = await API().getPercentage();
            setPercentage(Percentage.data)

            setCryptoCurrencyApi(response);
            setCurrencyApi(resCurrency)

            setIsloding(false)
            socket.emit("SubAdd", { "subs": [`5~CCCAGG~${response[0].symbol}~${resCurrency[0].symbol}`] })
        } catch (err) {
            console.log(err, 'cureencyapi')
        }
    };
    const handleChangeCryptoCurrency = (event) => {
        socket.emit("SubRemove", { "subs": [`5~CCCAGG~${sellectedCurrency}~${sellectedCountryCurrency == 'NGN' ? "USD" : sellectedCountryCurrency}`] })
        setPriceloding(true)
        setDynamicCurrency(1)
        setSearchButton(event.target.value)
        setSellectedCurrency(event.target.value)
        socket.emit("SubAdd", { "subs": [`5~CCCAGG~${event.target.value}~${sellectedCountryCurrency == 'NGN' ? "USD" : sellectedCountryCurrency}`] })

    };

    const handleChangeCountryCurrency = (event) => {
        socket.emit("SubRemove", { "subs": [`5~CCCAGG~${sellectedCurrency}~${sellectedCountryCurrency == 'NGN' ? "USD" : sellectedCountryCurrency}`] })
        setPriceloding(true)
        setDynamicCurrency(1)
        setSearchButton(event.target.value)
        setSellectedCountryCurrency(event.target.value);
        socket.emit("SubAdd", { "subs": [`5~CCCAGG~${sellectedCurrency}~${event.target.value == 'NGN' ? "USD" : event.target.value}`] })

    };


    useEffect(() => {

        GetData();
        // console.log(socket, '......................!')

        socket.on('m', function (data) {
            if (data.split('~')[4] == 1 || data.split('~')[4] == 2) {
                // console.log(data, ';;;;;;;;;;;;;;;;;;;')
                let roundValue = Math.round(data.split('~')[5])

                setDynamicCurrency(roundValue)
                setPriceloding(false)
                // console.log("[price chNGED]", data)
                // console.log("[price chNGED]", data.split('~')[4])
            }


        })

        firebase.database().ref(`user_id_${id}/web_notification`).on("value", snapshot => {

            const data = [];
            snapshot.forEach(snap => {

                const dd = snap.val()
                const costumdd = {
                    ad_type: dd.ad_type,
                    date: dd.date,
                    seen: dd.seen,
                    text: dd.text,
                    url: dd.url,
                    user_id: dd.user_id,
                    key: snap.key,
                    ad_user_id: dd.ad_user_id
                }

                data.unshift(costumdd);

                setNotification(data)
                // Key
                // console.log(snap.val(), '.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..................................sssssnap')

            });

        });

        firebase.database().ref(`user_id_${id}/web_signal`).on("value", snapshot => {

            const dad = [];
            snapshot.forEach(snap => {

                const dd = snap.val()
                // const costumdd = dd
                dad.unshift(dd);
                audio.play()
                // console.log(dd, 'dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd');
                setToseMassege(dd)


            });

        });

    }, []);

    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }}>
                <NavBar />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="" style={{ height: '100vh', backgroundColor: Colors.bodyCol,/*  backgroundImage: `url(${backgrounimage})` */ }} >
                <NavBar />
                {alertShow ?
                    <CoustumAlert alertMessage={alertMessage} />
                    : <></>}
                <div className="" style={{ marginTop: '100px' }}   >

                    <div className=' mx-auto mt-5 px-2 px-lg-3 shadow rounded col-sm-11 col-lg-7' style={{ backgroundColor: Colors.darkBlue }}>
                        <div className=" mx-auto px-2 px-lg-3 py-2  d-flex justify-content-between">
                            <h4 className="mt-1 text-light">Choose Crypto Currency:</h4>


                            <select
                                style={{ width: "30%" }}
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => handleChangeCryptoCurrency(e)}
                            >
                                {cryptoCurrencyApi.map((item) => {
                                    return (
                                        <option key={item.id} value={item.symbol}>
                                            {item.symbol}
                                        </option>
                                    );
                                })}
                            </select>

                        </div>
                        <div className=" mx-auto  px-2 px-lg-3 py-2 d-flex justify-content-between">
                            <h4 className="mt-1 text-light">Choose Currency:</h4>


                            <select
                                style={{ width: "30%" }}
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => handleChangeCountryCurrency(e)}
                            >
                                {currencyApi.map((item) => {

                                    return (
                                        <option key={item.id} value={item.symbol}>
                                            {item.symbol}
                                        </option>
                                    );
                                })}
                            </select>

                        </div>
                    </div>


                    {showDiv ? (<>
                        <div className="mx-auto my-5     py-2 col-sm-11 col-lg-7 d-lg-flex justify-content-between" >
                            <div className=" my-2  px-3 py-2 shadow rounded col-lg-5 " style={{ backgroundColor: Colors.darkBlue }}>

                                <h6 className='mt-1 text-light'>Buying Price:</h6>
                                <div className="d-flex mt-1">
                                    {priceloding ? (
                                        <div className="spinner-border text-secondary" role="status"></div>
                                    ) : (

                                        <h5 className="" style={{ color: Colors.lightGreen }}>{sellectedCountryCurrency == "NGN" ? (buyerSeePrice * 410).toFixed(2) : buyerSeePrice.toFixed(2)}</h5>
                                    )}
                                    <h5 style={{ marginLeft: '5px' }} className="text-light">{sellectedCountryCurrency}</h5>

                                </div>
                                <Button
                                    onClick={() => Show(2)}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    className="btn shadow-none mt-1 border-0 btn-primary"
                                    style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                >
                                    Buy Now
                                </Button>



                            </div>




                            <div className=" my-2  px-3 py-2 shadow rounded col-lg-5 " style={{ backgroundColor: Colors.darkBlue }}>

                                <h6 className='mt-1 text-light '>Selling Price:</h6>
                                <div className="d-flex mt-1">
                                    {priceloding ? (
                                        <div className="spinner-border text-secondary" role="status"></div>
                                    ) : (

                                        <h5 className="" style={{ color: Colors.lightGreen }}>{sellectedCountryCurrency == "NGN" ? (SellerSeePrice * 410).toFixed(2) : SellerSeePrice.toFixed(2)}</h5>
                                    )}
                                    <h5 style={{ marginLeft: '5px' }} className="text-light">{sellectedCountryCurrency}</h5>

                                </div>

                                <Button
                                    onClick={() => Show(1)}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    className="btn shadow-none mt-1 border-0 btn-primary"
                                    style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                >
                                    Sell Now
                                </Button>



                            </div>
                        </div>
                    </>) : (
                        <>
                            <div className="mx-auto my-5  text-light  py-2 col-sm-11 col-lg-7 " >
                                Amount:
                                <div className=" my-2  px-4 py-3 shadow rounded  d-flex col-12 " style={{ backgroundColor: Colors.darkBlue }}>
                                    <input
                                        value={searchInput}
                                        className="form-control rounded-0 border-0 shadow-none  "
                                        style={{
                                            width: "75%",

                                            paddingLeft: 20,
                                        }}

                                        placeholder={`Enter ${searchButton} amount to ${buyOrSell == 'buy' ? 'sell' : 'buy'}`}
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                SearchAdList(e)
                                            }
                                        }}

                                    />
                                    <Button
                                        onClick={openModa}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        className="btn shadow-none  rounded-0 border-0 btn-primary"
                                        style={{ backgroundColor: "green", width: "25%", backgroundColor: Colors.lightGreen, color: Colors.darkBlue }}
                                    >
                                        {searchButton}
                                    </Button>

                                </div>

                                {listLoding ? (
                                    <div className="spinner-border text-secondary" role="status"></div>
                                ) : (
                                    <>
                                        {adsList.length >= 1 ? (
                                            adsList.map((item) => {
                                                console.log(item, ',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,')
                                                return (

                                                    <div className=" my-2  px-3 py-2 shadow rounded justify-content-lg-between  d-lg-flex col-12 " style={{ backgroundColor: Colors.darkBlue, alignItems: 'center' }}>
                                                        <div className="col-lg-8">
                                                            <div className=" d-flex">
                                                                <h6 style={{ color: "white", fontSize: '20px' }}>
                                                                    {item.price}

                                                                </h6>
                                                                <h6 style={{ color: "white", fontSize: '20px', marginLeft: '10px' }}>{item.currency_symbol}/{item.symbol}</h6>
                                                            </div>


                                                            {item.amount_to_show === null ?
                                                                <div className=" d-flex">
                                                                    <h6 style={{ color: "white", fontSize: '20px' }}>Total Price:</h6>
                                                                    <h6 style={{ marginLeft: 10, color: "white", fontSize: '20px' }}>  {item.crypto_to_show}
                                                                    </h6>
                                                                    <h6 style={{ color: "white", fontSize: '20px', marginLeft: '3px' }}>{item.symbol}</h6>
                                                                </div>
                                                                : <div className=" d-flex">
                                                                    <h6 style={{ color: "white", fontSize: '20px' }}>Total Price:</h6>
                                                                    <h6 style={{ marginLeft: 10, color: "white", fontSize: '20px' }}>  {item.amount_to_show.toFixed(2)}
                                                                    </h6>
                                                                    <h6 style={{ color: "white", fontSize: '20px', marginLeft: '3px' }}>{item.currency_symbol}</h6>
                                                                </div>
                                                            }


                                                            <h6 style={{ color: "white", fontSize: '20px' }}>{item.bank_name}</h6>
                                                            <div className=" d-flex " style={{ alignItems: 'center' }}>

                                                                <div style={{ height: '15px', width: '15px', borderRadius: 100, backgroundColor: Colors.lightGreen }}></div>
                                                                <h6 style={{ color: "white", fontSize: '20px', marginLeft: '10px' }}>{item.first_name} {item.last_name}</h6>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            onClick={() => BandSModOpen(item)}
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            className="btn shadow-none  rounded-0 border-0 btn-primary"
                                                            style={{ height: '50px', backgroundColor: Colors.lightGreen, color: Colors.darkBlue }}
                                                        >
                                                            {buyOrSell == 'buy' ? 'Sell' : 'Buy'}
                                                        </Button>
                                                    </div>
                                                )
                                            })
                                        ) : (<>
                                            {searchResult}
                                        </>)}
                                    </>
                                )}

                                <h6 className="mt-3">   {buyOrSell == 'buy' ? 'Selling' : 'Buying'} {sellectedCurrency}

                                </h6>
                                <div className='d-flex justify-content-end'>

                                    <Button
                                        onClick={Show1}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        className="btn shadow-none  border-0  btn-primary"
                                        style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                    >
                                        {buyOrSell == 'buy' ? 'Looking to Buy Coins' : 'Looking to Sell Your Coins'}

                                    </Button>
                                </div>
                            </div>
                        </>)}





                </div>

                {/* ###############################
                ####### MODAL Buy and Sell ########
                ############################### */}

                {reloadMod == 0 ? (
                    <></>)
                    : (


                        <SellAndBuyMod

                            setAlertShow={setAlertShow}
                            setAlertMessage={setAlertMessage}
                            data={sellectedAdData}

                            percentage={percentage.percentage}
                            setReloadMod={setReloadMod}
                            user_id={id}
                            buyOrSell={buyOrSell}
                            searchInput={searchInput}
                            searchButton={searchButton}
                            open={BandSModalOpen}
                            close={BandSModClose} />
                    )}
                {/* #################################
                ####### MODAL Currncy Change ########
                ################################## */}

                <Modal open={modalOpen} onClose={closeModa}>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">

                            <div className="modal-body ">
                                <div className=" d-flex ">

                                    {sellectedCurrency == searchButton ? (
                                        <button
                                            onClick={ModaBtn1}
                                            //   key={item.id}
                                            style={{
                                                borderRadius: 20,
                                                width: 80,
                                                marginTop: "5px",
                                                marginLeft: 10, backgroundColor: Colors.darkBlue,
                                            }}
                                            type="button"
                                            className="btn btn-outline-primary active "
                                        >
                                            {sellectedCurrency}
                                            {/* {item.symbol} */}
                                        </button>
                                    ) : (<button
                                        onClick={ModaBtn1}
                                        //   key={item.id}
                                        style={{
                                            borderRadius: 20,
                                            width: 80,
                                            marginTop: "5px",
                                            marginLeft: 10,
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                    >
                                        {sellectedCurrency}
                                    </button>
                                    )}
                                    {sellectedCountryCurrency == searchButton ? (
                                        <button
                                            onClick={ModaBtn2}
                                            //   key={item.id}
                                            style={{
                                                borderRadius: 20,
                                                width: 80,
                                                marginTop: "5px",
                                                marginLeft: 10, backgroundColor: Colors.darkBlue,
                                            }}
                                            type="button"
                                            className="btn btn-outline-primary active "
                                        >
                                            {sellectedCountryCurrency}
                                            {/* {item.symbol} */}
                                        </button>
                                    ) : (<button
                                        onClick={ModaBtn2}
                                        //   key={item.id}
                                        style={{
                                            borderRadius: 20,
                                            width: 80,
                                            marginTop: "5px",
                                            marginLeft: 10,
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                    >
                                        {sellectedCountryCurrency}
                                    </button>)}




                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div >
        )
    }
}

export default Home
