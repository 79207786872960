import React, { useState, useEffect, useContext } from 'react'
import NavBarNoLogin from './NavBarNoLogin'
import API from "../Api";
import Button from "@material-ui/core/Button";
import io from 'socket.io-client';
import Modal from "@material-ui/core/Modal";
import * as Colors from "../Constant/Colors";
import ls from "localstorage-slim";
import { UserContext } from "../reducer/Context";
import { useHistory } from "react-router-dom";
import backgrounimage from '../Images/geometric-1732847.jpeg'


const FirstPage = () => {

    const { new_Data, setNew_Data } = useContext(UserContext);
    const [listLoding, setListLoding] = useState(false);
    const [isloding, setIsloding] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [BandSModalOpen, setBandSModalOpen] = useState(false);
    const [cryptoCurrencyApi, setCryptoCurrencyApi] = useState([]);
    const [searchResult, setSearchResult] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [sellectedAdData, setSellectedAdData] = useState({});
    const [percentage, setPercentage] = useState('');
    const [dynamicCurrency, setDynamicCurrency] = useState(1);
    const [priceType, setPriceType] = useState('');
    const [showDiv, setShowDiv] = useState(true);
    const [priceloding, setPriceloding] = useState(true);
    // console.log("[price chNGED]", dynamicCurrency.split('~')[5])
    const [adsList, setAdsList] = useState([]);
    const [currencyApi, setCurrencyApi] = useState([]);
    const [sellectedCurrency, setSellectedCurrency] = useState("BTC");
    const [sellectedCountryCurrency, setSellectedCountryCurrency] = useState("USD");

    let buyerSeePrice = dynamicCurrency + (dynamicCurrency / 100) * percentage.percentage;
    let SellerSeePrice = dynamicCurrency - (dynamicCurrency / 100) * percentage.percentage;
    const [searchButton, setSearchButton] = useState(sellectedCountryCurrency);
    const [buyOrSell, setBuyOrSell] = useState('');

    const history = useHistory();


    const Show = (props) => {
        setShowDiv(false)
        if (props == 1) {

            setBuyOrSell('buy')
        } else {
            setBuyOrSell('sell')

        }
        setPriceType(props)
    }
    const Show1 = () => {

        setAdsList([])
        setSearchInput('')
        if (buyOrSell == 'buy') {
            setBuyOrSell('sell')
            setPriceType(2)
        } else {
            setBuyOrSell('buy')
            setPriceType(1)

        }

    }
    const BandSModOpen = (data) => {
        setSellectedAdData(data)
        setBandSModalOpen(true)

    }
    const BandSModClose = () => {

        setBandSModalOpen(false)

    }
    const openModa = () => {

        setModalOpen(true)

    }
    const closeModa = () => {
        setModalOpen(false)
    }
    const ModaBtn1 = () => {
        setSearchButton(sellectedCurrency)
        closeModa()
    }
    const ModaBtn2 = () => {
        setSearchButton(sellectedCountryCurrency)
        closeModa()
    }


    const SearchAdList = async (e) => {
        console.log(sellectedCurrency,
            priceType,
            searchButton,
            sellectedCountryCurrency, '  sellectedCurrencypriceTypesearchButtosellectedCountryCurrency')
        setSearchInput(e.target.value)
        try {
            setListLoding(true)
            const response = await API().getAdListBeforeLogin(

                sellectedCurrency,
                priceType,
                searchButton,
                sellectedCountryCurrency,
                e.target.value

            );
            console.log(response.data.ads, 'response')
            if (response.data.status == true) {

                setAdsList(response.data.ads)

                if (response.data.ads.length == 0) {
                    setSearchResult('result not found')
                }
            } else {
                setAdsList([])

            }

            setListLoding(false)
        } catch (err) {
            console.log(err, 'ErrSearchAdList')
        }
    };

    const CheckUserLogin = async () => {
        setListLoding(true);
        const userData = ls.get("Cradentials", { decrypt: true });
        const dataToken = await JSON.parse(localStorage.getItem("userData"))

        if (userData != null) {
            // console.log(dataToken.data.id, 'ChekToken.message>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.')

            console.log('ChekToken.message>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.')
            console.log(dataToken.user_id, 'ChekToken.message>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.')

            const ChekToken = await API().postChekToken(dataToken.user_id)
            console.log(ChekToken, 'ChekToken.message>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.')
            if (ChekToken.message == 'token is correct') {


                const responce = await API().signin(
                    userData.userEmail,
                    userData.userPassord
                );

                if (responce.data.status == true) {
                    let data = JSON.stringify(responce.data);
                    localStorage.setItem("userData", data);
                    console.log(responce.data.data, 'ifStatus')
                    setNew_Data(responce.data);
                    localStorage.setItem('user_verifyed', responce.data.data.user_verifyed)

                    const userId = responce.data.data.id;
                    console.log(userId, 'userId')
                    // if (responce.data.data.user_verifyed === true) {
                    history.replace("/Home");
                    console.log('Home')
                    setListLoding(false);
                    // } else {
                    //     history.push({
                    //         pathname: "/Verification",
                    //         state: { detail: userId },
                    //     });
                    // }
                }

            } else {
                try {
                    // setIsloading(true)
                    ls.remove("Cradentials");
                    setNew_Data({ data: null });
                    localStorage.removeItem("userData");
                    const res = await API().getLogout()
                    history.replace("/");
                    return true;
                } catch (exception) {
                    return false;
                }
            }
        } else {
            setListLoding(false);
            // setValidation('email or password invalid');
        }
    };

    const GetData = async () => {
        try {
            const response = await API().getcryptoNoToken();
            const resCurrency = await API().getcurrencyNoToken();
            const resCryptoCompair = await API().getCryptoCompair(
                response.data[0].symbol,
                resCurrency.data[0].symbol,
            );


            let ObjectValues = Object.values(resCryptoCompair.data)
            let roundValue = Math.round(ObjectValues[0])
            setPriceloding(false)
            setDynamicCurrency(roundValue)

            // console.log(resCryptoCompair.data, 'cccccccccccccccccc')
            const Percentage = await API().getPercentage();

            setPercentage(Percentage.data)

            setCryptoCurrencyApi(response.data);
            setCurrencyApi(resCurrency.data)

            CheckUserLogin();
            setIsloding(false)
        } catch (err) {
            console.log(err, 'cureencyapi')
        }
    };
    const handleChangeCryptoCurrency = async (event) => {
        setPriceloding(true)
        try {

            const resCryptoCompair = await API().getCryptoCompair(

                event.target.value,
                sellectedCountryCurrency
            );


            let ObjectValues = Object.values(resCryptoCompair.data)

            let roundValue = Math.round(ObjectValues[0])
            setPriceloding(false)
            setDynamicCurrency(roundValue)
            setPriceloding(false)
        } catch (err) {
            console.log(err, 'errcryptocompair')
        }



        setSearchButton(event.target.value)
        setSellectedCurrency(event.target.value)

    };

    const handleChangeCountryCurrency = async (event) => {
        setPriceloding(true)
        try {

            const resCryptoCompair = await API().getCryptoCompair(
                sellectedCurrency,
                event.target.value
            );


            let ObjectValues = Object.values(resCryptoCompair.data)

            console.log(ObjectValues, 'ObjectValues')
            let roundValue = Math.round(ObjectValues[0])
            setPriceloding(false)
            setDynamicCurrency(roundValue)
            setPriceloding(false)
        } catch (err) {
            console.log(err, 'errcryptocompair')
        }


        setSearchButton(event.target.value)
        setSellectedCountryCurrency(event.target.value);


    };


    useEffect(() => {


        GetData();



    }, []);

    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol,/*  backgroundImage: `url(${backgrounimage})` */ }}>
                <NavBarNoLogin />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="" style={{ height: '100vh', backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }} >


                <NavBarNoLogin />
                <div className="" style={{ marginTop: '100px' }}   >

                    <div className=' mx-auto mt-5 px-2 px-lg-3 shadow rounded col-sm-11 col-lg-7' style={{ backgroundColor: Colors.darkBlue }}>
                        <div className=" mx-auto px-2 px-lg-3 py-2  d-flex justify-content-between">
                            <h4 className="mt-1 text-light">Choose Crypto Currency:</h4>


                            <select
                                style={{ width: "30%" }}
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => handleChangeCryptoCurrency(e)}
                            >
                                {cryptoCurrencyApi.map((item) => {
                                    return (
                                        <option key={item.id} value={item.symbol}>
                                            {item.symbol}
                                        </option>
                                    );
                                })}
                            </select>

                        </div>
                        <div className=" mx-auto  px-2 px-lg-3 py-2 d-flex justify-content-between">
                            <h4 className="mt-1 text-light">Choose Currency:</h4>


                            <select
                                style={{ width: "30%" }}
                                className="form-select"
                                aria-label="Default select example"
                                onChange={(e) => handleChangeCountryCurrency(e)}
                            >
                                {currencyApi.map((item) => {
                                    // console.log(item, 'item')
                                    return (
                                        <option key={item.id} value={item.symbol}>
                                            {item.symbol}
                                        </option>
                                    );
                                })}
                            </select>

                        </div>
                    </div>


                    {showDiv ? (<>
                        <div className="mx-auto my-5     py-2 col-sm-11 col-lg-7 d-lg-flex justify-content-between" >
                            <div className=" my-2  px-3 py-2 shadow rounded col-lg-5 " style={{ backgroundColor: Colors.darkBlue }}>

                                <h6 className='mt-1 text-light'>Buying Price:</h6>
                                <div className="d-flex mt-1">
                                    {priceloding ? (
                                        <div className="spinner-border text-secondary" role="status"></div>
                                    ) : (

                                        <h5 className="" style={{ color: Colors.lightGreen }}>{buyerSeePrice.toFixed(2)}</h5>
                                    )}
                                    <h5 style={{ marginLeft: '5px' }} className="text-light">{sellectedCountryCurrency}</h5>

                                </div>
                                <Button
                                    onClick={() => Show(2)}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    className="btn shadow-none mt-1 border-0 btn-primary"
                                    style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                >
                                    Buy Now
                                </Button>



                            </div>




                            <div className=" my-2  px-3 py-2 shadow rounded col-lg-5 " style={{ backgroundColor: Colors.darkBlue }}>

                                <h6 className='mt-1 text-light '>Selling Price:</h6>
                                <div className="d-flex mt-1">
                                    {priceloding ? (
                                        <div className="spinner-border text-secondary" role="status"></div>
                                    ) : (

                                        <h5 className="" style={{ color: Colors.lightGreen }}>{SellerSeePrice.toFixed(2)}</h5>
                                    )}
                                    <h5 style={{ marginLeft: '5px' }} className="text-light">{sellectedCountryCurrency}</h5>

                                </div>

                                <Button
                                    onClick={() => Show(1)}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    className="btn shadow-none mt-1 border-0 btn-primary"
                                    style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                >
                                    Sell Now
                                </Button>



                            </div>
                        </div>
                    </>) : (
                        <>
                            <div className="mx-auto my-5     py-2 col-sm-11 col-lg-7 " >


                                <p style={{ color: 'white' }}> Amount:</p>
                                <div className=" my-2  px-4 py-3 shadow rounded  d-flex col-12 " style={{ backgroundColor: Colors.darkBlue }}>
                                    <input
                                        value={searchInput}
                                        className="form-control rounded-0 border-0 shadow-none  "
                                        style={{
                                            width: "75%",

                                            paddingLeft: 20,
                                        }}

                                        placeholder={`Enter ${searchButton} amount to ${buyOrSell == 'buy' ? 'sell' : 'buy'}`}
                                        onChange={(e) => {
                                            if (!isNaN(e.target.value)) {
                                                SearchAdList(e)
                                            }
                                        }}

                                    />
                                    <Button
                                        onClick={openModa}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        className="btn shadow-none  rounded-0 border-0 btn-primary"
                                        style={{ backgroundColor: "green", width: "25%", backgroundColor: Colors.lightGreen, color: Colors.darkBlue }}
                                    >
                                        {searchButton}
                                    </Button>

                                </div>

                                {listLoding ? (
                                    <div className="spinner-border text-secondary" role="status"></div>
                                ) : (
                                    <>
                                        {adsList.length >= 1 ? (
                                            adsList.map((item) => {
                                                return (

                                                    <div className=" my-2  px-3 py-2 shadow rounded justify-content-lg-between  d-lg-flex col-12 " style={{ backgroundColor: Colors.darkBlue, alignItems: 'center' }}>
                                                        <div className="col-lg-8">
                                                            <div className=" d-flex">
                                                                <h6 style={{ color: "white", fontSize: '20px' }}>{item.price}</h6>
                                                                <h6 style={{ color: "white", fontSize: '20px', marginLeft: '10px' }}>{item.currency_symbol}/{item.symbol}</h6>
                                                            </div>

                                                            {item.amount_to_show === null ?
                                                                <div className=" d-flex">
                                                                    <h6 style={{ color: "white", fontSize: '20px' }}>Total Price:</h6>
                                                                    <h6 style={{ marginLeft: 10, color: "white", fontSize: '20px' }}>  {item.crypto_to_show}
                                                                    </h6>
                                                                    <h6 style={{ color: "white", fontSize: '20px', marginLeft: '3px' }}>{item.symbol}</h6>
                                                                </div>
                                                                : <div className=" d-flex">
                                                                    <h6 style={{ color: "white", fontSize: '20px' }}>Total Price:</h6>
                                                                    <h6 style={{ marginLeft: 10, color: "white", fontSize: '20px' }}>  {item.amount_to_show.toFixed(2)}
                                                                    </h6>
                                                                    <h6 style={{ color: "white", fontSize: '20px', marginLeft: '3px' }}>{item.currency_symbol}</h6>
                                                                </div>
                                                            }
                                                            {/* <div className=" d-flex">

                                                                <h6 style={{ color: "white", fontSize: '20px' }}>Crypto:</h6>
                                                                <h6 style={{ color: "white", fontSize: '20px', marginLeft: '10px' }}>{buyOrSell == 'buy' ?
                                                                    (item.amount_of_crypto
                                                                        + item.amount_of_crypto / 100 * percentage.percentage).toFixed(7)
                                                                    : (item.amount_of_crypto
                                                                        - item.amount_of_crypto / 100 * percentage.percentage).toFixed(7)} {item.symbol}</h6>
                                                            </div>

                                                            <div className=" d-flex">
                                                                <h6 style={{ color: "white", fontSize: '20px' }}>Total Price:</h6>
                                                                <h6 style={{ marginLeft: 10, color: "white", fontSize: '20px' }}>
                                                                    {item.search_price}

                                                                </h6>
                                                                <h6 style={{ color: "white", fontSize: '20px', marginLeft: '3px' }}>{item.currency_symbol}</h6>
                                                            </div> */}

                                                            <h6 style={{ color: "white", fontSize: '20px' }}>{item.bank_name}</h6>
                                                            <div className=" d-flex " style={{ alignItems: 'center' }}>

                                                                <div style={{ height: '15px', width: '15px', borderRadius: 100, backgroundColor: Colors.lightGreen }}></div>
                                                                <h6 style={{ color: "white", fontSize: '20px', marginLeft: '10px' }}>{item.first_name} {item.last_name}</h6>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            onClick={() => history.push("/signin")}
                                                            variant="contained"
                                                            color="primary"
                                                            type="button"
                                                            className="btn shadow-none  rounded-0 border-0 btn-primary"
                                                            style={{ height: '50px', backgroundColor: Colors.lightGreen, color: Colors.darkBlue }}
                                                        >
                                                            {buyOrSell == 'buy' ? 'Sell' : 'Buy'}
                                                        </Button>
                                                    </div>
                                                )
                                            })
                                        ) : (<p className='text-light'>{searchResult}</p>)}
                                    </>
                                )}

                                <h6 className="mt-3" style={{ color: 'white' }}>   {buyOrSell == 'buy' ? 'Selling' : 'Buying'} {sellectedCurrency}
                                </h6>
                                <div className='d-flex justify-content-end'>

                                    <Button
                                        onClick={Show1}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        className="btn shadow-none  border-0  btn-primary"
                                        style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                                    >
                                        {buyOrSell == 'buy' ? 'Looking to Buy Coins' : 'Looking to  Sell Your Coins'}

                                    </Button>
                                </div>
                            </div>
                        </>)}





                </div>

                {/* ###############################
                ####### MODAL Buy and Sell ########
                ############################### */}
                {/* <SellAndBuyMod data={sellectedAdData}
                    user_id={id}
                    buyOrSell={buyOrSell}
                    open={BandSModalOpen}
                    close={BandSModClose} /> */}
                {/* #################################
                ####### MODAL Currncy Change ########
                ################################## */}

                <Modal open={modalOpen} onClose={closeModa}>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">

                            <div className="modal-body ">
                                <div className=" d-flex ">

                                    {sellectedCurrency == searchButton ? (
                                        <button
                                            onClick={ModaBtn1}
                                            //   key={item.id}
                                            style={{
                                                borderRadius: 20,
                                                width: 80,
                                                marginTop: "5px",
                                                marginLeft: 10, backgroundColor: Colors.darkBlue,
                                            }}
                                            type="button"
                                            className="btn btn-outline-primary active "
                                        >
                                            {sellectedCurrency}
                                            {/* {item.symbol} */}
                                        </button>
                                    ) : (<button
                                        onClick={ModaBtn1}
                                        //   key={item.id}
                                        style={{
                                            borderRadius: 20,
                                            width: 80,
                                            marginTop: "5px",
                                            marginLeft: 10,
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                    >
                                        {sellectedCurrency}
                                    </button>
                                    )}
                                    {sellectedCountryCurrency == searchButton ? (
                                        <button
                                            onClick={ModaBtn2}
                                            //   key={item.id}
                                            style={{
                                                borderRadius: 20,
                                                width: 80,
                                                marginTop: "5px",
                                                marginLeft: 10, backgroundColor: Colors.darkBlue,
                                            }}
                                            type="button"
                                            className="btn btn-outline-primary active "
                                        >
                                            {sellectedCountryCurrency}
                                            {/* {item.symbol} */}
                                        </button>
                                    ) : (<button
                                        onClick={ModaBtn2}
                                        //   key={item.id}
                                        style={{
                                            borderRadius: 20,
                                            width: 80,
                                            marginTop: "5px",
                                            marginLeft: 10,
                                        }}
                                        type="button"
                                        className="btn btn-outline-primary"
                                    >
                                        {sellectedCountryCurrency}
                                    </button>)}




                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div >
        )
    }
}

export default FirstPage
