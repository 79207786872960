import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";

import { BsArrowLeft } from "react-icons/bs";


// import { AiOutlineCheck } from "react-icons/ai";


function SearchListModal(props) {


    const history = useHistory();

    const [loader, setLoader] = useState(false);

    const { id, setSearchAdsMod, setAlertShow, setAlertMessage } = props;




    const [cryptoCurrencyApi, setCryptoCurrencyApi] = useState([]);
    const [sellectedCurrency, setSellectedCurrency] = useState(
        JSON.stringify({
            id: 1,
            name: "Bitcoin",
            symbol: "",
        })
    );
    const selectedCurrency = JSON.parse(sellectedCurrency);

    // console.log(selectedCurrency, 'selectedCurrency')
    const [secondCryptoCurrencyApi, setSecondCryptoCurrencyApi] = useState([]);
    const [sellectedSecondCryptoCurrencyApi, setSellectedSecondCryptoCurrencyApi] = useState(JSON.stringify({
        id: 1,
        name: "Bitcoin",
        symbol: "",
    }));



    const selectedSecondCryptoCurrency = JSON.parse(sellectedSecondCryptoCurrencyApi);

    const [searchInput, setSearchInput] = useState('');






    const CreateSellingAdsModClose = () => {
        setSearchAdsMod(false)
    }



    const GetData = async () => {

        try {
            setLoader(true)

            const response = await API().getMyCryptoList(id);
            setCryptoCurrencyApi(response.crypto_list);
            setSellectedCurrency(JSON.stringify(response.crypto_list[0]))

            let array = []
            response.crypto_list.map((item, i) => {

                if (response.crypto_list[0].crpto_id != item.crpto_id) {
                    array.push(item)
                    if (i == response.crypto_list.length - 1) {

                        setSecondCryptoCurrencyApi(array)
                        setLoader(false)
                    } else {

                        setSellectedSecondCryptoCurrencyApi(JSON.stringify(item))

                    }

                }
            })

        } catch (err) { console.log(err, 'errSwap') }
    };

    useEffect(() => {
        GetData();
    }, []);


    const handleChange = async (event) => {
        setSellectedCurrency(event.target.value);
        const dd = JSON.parse(event.target.value)
        setLoader(true)

        let array = []
        cryptoCurrencyApi.map((item, i) => {
            if (dd.crpto_id != item.crpto_id) {
                array.push(item)
                if (i == cryptoCurrencyApi.length - 1) {

                    setSecondCryptoCurrencyApi(array)
                    setLoader(false)
                } else {

                    setSellectedSecondCryptoCurrencyApi(JSON.stringify(item))

                }

            }
        })


    };

    const handleChangeSecondCurrency = async (event) => {


        const dd = JSON.parse(event.target.value)
        setSellectedSecondCryptoCurrencyApi(event.target.value);

        console.log(dd.Address, 'ddddddddddddddddddddddddddddddddddddd')

    };




    return (
        <div style={{ backgroundColor: Colors.bodyCol, height: '100vh' }}>
            <div className="col-7 mx-auto">
                <div className=" pt-5 " >
                    <BsArrowLeft color="white"
                        onClick={CreateSellingAdsModClose}
                        size={30}
                        style={{ marginRight: 15 }}
                    />
                </div>
                <div className="mt-3  d-flex justify-content-between">
                    <select
                        style={{ height: 40, width: '35%' }}
                        className="form-select shadow-none "
                        aria-label="Default select example"
                        onChange={(e) => handleChange(e)}
                    >
                        {cryptoCurrencyApi.map((item) => {

                            return (
                                <>
                                    {item.currreny_name == selectedCurrency.currreny_name ?
                                        <option selected>
                                            {selectedCurrency.currreny_name}
                                        </option>
                                        :
                                        <option key={item.crpto_id} value={JSON.stringify(item)}>
                                            {item.currreny_name}
                                        </option>}
                                </>
                            );
                        })}
                    </select>

                    {loader ?
                        <div style={{ width: 294, height: 41 }} className="border rounded  bg-light d-flex justify-content-center ">
                            <div style={{ width: 30, height: 30, marginRight: 50, marginTop: 5 }} className="spinner-border  text-secondary" role="status"></div>
                        </div>
                        :
                        <select
                            style={{ height: 41, width: '35%' }}
                            className="form-select shadow-none "
                            aria-label="Default select example"
                            onChange={(e) => handleChangeSecondCurrency(e)}
                        >
                            {secondCryptoCurrencyApi.map((item) => {
                                return (
                                    <>
                                        {item.currreny_name == selectedSecondCryptoCurrency.currreny_name ?
                                            <option selected>
                                                {selectedSecondCryptoCurrency.currreny_name}
                                            </option>
                                            :
                                            <option key={item.crpto_id} value={JSON.stringify(item)}>
                                                {item.currreny_name}
                                            </option>}
                                    </>
                                );
                            })}
                        </select>
                    }


                </div>

                <div className="mt-3">

                    <div
                        className="border d-flex justify-content-center rounded shadow-sm align-items-center"
                        style={{
                            backgroundColor: "white",
                            padding: 0,

                            height: 42, width: '100%',
                        }}
                    >
                        <input
                            value={searchInput}
                            className="form-control border-0 shadow-none  "
                            style={{
                                width: "90%",

                            }}

                            onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                    setSearchInput(e.target.value);
                                }
                            }}

                            placeholder="0.0"
                        />


                    </div>

                </div>




            </div>

        </div>
    );
}

export default SearchListModal;