import React, { useContext } from "react";
import SingUp from "./SingUp";
import { UserContext } from "../reducer/Context";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import Wallet from "./Wallet";
import NavBar from "./NavBar";
import Verification from "./Verification";
import IndexTransfer from "./Transfer/IndexTransfer";
import IndexBS from "./Buy&sellscreens/IndexB&S";
import Notification from "./Notification";
import IndexSettings from "./SettingScreens/IndexSettings";
import Home from "./HomeScreen/Home";
import FirstPage from "./FirstPage";
import MyByinOffers from "./Offers/MyByinOffers";
import MySellingOffers from "./Offers/MySellingOffers";
import BuyinDetails from "./Offers/BuyingDetails";
import SellingDetails from "./Offers/SellingDetails";
import MyBuyingAds from "./Myads/MyBuyingAds";
import MySellingAds from "./Myads/MySellingAds";
import MyBuyindAdsOffers from "./Myads/MyBuyindAdsOffers";
import MySellindAdsOffers from "./Myads/MySellingAdsOffers";
import IndexInvits from "./Invites/IndexInvits";
import Index from "./PtoPTransaction/Index";
import IndexSwap from "./Swap/IndexSwap";
import MySwapAds from "./Myads/MySwapAds";
import MySwapOffers from "./Offers/MySwapOffers";
import MySwapAdsOffers from "./Myads/MySwapAdsOffers";
import MySwapOffersDetails from "./Offers/MySwapOffersDetails";

function Navigation() {
  const { new_Data, setNew_Data } = useContext(UserContext);
  const { data } = new_Data;
  console.log(data, "navi");

  return (
    <>
      {data == null ? (
        <Router>
          <Switch>

            <Route path="/signup">
              <SingUp />
            </Route>
            <Route path="/signin">
              <Login />
            </Route>
            <Route path="/">
              <FirstPage />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>

            <Route path="/Home">
              <Home />
            </Route>
            <Route path="/Wallet">
              <Wallet />
            </Route>
            <Route path="/Transfer">
              <IndexTransfer />
            </Route>
            <Route path="/CreateAds">
              <IndexBS />
            </Route>
            <Route path="/Notification">
              <Notification />
            </Route>
            <Route path="/Settings">
              <IndexSettings />
            </Route>
            <Route path="/mybuyingads">
              <MyBuyingAds />
            </Route>
            <Route path="/mybuyingadsoffers">
              <MyBuyindAdsOffers />
            </Route>
            <Route path="/mysellingads">
              <MySellingAds />
            </Route>
            <Route path="/mysellingadsoffers">
              <MySellindAdsOffers />
            </Route>
            <Route path="/MyByinOffers">
              <MyByinOffers />
            </Route>
            <Route path="/ptoptransactions">
              <Index />
            </Route>
            <Route path="/MySellingOffers">
              <MySellingOffers />
            </Route>
            <Route path="/buyingoffersdetails">
              <BuyinDetails />
            </Route>
            <Route path="/sellingoffersdetails">
              <SellingDetails />
            </Route>
            <Route path="/Verification">
              <Verification />
            </Route>
            <Route path="/invits">
              <IndexInvits />
            </Route>
            <Route path="/myswapoffers">
              <MySwapOffers />
            </Route>
            <Route path="/myswapadsoffer">
              <MySwapAdsOffers />
            </Route>
            <Route path="/myswapoffersdetails">
              <MySwapOffersDetails />
            </Route>
            <Route path="/myswapads">
              <MySwapAds />
            </Route>
            <Route path="/swap">
              <IndexSwap />
            </Route>
            <Route path="/signup">
              <SingUp />
            </Route>
            <Route path="/signin">
              <Login />
            </Route>
            <Route path="/">
              <FirstPage />
            </Route>
          </Switch>
        </Router>
      )}
    </>
  );
}

export default Navigation;
