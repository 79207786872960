import React, { useState, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { IoIosNotificationsOutline } from "react-icons/io";
import { NitiContext } from "../../reducer/Context";
import { useHistory } from "react-router-dom";

import firebase from './FirebaseConfig';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: 300, maxHeight: 300,
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'end',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'end',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: "white" /* theme.palette.primary.main */,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: "black" /* theme.palette.common.white */,
            },

        },
    },
}))(MenuItem);

export default function CustomizedMenus() {
    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, country, symbol } = user_Data.data;
    const [anchorEl, setAnchorEl] = useState(null);

    const { notification, setNotification } = useContext(NitiContext);
    //   const { data } = new_Data;
    // console.log(notification, "notification...........................!");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();

    const GoOffers = (item) => {
        // history.push("/details")

        // console.log(item, '...............>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')


        firebase.database().ref(`user_id_${id}/web_notification/${item.key}`).set({

            ad_type: item.ad_type,
            date: item.date,
            key: item.key,
            seen: 1,
            text: item.text,
            url: item.url,
            ad_user_id: item.ad_user_id,
            user_id: item.user_id,
        }).catch(alert);

        if (item.ad_type == 2) {

            if (id == item.ad_user_id) {


                if (item.text == "Your Transaction has been Approved") {
                    history.push({
                        pathname: "/Wallet",
                        state: { detail: item },
                    })
                } else {

                    history.push({
                        pathname: "/mysellingads",
                        state: { detail: item },
                    })
                }

            } else {
                if (item.text == "Your Transaction has been Approved") {
                    history.push({
                        pathname: "/Wallet",
                        state: { detail: item },
                    })
                } else {

                    history.push({
                        pathname: "/MyByinOffers",
                        state: { detail: item },
                    })
                }
            }
        }
        else {
            if (id == item.ad_user_id) {

                if (item.text == "Your Transaction has been Approved") {
                    history.push({
                        pathname: "/Wallet",
                        state: { detail: item },
                    })
                } else {
                    history.push({
                        pathname: "/mybuyingads",
                        state: { detail: item },
                    })
                }
            } else {
                // alert('Please go to your add')

                if (item.text == "Your Transaction has been Approved") {
                    history.push({
                        pathname: "/Wallet",
                        state: { detail: item },
                    })
                } else {

                    history.push({
                        pathname: "/MySellingOffers",
                        state: { detail: item },
                    })
                }

            }
        }
    }

    return (
        <div style={{}}>
            <div className='btn'
                style={{ marginTop: 2, fontSize: "1rem", color: "white" }}
                // aria-controls="customized-menu"
                // aria-haspopup="true"
                // variant="contained"
                // color="primary"
                onClick={handleClick}
            >
                <IoIosNotificationsOutline size={25} />
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}

                onClose={handleClose}
            >


                {notification.map((item) => {
                    // console.log(item, 'itemNoti.......................!')

                    return (
                        item.seen == 0 ?
                            <div className='  border-bottom border-dark justify-content-between d-flex p-2' style={{ backgroundColor: 'rgb(226, 226, 226)' }} onClick={() => GoOffers(item)}>

                                <ListItemText className='col-10' >
                                    {item.text}
                                </ListItemText>

                                <div style={{ height: 10, width: 10, backgroundColor: 'red', borderRadius: 100 }} >

                                </div>
                            </div>
                            :
                            <div className='border-bottom p-2' onClick={() => GoOffers(item)}>

                                <div className='col-10  ' >
                                    {item.text}
                                </div>

                            </div>
                    )
                })}

            </StyledMenu>
        </div>
    );
}
