import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import { AiOutlineClose } from "react-icons/ai";
import MyBuyingBidsDetails from "./MyBuyingBidsDetails";
import * as Colors from '../../Constant/Colors'

function MyBuyingBids(props) {
  const { open, close, set } = props;
  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id, symbol } = user_Data.data;
  const [myBuyingBids, setMyBuyingBids] = useState([]);
  const [myBidsDetails, setMyBidsDetails] = useState({});
  const [percentage, setPercentage] = useState('');
  const [openShowMyBuyingBidsDetails, setOpenShowMyBuyingBidsDetails] =
    useState(false);

  const ShowMyBuyingBidsDetails = (props) => {
    close();
    setMyBidsDetails(props);
    setOpenShowMyBuyingBidsDetails(true);
    // setOpenMyBuyingBids(false);
  };

  const ShowMyBuyingBidsDetailsClose = () => {
    setOpenShowMyBuyingBidsDetails(false);
    set(true);
  };

  const GetApi = async () => {
    try {
      const respons = await API().getMyBids(id);
      const { buying_ads } = respons;

      setMyBuyingBids(buying_ads);
      const Percentage = await API().getPercentage();
      setPercentage(Percentage.data.percentage)
      //   setIsLoading(false);
    } catch (err) {
      console.log(err, "myGetBids");
    }
  };
  useEffect(() => {
    GetApi();
  }, []);
  return (
    <div>
      <Modal open={open} onClose={close}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div className="modal-header">
              <h2>My Buying Offers</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                {myBuyingBids.length > 0 ? (
                  <>
                    {myBuyingBids.map((item) => {
                      return (
                        <div
                          key={item.bid_id}
                          className=" row col-10 mt-2 rounded shadow-sm"
                        >
                          <h5 className="mt-2">
                            <span className="text-success  mr-4">
                              {parseInt(item.price) + (item.price / 100) * percentage}{" "}
                            </span>
                            {`${symbol} / ${item.crpto_symbol}`}
                          </h5>

                          <h5 className="">
                            Maximum:{" "}
                            <span>
                              {item.maximum_crypto_amount} {item.crpto_symbol}
                            </span>
                          </h5>

                          <h5>{item.bank_name}</h5>
                          <h5>{item.name} {item.last_name}</h5>
                          <button
                            style={{ fontSize: 20, borderRadius: 20, color: Colors.darkBlue }}
                            onClick={() => ShowMyBuyingBidsDetails(item)}
                            type="button"
                            className="btn mb-2 btn-outline-primary"
                          >
                            Show Details
                          </button>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      style={{ height: 150 }}
                      className=" text-center col-10 rounded shadow-sm"
                    >
                      <h2 style={{ color: "gray", marginTop: "15%" }}>
                        No Bids Yet
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/*################################# 
        ## Show My Buyings Bids Details ## 
        #################################*/}
      <MyBuyingBidsDetails
        open={openShowMyBuyingBidsDetails}
        close={ShowMyBuyingBidsDetailsClose}
        BidsDetail={myBidsDetails}
      />
    </div>
  );
}

export default MyBuyingBids;
