import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { GoPlus } from "react-icons/go";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import * as Colors from "../../Constant/Colors";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CreateBuyingAds from "./CreateBuyingAds";
import BuyingAds from "./BuyingAds";
import CoustumAlert from '../../Constant/CoustumAlert'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",

    // alignItems: "center",
    // justifyContent: "center",
  },
  paper: {
    width: "100%",
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function IndexBuyingAds(props) {
  const { ApiBuyingAds, User_symbol, country, getdata, User_Id, percentage } = props;

  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [createBuyingAdsMod, setCreateBuyingAdsMod] = useState(false);
  const [buyingAdsMod, setBuyingAdsMod] = useState(false);
  const [buyingData, setBuyingData] = useState({});
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CreateBuyingAdsModOpen = () => {
    setCreateBuyingAdsMod(true);
  };

  const BuyingAdsModOpen = (data) => {
    setBuyingData(data);
    setBuyingAdsMod(true);
    setData(1)
  };
  const BuyingAdsModClose = () => {
    setBuyingAdsMod(false);
    setData(0)
  };


  return (
    <div className={classes.root}>
      {alertShow ?
        <CoustumAlert alertMessage={alertMessage} />
        : <></>}
      <Paper className={classes.paper}>
        <Button
          onClick={CreateBuyingAdsModOpen}
          variant="contained"
          color="primary"
          className="btn shadow-none col-lg-3 col-12  border-0 btn-primary"
          style={{ backgroundColor: Colors.darkBlue, fontSize: 16 }}
        >
          <GoPlus size={20} style={{ marginRight: 10 }} />  Create Buying Ads
        </Button>
        <div className=" text-center">
          <h4>Want to sell ? Here are some buying ads </h4>
        </div>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <TableRow hover>
              {/* <TableCell align="center">#</TableCell> */}

              <TableCell align="center">name</TableCell>
              <TableCell align="center">Rate</TableCell>
              <TableCell align="center">Price</TableCell>

              <TableCell align="center">Crypto {/* Maximum Amount */}</TableCell>
              <TableCell align="center">Bank Name</TableCell>
              <TableCell align="center">Sell Now</TableCell>
            </TableRow>
            <TableBody>
              {stableSort(ApiBuyingAds, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log(row, 'llllllllllkkkkkkkkkkkkkkjjjjjjjjjjjjjjjj')


                  return (


                    <TableRow hover key={row.ad_id}>
                      {/* <TableCell align="center">{(index + 1) + rowsPerPage}</TableCell> */}

                      <TableCell
                        // component="th"
                        // id={labelId}
                        // scope="row"
                        // padding="none"
                        align="center"
                      >
                        {row.first_name}
                      </TableCell>

                      <TableCell align="center">
                        {Math.round(row.with_commission_price / row.with_commission)}  {User_symbol} / {row.symbol}
                        {/* {Math.round(row.price)} */}
                        {/* {parseInt(row.price) - (row.price / 100) * percentage} {User_symbol}/{row.symbol} */}
                      </TableCell>
                      <TableCell align="center">
                        {row.with_commission_price} {User_symbol}
                        {/* {Math.round(row.price)} */}
                        {/* {parseInt(row.price) - (row.price / 100) * percentage} {User_symbol}/{row.symbol} */}
                      </TableCell>
                      <TableCell align="center">
                        {(row.with_commission).toFixed(7)} {row.symbol}
                        {/* {(row.maximum_crypto_amount + ((row.maximum_crypto_amount / 100) * percentage)).toFixed(7)} {row.symbol} */}
                      </TableCell>
                      <TableCell align="center">{row.bank_name}</TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => BuyingAdsModOpen(row)}
                          variant="contained"
                          color="primary"
                          type="button"
                          className="btn shadow-none border-0 btn-primary"
                          style={{ backgroundColor: Colors.lightBlue, color: "white" }}
                        >
                          Sell Now
                        </Button>
                      </TableCell>
                    </TableRow>

                  );

                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ApiBuyingAds.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/*############################### 
        ###  Buyings Ads Modal ### 
        ###############################*/}

      {data == 1 ?
        <BuyingAds
          setAlertShow={setAlertShow}
          setAlertMessage={setAlertMessage}
          data={buyingData}
          open={buyingAdsMod}
          close={BuyingAdsModClose}
          User_symbol={User_symbol}
          User_Id={User_Id}
          percentage={percentage}
        />
        : <></>}

      {/*############################### 
        ### Create Buyings Ads Modal ### 
        ###############################*/}
      <CreateBuyingAds

        setAlertShow={setAlertShow}
        setAlertMessage={setAlertMessage}
        getdata={getdata}
        User_Id={User_Id}
        User_symbol={User_symbol}
        country={country}
        open={createBuyingAdsMod}
        stateClose={setCreateBuyingAdsMod}
      // close={CreateBuyingAdsModClose}
      />
    </div>
  );
}
