import React, { useEffect, useState } from 'react'
import API from '../../Api';

import * as Colors from "../../Constant/Colors";

import NavBar from "../NavBar";
import AddList from './AddList';

import CoustumAlert from '../../Constant/CoustumAlert'

function IndexSwap() {


    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, email } = user_Data.data


    const [loader, setLoader] = useState(true);

    const [swapAdList, setSwapAdList] = useState([])


    const [cryptoCurrencyApi, setCryptoCurrencyApi] = useState([]);


    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');


    const GetData = async () => {
        try {
            const res = await API().getSwapAd(
                id
            )
            setSwapAdList(res.SwapAds)


            const response = await API().getMyCryptoList(id);
            setCryptoCurrencyApi(response.crypto_list);
            // console.log(response.crypto_list, '====================================');
            setLoader(false)
        } catch (error) {
            console.log(error, 'errSwapList')
        }
    }

    useEffect(() => {
        GetData()
    }, [])


    if (loader) {
        return (
            <div>
                <NavBar />
                <div
                    style={{ width: "100vw", height: "100vh" }}
                    className="d-flex justify-content-center  align-items-center"
                >
                    <div class="spinner-border text-secondary" role="status"></div>
                </div>
            </div>
        );
    } else {

        return (
            <div style={{ height: '100vh', /* backgroundImage: `url(${backgrounimage})` */ }}>
                <NavBar />

                {alertShow ?
                    <CoustumAlert alertMessage={alertMessage} />
                    : <></>}

                <AddList swapAdList={swapAdList} setAlertShow={setAlertShow}
                    setAlertMessage={setAlertMessage}
                    cryptoCurrencyApi={cryptoCurrencyApi} />

            </div>
        )
    }
}

export default IndexSwap