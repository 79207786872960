import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import io from 'socket.io-client';
import MenuItem from "@material-ui/core/MenuItem";
import { AiOutlineClose } from "react-icons/ai";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import * as Colors from "../Constant/Colors";
import API from "../Api";
import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Modal from "@material-ui/core/Modal";
import CoustumAlert from '../Constant/CoustumAlert'
import OtpInput from "react-otp-input";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import SellingTables from "./WalletTables/SellingTable";
const socket = io('wss://streamer.cryptocompare.com');

// https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=AUD

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    color: 'white',
    marginTop: theme.spacing(2),
  },
}));
export default function SimpleContainer() {
  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id, email } = user_Data.data

  const classes = useStyles();


  const [twoFectorAuthenMod, setTwoFectorAuthenMod] = useState(false);
  const [twoFectorAuthenCode, setTwoFectorAuthenCode] = useState('');
  const [twoFectorAuthenCodeRes, setTwoFectorAuthenCodeRes] = useState('');
  const [twoFectorAuthenCodeLoader, setTwoFectorAuthenCodeLoader] = useState(false);


  const [isloding, setIsloding] = useState(true);
  const [walletResloding, setWalletResloding] = useState(false);
  const [currencyApi, setCurrencyApi] = useState([]);
  const [pinStatus, setPinStatus] = useState(0);
  const [countrayCurrencyApi, setCountrayCurrencyApi] = useState([]);
  const [percentage, setPercentage] = useState('');
  const [walletApi, setWalletApi] = useState('');



  const [sellectedCurrency, setSellectedCurrency] = useState('');
  const [sellectedcountrayCurrency, setSellectedcountrayCurrency] = useState('');

  const [dynamicCurrency, setDynamicCurrency] = useState(1);
  const [priceloding, setPriceloding] = useState(true);
  const [transactionList, setTransactionList] = useState([]);
  const [tranferCoinMod, setTranferCoinMod] = useState(false);
  const [transferCoinBtn, setTransferCoinBtn] = useState(false);
  const [walletID, setWalletID] = useState('');
  const [crypto, setCrypto] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [userVerifyModal, setUserVerifyModal] = useState(false);
  const [otpMod, setOtpMod] = useState(false);
  const [OTPValue, setOTPValue] = useState("");
  const [OTPLoader, setOTPLoader] = useState(false);
  const [OTPres, setOTPres] = useState('');

  const [apiUserDetails, setApiUserDetails] = useState({});
  const {
    auth_enable,
    authy_number
  } = apiUserDetails;

  const [pinModal, setPinModal] = useState(false);

  const [copied, setCopied] = useState(false);

  const displyPrice = dynamicCurrency - (dynamicCurrency / 100) * percentage.percentage;


  // console.log(sellectedCurrency.Chain_name, 'sellectedCurrency>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

  const getdata = async () => {
    try {


      const respons = await API().getUserProfileDetail(id);
      setApiUserDetails(respons.user_detail);
      const resCountrayCurrency = await API().getcurrency();
      const resgetPinStatus = await API().getPinStatus(id);
      setPinStatus(resgetPinStatus.pin_status)


      setSellectedcountrayCurrency(resCountrayCurrency[0])
      setCountrayCurrencyApi(resCountrayCurrency)
      const response = await API().getMyCryptoList(id);

      setWalletID(response.crypto_list[0].wallet_id)
      setCurrencyApi(response.crypto_list);
      setSellectedCurrency(response.crypto_list[0])
      const Percentage = await API().getPercentage();
      setPercentage(Percentage.data)
      const resFundTransfer = await API().getFundTransferList(
        id,
      );
      // console.log(resFundTransfer, 'resFundTransfer')


      console.log(response.crypto_list[0].wallet_id, response.crypto_list[0].crpto_id, 'response.crypto_list[0].wallet_id, response.crypto_list[0].crpto_id>>>>>>>>>>>>>>>>>>>>>!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

      setTransactionList(resFundTransfer.Transfer_list)
      const resWallet = await API().getWalletBalance(response.crypto_list[0].wallet_id, response.crypto_list[0].crpto_id);

      console.log(resWallet, 'response>>>>>>>>>>>>>>>>>>>>>!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      if (resWallet.data.status) {

        setWalletApi(resWallet.data.balance)
        setWalletResloding(false)
      }

      const rescrypto = await API().getCryptoCompairStatic(
        response.crypto_list[0].currreny_name,
        resCountrayCurrency[0].symbol
      )
      let key = Object.values(rescrypto.data)

      // console.log(key[0], 'key[0]>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

      setDynamicCurrency(key[0])
      setPriceloding(false)
      // socket.emit("SubAdd", { "subs": [`5~CCCAGG~${response.crypto_list[0].currreny_name}~${resCountrayCurrency[0].symbol}`] })
      setIsloding(false);
    } catch (error) {
      console.log(error, "err");
    }
  };
  useEffect(() => {
    getdata();


    // socket.on('m', function (data) {
    //   console.log('')
    //   if (data.split('~')[4] == 1 || data.split('~')[4] == 2) {
    //     let roundValue = Math.round(data.split('~')[5])
    //     // console.log(roundValue, 'resWalletssssssssssssssssssaaaaaaaaaaaaaaaaa')
    //     setDynamicCurrency(roundValue)
    //     setPriceloding(false)

    //   }


    // })

  }, []);

  const handleChange = async (event) => {
    // socket.emit("SubRemove", { "subs": [`5~CCCAGG~${sellectedCurrency.currreny_name}~${sellectedcountrayCurrency.symbol == 'NGN' ? "USD" : sellectedcountrayCurrency.symbol}`] })
    setPriceloding(true)
    setDynamicCurrency(1)
    // console.log('hellooooooooooooooooooooooooooooooo')
    // socket.emit("SubAdd", { "subs": [`5~CCCAGG~${event.target.value.currreny_name}~${sellectedcountrayCurrency.symbol == 'NGN' ? "USD" : sellectedcountrayCurrency.symbol}`] })
    setWalletID(event.target.value.wallet_id)
    setWalletResloding(true)
    setSellectedCurrency(event.target.value);

    const rescrypto = await API().getCryptoCompairStatic(
      event.target.value.currreny_name,
      sellectedcountrayCurrency.symbol
    )
    let key = Object.values(rescrypto.data)


    setDynamicCurrency(key[0])
    setPriceloding(false)
    // const resMyTransection = await API().getMyTransection(
    //   user_Data.data.id,
    //   event.target.value.crpto_id
    // );
    // setTransactionList(resMyTransection.transection)
    // console.log(event.target.value, 'event.target.value')
    const resWallet = await API().getWalletBalance(event.target.value.wallet_id, event.target.value.crpto_id);


    console.log(event.target.value.wallet_id, event.target.value.crpto_id, '.....................................................................')
    if (resWallet.data.status) {

      console.log(resWallet.data.balance, 'resWallet.data.balance>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      setWalletApi(resWallet.data.balance)
      setWalletResloding(false)
    } else {

      setWalletApi('')
      setWalletResloding(false)
    }

  };

  const handleChangeCountrayCurrency = async (event) => {
    // socket.emit("SubRemove", { "subs": [`5~CCCAGG~${sellectedCurrency.currreny_name}~${sellectedcountrayCurrency.symbol == 'NGN' ? "USD" : sellectedcountrayCurrency.symbol}`] })
    setPriceloding(true)
    setDynamicCurrency(1)
    setSellectedcountrayCurrency(event.target.value)
    // socket.emit("SubAdd", { "subs": [`5~CCCAGG~${sellectedCurrency.currreny_name}~${event.target.value.symbol == 'NGN' ? "USD" : event.target.value.symbol}`] })

    const rescrypto = await API().getCryptoCompairStatic(
      sellectedCurrency.currreny_name,
      event.target.value.symbol
    )
    let key = Object.values(rescrypto.data)

    setDynamicCurrency(key[0])
    setPriceloding(false)

  }
  const closeTransection = () => {
    setTranferCoinMod(false)

  }


  console.log(walletID, 'kjdsahkjsah;ssssssssssssssssssss,k,kdskjhdsahjgsajsakh')
  const Transfer = async () => {
    setTransferCoinBtn(true)

    try {
      const ress = await API().postTransferCrypto(
        walletID,
        parseFloat(crypto),
        walletAddress
      )
      console.log(walletID, 'kjdsahkjsah;iudkjh,k,kdskjhdsahjgsajsakh')

      if (ress.status) {

        getdata()
        setTransferCoinBtn(false)
        closeTransection()
        // setTranferCoinMod(false)
        setAlertShow(true)
        setTimeout(() => {
          setAlertShow(false)

        }, 3000);
        setAlertMessage(ress.message)

      } else {
        setTransferCoinBtn(false)
        closeTransection()
        setAlertShow(true)
        setTimeout(() => {
          setAlertShow(false)

        }, 3000);
        setAlertMessage(ress.message)
      }
    } catch (err) { console.log(err, 'errrrfundtransection') }

  }
  const userVerifyModalOpen = () => {
    setUserVerifyModal(true)
    closeTransection()
  }
  const userVerifyModalClose = () => {
    setUserVerifyModal(false)
  }


  const OtpModOpen = async () => {

    try {

      const res = await API().postEmailConformationCode(id, email)

      // console.log(res, 'resemailsend>>>>>>>>>>>>>>>>>>>>>>>>>>')
      if (res.status == true) {
        // console.log(res)
        setOtpMod(true);

      }
    }
    catch (err) {
      console.log(err, 'erremailsend')
    }
  };
  const OtpModClose = () => {
    setOtpMod(false);
    setOTPValue('')
    setOTPres('')

  };

  const OTPSend = async () => {
    try {
      setOTPLoader(true)

      const res = await API().postTransectionVerfiyCode(id, OTPValue)
      if (res.status == true) {
        // Transfer()
        setPinModal(true)
        OtpModClose()
        // setPinModal(false)
        setOTPres('')
        setOTPLoader(false)
        setUserVerifyModal(false)

      } else {
        setOTPLoader(false)
        setOTPres('Invalid Pin Code')
      }

    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }

  const TwoFectorAuthenModClose = () => {
    setTwoFectorAuthenMod(false)
    setTwoFectorAuthenCodeLoader(false)
    setTwoFectorAuthenCodeRes('')
    setTwoFectorAuthenCode('')
  }

  const TwillioCodeSend = async () => {

    if (twoFectorAuthenCode.length >= 4) {


      setTwoFectorAuthenCodeLoader(true)
      // console.log(email,
      //   twoFectorAuthenCode, 'emailtwoFectorAuthenCode>>>>>>>>>>>>>>>>>')
      try {

        const res = await API().postAuthyVerfication(
          email,
          twoFectorAuthenCode

        )

        if (res.status) {
          setPinModal(true)
          TwoFectorAuthenModClose()
        } else {
          // setInsertPinModal(true)
          setTwoFectorAuthenCodeRes(res.message)

        }
        setTwoFectorAuthenCodeLoader(false)
      } catch (error) {
        console.log(error, 'errorTwillioCodeSend')
      }
    } else {
      setTwoFectorAuthenCodeRes('Please Enter Code')
    }
  }


  const TranSferCoin = async () => {
    try {
      setOTPLoader(true)

      const res = await API().postTransectionVerfiyCode(id, OTPValue)
      if (res.status == true) {
        Transfer()
        // setPinModal(true)
        setTranferCoinMod(false)
        OtpModClose()
        setPinModal(false)
        setOTPres('')
        setOTPLoader(false)
        setUserVerifyModal(false)

      } else {
        setOTPLoader(false)
        setOTPres('Invalid Pin Code')
      }

    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }


  const PinModalOpen = () => {
    setPinModal(true)
    userVerifyModalClose()
  }
  const PinModalClose = () => {
    setPinModal(false)
  }

  const AddressCpy = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)

    }, 3000);

  }

  if (isloding) {
    return (
      <div style={{ backgroundColor: Colors.bodyCol, paddingBottom: 10, height: '100vh', /* backgroundImage: `url(${backgrounimage})` */ }}>


        <NavBar />
        <div
          style={{ height: "80vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: Colors.bodyCol, paddingBottom: 10,/*  height: '100vh', */ /* backgroundImage: `url(${backgrounimage})` */ }}>
        <NavBar />
        {alertShow ?
          <CoustumAlert alertMessage={alertMessage} />
          : <></>}
        <div className="card_container px-lg-5" style={{ overflow: "auto", backgroundColor: Colors.darkBlue }}>
          <div className="wallet_hader">
            <div style={{ width: "200px" }} className="d-flex ">
              <div className="wallet_dropDown ">

                <FormControl className={classes.formControl}>
                  <Select
                    value={sellectedCurrency}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {currencyApi.map((item) => {
                      return (
                        <MenuItem key={item.crpto_id} value={item}>
                          {item.currreny_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Select
                    value={sellectedcountrayCurrency}
                    onChange={handleChangeCountrayCurrency}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                  >

                    {countrayCurrencyApi.map((item) => {

                      return (
                        <MenuItem key={item.id} value={item}>
                          {item.symbol}
                        </MenuItem>

                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <h4 className="" style={{ color: Colors.lightGreen }}>Current Balance</h4>

              {walletResloding ? (

                <div className="spinner-border text-secondary" role="status"></div>
              ) : (
                // 100000000
                <h6 className="text-light">{sellectedCurrency.currreny_name == 'BNB' || sellectedCurrency.currreny_name == 'USDT' ? walletApi.final_balance : walletApi.final_balance / 100000000} {sellectedCurrency.currreny_name}</h6>
              )}
              <h5 className="" style={{ color: Colors.lightGreen }}>Estimated Value</h5>
              {priceloding ? (
                <div className="spinner-border text-secondary" role="status"></div>
              ) : (
                <h6 className="text-light">{sellectedCurrency.currreny_name == 'BNB' ? /* displyPrice * 410 */  (walletApi.final_balance * (displyPrice)).toFixed(2) : (walletApi.final_balance / 10000 * (displyPrice / 10000)).toFixed(2)} {sellectedcountrayCurrency.symbol}</h6>
              )}
            </div>
          </div>
          <div className=' d-xl-flex justify-content-between '>
            <div className=" d-lg-flex align-items-center">
              <div style={{ marginLeft: 10, backgroundColor: Colors.lightBlue, color: Colors.darkBlue }} className='p-2 text-light '>
                Wallet Address:
              </div>
              <div style={{ marginLeft: 10 }} className='border walletAddress_div  p-2'>
                {/* {walletApi.address} */}
                <CopyToClipboard text={walletApi.address}
                  onCopy={AddressCpy}>
                  <span>{walletApi.address}</span>
                </CopyToClipboard>
              </div>
              {copied ?
                <h6 style={{ color: 'gray', fontSize: 20, marginLeft: 10 }}>Copied</h6>
                : <></>}
              <div style={{ marginLeft: 10, backgroundColor: Colors.lightBlue, color: Colors.darkBlue }} className='p-2 text-light '>
                {sellectedCurrency.Chain_name}
              </div>
            </div>

            <button
              onClick={() => {
                setTranferCoinMod(true)
                setCrypto('')
                setWalletAddress('')
              }
              }
              style={{ fontSize: 16, marginRight: 10, height: 40, width: 140, borderRadius: 50 }}
              type="button"
              className="btn mt-2 btn-outline-info"
            >
              Transfer Coin
            </button>
          </div>
          <SellingTables transactionList={transactionList} />
          {/* <BuyingTables transactionList={transactionList} /> */}
        </div>

        {/*####################### 
        ##### Tranfer Coin Mod ##### 
        #######################*/}

        <Modal open={tranferCoinMod}>
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div className="modal-header">
                <h1> Transfer {sellectedCurrency.currreny_name}</h1>
              </div>
              <div className="modal-body">
                <div className="col-12 row justify-content-center">
                  <div className="mt-2  d-flex justify-content-between align-items-center ">
                  </div>

                  <h6>{/* Crypto */} {sellectedCurrency.currreny_name}:</h6>
                  <div
                    className=" border  rounded  shadow-sm mx-auto "
                    style={{
                      width: "95%",
                      height: "45px",
                    }}
                  >
                    <input
                      className="form-control mt-1 border-0 shadow-none  "
                      value={crypto}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setCrypto(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "100%",

                      }}
                    />
                  </div>

                  <h6 className="mt-2">Wallet Address:</h6>
                  <div
                    className=" border  rounded  shadow-sm mx-auto "
                    style={{
                      width: "95%",
                      height: "45px",

                    }}
                  >
                    <input
                      className="form-control mt-1 border-0 shadow-none  "
                      value={walletAddress}
                      onChange={(e) => {
                        setWalletAddress(e.target.value);
                      }}
                      placeholder="Wallet Address"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="modal-footer ">
                <button
                  onClick={closeTransection}
                  type="button"
                  className="btn px-4 border-0 shadow-none rounded-pill btn-primary"
                  style={{ backgroundColor: Colors.lightBlue, color: 'Colors.darkBlue' }}
                >
                  Close
                </button>

                {walletAddress.length >= 2 && crypto.length >= 1 ?
                  <button
                    onClick={() => {
                      if (auth_enable == 1) {

                        setTwoFectorAuthenMod(true)
                      } else {
                        setPinModal(true)
                      }
                      closeTransection()
                    }}
                    type="button"
                    className="btn px-4 border-0 shadow-none rounded-pill btn-primary"
                    style={{ backgroundColor: Colors.darkBlue, }}
                  >
                    Transfer
                  </button>
                  :
                  <div
                    type="button"
                    className="btn px-4  rounded-pill "
                    style={{ backgroundColor: Colors.lightGreen, color: 'white' }}
                  >
                    Transfer
                  </div>
                }


              </div>
            </div>
          </div>
        </Modal>


        {/* ###########################
      ########## Verify Via Modal ##########
      ##########################* */}
        <Modal open={userVerifyModal}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-between mt-3">
                  <h4>Please Verify Via</h4>
                  <AiOutlineClose
                    onClick={userVerifyModalClose}
                    size={20}
                    style={{}}
                  />
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <button
                    onClick={OtpModOpen}
                    style={{ width: "45%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn border-0 shadow-none btn-primary  mt-3"
                  >

                    Via Email
                  </button>
                  <button
                    onClick={PinModalOpen}
                    style={{ width: "45%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn border-0 shadow-none btn-primary  mt-3"
                  >

                    Via Pin
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>



        {/* ###########################
      ########## OTP Modal ##########
      ##########################* */}
        <Modal open={otpMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Please check Email for verification code</h2>
                <AiOutlineClose
                  onClick={OtpModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">




                  <OtpInput
                    value={OTPValue}
                    onChange={(e) => setOTPValue(e)}
                    numInputs={4}
                    containerStyle={{
                      marginTop: "20px",
                      // marginLeft: "20px",
                      width: "300px",
                    }}
                    isInputNum={true}
                    inputStyle={{
                      height: "60px",
                      width: "60px",
                      marginLeft: "12px",
                    }}
                    separator={<span>{""}</span>}
                  />
                  <div className="col-6">
                    {OTPLoader ? (
                      <div className="d-flex mt-1 justify-content-center align-items-center">
                        <div
                          className="spinner-border text-secondary"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <p className="text-center text-danger">{OTPres}</p>
                    )}
                  </div>

                  <button
                    onClick={OTPSend}
                    style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn btn-primary  mt-3"
                  >

                    Send
                  </button>

                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* ###########################
      ########## Pin Modal ##########
      ##########################* */}
        <Modal open={pinModal}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">



              <div className="modal-body">

                <AiOutlineClose
                  onClick={PinModalClose}
                  size={20}
                  style={{ marginLeft: "90%" }}
                />
                <div className=" row justify-content-center">


                  {pinStatus ?
                    <>
                      <h6 className="text-center mt-2" >Enter your PIN</h6>
                      <OtpInput
                        value={OTPValue}
                        onChange={(e) => setOTPValue(e)}
                        numInputs={4}
                        containerStyle={{
                          marginTop: "20px",
                          // marginLeft: "20px",
                          width: "300px",
                        }}
                        isInputNum={true}
                        inputStyle={{
                          height: "60px",
                          width: "60px",
                          marginLeft: "12px",
                        }}
                        separator={<span>{""}</span>}
                      />
                      <div className="col-6">
                        {OTPLoader ? (
                          <div className="d-flex mt-1 justify-content-center align-items-center">
                            <div
                              className="spinner-border text-secondary"
                              role="status"
                            ></div>
                          </div>
                        ) : (
                          <p className="text-center text-danger">{OTPres}</p>
                        )}
                      </div>

                      <button
                        onClick={TranSferCoin}
                        style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                        type="button"
                        className="btn btn-primary  mt-3"
                      >

                        Send
                      </button>
                    </>
                    :
                    <h5 className="text-center mt-2" style={{ color: 'gray' }}>Please go to setting and create pin first</h5>
                  }



                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/*###################################
         ### 2 fector Authentication Modal ###
         ##################################*/}
        <Modal open={twoFectorAuthenMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2> Enter Google Auth Code</h2>
                <AiOutlineClose
                  onClick={TwoFectorAuthenModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">

                <div
                  className=" mt-1 d-flex justify-content-between shadow-sm mx-auto "
                  style={{
                    width: "90%",
                    height: "45px",
                    backgroundColor: 'white',
                    paddingTop: 10,
                    paddingBottom: 10,
                    border: "1px solid rgb(199, 199, 199)",
                    borderRadius: 5,
                  }}
                >
                  <input
                    value={twoFectorAuthenCode}
                    className="form-control border-0 shadow-none  "
                    style={{
                      width: "100%",
                      paddingLeft: 20,
                    }}

                    placeholder="Enter Code"
                    onChange={(e) => setTwoFectorAuthenCode(e.target.value)}
                  />

                </div>
                {twoFectorAuthenCodeLoader ? <></> :
                  <h6 className="text-danger text-center mt-2">{twoFectorAuthenCodeRes}</h6>}

                <div className="mt-3 d-flex justify-content-center">
                  <button
                    style={{ width: "60%", fontSize: 20, backgroundColor: Colors.darkBlue, color: "white" }}
                    type="button"
                    className="btn btn-outline    "
                    onClick={TwillioCodeSend}
                  >
                    {twoFectorAuthenCodeLoader ?
                      <div className="spinner-border text-secondary" role="status"></div> : ' Verify'}

                  </button>
                </div>

              </div>
            </div>
          </div>
        </Modal>

      </div>
    );
  }
}
