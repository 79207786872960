import React, { useEffect, useState, useRef } from 'react';
import backgrounimage from '../../Images/geometric-1732847.jpeg'
import profile from '../../Images/image1.jpg';
import NavBar from '../NavBar';
import { FiSend, FiPaperclip, FiCameraOff } from "react-icons/fi";
import * as Colors from '../../Constant/Colors'
import { useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import BankDetailsMod from '../SettingScreens/BankDetailsMod';
import API from "../../Api";
import ScrollToBottom from 'react-scroll-to-bottom';
import io from 'socket.io-client';
import CoustumAlert from '../../Constant/CoustumAlert'
import tune from '../../Images/iphone_notification_so.mp3'

import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";

const domain = 'https://f557-162-241-121-1.ngrok.io'
var socket = io(domain);
function MySwapOffersDetails() {
    const chatDiv = useRef().current;
    const [isloding, setIsloding] = useState(true);

    var getMessages = []
    const item = useLocation().state.detail;
    const percentage = useLocation().state.percentage;
    console.log(item, 'iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii')

    const {
        name,
        quantity,
        crpto_symbol,
        bid_amount,
        // maximum_crypto_amount,
        // minimum_crypto_amount,
        // country_name,
        // bank_name,
        // payment_method,
        // last_name,
        seller_status,
        buyer_status,
        bid_id,
        bid_user_id,
        crpto_id,
        ad_id,
        approved,
        approved_transection,
        decline_transection,
        bankTranfer,
        dispute_transection,
        swap_id

    } = item

    console.log(item, 'itttttttttttttsssssssssssssssssssssssssssssshhhhhhhhhhhhhhhhhhhhhhhhhhhh')
    const [twoFectorAuthenMod, setTwoFectorAuthenMod] = useState(false);
    const [twoFectorAuthenCode, setTwoFectorAuthenCode] = useState('');
    const [twoFectorAuthenCodeRes, setTwoFectorAuthenCodeRes] = useState('');
    const [twoFectorAuthenCodeLoader, setTwoFectorAuthenCodeLoader] = useState(false);

    // console.log(percentage, 'percentage')
    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, symbol, first_name, email } = user_Data.data;
    const [chatShow, setChatShow] = useState(0)
    const [chatButton, setChatButton] = useState('Chat')
    const [data, setData] = useState(0);
    const [bankDetailApi, setBankDetailApi] = useState('');
    const [message, setMessage] = useState('');
    const [resMessage, setResMessage] = useState([]);
    const [transectionId, setTransectionId] = useState(0);
    const [bankDetaiMod, setBankDetaiMod] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [fullSizeImage, setFullSizeImage] = useState("");

    const [errors, setErrors] = useState("");
    const [profileImage, setProfileImage] = useState({
        image: null,
        name: null,
    });
    const [values, setValues] = useState({
        image: null,
        name: null,
    });


    const [alertShow, setAlertShow] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [data1, setData1] = useState(0);

    const audio = new Audio(tune);
    var getMessages = []
    // console.log(transectionId, 'ss')

    const chatOpen = () => {
        if (chatShow == 0) {

            setChatShow(1)
            setChatButton('Close')
        } else {
            setChatShow(0)
            setChatButton('Chat')
        }
    }

    const SallerBankDetails = async () => {
        // console.log(data, 'data')
        // setBidUserId(data.bid_user_id)
        // setBidDetails(data)
        // setAddId(data.ad_id)
        try {

            const resDetails = await API().getAcceptSellingOfferDetail(
                crpto_id,
                bid_user_id,
                quantity,
                ad_id,
                id
            )
            // console.log(resDetails, 'dddddetails')
            if (resDetails.status == true) {
                setBankDetailApi(resDetails)
                setBankDetaiMod(true)

                // MyBuyingOfferModClose()
                setData(1)
            } else {

                setAlertShow(true)
                setTimeout(() => {
                    setAlertShow(false)

                }, 3000);
                setAlertMessage(`Saller have No ${item.crpto_symbol}`)
                // MyBuyingOfferModClose()
            }
            // // console.log(resDetails, 'resDetails')


        } catch (err) {
            console.log(err, 'errOtpSetTransection')
        }
    }

    const SendMasseg = () => {
        socket.emit("text", { "msg": message, "t_id": transectionId, "u_id": id })

        setMessage('')

        let dd = {
            Message: message,
            first_name: first_name,
            file: null,
            user_id: id,
        }


        // console.log(JSON.parse(data.sender), 'dattaaaaa')

        console.log('hello')
        getMessages = resMessage
        getMessages.push(dd)


        setResMessage(getMessages)


    }

    const ShowImage = (item) => {
        setShowImageModal(true)
        setFullSizeImage(item)
    }

    const DisputeRequest = async () => {


        try {
            const res = await API().getDispute(
                item.id,
                id
            )
            // if (res.status) {
            //     getData()
            // }

            console.log(res, 'res tansection_id')

        } catch (err) { console.log(err, 'errAcceptTransection') }
    }

    const GetData = async () => {
        try {
            const res = await API().getSwapTransectionId(swap_id)
            setTransectionId(res.transection_id)

            const resMessage = await API().postMessage(res.transection_id)
            setResMessage(resMessage.data)
            // console.log(resMessage, 'resMessage...>>>>.............1')
            console.log(res.transection_id, 'restransectionid................1')


            // console.log(socket, 'http://162.241.121.1:3000.............................!')
            socket.emit("user_join", { "t_id": res.transection_id, "u_id": id })

            socket.on("user_joined", (data) => {

            })


            socket.on("message", (data) => {

                getMessages = resMessage.data
                let senderDada = JSON.parse(data.sender)
                let dd = {
                    Message: data.message,
                    first_name: senderDada.first_name,
                    file: senderDada.file,
                    last_name: senderDada.last_name,
                    user_id: senderDada.id,
                    file: data.url
                }


                console.log(JSON.parse(data.sender), 'data>>>>>>>>>>>>>>>>>>>>>buying')

                if (id != senderDada.id) {
                    getMessages.push(dd)

                    audio.play()


                    setResMessage(getMessages)
                } else {
                    // setResMessage(getMessages)

                }
                setData1(1)
                setData1(0)
            })

            setIsloding(false)
        } catch (err) { console.log(err, 'errtransectionid') }
    }


    const ImageRef = useRef();
    const fileOpen = () => {
        const trigger = ImageRef.current;
        trigger.click();
    };

    let UploadImage = async (files) => {



        console.log(files, 'image>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer 315|C1DgmLVn8kCxwHN6ioyETx5jxjimMYjy5QXV3wxS");

        var formdata = new FormData();
        formdata.append("room_id", transectionId);
        formdata.append("user_id", id);
        formdata.append("message", '');
        formdata.append("file", files);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://f557-162-241-121-1.ngrok.io//upload_image", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));


    }



    const handleChangeImage = async (e) => {
        const { value, files } = e.target;
        let ddddd = value.split("\\");
        ddddd = ddddd[ddddd.length - 1];
        const file = files[0];

        let ext = value.split(".");

        ext = ext[ext.length - 1];
        if (
            ext === "jpg" ||
            ext === "JPG" ||
            ext === "jpeg" ||
            ext === "bmp" ||
            ext === "png"
        ) {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: { error: false, text: "" },
                };
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                const FileasDataUrl = e.target.result;

                const base64 = FileasDataUrl.split(",");

                const base64Encoded = base64[base64.length - 1];
                setProfileImage({ name: ddddd, image: base64Encoded });

                setMessage('')


                let dd = {
                    Message: '',
                    first_name: first_name,
                    file: ddddd,
                    user_id: id,
                }
                getMessages = resMessage
                console.log('hello')
                getMessages.push(dd)


                setResMessage(getMessages)

                setValues({ image: FileasDataUrl, name: ddddd });

                UploadImage(file)



            };
            reader.readAsDataURL(file);
        } else {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: {
                        error: true,
                        text: "Only jpg, jpeg, bmp and png are allowed",
                    },
                };
            });
        }

        e.target.value = "";
    };


    const TwoFectorAuthenModClose = () => {
        setTwoFectorAuthenMod(false)
        setTwoFectorAuthenCodeLoader(false)
        setTwoFectorAuthenCodeRes('')
        setTwoFectorAuthenCode('')
    }
    const TwillioCodeSend = async () => {

        if (twoFectorAuthenCode.length >= 4) {
            setTwoFectorAuthenCodeLoader(true)
            try {
                // const res = await API().postAuthyVerfication(
                //     email,
                //     twoFectorAuthenCode
                // )
                // if (res.status) {
                const res = await API().getSellerSwapStatus(
                    item.id,
                    // bid_id,
                    // ad_id,
                    // bid_amount,
                    // bid_user_id,
                    // quantity,//ok
                    // // quantity,
                    // crpto_id
                )

                console.log(res, 'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
                if (res.status == true) {

                    setAlertShow(true)
                    setTimeout(() => {
                        setAlertShow(false)

                    }, 3000);
                    setAlertMessage('Accepted')
                }
                TwoFectorAuthenModClose()
                // } else {

                //     setTwoFectorAuthenCodeRes(res.message)

                // }
                setTwoFectorAuthenCodeLoader(false)
            } catch (error) {
                console.log(error, 'errorTwillioCodeSend')
            }
        } else {
            setTwoFectorAuthenCodeRes('Please Enter Code')
        }
    }



    useEffect(() => {
        GetData()

    }, []);
    if (isloding) {
        return (
            <div style={{ height: "100vh", backgroundColor: Colors.bodyCol }}>
                <NavBar />
                <div
                    style={{ height: "80vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="spinner-border" style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {
        return <div className="" style={{ height: '100vh', backgroundColor: Colors.bodyCol, }}>

            <NavBar />

            {alertShow ?
                <CoustumAlert alertMessage={alertMessage} />
                : <></>}
            <div className=' d-lg-flex p-4 justify-content-lg-center  '>

                <div className=' mt-3  col-lg-6 row justify-content-center '>
                    <div className='  col-lg-12 p-3 col-12  ' style={{ backgroundColor: 'white', borderRadius: 30 }}>
                        <h2 className=' text-center  ' >My Offer</h2>

                        <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >You will send: </h5>
                            <h5 className=' col-md-7 ' style={{}} >  {item.sell_bid_crypto} {item.recive_symbol}</h5>
                        </div>
                        <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >You will receive: </h5>
                            <h5 className=' col-md-7 ' style={{}} >  {item.recive_bid_crypto} {item.send_symbol}</h5>
                        </div>

                        <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >Offer Status: </h5>
                            <h5 className=' col-md-7 ' style={{}} >{seller_status == 1 ? approved ? "Approved" : "Accepted" : "Unaccepted"}</h5>
                        </div>
                        <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >Ad Created By: </h5>
                            <h5 className=' col-md-7 ' style={{}} >{item.user_name}</h5>
                        </div>
                        {/* <h3 className=' text-center  border-top ' >Ad Detail</h3> */}


                        {/* <div className=' mt-2 d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >Amount Limit: </h5>
                            <div className=' col-md-7 '>
                                <h5 className='' >  {(item.minimum_crypto_amount - (item.minimum_crypto_amount / 100) * percentage).toFixed(7)} {item.crpto_symbol} /

                                </h5>
                                <h5 className='' >
                                    {(item.maximum_crypto_amount - (item.maximum_crypto_amount / 100) * percentage).toFixed(7)} {item.crpto_symbol}
                                </h5>
                            </div>
                        </div>
                        <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >Location: </h5>
                            <h5 className=' col-md-7 ' style={{}} >{country_name}</h5>
                        </div>
                        <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >Bank Name: </h5>
                            <h5 className=' col-md-7 ' style={{}} >{bank_name}</h5>
                        </div>
                        <div className='d-md-flex pt-2 pb-2 border-top justify-content-center '>
                            <h5 className=' col-md-5 ' style={{}} >Payment Method: </h5>
                            <h5 className=' col-md-7 ' style={{}} > {payment_method}</h5>
                        </div>
                        <div className='d-md-flex pt-2 pb-2 border-top justify-content-center  border-bottom'>
                            <h5 className=' col-md-5 ' style={{}} >Name: </h5>
                            <h5 className=' col-md-7 ' style={{}} >{name} {last_name}</h5>
                        </div> */}

                        {buyer_status == 0 ?

                            <Button

                                style={{
                                    fontSize: 15,
                                    marginBottom: "10px",
                                    borderRadius: "20px",
                                    marginTop: "5px",
                                    backgroundColor: 'rgb(119, 112, 173)',
                                }}
                                variant="contained"
                                color="secondary"

                            >
                                Waiting...
                            </Button>

                            :
                            <>
                                {seller_status == 0 ?
                                    <div className=' pt-2 pb-2 mx-auto   col-md-12 col-lg-12   '>
                                        <Button
                                            onClick={() => setTwoFectorAuthenMod(true)}
                                            style={{
                                                fontSize: 15,
                                                marginBottom: "10px",
                                                borderRadius: "20px",
                                                marginTop: "5px",
                                                backgroundColor: Colors.darkBlue,
                                            }}
                                            variant="contained"
                                            color="secondary"

                                        >
                                            Accept Offer
                                        </Button>
                                    </div>
                                    :
                                    <>
                                        {approved_transection || decline_transection ? <></>
                                            :
                                            <div className='d-md-flex pt-2 pb-2 mx-auto  border-top col-md-12 col-lg-12  justify-content-around '>

                                                {/* <Button
                                                    onClick={SallerBankDetails}
                                                    style={{
                                                        fontSize: 15,
                                                        marginBottom: "10px",
                                                        borderRadius: "20px",
                                                        marginTop: "5px",
                                                        backgroundColor: Colors.darkBlue,

                                                    }}
                                                    variant="contained"
                                                    color="secondary"

                                                >
                                                    Bank Tranfer
                                                </Button> */}
                                                {/* {bankTranfer ? <> */}
                                                {dispute_transection ?
                                                    <button style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-danger mt-2">Transaction Disputed </button>
                                                    :
                                                    <button style={{ borderRadius: "20px", width: 150, height: 38 }} className="btn  btn-outline-info mt-2" onClick={() => DisputeRequest(bid_id)}   >Dispute</button>
                                                }
                                                {/* </> : <></>} */}


                                                <button style={{ borderRadius: "20px", width: 150, height: 38 }} className="btn  btn-outline-info mt-2" onClick={chatOpen}  >{chatButton}</button>

                                            </div>


                                        }
                                    </>

                                }


                            </>
                        }
                    </div>

                </div>

                {/*######################
            ######### CHAT ########## 
            ######################*/}

                {chatShow == 1 ?
                    <div className=' mt-lg-3 ms-lg-3 mt-3 col-lg-4 p-lg-3 ' style={{ backgroundColor: 'white', borderRadius: 30 }}>
                        {resMessage.length == 0 ?
                            <div style={{ height: "90%", }} className='chatDivAdd d-flex justify-content-center border align-items-center '>
                                <h5 className='' style={{ color: 'rgb(221, 221, 221)' }}>You have no conversation</h5>
                            </div>
                            :

                            <ScrollToBottom className="overflow-auto  chatDiv">
                                {resMessage.map((item) => {
                                    // console.log(item, '.................................................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                                    return (
                                        <>
                                            {item.user_id == id ?
                                                <div className=" d-flex justify-content-end">
                                                    <div style={{ padding: 5, alignSelf: 'flex-end' }} className="border col-md-6 col-sm-12 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                                                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>

                                                            {item.image != null ?
                                                                <img
                                                                    src={`https://foloswap.com${item.image}`}
                                                                    class="img-thumbnail rounded-circle "
                                                                    alt="Profile"
                                                                    style={{ height: "40px", width: "40px" }}
                                                                />
                                                                : <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "40px", width: "40px" }} >

                                                                    <FiCameraOff color="gray" size={20} />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div style={{}} className=" col-10">
                                                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">{item.first_name}</h6>
                                                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                                                                {item.Message}  </p>

                                                            {item.file != null ?
                                                                <img
                                                                    onClick={() => ShowImage(`${domain}/static/uploads/${item.file}`)}
                                                                    src={`${domain}/static/uploads/${item.file}`}
                                                                    style={{ height: 220, width: 200 }}
                                                                    className=""
                                                                    alt="Profile"
                                                                />
                                                                : <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ maxWidth: 900, padding: 5 }} className="border col-lg-6 col-sm-10 rounded d-flex mt-1 justify-content-between">

                                                    <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                                                        {item.image != null ?
                                                            <img
                                                                src={`https://foloswap.com${item.image}`}
                                                                class="img-thumbnail rounded-circle "
                                                                alt="Profile"
                                                                style={{ height: "40px", width: "40px" }}
                                                            />
                                                            : <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "40px", width: "40px" }} >

                                                                <FiCameraOff color="gray" size={20} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{ width: 210 }} className="">
                                                        <h6 style={{ marginTop: 1, color: 'black' }} className="">{item.first_name}</h6>
                                                        <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">{item.Message}</p>
                                                        {item.file != null ?
                                                            <img
                                                                onClick={() => ShowImage(`${domain}/static/uploads/${item.file}`)}
                                                                src={`${domain}/static/uploads/${item.file}`}
                                                                style={{ height: 220, width: 200 }}
                                                                className=""
                                                                alt="Profile"
                                                            />
                                                            : <></>}
                                                    </div>
                                                </div>
                                            }
                                        </>

                                    )
                                })}

                            </ScrollToBottom>}

                        <div style={{
                            width: "100%",
                            height: "45px",
                            backgroundColor: "rgb(255, 255, 255)",
                            padding: 10,

                            border: "1px solid gray",
                            borderRadius: 50,
                        }} className="d-flex align-items-center mt-2">
                            <input value={message} placeholder="type message..." className="form-control border-0 shadow-none " onChange={(e) => setMessage(e.target.value)} />

                            <FiPaperclip style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} onClick={fileOpen} />

                            <input
                                ref={ImageRef}
                                onChange={(e) => handleChangeImage(e)}
                                className="d-none"
                                type="file"
                            />
                            <FiSend onClick={SendMasseg} style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                        </div>



                    </div>
                    : <></>
                }

            </div>
            {
                data == 1 ?
                    <BankDetailsMod
                        userId={id}
                        addId={ad_id}
                        bidDetails={{ quantity, bid_amount }}
                        bidUserId={bid_user_id}
                        bankDetaiMod={bankDetaiMod}
                        setBankDetaiModClose={setBankDetaiMod}
                        bankDetailApi={bankDetailApi}
                        setData={setData}
                    />
                    : <></>
            }



            {/* ###########################
      ########## Show Image ##########
      ##########################* */}
            <Modal open={showImageModal}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header  justify-content-end">

                            <AiOutlineClose
                                onClick={() => setShowImageModal(false)}
                                size={20}
                                style={{ marginRight: 15 }}
                            />
                        </div>
                        <div className="modal-body">
                            <img

                                src={fullSizeImage}
                                style={{ height: 420, width: "100%" }}
                                className=""
                                alt="Profile"
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            {/*###################################
         ### 2 fector Authentication Modal ###
         ##################################*/}
            <Modal open={twoFectorAuthenMod}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2> Enter Google Auth Code</h2>
                            <AiOutlineClose
                                onClick={TwoFectorAuthenModClose}
                                size={20}
                                style={{ marginRight: 15 }}
                            />
                        </div>
                        <div className="modal-body">

                            <div
                                className=" mt-1 d-flex justify-content-between shadow-sm mx-auto "
                                style={{
                                    width: "90%",
                                    height: "45px",
                                    backgroundColor: 'white',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    border: "1px solid rgb(199, 199, 199)",
                                    borderRadius: 5,
                                }}
                            >
                                <input
                                    value={twoFectorAuthenCode}
                                    className="form-control border-0 shadow-none  "
                                    style={{
                                        width: "100%",
                                        paddingLeft: 20,
                                    }}

                                    placeholder="Enter Code"
                                    onChange={(e) => setTwoFectorAuthenCode(e.target.value)}
                                />

                            </div>
                            {twoFectorAuthenCodeLoader ? <></> :
                                <h6 className="text-danger text-center mt-2">{twoFectorAuthenCodeRes}</h6>}

                            <div className="mt-3 d-flex justify-content-center">
                                <button
                                    style={{ width: "60%", fontSize: 20, backgroundColor: Colors.darkBlue, color: "white" }}
                                    type="button"
                                    className="btn btn-outline    "
                                    onClick={TwillioCodeSend}
                                >
                                    {twoFectorAuthenCodeLoader ?
                                        <div className="spinner-border text-secondary" role="status"></div> : ' Verify'}

                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>

        </div >;
    }
}

export default MySwapOffersDetails;