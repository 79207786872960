import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { NavLink } from "react-router-dom";
import * as Colors from "../Constant/Colors";
import { useHistory } from "react-router-dom";
import NotificationDropdoun from './Notification/NotificationDropdoun';
import OffersDropDown from './OffersDropDown';
import MyadsDroupDown from './Myads/MyadsDropDown';
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import Alert from '@material-ui/lab/Alert';
import { ToseContext } from "../reducer/Context";
import API from "../Api";



function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgb(230, 86, 136)",
    alignItems: "center",
    height: 80,
    // justifyContent: "center",
  },
}));

export default function NavBar() {
  const classNamees = useStyles();

  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id } = user_Data.data
  const { toseMassege, setToseMassege } = useContext(ToseContext)
  console.log(toseMassege, 'NavBar>>>>>>>>>>>>>>>>>>>>>|||||||||||||||||>>>>>>>>>>>>>>>>>>>>>>>>>>')
  const userVerified = localStorage.getItem("user_verifyed");
  // console.log(userVerified, 'userVerified>>>>>>>>>>>>>>>>>>>>>>>>>>>')

  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory();

  const DelNotification = async () => {
    const res = await API().getDeleteNotification(
      id
    )
  }

  const ClickNoti = () => {
    var data = { url: toseMassege.ad_id }

    if (toseMassege.ad_type == 1) {
      console.log(toseMassege.bid_id.length, 'toseMassege.bid_id.length ddd');
      console.log(toseMassege.bid_id, 'toseMassege.bid_id');

      if (toseMassege.bid_id.length < 1) {

        history.push("/mybuyingads")

        DelNotification()
        setToseMassege('')
      } else {
        history.push({
          pathname: "/MySellingOffers",
          state: { detail: data },
        })

        DelNotification()
        setToseMassege('')
      }
    } else {

      if (toseMassege.bid_id.length < 1) {

        history.push("/mysellingads")

        DelNotification()
        setToseMassege('')
      } else {
        history.push({
          pathname: "/MyByinOffers",
          state: { detail: data },
        })

        DelNotification()
        setToseMassege('')
      }
      console.log('eeeeeeeeeeeeeeeeeeee');
    }
    // else{
    //   if () {

    //   }
    // }
  }


  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark " style={{ backgroundColor: Colors.NavbarCol }}>
        <div className="container-fluid">
          <a activeClassName="active"
            href="https://foloswap.com/" className="navbar-brand " style={{ marginLeft: "30px", color: 'white' }}>
            FoloSwap
          </a>
          <button
            className="navbar-toggler  "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">

              <li
                className="nav-item"
                style={{ fontSize: "1.rem", marginLeft: "20px" }}
              >
                <NavLink
                  className="nav-link"


                  activeClassName="active"
                  to="/Home"
                >
                  Home
                </NavLink>
              </li>


              <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "20px" }}
              >
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/Wallet"
                >
                  Wallet
                </NavLink>
              </li>

              {/* <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "20px" }}
              >
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/swap"
                >
                  Swap
                </NavLink>
              </li> */}


              <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "20px" }}
              >
                <NavLink
                  className="nav-link"

                  activeClassName="active"
                  to="/CreateAds"
                >
                  Buy & Sell
                </NavLink>
              </li>




              <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "10px" }}
              >

                <OffersDropDown />

              </li>
              <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "10px" }}
              >

                <MyadsDroupDown />

              </li>
              <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "20px" }}
              >
                <NavLink
                  className="nav-link"

                  activeClassName="active"
                  to="/Settings"
                >
                  Settings
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "1rem", marginLeft: "20px" }}
              >

                <NotificationDropdoun />

              </li>
            </ul>

            {userVerified == 'false' ?

              <button
                type="button"
                className="btn "
                style={{ color: 'white', padding: "3px 5px", borderRadius: 50, fontWeight: 'bold', backgroundColor: 'red' }}
                onClick={() => setModalOpen(true)}


              >Unverified</button>
              : <>
                <dive

                  style={{ color: 'white', padding: "5px 20px", borderRadius: 50, fontWeight: 'bold', backgroundColor: '#006E16' }}

                // activeClassName="active"

                >Verified</dive>
              </>}
          </div>


        </div>



      </nav>
      {userVerified == 'false' ?
        <div className="mx-auto" style={{ backgroundColor: 'white', width: '60vw' }}>
          <div class="alert alert-danger" style={{ fontSize: 20, textAlign: 'center' }} role="alert">
            Click the unverified menu to verify your account and upload your identity.
          </div>

        </div>

        : <></>}

      {toseMassege.hasOwnProperty('ad_id') ?
        <div className=" col-lg-4  col-xl-3 col-md-6 col-8 mx-auto p-1 text-center" style={{ borderRadius: 10, backgroundColor: Colors.lightGreen }}>
          <span style={{ color: Colors.darkBlue }}>
            Your offer has been accepted
          </span>

          <span className="btn " style={{ marginLeft: 5, color: '#4750ff' }}
            onClick={ClickNoti}>
            Click to view
          </span>
        </div>
        : <></>}

      {/* ############################
      ### Verification Screen Modal ##
      #############################*/}
      <Modal open={modalOpen}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">

            <div className="modal-body d-flex  justify-content-between ">

              <div className="d-flex align-items-center" >
                <h5> Click here to</h5>
                <button
                  onClick={() => history.push({
                    pathname: "/Verification",
                    state: { detail: id },
                  })}
                  className="border-0 " style={{ marginTop: -8, backgroundColor: 'white', fontSize: 20, color: 'blue', marginLeft: 5 }}> Verify</button>
              </div>

              <AiOutlineClose
                onClick={() => setModalOpen(false)}
                size={20}
                style={{ marginRight: 15 }}
              />

            </div>
          </div>
        </div>
      </Modal>
    </div>

  );
}
