import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import Button from "@material-ui/core/Button";
import * as Colors from '../../Constant/Colors'
import API from "../../Api";
import { FiSend, FiPaperclip, FiCameraOff } from "react-icons/fi";
import profile from '../../Images/image1.jpg';
import BankDetailsMod from "./BankDetailsMod";




function MyBuyingBidsDetails(props) {
  const { open, close, BidsDetail } = props;


  const {
    name,
    quantity,
    crpto_symbol,
    bid_amount,
    maximum_crypto_amount,
    minimum_crypto_amount,
    country_name,
    bank_name,
    payment_method,
    last_name,
    seller_status,
    buyer_status,
    bid_id,
    bid_user_id,
    crpto_id,
    ad_id

  } = BidsDetail;
  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { id, symbol } = user_Data.data;
  const [addId, setAddId] = useState("");
  const [bidUserId, setBidUserId] = useState("");
  const [OTPLoader, setOTPLoader] = useState(false);
  const [bankDetaiMod, setBankDetaiMod] = useState(false);
  const [bankDetailApi, setBankDetailApi] = useState('');
  const [data, setData] = useState(0);
  const [chatModal, setChatModal] = useState(false);
  // const [bidDetails, setBidDetails] = useState({});
  const chatModOpen = () => {
    setChatModal(true)
  }
  const chatModClose = () => {
    setChatModal(false)
  }

  const MyBuyingOfferModClose = () => {
    close(false);
    // set(true);
  };
  const SallerBankDetails = async () => {
    // setBidUserId(data.bid_user_id)
    // setBidDetails(data)
    // setAddId(data.ad_id)
    try {

      const resDetails = await API().getAcceptSellingOfferDetail(
        crpto_id,
        bid_user_id,
        quantity,
        ad_id,
        id
      )
      if (resDetails.status == true) {
        setBankDetailApi(resDetails)
        setBankDetaiMod(true)

        MyBuyingOfferModClose()
        setData(1)
      } else {
        alert(`Saller have No ${crpto_symbol}`)
        MyBuyingOfferModClose()
      }
    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }


  return (
    <div>
      <Modal open={open} onClose={close}>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div className="modal-header">
              <h2>Offers Details</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                <div
                  style={{
                    borderRadius: 20,
                    border: "2px solid rgb(215, 215, 215)",
                  }}
                  className=" row col-10 mt-2 align-items-center shadow-sm"
                >
                  <h3 className="text-center mt-2 mb-3">My Offer</h3>

                  <h5 className="col-5">Quantity of:</h5>
                  <h5 className="col-7" style={{ color: "gray" }}>
                    {quantity} {crpto_symbol}
                  </h5>
                  <h5 className="col-5">Offer Amount:</h5>
                  <h5 className="col-7" style={{ color: "gray" }}>
                    {bid_amount} {symbol}
                  </h5>
                  <h5 className="col-5">Offer Status:</h5>
                  <h5 className="col-7" style={{ color: "gray" }}>
                    {seller_status == 1 ? "Accepted" : "Unaccepted"}
                  </h5>
                  <h3 className="text-center mt-2 mb-3">Ad Detail</h3>
                  <h5 className="col-5">Amount Limit:</h5>
                  <h5 className="col-7" style={{ color: "gray" }}>
                    {minimum_crypto_amount}
                    {crpto_symbol} to {maximum_crypto_amount}
                    {crpto_symbol}
                  </h5>
                  <h5 className="col-5">Location:</h5>
                  <h5 className="col-7 " style={{ color: "gray" }}>
                    {country_name}
                  </h5>
                  <h5 className="col-5">Bank Name:</h5>
                  <h5 className="col-7 " style={{ color: "gray" }}>
                    {bank_name}
                  </h5>
                  <h5 className="col-5">Payment Method:</h5>
                  <h5 className="col-7 " style={{ color: "gray" }}>
                    {payment_method}
                  </h5>
                  <h5 className="col-5">Name:</h5>
                  <h5 className="col-7" style={{ color: "gray" }}>
                    {name} {last_name}
                  </h5>

                  <div className="">
                    {seller_status == 0 ?
                      <Button

                        style={{
                          fontSize: 15,
                          marginBottom: "10px",
                          borderRadius: "20px",
                          marginTop: "5px",
                          backgroundColor: 'rgb(119, 112, 173)',
                        }}
                        variant="contained"
                        color="secondary"

                      >
                        Waiting...
                      </Button>

                      :

                      <div className="d-flex justify-content-between align-items-center">

                        <Button
                          onClick={SallerBankDetails}
                          style={{
                            fontSize: 15,
                            marginBottom: "10px",
                            borderRadius: "20px",
                            marginTop: "5px",
                            backgroundColor: Colors.darkBlue,
                          }}
                          variant="contained"
                          color="secondary"

                        >
                          Bank Tranfer
                        </Button>
                        <>
                          {chatModal ?
                            <Button

                              style={{
                                fontSize: 15,
                                // marginBottom: "10px",
                                borderRadius: "20px",


                                backgroundColor: Colors.darkBlue,

                              }}
                              className="col-lg-5"
                              variant="contained"
                              color="secondary"
                              onClick={chatModClose}
                            >
                              close
                            </Button>
                            :
                            <Button

                              style={{
                                fontSize: 15,
                                // marginBottom: "10px",
                                borderRadius: "20px",
                                // marginTop: "10px",
                                // height: '5%',
                                width: '40%',
                                backgroundColor: Colors.darkBlue,

                              }}
                              className="col-lg-5"
                              variant="contained"
                              color="secondary"
                              onClick={chatModOpen}
                            >
                              Chat
                            </Button>
                          }
                        </>
                      </div>
                    }
                  </div>
                </div>


              </div>
              {chatModal ?

                <div style={{
                  // borderRadius: 20,
                  border: "2px solid rgb(215, 215, 215)", marginLeft: '8%', marginTop: '2%', padding: 10,
                  width: '85%',
                  // paddingBottom: 10
                }} className="shadow-sm ">
                  <div style={{ maxHeight: 250, paddingBottom: 10 }} className="   overflow-scroll  ">

                    {/* msg */}
                    <div style={{ maxWidth: 800, padding: 5 }} className="border col-lg-8 col-sm-10 rounded d-flex justify-content-between">

                      <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                        {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                        <img
                          src={profile}
                          class="img-thumbnail rounded-circle "
                          alt="Profile"
                          style={{ height: "40px", width: "40px" }}
                        />
                        {/* } */}
                      </div>
                      <div style={{ width: 210 }} className="">
                        <h6 style={{ marginTop: 1, color: 'black' }} className="">hassan ali</h6>
                        <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">for testing</p>
                      </div>
                    </div>
                    {/* msg */}
                    {/* msg */}
                    <div className=" d-flex justify-content-end">
                      <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                          {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                          <img
                            src={profile}
                            class="img-thumbnail rounded-circle "
                            alt="Profile"
                            style={{ height: "40px", width: "40px" }}
                          />
                          {/* } */}
                        </div>
                        <div style={{ width: 210 }} className="">
                          <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                          <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                            testing massage</p>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex justify-content-end">
                      <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                          {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                          <img
                            src={profile}
                            class="img-thumbnail rounded-circle "
                            alt="Profile"
                            style={{ height: "40px", width: "40px" }}
                          />
                          {/* } */}
                        </div>
                        <div style={{ width: 210 }} className="">
                          <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                          <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                            testing massage</p>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex justify-content-end">
                      <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                          {/* {image == null ?
<FiCameraOff color="gray" size={20} />
: */}
                          <img
                            src={profile}
                            class="img-thumbnail rounded-circle "
                            alt="Profile"
                            style={{ height: "40px", width: "40px" }}
                          />
                          {/* } */}
                        </div>
                        <div style={{ width: 210 }} className="">
                          <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                          <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                            testing massage</p>
                        </div>
                      </div>
                    </div>
                    {/* msg */}

                  </div>
                  <div style={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: 10,

                    border: "1px solid gray",
                    borderRadius: 50,
                  }} className="d-flex align-items-center">
                    <input placeholder="text" className="form-control border-0 shadow-none " />

                    <FiPaperclip style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                    <FiSend style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                  </div>
                </div>
                : <></>}
            </div>
          </div>
        </div>
      </Modal>

      {/* ###########################
      ###### Bank Details Mod #######
      ##########################* */}

      {data == 1 ?
        <BankDetailsMod
          userId={id}
          addId={ad_id}
          bidDetails={{ quantity, bid_amount }}
          bidUserId={bid_user_id}
          bankDetaiMod={bankDetaiMod}
          setBankDetaiModClose={setBankDetaiMod}
          bankDetailApi={bankDetailApi}
          setData={setData}
        />
        : <></>}
    </div>
  );
}

export default MyBuyingBidsDetails;
