import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { FiSend, FiPaperclip, FiCameraOff } from "react-icons/fi";
import Button from "@material-ui/core/Button";
import * as Colors from '../../Constant/Colors'
import OtpInput from "react-otp-input";
import profile from '../../Images/image1.jpg';


import API from "../../Api";
import NavBar from "../NavBar";


function MySellingBidsDetails(props) {
  const { open, close, BidsDetail, getApi } = props;
  const {
    name,
    quantity,
    crpto_symbol,
    bid_amount,
    maximum_crypto_amount,
    minimum_crypto_amount,
    country_name,
    bank_name,
    payment_method,
    last_name,
    buyer_status,
    seller_status,
    bid_user_id,
    bid_id,
    ad_id, crpto_id
  } = BidsDetail;

  const user_Data = JSON.parse(localStorage.getItem("userData"));
  const { symbol, id, email } = user_Data.data;
  const [OTPValue, setOTPValue] = useState("");
  const [OTPLoader, setOTPLoader] = useState(false);

  const [otpMod, setOtpMod] = useState(false);
  const [OTPres, setOTPres] = useState('');
  const [transectionImageApif, setTransectionImageApif] = useState({});
  const [transectionImgStatus, setTransectionImgStatus] = useState(false);
  const [transectionImgModal, setTransectionImgModal] = useState(false);
  const [chatModal, setChatModal] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const OtpModClose = () => {
    setOtpMod(false);
    close(true);
    setOTPValue('')
    setOTPres('')

  };

  const SeeTranImg = () => {
    setTransectionImgModal(true)
  }
  const OtpModOpen = async () => {

    try {

      const res = await API().postEmailConformationCode(id, email)
      if (res.status == true) {

        setOtpMod(true);
        // close(false);

      }
    }
    catch (err) {
      console.log(err, 'erremailsend')
    }
  };

  const OTPSend = async () => {
    try {
      setOTPLoader(true)

      const res = await API().postTransectionVerfiyCode(id, OTPValue)

      if (res.status == true) {
        getApi()
        const res = await API().postSellerAcceptStatus(
          id,
          bid_id,
          ad_id,
          bid_amount,
          bid_user_id,
          quantity,//ok
          // quantity,
          crpto_id
        )


        if (res.status == true) {
          alert('Accepted')
          OtpModClose()
          setOTPres('')


          setOTPLoader(false)
        }
      } else {
        setOTPLoader(false)
        setOTPres('Invalid Pin Code')
      }

    } catch (err) {
      console.log(err, 'errOtpSetTransection')
    }
  }
  const chatModOpen = () => {
    setChatModal(true)
  }
  const chatModClose = () => {
    setChatModal(false)
  }
  const getData = async () => {
    try {
      console.log(
        bid_user_id,
        id,
        ad_id, 'ssssssssssssssssssssssssssssssssssssssssss')
      const res = await API().getTransectionDetail(
        bid_user_id,
        id,
        ad_id
      )

      if (res.status) {

        setTransectionImgStatus(res.status)
        setTransectionImageApif(res.transection)
      }
      setIsloading(false)
    } catch (err) { console.log(err, 'errrgetTransectionDetail') }

  }

  const aprovedTransection = async () => {
    try {
      const res = await API().postAcceptTransection(
        transectionImageApif.tansection_id,
        ad_id,
        quantity,
        bid_user_id,
        crpto_id,
      )
      if (res.status) {
        close(true)

      }
    } catch (err) { console.log(err, 'errAcceptTransection') }
  }
  useEffect(() => {
    getData()
  }, [])

  if (isloading) {
    return (
      <div style={{ backgroundColor: Colors.Bgcolor, height: "100vh" }}>

        <div
          style={{ height: "80vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div class="spinner-border text-secondary" role="status"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Modal open={open} onClose={close}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" >
            <div class="modal-content " >
              <div className="modal-header">
                <h2>Offers Details</h2>
                <AiOutlineClose
                  onClick={close}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">
                  <div
                    style={{
                      borderRadius: 20,
                      border: "2px solid rgb(215, 215, 215)",
                    }}
                    className=" row col-10 mt-2 align-items-center shadow-sm"
                  >
                    <div className="d-flex">

                      <h3 className=" mt-2 mb-3  w-50 text-center " style={{ marginLeft: '25%' }}>My Offer</h3>

                      {transectionImgStatus ?
                        <>
                          {chatModal ?
                            <Button

                              style={{
                                fontSize: 15,
                                marginBottom: "10px",
                                borderRadius: "20px",
                                marginTop: "10px",
                                height: '5%',


                                backgroundColor: Colors.darkBlue,

                              }}
                              className="col-lg-3"
                              variant="contained"
                              color="secondary"
                              onClick={chatModClose}

                            >
                              close
                            </Button>
                            :
                            <Button

                              style={{
                                fontSize: 15,
                                // marginBottom: "10px",
                                borderRadius: "20px",
                                marginTop: "10px",
                                height: '5%',

                                backgroundColor: Colors.darkBlue,

                              }}
                              className="col-lg-3"
                              variant="contained"
                              color="secondary"
                              onClick={chatModOpen}
                            >
                              Chat
                            </Button>
                          }
                        </>
                        : <></>}
                    </div>
                    <h5 className="col-5">Quantity of:</h5>
                    <h5 className="col-7" style={{ color: "gray" }}>
                      {quantity} {crpto_symbol}
                    </h5>
                    <h5 className="col-5">Offer Amount:</h5>
                    <h5 className="col-7" style={{ color: "gray" }}>
                      {bid_amount}  {symbol}
                    </h5>
                    <h5 className="col-5">Offer Status:</h5>
                    <h5 className="col-7" style={{ color: "gray" }}>
                      {buyer_status == 0 ? 'Unaccepted' : 'Accepted'}
                    </h5>
                    <h3 className="text-center mt-2 mb-3">Ad Detail</h3>
                    <h5 className="col-5">Amount Limit:</h5>
                    <h5 className="col-7" style={{ color: "gray" }}>
                      {minimum_crypto_amount}
                      {crpto_symbol} to {maximum_crypto_amount}
                      {crpto_symbol}
                    </h5>
                    <h5 className="col-5">Location:</h5>
                    <h5 className="col-7 " style={{ color: "gray" }}>
                      {country_name}
                    </h5>
                    <h5 className="col-5">Bank Name:</h5>
                    <h5 className="col-7 " style={{ color: "gray" }}>
                      {bank_name}
                    </h5>
                    <h5 className="col-5">Payment Method:</h5>
                    <h5 className="col-7 " style={{ color: "gray" }}>
                      {payment_method}
                    </h5>
                    <h5 className="col-5">Name:</h5>
                    <h5 className="col-7" style={{ color: "gray" }}>
                      {name} {last_name}
                    </h5>
                    <div>
                      {buyer_status == 0 ?

                        <Button

                          style={{
                            fontSize: 15,
                            marginBottom: "10px",
                            borderRadius: "20px",
                            marginTop: "5px",
                            backgroundColor: 'rgb(119, 112, 173)',
                          }}
                          variant="contained"
                          color="secondary"

                        >
                          Waiting...
                        </Button>

                        :
                        <>
                          {seller_status == 0 ?

                            <Button
                              onClick={OtpModOpen}
                              style={{
                                fontSize: 15,
                                marginBottom: "10px",
                                borderRadius: "20px",
                                marginTop: "5px",
                                backgroundColor: Colors.darkBlue,
                              }}
                              variant="contained"
                              color="secondary"

                            >
                              Accept Offer
                            </Button>


                            :
                            <>
                              {transectionImgStatus ?

                                <div className="d-flex justify-content-between align-items-center">

                                  <Button
                                    onClick={aprovedTransection}
                                    style={{
                                      fontSize: 15,
                                      marginBottom: "10px",
                                      borderRadius: "20px",
                                      marginTop: "5px",
                                      backgroundColor: Colors.darkBlue,
                                    }}
                                    variant="contained"
                                    color="secondary"

                                  >
                                    Approve
                                  </Button>
                                  <button onClick={SeeTranImg} style={{ borderRadius: "20px", height: 38 }} className="btn  btn-outline-info " >See Transection slip</button>

                                </div>
                                :
                                <div className="d-flex justify-content-between align-items-center">
                                  <Button

                                    style={{
                                      fontSize: 15,
                                      marginBottom: "10px",
                                      borderRadius: "20px",
                                      marginTop: "5px",
                                      backgroundColor: 'rgb(119, 112, 173)',
                                    }}
                                    variant="contained"
                                    color="secondary"

                                  >
                                    Waiting...
                                  </Button>

                                  <>
                                    {chatModal ?
                                      <Button

                                        style={{
                                          fontSize: 15,
                                          // marginBottom: "10px",
                                          borderRadius: "20px",


                                          backgroundColor: Colors.darkBlue,

                                        }}
                                        className="col-lg-5"
                                        variant="contained"
                                        color="secondary"
                                        onClick={chatModClose}
                                      >
                                        close
                                      </Button>
                                      :
                                      <Button

                                        style={{
                                          fontSize: 15,
                                          // marginBottom: "10px",
                                          borderRadius: "20px",
                                          // marginTop: "10px",
                                          // height: '5%',
                                          width: '40%',
                                          backgroundColor: Colors.darkBlue,

                                        }}
                                        className="col-lg-5"
                                        variant="contained"
                                        color="secondary"
                                        onClick={chatModOpen}
                                      >
                                        Chat
                                      </Button>
                                    }
                                  </>
                                </div>
                              }
                            </>
                          }

                        </>
                      }
                    </div>

                  </div>
                </div>
                {chatModal ?

                  <div style={{
                    // borderRadius: 20,
                    border: "2px solid rgb(215, 215, 215)", marginLeft: '8%', marginTop: '2%', padding: 10,
                    width: '85%',
                    // paddingBottom: 10
                  }} className="shadow-sm ">
                    <div style={{ maxHeight: 250, paddingBottom: 10 }} className="   overflow-scroll  ">

                      {/* msg */}
                      <div style={{ maxWidth: 800, padding: 5 }} className="border col-lg-8 col-sm-10 rounded d-flex justify-content-between">

                        <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                          {/* {image == null ?
                          <FiCameraOff color="gray" size={20} />
                            : */}
                          <img
                            src={profile}
                            class="img-thumbnail rounded-circle "
                            alt="Profile"
                            style={{ height: "40px", width: "40px" }}
                          />
                          {/* } */}
                        </div>
                        <div style={{ width: 210 }} className="">
                          <h6 style={{ marginTop: 1, color: 'black' }} className="">hassan ali</h6>
                          <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">for testing</p>
                        </div>
                      </div>
                      {/* msg */}
                      {/* msg */}
                      <div className=" d-flex justify-content-end">
                        <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                          <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                            {/* {image == null ?
  <FiCameraOff color="gray" size={20} />
    : */}
                            <img
                              src={profile}
                              class="img-thumbnail rounded-circle "
                              alt="Profile"
                              style={{ height: "40px", width: "40px" }}
                            />
                            {/* } */}
                          </div>
                          <div style={{ width: 210 }} className="">
                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                              testing massage</p>
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-end">
                        <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                          <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                            {/* {image == null ?
  <FiCameraOff color="gray" size={20} />
    : */}
                            <img
                              src={profile}
                              class="img-thumbnail rounded-circle "
                              alt="Profile"
                              style={{ height: "40px", width: "40px" }}
                            />
                            {/* } */}
                          </div>
                          <div style={{ width: 210 }} className="">
                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                              testing massage</p>
                          </div>
                        </div>
                      </div>
                      <div className=" d-flex justify-content-end">
                        <div style={{ maxWidth: 800, padding: 5, alignSelf: 'flex-end' }} className="border col-lg-8 col-sm-10 mt-1 rounded d-flex flex-row-reverse justify-content-between">

                          <div className=" border d-flex align-items-center  shadow-sm rounded-circle  justify-content-center  " style={{ height: "40px", width: "40px" }}>
                            {/* {image == null ?
  <FiCameraOff color="gray" size={20} />
    : */}
                            <img
                              src={profile}
                              class="img-thumbnail rounded-circle "
                              alt="Profile"
                              style={{ height: "40px", width: "40px" }}
                            />
                            {/* } */}
                          </div>
                          <div style={{ width: 210 }} className="">
                            <h6 style={{ marginTop: 1, color: 'black' }} className="text-end">junaid</h6>
                            <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="text-end">
                              testing massage</p>
                          </div>
                        </div>
                      </div>
                      {/* msg */}

                    </div>
                    <div style={{
                      width: "100%",
                      height: "45px",
                      backgroundColor: "rgb(255, 255, 255)",
                      padding: 10,

                      border: "1px solid gray",
                      borderRadius: 50,
                    }} className="d-flex align-items-center">
                      <input placeholder="text" className="form-control border-0 shadow-none " />

                      <FiPaperclip style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                      <FiSend style={{ marginRight: 10 }} size={25} color={Colors.darkBlue} />
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>
          </div>
        </Modal>

        {/* ###########################
      ########## OTP Modal ##########
      ##########################* */}
        <Modal open={otpMod}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h2>Please check Email for verication code</h2>
                <AiOutlineClose
                  onClick={OtpModClose}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">




                  <OtpInput
                    value={OTPValue}
                    onChange={(e) => setOTPValue(e)}
                    numInputs={4}
                    containerStyle={{
                      marginTop: "20px",
                      // marginLeft: "20px",
                      width: "300px",
                    }}
                    isInputNum={true}
                    inputStyle={{
                      height: "60px",
                      width: "60px",
                      marginLeft: "12px",
                    }}
                    separator={<span>{""}</span>}
                  />
                  <div className="col-6">
                    {OTPLoader ? (
                      <div className="d-flex mt-1 justify-content-center align-items-center">
                        <div
                          className="spinner-border text-secondary"
                          role="status"
                        ></div>
                      </div>
                    ) : (
                      <p className="text-center text-danger">{OTPres}</p>
                    )}
                  </div>

                  <button
                    onClick={OTPSend}
                    style={{ width: "90%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                    type="button"
                    className="btn btn-primary  mt-3"
                  >

                    Send
                  </button>

                </div>
              </div>
            </div>
          </div>
        </Modal>


        {/* ###########################
      ########## Transection Img Modal ##########
      ##########################* */}
        <Modal open={transectionImgModal}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header  justify-content-end">

                <AiOutlineClose
                  onClick={() => setTransectionImgModal(false)}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
                <div className=" row justify-content-center">



                  <div className="  " style={{ height: "480px", width: "520px" }}>
                    <img
                      src={transectionImageApif.transection_image}
                      class="img-thumbnail  "
                      alt="Profile"
                      style={{ height: "480px", width: "520px" }}
                    />
                  </div>





                </div>
              </div>
            </div>
          </div>
        </Modal>


        {/* ###########################
      ########## Chat Modal ##########
      ##########################* */}
        {/* <Modal open={chatModal} style={{ maxHeight: 300, marginTop: '20%' }}>
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " style={{ marginLeft: '50vw' }} >
            <div className="modal-content">
              <div className=" d-flex border align-items-center justify-content-between">
                <h4>Chat Box</h4>
                <AiOutlineClose
                  onClick={() => setChatModal(false)}
                  size={20}
                  style={{ marginRight: 15 }}
                />
              </div>
              <div className="modal-body">
              
                <div className="border mt-1">
                  sssssssssssssss

                </div>
                <div className="border align-self-end mt-1">
                  sssssssssssssss

                </div>
            

              </div>
            </div>
          </div>
        </Modal> */}
      </div>
    );
  }
}

export default MySellingBidsDetails;
