import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";


export default function BuyingAds(props) {

  const history = useHistory();
  const { open, close, data, User_symbol, User_Id, percentage, setAlertShow, setAlertMessage } = props;
  console.log(data, "data");

  var item = { url: data.ad_id }
  const [quantity, setQuantity] = useState();

  let a = parseFloat(quantity)

  const [quantityValidation, setQuantityValidation] = useState(true);
  const [bidAmount, setBidAmount] = useState('');
  const [bidAmountValidation, setBidAmountValidation] = useState(true);

  // const [pp, setPp] = useState(0);
  // let ppp
  // if (pp > 100) {
  //   ppp = Math.round(pp)
  //   // setBidAmount(ppp)
  // } else {
  //   ppp = pp.toFixed(2)
  //   // setBidAmount(ppp)

  // }
  // const dd =((parseInt(data.price) - (data.price / 100) * percentage))
  // if (quantity < 0) {

  //   pp = parseFloat(quantity) / (parseInt(data.price) /* - (data.price / 100) * percentage */)
  // } else {

  // let pp
  // pp = parseFloat(quantity) * ((parseInt(data.price) /* - (data.price / 100) * percentage */))

  // }
  // console.log(pp, 'pp>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')


  const BidAmount = async (e) => {


    if (!isNaN(e.target.value)) {
      setBidAmount(e.target.value);


      var cry = parseFloat(e.target.value) / ((parseInt(data.price) /* - (data.price / 100) * percentage */))


      console.log(cry, 'resPrice>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
      if (e.target.value.length > 0) {

        const res = await API().ReversePriceCalculation(
          cry,
          2
        )
        console.log(res, 'resPrice>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        // setPp(res.rate)

        if (data.symbol == 'USDT') {

          setQuantity(Math.round(res.rate))
        } else {
          setQuantity(res.rate)

        }


      } else {
        setQuantity(0)

        // setPp(0)
      }
    }
  }

  const Quentity = async (e) => {

    if (!isNaN(e.target.value)) {
      setQuantity(e.target.value);

      var pri = parseFloat(e.target.value) * ((parseInt(data.price) /* - (data.price / 100) * percentage */))
      if (e.target.value.length > 0) {

        const res = await API().getPrice(
          pri,
          1
        )
        setBidAmount(Math.round(res.rate))
        console.log(res, 'resPrice>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        // setPp(res.rate)

      } else {
        // setPp(0)
        setBidAmount(0)

      }
    }
  }

  const CloseBtn = () => {
    close();
    setBidAmount("");
    setQuantity("");
    // setPp(0)
  };
  const quantValid = function (qn) {
    return function (qn) {
      if (qn.length > 0) {
        return true;
      } else {
        return false;
      }
    };
  };

  const BidFunc = async () => {
    var QnValid = quantValid("qn");
    var QnVal = QnValid(quantity);
    console.log(quantity, ' quantity>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    setQuantityValidation(QnVal);
    // console.log(id, add_id, quantity, bidAmount, 'bidsend');
    try {
      if (quantity > 0 && bidAmount > 0) {

        if (quantity >= data.with_commission_min && quantity <= data.with_commission.toFixed(7)) {

          const response = await API().postBidRequest(
            User_Id,
            data.ad_id,
            quantity,
            bidAmount,
            data.crypto_currency
          );
          setBidAmount("");
          setQuantity("");
          setAlertShow(true)
          setTimeout(() => {
            setAlertShow(false)
            history.push({
              pathname: "/MySellingOffers",
              state: { detail: item },
            })
          }, 3000);
          setAlertMessage(response.message)
          // alert(response.message);
          // console.log(response, "resbid");
          close();
        } else {
          setQuantityValidation(false);
        }
      }
      // else {
      //   if (quantity > 0) {
      //     const response = await API().postBidRequest(
      //       User_Id,
      //       data.ad_id,
      //       quantity,
      //       bidAmount,
      //       data.crypto_currency

      //     );
      //     setBidAmount("");
      //     setQuantity("");
      //     setAlertShow(true)

      //     setTimeout(() => {
      //       setAlertShow(false)

      //       history.push({
      //         pathname: "/MySellingOffers",
      //         state: { detail: item },
      //       })


      //     }, 3000);
      //     // alert(response.message);
      //     setAlertMessage(response.message)
      //     // console.log(response, "resbid");
      //     close();
      //   }
      // }
    } catch (err) {
      console.log(err, "errBidFunc");
    }
  };

  return (
    <Modal open={open} close={close}>

      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >
          <div className="modal-header">
            <h2>Selling Request</h2>
          </div>
          <div className="modal-body">
            <div className="mb-2 col-10 mx-auto ">
              {quantityValidation ? (
                <>
                  <h6>Amount oF {data.symbol}:</h6>
                  <input
                    value={quantity}
                    placeholder="0"
                    onChange={(e) => Quentity(e)}
                    className="form-control "
                  ></input>
                </>
              ) : (
                <>
                  <h6 className="text-danger">Amount oF {data.symbol}:</h6>
                  <input
                    value={quantity}
                    placeholder="0"
                    onChange={(e) => Quentity(e)}
                    className="form-control "
                  ></input>
                </>
              )}

              {quantityValidation ? (
                <>
                  <h6>Amount oF {User_symbol}:</h6>
                  <input
                    value={bidAmount}
                    placeholder='0'
                    onChange={(e) => BidAmount(e)}
                    className="form-control "
                  ></input>
                </>
              ) : (
                <>
                  <h6 className="text-danger">Amount oF {User_symbol}:</h6>
                  <input
                    value={bidAmount}
                    placeholder='0'
                    onChange={(e) => BidAmount(e)}
                    className="form-control "
                  ></input>
                </>
              )}
            </div>
            <div className="row justify-content-center">
              <div className="col-10 row mt-2 rounded shadow" >
                <h5 className="col-6 mt-2">Selling to: </h5>
                <h5 className="col-6 mt-2">{data.first_name}</h5>

                <h5 className="col-6 mt-2">Price</h5>
                <h5 className="col-6 mt-2">
                  {Math.round(data.price)} {User_symbol}/{data.symbol}
                  {/* {parseInt(data.price) - (data.price / 100) * percentage} {User_symbol}/{data.symbol} */}
                </h5>
                <h5 className="col-6 mt-2"> Crypto{/* Amount Limit */}</h5>
                <div className="col-6 mt-2">
                  {/* <h5 style={{ color: "green" }}>
                    {data.minimum_crypto_amount } {data.symbol}{" "}
                  </h5> */}
                  <h5 style={{ color: "red" }}>
                    {data.with_commission.toFixed(7)}
                    {/* {(data.maximum_crypto_amount + (data.maximum_crypto_amount / 100) * percentage).toFixed(7)} {data.symbol} */}
                  </h5>
                </div>
                <h5 className="col-6 mt-2">Minimum Crypto{/* Amount Limit */}</h5>
                <div className="col-6 mt-2">
                  {/* <h5 style={{ color: "green" }}>
                    {data.minimum_crypto_amount } {data.symbol}{" "}
                  </h5> */}
                  <h5 style={{ color: "green" }}>
                    {data.with_commission_min.toFixed(7)}
                    {/* {(data.maximum_crypto_amount + (data.maximum_crypto_amount / 100) * percentage).toFixed(7)} {data.symbol} */}
                  </h5>
                </div>

                <h5 className="col-6 mt-2">Payment Method</h5>
                <h5 className="col-6 mt-2">{data.name}</h5>
                <h5 className="col-6 mt-2">Location</h5>
                <h5 className="col-6 mt-2">{data.country_name} </h5>
              </div>
            </div>
          </div>
          <div className="modal-footer ">
            <button
              type="button"
              class="btn px-lg-4 px-3 rounded-pill border-0 btn-primary"
              onClick={CloseBtn}
              style={{ backgroundColor: Colors.lightBlue, color: "white" }}
            >
              Close
            </button>
            <button
              onClick={BidFunc}
              type="button"
              className="btn px-lg-4 px-3 rounded-pill border-0 btn-primary"
              style={{ backgroundColor: Colors.darkBlue }}
            >
              Send Offer ({data.symbol})
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
