import React, { useState } from 'react'
import * as Color from "../../Constant/Colors";

import NavBar from "../NavBar";
import { MdLock, MdEmail } from 'react-icons/md'
import API from '../../Api';


function InviteFreind(props) {
    const { GetData } = props


    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id } = user_Data.data;


    const [validation, setValidation] = useState({
        EmailValidation: true,

    });
    const [email, setEmail] = useState('');
    const [emailSendLoader, setEmailSendLoader] = useState(false)
    const [emailResendMessage, setEmailResendMessage] = useState('')

    const validateEmail = (e) => /^[^@ ]+@[^@ ]+\.[^@ \.]+$/.test(e);

    const isValidEmail = function (value) {
        if (validateEmail(value)) {
            return true;
        } else {
            return false;
        }
    };

    const InviteFriend = async () => {
        var emailValid = isValidEmail(email);
        setValidation({
            EmailValidation: emailValid
        })

        if (emailValid) {
            setEmailSendLoader(true)
            try {
                const res = await API().postReferUser(
                    id,
                    email
                )
                if (res.status) {
                    GetData()
                    setEmailResendMessage('')
                    setEmail('')

                } else {
                    setEmailResendMessage(res.msg)
                }



                setEmailSendLoader(false)
                console.log(res, 'resPostShareRefferal')
            } catch (err) { console.log(err, 'errsendInviteFreind') }
        }
    }
    return (
        <>
            <NavBar />
            <div className='col-lg-4 mt-5 col-md-8 mx-auto '>
                <h1 className='text-center ' style={{ color: 'white' }} >Invite Friends</h1>
                {/* <p className='text-center'>   You earn whenever you invite friends or we let you make some
                    payment without charging a fee</p> */}



                <h5 className='' style={{ color: Color.lightGreen }}>Invite using email address</h5>
                {validation.EmailValidation ?
                    <div
                        className=" mt-1 d-flex justify-content-around shadow-sm mx-auto "
                        style={{
                            // width: "85%",
                            height: "45px",
                            backgroundColor: "rgb(255, 255, 255)",
                            paddingTop: 10,
                            paddingBottom: 10,
                            border: "1px solid gray",
                            borderRadius: 5,
                        }}
                    >

                        <MdEmail size={25} />
                        <input
                            value={email}
                            className="form-control border-0 shadow-none  "
                            style={{
                                width: "85%",
                                paddingLeft: 5,

                            }}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                    </div>
                    :
                    <div
                        className=" mt-1 d-flex justify-content-around shadow-sm mx-auto "
                        style={{
                            // width: "85%",
                            height: "45px",
                            backgroundColor: "rgb(255, 255, 255)",
                            paddingTop: 10,
                            paddingBottom: 10,
                            border: "1px solid red",
                            borderRadius: 5,
                        }}
                    >

                        <MdEmail size={25} />
                        <input
                            value={email}
                            className="form-control border-0 shadow-none  "
                            style={{
                                width: "85%",
                                paddingLeft: 5,

                            }}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />

                    </div>
                }

                {emailSendLoader ?
                    <div
                        style={{ /*  backgroundImage: `url(${backgrounimage})` */ }}
                        className="d-flex mt-2 justify-content-center align-items-center"
                    >
                        <div className="spinner-border " style={{}} role="status"></div>
                    </div>
                    :
                    <p className='text-center mt-3' style={{ color: 'white', }} >{emailResendMessage}</p>
                }
                <div className=' d-flex justify-content-center'>

                    <button
                        onClick={InviteFriend}
                        style={{
                            width: "100%",

                            marginBottom: 3,
                            color: 'white',
                            backgroundColor: Color.darkBlue


                        }}
                        type="button"
                        className="btn  shadow-none py-2 px-4  "
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    )
}

export default InviteFreind