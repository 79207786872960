import React, { useRef, useState } from 'react'
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import * as Colors from '../../Constant/Colors'
import { RiCameraSwitchLine } from "react-icons/ri";
import API from '../../Api';


function BankDetailsMod(props) {
    const { bankDetaiMod, bidDetails, setBankDetaiModClose, bankDetailApi, bidUserId, userId, addId, setData, setAlertShow, setAlertMessage } = props
    // console.log(props, 'ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp');



    const { user_name, bank_name, account_no } = bankDetailApi.Details
    const { quantity, bid_amount, bid_id } = bidDetails
    const [values, setValues] = useState({
        image: null,
        name: null,
    });
    const [errors, setErrors] = useState("");
    const [chekImgStatus, setChekImgStatus] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [profileImage, setProfileImage] = useState({
        image: null,
        name: null,
    });
    const ImageRef = useRef();
    const fileOpen = () => {
        const trigger = ImageRef.current;
        trigger.click();
    };

    const handleChangeImage = (e) => {
        const { value, files } = e.target;
        let ddddd = value.split("\\");
        ddddd = ddddd[ddddd.length - 1];

        const file = files[0];
        let ext = value.split(".");

        ext = ext[ext.length - 1];
        if (
            ext === "jpg" ||
            ext === "JPG" ||
            ext === "jpeg" ||
            ext === "bmp" ||
            ext === "png"
        ) {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: { error: false, text: "" },
                };
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                const FileasDataUrl = e.target.result;

                const base64 = FileasDataUrl.split(",");

                const base64Encoded = base64[base64.length - 1];
                setProfileImage({ name: ddddd, image: base64Encoded });

                setValues({ image: FileasDataUrl, name: ddddd });
                // setValues((prev) => {
                //   return {
                //     ...prev,
                //     image: [
                //       {
                //         name: value,
                //         base64: base64Encoded,
                //       },
                //     ],
                //   };
                // });
            };
            reader.readAsDataURL(file);
        } else {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: {
                        error: true,
                        text: "Only jpg, jpeg, bmp and png are allowed",
                    },
                };
            });
        }
    };

    const closeMod = () => {
        setBankDetaiModClose(false)
        setChekImgStatus('')
        setValues({
            image: null,
            name: null,
        })
        setProfileImage({
            image: null,
            name: null,
        });
        setData(0)
    }


    const imageUploade = async () => {
        // console.log(bid_id, 'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb');
        try {
            if (profileImage.image != null) {
                setImageLoader(true)
                const res = await API().postTransectionImageUplode(
                    bidUserId,
                    userId,
                    addId,
                    profileImage.image,
                    profileImage.name,
                    bid_amount,
                    quantity,
                    bid_id
                )
                if (res.status) {
                    setImageLoader(false)
                    closeMod()

                    setAlertShow(true)

                    setTimeout(() => {
                        setAlertShow(false)
                        // history.goBack()
                    }, 3000);
                    setAlertMessage('Uploaded')
                }
            } else {
                setChekImgStatus('Upload Image !')
            }
        } catch (err) { console.log(err, 'errTansSlipUplode') }

    }


    return (
        <div>
            <Modal open={bankDetaiMod}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Seller Bank Details</h2>
                            <AiOutlineClose
                                onClick={() => closeMod()}
                                size={20}
                                style={{ marginRight: 15 }}
                            />
                        </div>
                        <div className="modal-body">
                            <div className=" row justify-content-center  ">
                                <div className=" col-11 border rounded shadow-sm p-2">
                                    <div className="d-flex align-items-center ">
                                        <h5 className=" col-5" >Name:</h5>
                                        <h6 className=" col-5" style={{ color: 'gray' }}>{user_name}</h6>

                                    </div>
                                    <div className="d-flex align-items-center ">
                                        <h5 className=" col-5" >Bank Name:</h5>
                                        <h6 className=" col-5" style={{ color: 'gray' }}>{bank_name}</h6>

                                    </div>
                                    <div className="d-flex align-items-center ">
                                        <h5 className=" col-5" >Bank Account No:</h5>
                                        <h6 className=" col-5" style={{ color: 'gray' }}>{account_no}</h6>

                                    </div>
                                </div>


                                <div className=" mt-3  ">
                                    <h4 className="" style={{ marginLeft: 10 }} >Upload s Bank Transfer Slip:
                                    </h4>
                                    <input
                                        ref={ImageRef}
                                        onChange={(e) => handleChangeImage(e)}
                                        className="d-none"
                                        type="file"
                                    />
                                    {values.image === null ? (
                                        <>

                                            <div
                                                onClick={fileOpen}
                                                style={{ height: 250, width: 350 }}
                                                className="img-thumbnail d-flex justify-content-center shadow-sm mx-auto"
                                            >
                                                <RiCameraSwitchLine
                                                    className="my-auto"
                                                    size={40}
                                                    color="gray"
                                                />
                                            </div>
                                            <p className="text-center mt-2 text-danger">
                                                {chekImgStatus}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <div className=" row justify-content-center">
                                                <img
                                                    onClick={fileOpen}
                                                    src={values.image}
                                                    style={{ height: 250, width: 400, }}
                                                    className="img-thumbnail "
                                                    alt="Profile"
                                                />
                                            </div>
                                            <div className=" text-center">
                                                {imageLoader ? <div class="spinner-border text-secondary  " role="status"></div> : <></>}
                                            </div>
                                        </>
                                    )}

                                </div>
                                <button
                                    onClick={imageUploade}
                                    style={{ width: "93%", fontSize: 20, backgroundColor: Colors.darkBlue }}
                                    type="button"
                                    className="btn btn-primary border-0  mt-3"
                                >

                                    Send
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default BankDetailsMod
