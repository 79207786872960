import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { GoPlus } from "react-icons/go";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import * as Colors from "../../Constant/Colors";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",

        // alignItems: "center",
        // justifyContent: "center",
    },
    paper: {
        width: "100%",
        padding: 10,
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));


function InvitesList(props) {

    const { isloading, invitList } = props

    const classes = useStyles();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");




    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const [data, setData] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };







    if (isloading) {
        return (
            <div style={{ backgroundColor: Colors.Bgcolor, height: "30vh", backgroundColor: Colors.bodyCol, /* backgroundImage: `url(${backgrounimage})` */ }}>

                <div
                    style={{ height: "30vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div class="spinner-border " style={{ color: Colors.lightBlue }} role="status"></div>
                </div>
            </div>
        );
    } else {

        return (

            <div className='container mt-3'>
                <Paper className={classes.paper}>

                    <div className=" text-center">
                        <h4>Invites </h4>
                    </div>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            aria-label="enhanced table"
                        >
                            <TableRow hover>
                                {/* <TableCell align="center">#</TableCell> */}

                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Invitation Code</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Invite at</TableCell>
                                {/* <TableCell align="center">Sell Now</TableCell> */}
                            </TableRow>
                            <TableBody>
                                {stableSort(invitList, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        console.log(row, 'llllllllllkkkkkkkkkkkkkkjjjjjjjjjjjjjjjj')


                                        return (


                                            <TableRow hover key={row.ad_id}>


                                                <TableCell
                                                    align="center"
                                                >
                                                    {row.email}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.refer_code}
                                                </TableCell>
                                                <TableCell align="center">{row.refer_status}</TableCell>
                                                <TableCell align="center">{row.updated_at}</TableCell>

                                            </TableRow>

                                        );

                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[4, 10, 25]}
                        component="div"
                        count={invitList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>

        )
    }
}

export default InvitesList