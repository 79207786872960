import React, { useRef, useState, useEffect } from "react";
import { LineChart, LineChartProps } from "@opd/g2plot-react";
import API from "../../Api";
import NavBar from "../NavBar";
import * as Colors from "../../Constant/Colors";
function IndexTransfer() {
  const [apiChipValue, setApiChipValue] = useState([]);
  const [isloding, setIsloding] = useState(true);
  const [selectedChipValue, setSelectedChipValue] = useState([]);
  const [apiGraphPrice, setApiGraphPrice] = useState([1]);
  const [apiGraphDate, setApiGraphDate] = useState([]);
  // console.log(selectedChipValue, "selected");

  const getdata = async (item) => {
    // console.log('sssss')
    // console.log(item.symbol, "itemmmmmmmmmm");
    setIsloding(true);
    try {
      // console.log(selectedChipValue.symbol, "selected");
      const resGraphdata = await API().getGraphdata(item.symbol);
      const storeGraph = resGraphdata;
      // console.log(resGraphdata, "resGraph");
      // for (const property in storeGraph.rates) {
      //   console.log(`${property}: ${storeGraph.rates}`);
      // }
      // setApiGraphValue(storeGraph);
      // setApiGraphDate(Object.keys(storeGraph.rates));
      let graphdate = Object.keys(storeGraph.rates);
      const graphPrice = Object.values(storeGraph.rates);
      let arrGraphPrice = await graphPrice.map((item) => {
        const ItemKey = Object.keys(item);
        // console.log(ItemKey[0], "gppp");
        return item[ItemKey];
      });

      // console.log(graphdate, "arrrgradate");
      // console.log(arrGraphPrice, "arrrgra");

      setApiGraphDate(graphdate);
      setApiGraphPrice(arrGraphPrice);

      setIsloding(false);
    } catch (error) {
      console.log(error);
    }
    setIsloding(false);
  };
  // console.log(apiGraphDate, "apiiiiigraphdate");
  // console.log(apiGraphPrice, "apiiiiigraphvale");

  const GetChipValue = async () => {
    try {
      const response = await API().getcrypto();
      let arr = response.map((item, i) => {

        if (i === 0) {
          item.isSelected = true;
          setSelectedChipValue(item);
          getdata(item);
        } else {
          item.isSelected = false;
        }
        return item;
      });
      // console.log(arr, "arr");
      setApiChipValue(arr);
    } catch (err) {
      alert(err, "chip");
    }
  };

  const selectionHandler = (id) => {
    const arr = apiChipValue.map((item) => {
      item.isSelected = false;
      if (item.id === id) {
        item.isSelected = true;
        setSelectedChipValue(item);
        getdata(item);
      }
      return item;
    });

    setApiChipValue(arr);

    // console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;');
  };

  useEffect(() => {
    // getdata();
    GetChipValue();
  }, []);
  const config: LineChartProps = {
    height: 400,

    autoFit: true,
    xField: "year",
    yField: "value",
    meta: {
      value: {
        max: 10,
      },
    },
    data: [
      { year: apiGraphDate[0], value: apiGraphPrice[0] },
      { year: apiGraphDate[1], value: apiGraphPrice[1] },
      { year: apiGraphDate[2], value: apiGraphPrice[2] },
      { year: apiGraphDate[3], value: apiGraphPrice[3] },
      { year: apiGraphDate[4], value: apiGraphPrice[4] },
      { year: apiGraphDate[5], value: apiGraphPrice[5] },
      { year: apiGraphDate[6], value: apiGraphPrice[6] },
      { year: apiGraphDate[7], value: apiGraphPrice[7] },
      { year: apiGraphDate[8], value: apiGraphPrice[8] },
      { year: apiGraphDate[9], value: apiGraphPrice[9] },
    ],
  };
  const chartRef = useRef();

  if (isloding) {
    return (
      <div style={{ backgroundColor: Colors.Bgcolor, paddingBottom: 10, height: "100vh" }} >
        <NavBar />
        <div
          style={{ height: "80vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ backgroundColor: Colors.Bgcolor, paddingBottom: 10 }}>
        <NavBar />

        <div className="p-5 mb-4" style={{ overflow: "auto", backgroundColor: Colors.Bgcolor, paddingBottom: 10 }}>
          <div className="d-lg-flex mt-4 justify-content-between">
            <div className="col-10 col-lg-6  ">
              <div className="col-sm-8 col-lg-12 ">
                {apiChipValue.map((item) => {
                  // console.log(item, "item");
                  return (
                    <>
                      {item.isSelected === true ? (
                        <button
                          onClick={() => selectionHandler(item.id)}
                          key={item.id}
                          style={{
                            borderRadius: 20,
                            width: 80,
                            marginTop: "5px",
                            marginLeft: 10, backgroundColor: Colors.darkBlue,
                          }}
                          type="button"
                          class="btn btn-outline-primary active "
                        >
                          {item.symbol}
                        </button>
                      ) : (
                        <button
                          onClick={() => selectionHandler(item.id)}
                          key={item.id}
                          style={{
                            borderRadius: 20,
                            width: 80,
                            marginTop: "5px",
                            marginLeft: 10,
                          }}
                          type="button"
                          class="btn btn-outline-primary"
                        >
                          {item.symbol}
                        </button>
                      )}
                    </>
                  );
                })}
              </div>
              <div
                style={{ marginLeft: 15 }}
                className=" col-sm-8 mt-4 d-flex justify-sm-content-between"
              >
                <div className="col-sm-12">
                  <h4 style={{ color: Colors.lightGreen }}>Bitcoin Price</h4>
                  <h5 style={{ color: Colors.darkBlue }}>$2259.32</h5>
                </div>
                <div className="col-sm-12">
                  <h4 style={{ color: Colors.lightGreen }}>Since Last Week</h4>
                  <h5 style={{ color: Colors.darkBlue }}>$2259.32</h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-12  mt-3"
              style={{ marginLeft: "20px" }}
            >
              <h1 style={{ color: Colors.darkBlue }} >BTC Balance</h1>
              <h5 style={{ color: Colors.lightGreen }}>.937374646464</h5>
              <h4 style={{ color: Colors.darkBlue }}>$833746473929</h4>
            </div>
          </div>
          <div>
            <LineChart {...config} chartRef={chartRef} />
          </div>
        </div>
      </div>
    );
  }
}

export default IndexTransfer;
