import { useState, useMemo, useEffect } from "react";
import "./App.css";
import { UserContext, NitiContext, ToseContext } from "./reducer/Context";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";


import Navigation from "../src/Screems/Navigation";

export default function App() {

  // const user_Data = JSON.parse(localStorage.getItem("userData"));
  // const { id, country, symbol } = user_Data.data;
  const [new_Data, setNew_Data] = useState({ "data": null });
  const [notification, setNotification] = useState([]);

  const [toseMassege, setToseMassege] = useState([]);

  // const [notificationKey, setNotificationKey] = useState(null);
  // const value = useMemo(() => {(new_Data, setNew_Data)}, [new_Data, setNew_Data]);

  const value = useMemo(
    () => ({ new_Data, setNew_Data }),
    [new_Data, setNew_Data],
  );
  const notiValue = useMemo(
    () => ({ notification, setNotification }),
    [notification, setNotification],
  );

  const toseValue = useMemo(
    () => ({ toseMassege, setToseMassege }),
    [toseMassege, setToseMassege],
  );

  useEffect(() => {

    // production
    if (process.env.NODE_ENV === 'production') {
      console.log = function () { };
      document.addEventListener('contextmenu', event => event.preventDefault());




      document.onkeydown = function (e) {

        // if (e.keyCode == 16) {
        //   return false;
        // }

        if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
          return false;
        }

      }
    }


  }, [])



  return (
    <UserContext.Provider value={value}>
      <NitiContext.Provider value={notiValue}>
        <ToseContext.Provider value={toseValue}>


          <div>

            <Navigation />

          </div>
        </ToseContext.Provider>
      </NitiContext.Provider>
    </UserContext.Provider>
  );
}
