
import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAWNfN5KxRqCrRCJy5px_MNksnWk02RGAg",
    authDomain: "foloswap.firebaseapp.com",
    databaseURL: "https://foloswap-default-rtdb.firebaseio.com",
    projectId: "foloswap",
    storageBucket: "foloswap.appspot.com",
    messagingSenderId: "221336493833",
    appId: "1:221336493833:web:04dd649b631c4a6d4fbdd8",
    measurementId: "G-0H1CH0Z89D"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
