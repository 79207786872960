import React, { useState, useEffect } from 'react'
import * as Colors from '../../Constant/Colors'
import InviteFreind from './InviteFreind';
import InvitesList from './InvitesList';

import API from "../../Api";


function IndexInvits() {
    const user_Data = JSON.parse(localStorage.getItem("userData"));
    const { id, user_verifyed, country } = user_Data.data;

    const [isloading, setIsloading] = useState(true);
    const [invitList, setInvitList] = useState([]);


    const GetData = async () => {
        try {
            const arry = []
            const res = await API().getMyRefers(
                id
            )
            {
                res.refer_list.map((item) => {
                    console.log(item, 'iiiiiiiiiiiiiiiiiiiiii')

                    const date = new Date(item.updated_at)

                    const updated_at = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`

                    const data = {
                        id: item.id,
                        updated_at: updated_at,
                        email: item.email,
                        refer_code: item.refer_code,
                        refer_status: item.refer_status
                        // status:item.status,
                        // sender_id:item.sender_id
                    }
                    arry.push(data)
                    // console.log(created_at,'iiiDateiiiiiiiiiiiiiiiiiii')
                })
            }



            setInvitList(arry)
            setIsloading(false)
            // console.log(res.refer_list, 'resgetMyRefers>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
        } catch (err) {
            console.log(err, 'errgetMyRefers>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

        }
    }
    useEffect(() => {

        GetData()

    }, [])


    return (
        <div style={{ backgroundColor: Colors.Bgcolor, height: "100vh", backgroundColor: Colors.bodyCol }}>
            <InviteFreind GetData={GetData} />
            <InvitesList isloading={isloading} invitList={invitList} />

        </div>
    )
}


export default IndexInvits