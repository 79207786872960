import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import API from "../../Api";
import * as Colors from "../../Constant/Colors";
import { useHistory } from "react-router-dom";

import { AiOutlineCheck } from "react-icons/ai";


export default function OfferBid(props) {

  const history = useHistory();
  const { id, open, close, adDetails, bidUserWalletResceive, bidUserWalletSend,
    setAlertShow, setAlertMessage } = props;

  console.log(bidUserWalletSend, 'adDetails')
  console.log(bidUserWalletResceive, 'adDetails')


  const [walletAddress, setWalletAddress] = useState('');

  const [chek, setChek] = useState(0);
  const [resMessage, setResMessage] = useState('');
  const [btnLoader, setBtnLoader] = useState(false);

  const [validation, setValidation] = useState({

    WalletAddress: true,

  });

  // setWalletAddress(bidUserWalletResceive.Address)
  const CheckOrNot = () => {
    if (!chek) {
      setChek(1)
    } else {
      setChek(0)
      setWalletAddress('')

    }
  }

  const WalletAddressValid = function (fm) {
    return function (fm) {
      if (fm.length > 2) {
        return true;
      } else {
        return false;
      }
    };
  };

  console.log(
    bidUserWalletResceive, ',,.......................................bbbbbbbbbbbbbbbbbbb,')
  const SendBid = async () => {

    var walletAddressValid = WalletAddressValid("ad");
    var dataWalletAddressValid = walletAddressValid(walletAddress);
    console.log(id,
      adDetails.swap_id,
      adDetails.recive_crypto,
      adDetails.sell_crypto,
      bidUserWalletSend.wallet_id,
      bidUserWalletResceive.Address,
      bidUserWalletSend.crpto_id, 'bidUserWalletSend.wallet_idbidUserWalletResceive.wallet_id,')
    setValidation({
      WalletAddress: dataWalletAddressValid
    })
    // if (dataWalletAddressValid) {
    setBtnLoader(true)
    try {
      const res = await API().postSwapBidRequest(
        id,
        adDetails.swap_id,
        adDetails.recive_crypto,
        adDetails.sell_crypto,
        bidUserWalletSend.wallet_id,
        bidUserWalletResceive.Address,
        bidUserWalletSend.crpto_id,
      )
      close()
      setAlertShow(true)
      setTimeout(() => {
        setAlertShow(false)

      }, 3000);
      setAlertMessage(res.message)
      console.log(res, 'resSwapBidRequest')
      setBtnLoader(false)
    } catch (error) {
      console.log(error, 'errorPostSwapBidRequest')
    }
    // }

  }





  return (
    <Modal open={open} close={close}>

      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >
          <div className="modal-header">
            <h2>Swap Request</h2>
          </div>
          <div className="modal-body">

            <div className="row justify-content-center">

              {/* <div className="mt-3">
                <div className="d-flex ">

                  <h6 style={{ marginLeft: 25 }}> FoloSwap {adDetails.send_crypto_symbol} Wallet Address:</h6>
                  {!chek ?
                    <div style={{ marginLeft: 5, height: 22, width: 22, border: '1px solid rgb(187, 187, 187)' }} className="d-flex justify-content-center align-items-center rounded" onClick={CheckOrNot}
                    >
                      <AiOutlineCheck size={20} color='rgb(187, 187, 187)' />
                    </div>
                    :
                    <div style={{ marginLeft: 5, height: 22, width: 22, border: '1px solid green' }} className="d-flex justify-content-center align-items-center rounded" onClick={CheckOrNot}
                    >
                      <AiOutlineCheck size={20} color='green' />
                    </div>
                  }
                </div>
                {validation.WalletAddress ?
                  <div
                    className="border d-flex mx-auto justify-content-between rounded shadow-sm"
                    style={{
                      backgroundColor: "white",
                      padding: 0,
                      paddingLeft: 10,
                      height: 42, width: '88%',
                    }}
                  >
                    <input
                      value={walletAddress}
                      className="form-control border-0 shadow-none  "
                      style={{
                        width: "100%",
                        paddingLeft: 20,
                      }}

                      onChange={(e) => setWalletAddress(e.target.value)}
                      placeholder="Wallet Address"
                    />

                  </div>
                  :
                  <div
                    className="border mx-auto border-danger d-flex  justify-content-between rounded shadow-sm"
                    style={{
                      backgroundColor: "white",
                      padding: 0,
                      paddingLeft: 10,
                      height: 42, width: '88%',
                    }}
                  >
                    <input
                      value={walletAddress}
                      className="form-control border-0 shadow-none  "
                      style={{
                        width: "100%",
                        paddingLeft: 20,
                      }}

                      onChange={(e) => setWalletAddress(e.target.value)}
                      placeholder="Please Enter Wallet Address"
                    />

                  </div>
                }
              </div> */}


              <div className="col-10 row mt-3 rounded shadow" >
                <h5 className="col-6 mt-2">Ad by: </h5>
                <h5 className="col-6 mt-2">{adDetails.name}</h5>

                <h5 className="col-6 mt-2">You will recieve:</h5>
                <h5 className="col-6 mt-2">
                  {adDetails.sell_crypto} {adDetails.send_crypto_symbol}

                </h5>
                <h5 className="col-6 mt-2"> You will send:</h5>
                <div className="col-6 mt-2">

                  <h5 style={{ color: "red" }}>
                    {adDetails.recive_crypto}  {adDetails.recive_crypto_symbol}

                  </h5>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer ">
            <button
              type="button"
              class="btn px-lg-4 px-3 rounded-pill border-0 btn-primary"
              onClick={close}
              style={{ backgroundColor: Colors.lightBlue, color: "white" }}
            >
              Close
            </button>
            <button
              onClick={SendBid}
              type="button"
              className="btn px-lg-4 px-3 rounded-pill border-0 btn-primary"
              style={{ backgroundColor: Colors.darkBlue }}
            >
              {btnLoader ? <div style={{ width: 20, height: 20, marginRight: 40, marginLeft: 40, marginTop: 5 }} className="spinner-border text-secondary" role="status"></div> :
                'Send Offer'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
